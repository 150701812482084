/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpsertExtendedProfileMutationVariables = Types.Exact<{
  interests?: Types.Maybe<Types.Scalars["String"]>;
}>;

export type UpsertExtendedProfileMutation = {
  upsertExtendedProfile: Pick<Types.Claim, "user_id"> & {
    fields_HACKTHENORTH2024_EXTENDED_PROFILES: {
      answers: { interests: Pick<Types.StringFieldAnswerTypeWrapper, "value"> };
    };
  };
};

export const UpsertExtendedProfileDocument = gql`
  mutation UpsertExtendedProfile($interests: String) {
    upsertExtendedProfile(interests: $interests) {
      user_id
      fields_HACKTHENORTH2024_EXTENDED_PROFILES {
        answers {
          interests {
            value
          }
        }
      }
    }
  }
`;

/**
 * __useUpsertExtendedProfileMutation__
 *
 * To run a mutation, you first call `useUpsertExtendedProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExtendedProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExtendedProfileMutation, { data, loading, error }] = useUpsertExtendedProfileMutation({
 *   variables: {
 *      interests: // value for 'interests'
 *   },
 * });
 */
export function useUpsertExtendedProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertExtendedProfileMutation,
    UpsertExtendedProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpsertExtendedProfileMutation,
    UpsertExtendedProfileMutationVariables
  >(UpsertExtendedProfileDocument, baseOptions);
}
export type UpsertExtendedProfileMutationHookResult = ReturnType<
  typeof useUpsertExtendedProfileMutation
>;
export type UpsertExtendedProfileMutationResult =
  ApolloReactCommon.MutationResult<UpsertExtendedProfileMutation>;
export type UpsertExtendedProfileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpsertExtendedProfileMutation,
    UpsertExtendedProfileMutationVariables
  >;
