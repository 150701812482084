import { IS_PRODUCTION } from "../utils/env";

export const CURRENT_EVENT_SLUG = "hackthenorth2024";

export const EVENT_ID = IS_PRODUCTION ? 1928 : 1929;

export const APPLICATIONS_SLUG = "hackthenorth2024-applications";

export const MENTORSHIP_REQUESTS_SLUG = "hackthenorth2024-mentorship-requests";

export const MENTORSHIP_PROFILES_SLUG = "hackthenorth2024-mentorship-profiles";
