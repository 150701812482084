/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type RegisterSelfToBusMutationVariables = Types.Exact<{
  busStopId: Types.Scalars["Float"];
}>;

export type RegisterSelfToBusMutation = {
  registerSelfToBus: Pick<Types.BusAttendee, "bus_id">;
};

export const RegisterSelfToBusDocument = gql`
  mutation RegisterSelfToBus($busStopId: Float!) {
    registerSelfToBus(busStopId: $busStopId) {
      bus_id
    }
  }
`;

/**
 * __useRegisterSelfToBusMutation__
 *
 * To run a mutation, you first call `useRegisterSelfToBusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSelfToBusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSelfToBusMutation, { data, loading, error }] = useRegisterSelfToBusMutation({
 *   variables: {
 *      busStopId: // value for 'busStopId'
 *   },
 * });
 */
export function useRegisterSelfToBusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterSelfToBusMutation,
    RegisterSelfToBusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterSelfToBusMutation,
    RegisterSelfToBusMutationVariables
  >(RegisterSelfToBusDocument, baseOptions);
}
export type RegisterSelfToBusMutationHookResult = ReturnType<
  typeof useRegisterSelfToBusMutation
>;
export type RegisterSelfToBusMutationResult =
  ApolloReactCommon.MutationResult<RegisterSelfToBusMutation>;
export type RegisterSelfToBusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RegisterSelfToBusMutation,
    RegisterSelfToBusMutationVariables
  >;
