import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import {
  RBCImg,
  CohereImg,
  ShopifyImg,
  HRTImg,
  IntactImg,
  ConvexImg,
  GroqImg,
  DatabricksImg,
  SunLifeImg,
  CapitalOneImg,
  SiemensImg,
} from "src/static/img";

export const ACCEPTED_FIELDS = [
  Field.RECRUITMENT_CONSENT,
  Field.CANADIAN_CITIZEN,
  Field.PERMANENT_RESIDENT,
  Field.LOOKING_FOR_INTERNSHIPS,
  Field.LOOKING_FOR_FULLTIME,
  Field.RESUME,
  Field.SPONSOR_INTEREST,
  Field.COFFEE_CHAT,
  Field.CAREER_RSVP_STAGE,
  Field.EVENT_RSVP_STAGE,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> =
  {}; // no validation needed - they can leave all fields on this page empty if they want

export const GOLD_SPONSORS: { id: string; logo: string | null }[] = [
  { id: "RBC", logo: RBCImg },
  { id: "Cohere", logo: CohereImg },
  { id: "Shopify", logo: ShopifyImg },
];

const SILVER_SPONSORS: { id: string; logo: string | null }[] = [
  { id: "Hudson River Trading", logo: HRTImg },
  { id: "Capital One", logo: CapitalOneImg },
  { id: "Databricks", logo: DatabricksImg },
  { id: "Sun Life", logo: SunLifeImg },
  { id: "Intact", logo: IntactImg },
  { id: "Convex", logo: ConvexImg },
  { id: "Siemens", logo: SiemensImg },
  { id: "Groq", logo: GroqImg },
];

export const COMPANY_INTEREST_OPTIONS = GOLD_SPONSORS.concat(SILVER_SPONSORS);

export const COFFEE_CHAT_OPTIONS = GOLD_SPONSORS;
