/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetOpenMentorshipRequestsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
}>;

export type GetOpenMentorshipRequestsQuery = {
  claims: Array<
    Pick<Types.Claim, "id" | "name" | "created_at"> & {
      stage: Pick<Types.Stage, "slug">;
      fields: Array<
        Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
          answer?: Types.Maybe<
            Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
          >;
        }
      >;
    }
  >;
};

export const GetOpenMentorshipRequestsDocument = gql`
  query GetOpenMentorshipRequests($slug: String!) {
    claims(
      where: {
        pipeline: { is: { slug: { equals: $slug } } }
        stage: { is: { slug: { equals: "open" } } }
      }
    ) {
      id
      name
      stage {
        slug
      }
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
      created_at
    }
  }
`;

/**
 * __useGetOpenMentorshipRequestsQuery__
 *
 * To run a query within a React component, call `useGetOpenMentorshipRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOpenMentorshipRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOpenMentorshipRequestsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetOpenMentorshipRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetOpenMentorshipRequestsQuery,
    GetOpenMentorshipRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetOpenMentorshipRequestsQuery,
    GetOpenMentorshipRequestsQueryVariables
  >(GetOpenMentorshipRequestsDocument, baseOptions);
}
export function useGetOpenMentorshipRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetOpenMentorshipRequestsQuery,
    GetOpenMentorshipRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetOpenMentorshipRequestsQuery,
    GetOpenMentorshipRequestsQueryVariables
  >(GetOpenMentorshipRequestsDocument, baseOptions);
}
export type GetOpenMentorshipRequestsQueryHookResult = ReturnType<
  typeof useGetOpenMentorshipRequestsQuery
>;
export type GetOpenMentorshipRequestsLazyQueryHookResult = ReturnType<
  typeof useGetOpenMentorshipRequestsLazyQuery
>;
export type GetOpenMentorshipRequestsQueryResult =
  ApolloReactCommon.QueryResult<
    GetOpenMentorshipRequestsQuery,
    GetOpenMentorshipRequestsQueryVariables
  >;
