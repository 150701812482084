/* eslint-disable @typescript-eslint/camelcase */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useUpdateClaimMutation } from "src/api/claims/updateClaim.generated";
import { MENTORSHIP_REQUESTS_SLUG } from "src/shared/constants/event";
import { useUserContext } from "src/shared/contexts";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import {
  MentorshipRequest,
  MentorshipRequestInput,
} from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer, parseState } from "src/shared/utils/hackerapi";

import { useGetMentorshipRequestsLazyQuery } from "./graphql/getMentorshipRequests.generated";
import { useSubmitMentorshipRequestMutation } from "./graphql/submitMentorshipRequest.generated";

interface MentorshipRequestContextState {
  requests: MentorshipRequest[];
  isLoading: boolean;
  submitRequest: (data: MentorshipRequestInput) => Promise<unknown>;
  updateRequest: (data: MentorshipRequest) => Promise<unknown>;
  loadError?: string;
  updateError?: string;
  submitError?: string;
}

const DEFAULT_STATE: MentorshipRequestContextState = {
  requests: [],
  isLoading: false,
  submitRequest: () =>
    Promise.reject("No parent MentorshipRequestContextProvider found"),
  updateRequest: () =>
    Promise.reject("No parent MentorshipRequestContextProvider found"),
};

const MentorshipRequestContext: React.Context<MentorshipRequestContextState> =
  createContext(DEFAULT_STATE);

MentorshipRequestContext.displayName = "MentorshipRequestContext";

export const useMentorshipRequestContext = () =>
  useContext(MentorshipRequestContext);

export const MentorshipRequestContextProvider: React.FC = ({ children }) => {
  const { id, info } = useUserContext();

  const [getMentorshipRequestsQuery, { data, loading, error: loadError }] =
    useGetMentorshipRequestsLazyQuery();
  const [
    submitMentorshipRequestMutation,
    { loading: submitLoading, error: submitError },
  ] = useSubmitMentorshipRequestMutation();
  const [updateClaimMutation, { loading: updateLoading, error: updateError }] =
    useUpdateClaimMutation();

  useEffect(() => {
    if (id) {
      getMentorshipRequestsQuery({
        variables: { slug: MENTORSHIP_REQUESTS_SLUG, menteeId: id.toString() },
      });
    }
  }, [id, getMentorshipRequestsQuery]);

  const requests: MentorshipRequest[] = useMemo(
    () =>
      data?.claims.map((request) => ({
        id: request.id,
        title: getFieldAnswer(request.fields, "title"),
        description: getFieldAnswer(request.fields, "description"),
        category: getFieldAnswer(request.fields, "category"),
        priority: getFieldAnswer(request.fields, "priority"),
        created_at: request.created_at,
        reopened: getFieldAnswer(request.fields, "reopened"),
        mentee_name: getFieldAnswer(request.fields, "mentee_name"),
        mentee_id: getFieldAnswer(request.fields, "mentee_id"),
        mentor_id: getFieldAnswer(request.fields, "mentor_id"),
        stage_slug: request.stage.slug,
      })) ?? [],
    [data]
  );

  const submitRequest: MentorshipRequestContextState["submitRequest"] =
    useCallback(
      (data: MentorshipRequestInput) => {
        if (id && info) {
          const parsedData = parseState({
            ...data,
            mentee_id: id,
            mentee_name: info.name,
            reopened: false,
            location: "",
          });

          return submitMentorshipRequestMutation({
            variables: {
              requestData: {
                name: data.title ?? "Mentorship Request",
                pipeline_slug: MENTORSHIP_REQUESTS_SLUG,
                stage_slug: MentorshipRequestStage.OPEN,
                answers: parsedData,
              },
            },
          });
        }
        return Promise.resolve();
      },
      [submitMentorshipRequestMutation, id, info]
    );

  const updateRequest: MentorshipRequestContextState["updateRequest"] =
    useCallback(
      (data: MentorshipRequest) => {
        const {
          id,
          title,
          description,
          category,
          priority,
          stage_slug,
          mentor_id,
        } = data;
        const answers = parseState({
          title,
          description,
          category,
          priority,
          mentor_id,
        });
        return updateClaimMutation({
          variables: {
            updatedData: {
              id,
              stage_slug,
              answers,
            },
          },
        });
      },
      [updateClaimMutation]
    );

  const state: MentorshipRequestContextState = {
    requests,
    isLoading: loading || submitLoading || updateLoading,
    submitRequest,
    updateRequest,
    loadError: loadError?.message,
    submitError: submitError?.message,
    updateError: updateError?.message,
  };

  return (
    <MentorshipRequestContext.Provider value={state}>
      {children}
    </MentorshipRequestContext.Provider>
  );
};

MentorshipRequestContextProvider.displayName =
  "MentorshipRequestContextProvider";
