/**
 * TODO: MAKE THIS INTO AN IndividualsContext or something so it can fetch information about any role
 */
/* eslint-disable @typescript-eslint/camelcase */
import { useEffect, useState } from "react";

import {
  FileFieldAnswerType,
  Maybe,
  SponsorCompanyTier,
} from "src/api/types.generated";
import { CURRENT_EVENT_SLUG } from "src/shared/constants/event";

import { useGetSponsorsQuery } from "./graphql/getSponsors.generated";
import {
  useGetSponsorsInfoQuery,
  GetSponsorsInfoQuery,
} from "./graphql/getSponsorsInfo.generated";

export const useCareerSponsorState = () => {
  const { data: sponsors } = useGetSponsorsQuery({
    variables: { event_slug: CURRENT_EVENT_SLUG },
  });
  const { data: sponsorsInfo } = useGetSponsorsInfoQuery({
    fetchPolicy: "no-cache",
  });

  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [techTalks, setTechTalks] = useState<
    {
      slug: string;
      title: Maybe<string> | undefined;
      description: Maybe<string> | undefined;
    }[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [companies, setCompanies] = useState<
    {
      slug: string;
      tier: SponsorCompanyTier;
      logo:
        | Pick<
            FileFieldAnswerType,
            "id" | "name" | "mime_type" | "expiry" | "uri"
          >
        | undefined;
    }[]
  >([]);

  useEffect(() => {
    if (sponsors && sponsorsInfo) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const sponsorCompanyIdToPerks = sponsors?.sponsorCompaniesByEvent.reduce<
        Record<number, GetSponsorsInfoQuery["claims"][0]>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      >((acc, { id }): any => {
        const perks = sponsorsInfo.claims?.find(
          (claim) =>
            claim.fields_HACKTHENORTH2021_SPONSOR_PERKS.answers
              .sponsor_company_id?.value === id
        );

        if (!perks) {
          console.error("failed");
          return;
        }
        return { ...acc, [id]: perks };
      }, {});

      //   const cleanedSponsorCompanies = sponsors?.sponsorCompaniesByEvent.filter(
      //     (c) => c.slug !== "spongebub_hackthenorth2021"
      //   );

      //   const techTalksData = cleanedSponsorCompanies.map((company) => ({
      //     slug: company.slug,
      //     title:
      //       sponsorCompanyIdToPerks[company.id]
      //         ?.fields_HACKTHENORTH2021_SPONSOR_PERKS.answers
      //         .tech_talk_proposal_title?.value,
      //     description:
      //       sponsorCompanyIdToPerks[company.id]
      //         ?.fields_HACKTHENORTH2021_SPONSOR_PERKS.answers
      //         .tech_talk_proposal_description?.value,
      //   }));

      //   setTechTalks(techTalksData);

      //   const companiesData = cleanedSponsorCompanies.map((company) => {
      //     const logo =
      //       sponsorCompanyIdToPerks[company.id]
      //         ?.fields_HACKTHENORTH2021_SPONSOR_PERKS.answers.website_logo_file
      //         ?.value?.[0];

      //     return {
      //       slug: company.slug,
      //       tier: company.tier,
      //       logo,
      //     };
      //   });

      //   setCompanies(companiesData);

      setLoading(false);
    }
  }, [sponsors, sponsorsInfo]);

  return {
    techTalks,
    companies,
    loading,
  };
};
