import { Field } from "src/shared/contexts/HackerContext/types";

export const ACCEPTED_FIELDS = [
  Field.HACKATHON_HOST_REQUESTED,
  Field.TECH_TALKS,
  Field.MEETUPS,
  Field.GITHUB,
  Field.INSTAGRAM,
  Field.FACEBOOK,
  Field.TWITTER,
  Field.LINKEDIN,
  Field.DISCORD_TAG,
  Field.TIKTOK,
  Field.EVENT_RSVP_STAGE,
  Field.TRAVEL_RSVP_STAGE,
];

// export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
//   [Field.PROJECT_STREAM]: notNull,
// }; // validation handled in onSave since some fields depend on other fields

export const MEETUP_MAX = 3;

export const TECH_TALK_OPTIONS = [
  {
    label: "Shopify - Hacking Reality: The Future of AR/VR",
    description:
      "Ready to step into the future? Join us as we explore how AR and VR are transforming the tech landscape. We'll dive into the most innovative AR/VR advancements and show you how you can create immersive, interactive experiences using Shopify's tools. Whether you're into gaming, education, or creative arts, discover how AR/VR can elevate your projects.",
  },
  {
    label:
      "Shopify - AI Unleashed: Transforming Tech with Intelligent Solutions",
    description:
      "AI isn't just a buzzword; it's a revolution in how we interact with technology. Join us for a deep dive into the most exciting AI advancements and how they're changing the game across various fields. From personalized experiences to automated solutions, discover how you can harness AI to innovate and lead in your projects.",
  },
  {
    label: "Shopify - Tinkering: The Heart of Innovation",
    description:
      "At Shopify, we believe that tinkering is at the heart of innovation. Join us as we explore the power of experimentation and how small tweaks can lead to big breakthroughs. We'll share stories of how tinkering has driven some of our most innovative projects and show you how to apply this mindset to your own work.",
  },
  {
    label:
      "Siemens - Transcending Physical Boundaries: Bridging Real and Virtual Worlds with Digital Twins and Artificial Intelligence",
    description:
      "Explore how digital twins and AI integrate physical and virtual environments, enhancing real-time decision-making to bridge operational silos.",
  },
  {
    label:
      "Siemens - Decoding the Enigma: Unraveling Data Bias in Machine Learning and Artificial Intelligence",
    description:
      "Learn how bias in AI can lead to skewed and potentially harmful outcomes that may go unnoticed even by well-intentioned scientists and engineers.",
  },
  {
    label:
      "Siemens - Harnessing the Power of AI for Real Time Location Systems (RTLS) in Smart Buildings",
    description:
      "Enabled by Siemens-Enlighted's IoT sensor network in smart buildings, the Enlighted indoor localization solution uses ML to enhance location accuracy. It begins with defining zones and collecting data, such as RSSI, from Bluetooth Low Energy (BLE) tags. This data is labeled to create a BLE fingerprint for each zone. An RNN model is then trained to predict tag locations based on this data. Thanks to the ML approach, the system adapts to high levels of noise while maintaining high accuracy.",
  },
  {
    label: "Intuit - Beyond the Hype: LLMs and their real-world use cases",
    description:
      "With LLMs being all the rage since the launch of ChatGPT it's hard to see through the fog of hyped of solutions promising to solve every problem ever to plague humanity. In this talk we break through the hype to explore what LLMs actually are and are capable of with a focus on how they can help supplement workers like customer service experts instead of supplanting them",
  },
  {
    label:
      "Intuit - 印出 'Hello World': The hidden world of non-English based programming languages",
    description:
      "Have you ever wondered why even though there is an 1.8 billion non-English speaking majority around world but 99.9% of programming languages are written in English. In this talk we dive into the wonderful world of English based programming languages.",
  },
  {
    label:
      "Defang - Develop, Deploy, and Debug your first cloud app with Defang",
    description:
      "Over the last 15+ years, cloud has become the dominant platform to run the back-end of any application. However, over this time, cloud platforms have also become increasingly more complex, with a bewildering array of choices that stump even the most experienced development teams, not to mention students or hobbyists. Defang provides a radically simpler way for developers to Develop, Deploy, and Debug cloud apps. Give it a try at defang.io!",
  },
  {
    label: "Groq - Tool Calling with Open Source LLMs",
    description:
      "We all want faster, cheaper and more accurate tool calling abilities, but what are our options for improving accuracy and reliability of tool calling with Open Source LLMs? A systematic approach suggests a robust evaluation system is required to properly understand how every tiny design decision influences total system performance. When working with open source LLMs like Llama 3, the need to rigorously evaluate is crucial as many more adjustments can be made that influence the Tool Calling process.",
  },
  {
    label:
      "Convex - How to use the Convex backend platform to build a full-stack web app in minutes",
    description:
      "Convex (https://convex.dev/c/hackthenorth) is a full-featured backend platform for rapid web app development, and in this workshop, you'll learn all the basics you need to start building on Convex. Through a demo app and hands-on exercises you'll learn how to write serverless functions to store & retrieve data in your Convex database, and use the React client library to connect your app's UI to your Convex backend. By the end of the workshop, you'll start prototyping your own app using Convex's handy app templates.",
  },
  {
    label: "Cohere - Hallucination to Reality: The Power of RAG Systems",
    description:
      "The rise of LLMs that coherently use language has led to an appetite to ground the generation of these models in facts and private collections of data. This is motivated by the desire to reduce the hallucinations of these models, as well as supply them with up-to-date, often private information that is not a part of their training data. Retrieval-augmented generation is the method that uses a search step to ground models in relevant data sources. In this talk, we'll go in depth on advanced schematics of RAG systems and tips on how to improve them.",
  },
  {
    label: "P&G - What am I supposed to do with this degree?",
    description:
      'Join us for an exhilarating hackathon workshop as we answer the question we all seem to have: "What am I supposed to do with this degree?" Through a series of mini challenges and quests, we will discover the numerous exciting and diverse career paths the tech world has to offer. Engage in thrilling code-cracking quests, hone your debugging skills, navigate the unique challenges of a deaf-blind 1v1 encounter, and compete in lightning-fast typing races. Each activity will shed light on the lesser known possibilities that await you in the realm of technology. Don\'t miss this opportunity to explore and discover your niche in the ever-evolving tech industry; you may leave with a newfound passion for a career you had never considered before! Join us for an unforgettable adventure of learning, growth, and endless possibilities.',
  },
  {
    label: "P&G - How Tech Leaders Communicate: the Power of Public Speaking",
    description:
      "Join us for an immersive workshop that explores the art of effective communication. Discover the impact of public speaking and leadership in the dynamic world of software engineering. Learn how being a leader can propel your career to new heights, specifically within the prestigious realm of P&G. In this interactive workshop, we will discover the strategies and techniques used by influential leaders to not only inspire and captivate any audience, but leave a lasting impact as well. Through engaging activities and real-world examples, you will gain the confidence and skills to express your ideas with clarity, authority, and charisma. Whether you aspire to lead teams, present groundbreaking concepts, or secure coveted positions, mastering the art of public speaking is the key to unlocking a world of endless possibilities. Don't miss this opportunity to elevate your career in software engineering and beyond.",
  },
  {
    label:
      "Voiceflow - Scaling a Codebase from Hacky Project to Professional Product",
    description:
      "Join us for a session on transforming a hackathon project into a professional product! Learn from Voiceflow's journey as we explore crucial technical decisions and strategies that have enabled our success as a software startup. We'll cover our tech stack choices, including JavaScript/TypeScript, React, and NodeJS, and discuss the importance of maintainable code through patterns like immutability and abstraction. Discover our approach to testing and linting to ensure code quality, and see how automation and CI/CD streamline deployments with feature flags and cloud-based testing environments. We'll also share best practices in DevOps, including service management, Datadog monitoring, and deployment pipelines. This talk is perfect for aspiring developers aiming to scale their projects into robust, professional-grade applications.",
  },
  {
    label: "TD - Virtual Reality at TD",
    description:
      "TD Bank taps virtual reality to help tellers learn to navigate tense scenarios.",
  },
  {
    label: "TD - TD Invent: Shaping the future of banking",
    description:
      "Explore how TD is innovating in the banking sector through technology.",
  },
  {
    label: "Chroma - Building AI Applications Without The Bullshit",
    description:
      "AI application development is just under two years old, and already there's a ton of new jargon and abstractions to learn: Prompting? Chaining? Retrieval? In-Context Learning? It's almost as bad as front-end!",
  },
  {
    label:
      "Mappedin - Interesting Applications of AI and the Ethics of Certain Uses",
    description:
      "Delve into fascinating AI applications beyond the usual suspects like ChatGPT and self-driving cars. Learn about AI in animal call translation for conservation efforts and the ethical dilemmas posed by rapid AI advancements, such as deepfakes, art generation without artist compensation, and voice replication scams.",
  },
  {
    label: "Mappedin - The History of NLP and the Future of LLMs",
    description:
      "Explore the evolution of Natural Language Processing (NLP) from early computational linguistics to modern Large Language Models (LLMs). Learn how AI surpassed human capabilities in understanding language and what the future holds for LLMs as they continue to improve with more data and parameters.",
  },
  {
    label: "Databricks - Lessons from training LLMs at Scale",
    description:
      "Earlier this year, Databricks released DBRX, a 132 billion parameter open-source MoE language model. This talk will go over the tradeoffs and design choices that are involved in training LLMs at scale such as DBRX. These lessons continue to apply to recent LLMs such as Llama 405B and more broadly, to any large engineering endeavour.",
  },
  {
    label: 'Jane Street - Jane Street Tech Talk - "Aria"',
    description:
      "In this talk, we explore how transaction logs, state machine replication, and control flow inversion have allowed us to structure our applications in a way that lets us reliably deal with data races and process failures. We introduce Aria, a low-latency framework built on top of these ideas that has helped Jane Street scale our trading infrastructure while making it more performant, testable, and robust.",
  },
  {
    label: "RBC - A Taste of Microfrontend",
    description:
      "If you enjoy trying new foods, you'll love exploring new tech! Join our workshop to discover the parallels between being a foodie and a frontend developer. We'll take you through the history of frontend development and introduce the exciting world of microfrontends. Learn the vital DOs and DON'Ts, get hands-on with best practices, and find out how mastering this concept can land you a frontend role in any enterprise!",
  },
  {
    label:
      "Geotab - Revolutionize Enterprise Machine Learning Platform: Scale Smart and Spend Less with Open Source Tools",
    description:
      "Join us for an engaging session where we delve into our journey of scaling the Machine Learning Platform efficiently without breaking the bank! We'll discuss how we've transformed the platform to achieve cost-effective scalability and how, as a team of just three engineers, we support over 100 data scientists.",
  },
  {
    label: "Rocket Companies - Methodology for Architecting Systems",
    description:
      "In this presentation, we will explore the multifaceted process of developing software in an enterprise environment, focusing on both design and infrastructure considerations. We will begin with problem identification, followed by evaluating build vs. buy decisions based on team skills and tools. We will then address software resiliency through event bus architecture, dynamic scalability, and failover mechanisms, and emphasize maintainability with unit testing, documentation, and code simplicity. On the infrastructure side, we will discuss Infrastructure as Code (IaC), comparing serverless and container services, and highlight the importance of Continuous Integration and Continuous Deployment (CICD) pipelines. This session aims to provide a comprehensive understanding of the strategies and best practices for developing robust, scalable, and maintainable software in an enterprise setting.",
  },
  {
    label: "Rocket Companies - Strategies for Choosing Technologies",
    description:
      "In this talk we will give a high-level overview of the various strategies, approaches, and considerations for choosing software, programming languages, runtimes, libraries, and frameworks. We will explore the differences in how these decisions are evaluated and made between various sized organizations from large enterprises to everyday hobbyist. Additionally, attendees will learn various tips and strategies for choosing technologies to grow and direct their careers.",
  },
  {
    label:
      "Genesys - Jumpstart your career with Generative AI – A real-life success story of our intern",
    description:
      "Are you a student eager to make a splash in your first internship or job? Discover how Generative AI can transform you from a coding newbie to a full-fledged developer. We'll share a real-world success story of how our intern, overwhelmed by new technologies, development processes and tight project timeline, became a high-performing contributor in record time thanks to Generative AI. Don't miss this opportunity to gain a competitive edge!",
  },
  {
    label: "Genesys - Our Hackathon Champion Story: Building Bots with Heart",
    description:
      "Imagine creating a chatbot that focuses on making customers' and agents' lives better by de-escalating difficult customer service situations. One of our internal hackathon winners did just that! Their AI-powered assistant not only handled complex customer inquiries but also built genuine connections. By combining cutting-edge chained language models and a deep understanding of customer needs, they created a chatbot that can turn frustrated customers into loyal advocates. Want to learn how they did it?",
  },
];

export const MEETUP_OPTIONS = [
  "High Schoolers in Tech",
  "2SLGBTQIA+ in Tech",
  "Black in Tech",
  "Women in Tech",
  "Beginners in Tech",
];
