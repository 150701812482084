import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import { ApiWorkshopData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
  PerkSelect,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription, TextInputTitle } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

export const API_WORKSHOP_VALIDATION_FIELDS: (keyof ApiWorkshopData)[] = [
  "api_workshop_title",
  "api_workshop_description",
  "api_workshop_representative_name",
  "api_workshop_representative_email",
  "api_workshop_time_one",
  "api_workshop_time_two",
  "api_workshop_time_three",
];

export const ApiWorkshop: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const timeOne = getState("api_workshop_time_one");
  const timeTwo = getState("api_workshop_time_two");
  const timeThree = getState("api_workshop_time_three");

  const timeTimeError = (timeOne &&
    timeTwo &&
    timeThree &&
    (timeOne === timeTwo ||
      timeOne === timeThree ||
      timeTwo === timeThree)) as boolean;

  return (
    <>
      <Header
        title="API Workshop"
        subtitle="This perk is for hosting an API workshop that will take place from Sept 13-15, 2024."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("api_workshop_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("api_workshop_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.API_WORKSHOP}
        readOnly={isReadOnly}
      >
        {timeTimeError && (
          <>
            <PerkError
              error={<>Make sure all your time selections are different</>}
            />
            <Spacer height={16} />
          </>
        )}
        <PerkTextInput
          title="Workshop Title*"
          value={getState("api_workshop_title") ?? ""}
          placeholder="Your workshop title"
          isReadOnly={isReadOnly}
          onChange={(e) => updateState("api_workshop_title", e.target.value)}
          error={error?.api_workshop_title && <>This field is required</>}
        />
        <Spacer height={32} />
        <PerkTextArea
          title="Workshop Description* (max 100 words)"
          value={getState("api_workshop_description") ?? ""}
          placeholder="What will your workshop be about?"
          wordLimit={100}
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("api_workshop_description", e.target.value)
          }
          error={error?.api_workshop_description && <>This field is required</>}
        />
        <Spacer height={32} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) => updateState("api_workshop_time_one", e.target.value)}
          value={getState("api_workshop_time_one") ?? ""}
          label="Select timeslot (First choice)"
          isReadOnly={isReadOnly}
          error={error?.api_workshop_time_one && <>This field is required</>}
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) => updateState("api_workshop_time_two", e.target.value)}
          value={getState("api_workshop_time_two") ?? ""}
          label="Select timeslot (Second choice)"
          isReadOnly={isReadOnly}
          error={error?.api_workshop_time_two && <>This field is required</>}
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("api_workshop_time_three", e.target.value)
          }
          value={getState("api_workshop_time_three") ?? ""}
          label="Select timeslot (Third choice)"
          isReadOnly={isReadOnly}
          error={error?.api_workshop_time_three && <>This field is required</>}
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={24} />
        <TextInputTitle style={{ fontSize: "20px" }}>
          Representative
        </TextInputTitle>
        <Spacer height={8} />
        <TextDescription>Who will be moderating the workshop?</TextDescription>
        <Spacer height={16} />
        <PerkTextInput
          title="Name"
          value={getState("api_workshop_representative_name") ?? ""}
          placeholder="Your representative's name"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("api_workshop_representative_name", e.target.value)
          }
          error={
            error?.api_workshop_representative_name && (
              <>This field is required</>
            )
          }
        />
        <Spacer height={8} />
        <PerkTextInput
          title="Email"
          value={getState("api_workshop_representative_email") ?? ""}
          placeholder="Your representative's email"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("api_workshop_representative_email", e.target.value)
          }
          error={
            error?.api_workshop_representative_email && (
              <>This field is required</>
            )
          }
        />
        {/*
        <Spacer height={32} />
        <Text mods="medium">
          Optional: Upload a pre-recorded video of your workshop to be used in
          case of poor connection or other day-of technical issues.
        </Text>
        <PerkTextInput
          //name={`${SponsorPerkType.API_WORKSHOP}--api_workshop_video_link`}
          title=""
          value={getState("api_workshop_video_link") ?? ""}
          placeholder="Link to pre-recorded video (YouTube, Dropbox, Google Drive, etc.)"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("api_workshop_video_link", e.target.value)
          }
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={
            getState("api_workshop_accepted_hackers_only") === true
              ? "Yes"
              : getState("api_workshop_accepted_hackers_only") === false
              ? "No"
              : undefined
          }
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerkType.TECH_TALK_TIME}---accepted-hackers`}
          title="We will be holding API workshops during Gear Up, a pre-hack event which will feature sessions that will be open to the public. Would you like your workshop attendees to be limited to accepted hackers only?"
          onChange={(option) => {
            if (option === "Yes") {
              updateState("api_workshop_accepted_hackers_only", true);
            } else {
              updateState("api_workshop_accepted_hackers_only", false);
            }
          }}
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={
            getState("api_workshop_recording_available") === true
              ? "Yes"
              : getState("api_workshop_recording_available") === false
              ? "No"
              : undefined
          }
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerkType.API_WORKSHOP}--recording`}
          title="Would you like a recording of your workshop to be made publicly available on our Youtube channel after the event?"
          onChange={(option) => {
            if (option === "Yes") {
              updateState("api_workshop_recording_available", true);
            } else {
              updateState("api_workshop_recording_available", false);
            }
          }}
        />*/}
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription>
      As a sponsor of an API prize, you have the opportunity to host a 30-minute
      workshop teaching hackers to work with your public-facing API.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Please rank the times that work best for you from those listed below. Once
      this perk has been submitted, our team will be in contact to finalize your
      time. We strongly encourage you to hold your session in these time slots
      since we&apos;ve reserved them for sponsors. If these times don&apos;t
      work for you, or you have a specific time that you want to run your
      session, please email{" "}
      <Link href="mailto:sponsor@hackthenorth.com" target="_blank">
        sponsor@hackthenorth.com
      </Link>
      .
    </TextDescription>
    <Spacer height={16} />
    <TextDescription style={{ fontStyle: "italic" }}>
      Note that all times are in Eastern Daylight Time (EDT).
    </TextDescription>
  </>
);
