/* TODO (@jenny): remove these eslint-disables */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text, Spacer, Flex } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Checkbox, RadioButton, Spinner, Link } from "north.js";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import "twin.macro";

import { DropdownTagInput, PageWrapper } from "src/shared/components";
import { successToast, errorToast } from "src/shared/components/Toast";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize, useIsMounted } from "src/shared/hooks";
import {
  answersToOptions,
  answerToOption,
  Option,
} from "src/shared/utils/react-select";
import { Nullable } from "src/shared/utils/typescript";
import { notNull } from "src/shared/utils/validation";
import { ErrorSignIcon, ArrowRight } from "src/static/icons";

import RSVPSidebar from "../rsvp-sidebar";

import {
  ACCEPTED_FIELDS,
  VALIDATORS,
  TRAVEL_TYPE_OPTIONS,
  TRAVEL_TYPE_MAP,
  TRAVEL_TYPE_MAP_REVERSE,
} from "./constants";
import GeoCoder from "./GeoCoder";
import { getReimbursementAmount } from "./ReimbursementAmount";
import TravelInformation from "./TravelInformation";

const isEmpty = (s: Nullable<string>) => s === "" || s === null;

const TravelPage = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isMounted = useIsMounted();
  const { updateResponses, isLoading, isReadOnly, navigateNext, navigateBack } =
    useHackerContext();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const [showErrors, setShowErrors] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reimbursementAmount, setReimbursementAmount] = useState(0);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {});
  });

  useEffect(() => {
    const reimbursementAmount = getReimbursementAmount(
      responsesState[Field.LOCATION]
    )?.amount;
    setReimbursementAmount(reimbursementAmount);
  }, [responsesState]);

  const isLocationInternational = !responsesState[Field.LOCATION]
    ?.toLowerCase()
    .includes("canada");
  const isLocationValid = !isEmpty(responsesState[Field.LOCATION]);
  const isTravelOptionValid = !isEmpty(responsesState[Field.TRAVEL_OPTION]);
  const isEntryEligibilityAcknowledgementValid = isLocationInternational
    ? responsesState[Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT] === true
    : true;
  const isRequiresVisaValid = isLocationInternational
    ? responsesState[Field.REQUIRES_VISA] !== null
    : true;
  const isVisaAcknowledgementValid = responsesState[Field.REQUIRES_VISA]
    ? responsesState[Field.VISA_ACKNOWLEDGEMENT] === true
    : true;

  const allAnswersAreValid =
    isValid &&
    isEntryEligibilityAcknowledgementValid &&
    isVisaAcknowledgementValid;
  useEffect(() => {
    if (allAnswersAreValid && isMounted) {
      navigateNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  const ErrorTextComponent = !showErrors && (
    <Flex tw="ml-8 my-4">
      <ExclamationTriangleIcon />
      <ErrorText>Please enter your location.</ErrorText>
    </Flex>
  );

  const getMods = (isFieldValid: boolean): string =>
    !isFieldValid && showErrors ? "error" : "";

  const onSave = async () => {
    setShowErrors(true);
    if (allAnswersAreValid) {
      try {
        const { errors } = await updateResponses({
          ...responsesState,
          [Field.TRAVEL_RSVP_STAGE]: PageStage.COMPLETED,
        });

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        successToast("Answers successfully saved!");
        setSubmitting(true);
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e);
        }
      }
    } else {
      window.scrollTo(0, 0);
      errorToast("Please fill out all the required fields.");
      setShowErrors(true);
    }
  };

  if (!notNull(responsesState[Field.LEGAL_NAME])) {
    setResponsesState(Field.LEGAL_NAME, responsesState[Field.SHORT_NAME]);
  }
  if (isEmpty(responsesState[Field.LOCATION])) {
    setResponsesState(Field.LOCATION, responsesState[Field.SHORT_LOCATION]);
  }

  return (
    <PageWrapper>
      <Prompt
        when={isBlocking && !submitting}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      {isTabletOrSmaller && <RSVPSidebar currentRoute="Travel" />}
      {/* Header */}
      <Title>Travel</Title>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>
      <Spacer height={42} />

      <Text mods="bold textBody">
        Where will you be travelling from to Hack the North?
      </Text>
      <Spacer height={12} />
      <GeoCoder
        disabled={isReadOnly}
        shortAnswer
        focusOnFlip
        placeholder="Please enter city, province, and country (e.g. Waterloo, Ontario, Canada)"
        value={
          responsesState[Field.LOCATION]
            ? answerToOption(responsesState[Field.LOCATION])
            : undefined
        }
        onChange={(e: any) => {
          setResponsesState(Field.LOCATION, e.value);
          setIsBlocking(true);
        }}
        tabIndex={0}
      />
      <Spacer height={4} />
      {!isLocationValid && ErrorTextComponent}

      {isLocationInternational && (
        <>
          <Spacer height={24} />

          <Text mods="bold textBody">Entry eligibility acknowledgement</Text>
          <Spacer height={12} />
          <StyledCheckbox
            label="I understand that I am responsible for confirming my legal eligibility to enter Canada. I understand that my visa might not arrive in time for the event, forfeiting my spot and making me ineligible for reimbursement."
            checked={
              responsesState[Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT] === true
            }
            onChange={(e: any) => {
              setResponsesState(
                Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT,
                e.target.checked
              );
              setIsBlocking(true);
            }}
            isValid={isEntryEligibilityAcknowledgementValid}
            showErrors={showErrors}
          />
          <Spacer height={24} />

          <Text mods="bold textBody">
            Do you need a visa to travel to the event?
          </Text>
          <Spacer height={12} />
          {[
            { option: "Yes", val: true },
            { option: "No", val: false },
          ].map(({ option, val }) => (
            <>
              <StyledRadioButton
                id={option}
                name="needsVisa"
                label={option}
                checked={responsesState[Field.REQUIRES_VISA] === val}
                onChange={(e: any) => {
                  setResponsesState(Field.REQUIRES_VISA, val);
                  setIsBlocking(true);
                }}
                isValid={isRequiresVisaValid}
                showErrors={showErrors}
              />
              <Spacer height={12} />
            </>
          ))}
          <Text mods="caption grey">
            If you are not sure, you can try the{" "}
            <LinkStyled href="http://www.cic.gc.ca/english/visit/visas.asp?_ga=2.40953506.1487362425.1532316154-990218212.1522716839">
              Government of Canada’s visa questionnaire
            </LinkStyled>
            .
          </Text>
          {responsesState[Field.REQUIRES_VISA] === true && (
            <>
              <Spacer height={24} />
              <Text mods="bold textBody">Visa acknowledgement</Text>
              <Spacer height={12} />
              <StyledCheckbox
                label="I understand that I am responsible for obtaining my own visa and any fees associated with acquiring it."
                checked={responsesState[Field.VISA_ACKNOWLEDGEMENT] === true}
                onChange={(e: any) => {
                  setResponsesState(
                    Field.VISA_ACKNOWLEDGEMENT,
                    e.target.checked
                  );
                  setIsBlocking(true);
                }}
                isValid={isVisaAcknowledgementValid}
                showErrors={showErrors}
              />
              <Spacer height={24} />
              <MoreDetailsBox>
                <Text mods="bold textBody">
                  Recommendation Letter Instructions
                </Text>
                <Spacer height={12} />
                <Text>
                  If you require a letter of recommendation for your visa
                  application, please fill out{" "}
                  <LinkStyled href="https://docs.google.com/forms/d/e/1FAIpQLScsPVZ2Y6TZ0EIJObh6OGkF6-7lbobfWpP0QMxUi-Hgiqqx3w/viewform">
                    this form
                  </LinkStyled>{" "}
                  so we can provide you with a Visa Invitation Letter.
                </Text>
              </MoreDetailsBox>
            </>
          )}
        </>
      )}
      <Spacer height={24} />

      <Text mods="bold textBody">
        How will you be getting to Hack the North?
      </Text>
      <Spacer height={12} />
      {TRAVEL_TYPE_OPTIONS.map((travelOption) => {
        return (
          <>
            <StyledRadioButton
              id={travelOption}
              name="travel option"
              label={travelOption}
              description={
                travelOption === "Flight" &&
                "If there’s a bus coming from your location, you will not be reimbursed for your flight. Please contact travel@hackthenorth.com if you’re unsure about your eligibility for reimbursement."
              }
              checked={
                TRAVEL_TYPE_MAP_REVERSE[
                  responsesState[Field.TRAVEL_OPTION] ?? ""
                ] === travelOption
              }
              onChange={(e: any) => {
                setResponsesState(
                  Field.TRAVEL_OPTION,
                  TRAVEL_TYPE_MAP[travelOption]
                );
                setIsBlocking(true);
              }}
              isValid={isTravelOptionValid}
              showErrors={showErrors}
            />
            <Spacer height={12} />
          </>
        );
      })}
      <Spacer height={12} />

      {/* The typing makes it such that I can't just use the value directly (nulls and undefineds can't be used as indices. */}
      {responsesState[Field.TRAVEL_OPTION] && (
        <MoreDetailsBox>
          <TravelInformation
            title={responsesState[Field.TRAVEL_OPTION] ?? ""}
            reimbursementAmount={reimbursementAmount}
          />
        </MoreDetailsBox>
      )}

      <Spacer height={24} />

      <FlexRow>
        <Button color="primary" disabled={isLoading} onClick={onSave} size="md">
          {isLoading ? (
            <Spinner />
          ) : (
            <FlexRow>
              <p>
                {responsesState[Field.TRAVEL_RSVP_STAGE] === PageStage.COMPLETED
                  ? "Save"
                  : "Next"}
              </p>
              <Spacer width="8px" />
              <ArrowRight width={16} />
            </FlexRow>
          )}
        </Button>
        <Spacer width="32px" />
      </FlexRow>
    </PageWrapper>
  );
};

const Title = styled.h1`
  font-size: 28px;
  font-weight: 900;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  line-height: 1.5;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  margin-bottom: 8px;
  margin-top: 0;
`;

const StyledRadioButton = styled(RadioButton)<{
  isValid: boolean;
  showErrors: boolean;
}>`
  ${({ isValid, showErrors, theme }) =>
    !isValid && showErrors
      ? `border: none; box-shadow: 0 0 0 2px ${theme.globalConstants.color.textDanger}`
      : ``}
`;

const StyledCheckbox = styled(Checkbox)<{
  isValid: boolean;
  showErrors: boolean;
}>`
  ${({ isValid, showErrors, theme }) =>
    !isValid && showErrors
      ? `border: none; box-shadow: 0 0 0 2px ${theme.globalConstants.color.textDanger}`
      : ``}
`;

const MoreDetailsBox = styled.div`
  border-radius: 9px;
  background-color: #f9fafb;
  padding: 32px;
`;

const ErrorText = styled(Text).attrs({ mods: "error" })`
  font-size: 12px !important;
`;

const ExclamationTriangleIcon = styled(ErrorSignIcon)`
  margin-top: 8px;
  margin-right: 8px;
  height: 12px;
  width: 12px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const LinkStyled = styled(Link)`
  font-weight: 600;
  color: ${({ theme }) => theme.globalConstants.color.textPrimary} !important;
`;

export default TravelPage;
