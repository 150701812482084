import { DateTime, DurationInput } from "luxon";

import { formatTimeRangeV2 } from "src/shared/utils/formatTime";
import { THackerAPIDatetime } from "src/shared/utils/hackerapi";
import { timesToOptionsExplicitV2 } from "src/shared/utils/react-select";

// Sept 14, 2024 from 12:30 am - 3:30 am in EDT
const API_WORKSHOP_TIMES: [THackerAPIDatetime, DurationInput][] = [
  [DateTime.utc(2024, 9, 14, 4, 30, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 5, 0, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 5, 30, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 6, 0, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 6, 30, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 7, 0, 0).toISO(), { minutes: 30 }],
];

export const TIME_OPTIONS = [
  ...timesToOptionsExplicitV2(formatTimeRangeV2, API_WORKSHOP_TIMES),
  { label: "", value: "" },
];
