/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import { HackerFragmentFragmentDoc } from "../../shared/contexts/HackerContext/graphql/hackerFragment.generated";
import { HackerFragmentFragment } from "../../shared/contexts/HackerContext/graphql/hackerFragment.generated";
import * as Types from "../types.generated";
export type GetHackersQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  filters?: Types.Maybe<
    Array<Types.FieldAnswerWhereInput> | Types.FieldAnswerWhereInput
  >;
  skip: Types.Scalars["Int"];
  take: Types.Scalars["Int"];
}>;

export type GetHackersQuery = {
  claims: Array<{ user: Pick<Types.User, "email"> } & HackerFragmentFragment>;
};

export const GetHackersDocument = gql`
  query GetHackers(
    $slug: String!
    $filters: [FieldAnswerWhereInput!]
    $skip: Int!
    $take: Int!
  ) {
    claims(
      where: {
        AND: [
          { pipeline: { is: { slug: { equals: $slug } } } }
          {
            OR: [
              { stage: { is: { slug: { equals: "confirmed" } } } }
              { stage: { is: { slug: { equals: "checked_in" } } } }
            ]
          }
          { field_answers: { some: { AND: $filters } } }
        ]
      }
      skip: $skip
      take: $take
    ) {
      ...hackerFragment
      user {
        email
      }
    }
  }
  ${HackerFragmentFragmentDoc}
`;

/**
 * __useGetHackersQuery__
 *
 * To run a query within a React component, call `useGetHackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHackersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetHackersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHackersQuery,
    GetHackersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetHackersQuery, GetHackersQueryVariables>(
    GetHackersDocument,
    baseOptions
  );
}
export function useGetHackersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHackersQuery,
    GetHackersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHackersQuery,
    GetHackersQueryVariables
  >(GetHackersDocument, baseOptions);
}
export type GetHackersQueryHookResult = ReturnType<typeof useGetHackersQuery>;
export type GetHackersLazyQueryHookResult = ReturnType<
  typeof useGetHackersLazyQuery
>;
export type GetHackersQueryResult = ApolloReactCommon.QueryResult<
  GetHackersQuery,
  GetHackersQueryVariables
>;
