import { IconName } from "src/shared/components/Icon";

export enum SocialPlatforms {
  FACEBOOK = "Facebook",
  INSTA = "Instagram",
  X = "X",
  LINKEDIN = "LinkedIn",
  MEDIUM = "Medium",
  TIKTOK = "TikTok",
}

export const SOCIALS: Record<
  SocialPlatforms,
  { icon: IconName; link: string }
> = {
  [SocialPlatforms.INSTA]: {
    icon: "instagram",
    link: "https://www.instagram.com/hackthenorth",
  },
  [SocialPlatforms.X]: {
    icon: "xlogo",
    link: "https://x.com/hackthenorth",
  },
  [SocialPlatforms.FACEBOOK]: {
    icon: "facebook",
    link: "https://www.facebook.com/hackthenorth/",
  },
  [SocialPlatforms.LINKEDIN]: {
    icon: "linkedin",
    link: "https://www.linkedin.com/company/hack-the-north",
  },
  [SocialPlatforms.TIKTOK]: {
    icon: "tiktok",
    link: "https://www.tiktok.com/@hackthenorth",
  },
  [SocialPlatforms.MEDIUM]: {
    icon: "medium",
    link: "https://hackthenorth.medium.com/",
  },
};
