import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { isNonNegative, notBlank, isDate } from "src/shared/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.FRIDAY_FLIGHT_AIRLINE,
  Field.FRIDAY_FLIGHT_ARRIVAL_TIME,
  Field.FRIDAY_FLIGHT_NUMBER,

  Field.SUNDAY_FLIGHT_AIRLINE,
  Field.SUNDAY_FLIGHT_DEPARTURE_TIME,
  Field.SUNDAY_FLIGHT_NUMBER,

  Field.FLIGHT_CURRENCY,
  Field.FLIGHT_AMOUNT,
  Field.REIMBURSEMENT_METHOD,
  Field.REIMBURSEMENT_RECIPIENT_NAME,
  Field.REIMBURSEMENT_RECIPIENT_ETRANSFER,
  Field.REIMBURSEMENT_RECIPIENT_PAYPAL,
  Field.REIMBURSEMENT_ACKNOWLEDGEMENT,

  Field.DEPARTURE_AIRPORT,
  Field.ARRIVAL_AIRPORT,

  Field.TRAVEL_PHONE_NUMBER,
  Field.BUS_COORDINATOR_INTEREST,

  Field.TRAVEL_RECEIPT,

  Field.REQUIRES_VISA,
  Field.SHARE_EMAIL,
  Field.VISA_TRACKING,
];

export const RECIPIENT_DETAIL_FIELDS = [
  Field.REIMBURSEMENT_RECIPIENT_ETRANSFER,
  Field.REIMBURSEMENT_RECIPIENT_NAME,
  Field.REIMBURSEMENT_RECIPIENT_PAYPAL,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.FRIDAY_FLIGHT_AIRLINE]: notBlank,
  [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: isDate,
  [Field.FRIDAY_FLIGHT_NUMBER]: notBlank,
  [Field.ARRIVAL_AIRPORT]: (value: TAnswer) =>
    !!value && (value as string).length === 3,

  [Field.SUNDAY_FLIGHT_AIRLINE]: notBlank,
  [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: isDate,
  [Field.SUNDAY_FLIGHT_NUMBER]: notBlank,
  [Field.DEPARTURE_AIRPORT]: (value: TAnswer) =>
    !!value && (value as string).length === 3,

  [Field.FLIGHT_CURRENCY]: notBlank,
  [Field.FLIGHT_AMOUNT]: isNonNegative,

  [Field.SHARE_EMAIL]: (value: TAnswer) => value === true || value === false,

  [Field.REIMBURSEMENT_METHOD]: notBlank,
  [Field.REIMBURSEMENT_ACKNOWLEDGEMENT]: (value: TAnswer) => !!value,
};

export const FLIGHT_DEADLINE = "August 31, 2024 11:59 PM EDT";
export const BUS_DEADLINE = "August 31, 2024 11:59 PM EDT";

export const BUS_PICKUP_DATE = "September 13, 2024";
export const BUS_DROPOFF_DATE = "September 15, 2024";

export const REIMBURSEMENT_CURRENCIES = {
  AUD: "Australian Dollar (AUD)",
  BRL: "Brazilian Real (BRL)",
  CAD: "Canadian Dollar (CAD)",
  CNY: "Chinese Yuan (CNY)",
  CZK: "Czech Koruna (CZK)",
  DKK: "Danish Krone (DKK)",
  EUR: "Euro (EUR)",
  HKD: "Hong Kong Dollar (HKD)",
  HUF: "Hungarian Forint (HUF)",
  INR: "Indian Rupee (INR)",
  ILS: "Israeli New Shekel (ILS)",
  JPY: "Japanese Yen (JPY)",
  MYR: "Malaysian Ringgit (MYR)",
  MXN: "Mexican Peso (MXN)",
  TWD: "New Taiwan Dollar (TWD)",
  NZD: "New Zealand Dollar (NZD)",
  NOK: "Norwegian Krone (NOK)",
  PHP: "Philippine Peso (PHP)",
  PLN: "Polish Złoty (PLN)",
  GBP: "Pound Sterling (GBP)",
  RUB: "Russian Ruble (RBP)",
  SGD: "Singapore Dollar (SGD)",
  SEK: "Swedish Krona (SEK)",
  CHF: "Swiss Franc (CHF)",
  THB: "Thai Baht (THB)",
  USD: "United States Dollar (USD)",
};

export const REIMBURSEMENT_SELECT_OPTIONS: { label: string; value: string }[] =
  Object.entries(REIMBURSEMENT_CURRENCIES).map(([k, v]) => {
    return {
      label: v,
      value: k,
    } as { label: string; value: string };
  });

export const REIMBURSEMENT_METHODS = [
  {
    label: "PayPal",
    value: "paypal",
  },
  {
    label: "Interac e-Transfer",
    value: "cadETransfer",
  },
];

export const BUS_LOCATIONS = [
  "University of Ottawa",
  "Queen's University",
  "University of Toronto (St. George)",
  "Western University",
  "University of Windsor",
  "UMich Ann Arbor",
  "Pearson Airport",
];
