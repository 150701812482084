/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type FindBusesByLocationQueryVariables = Types.Exact<{
  location: Types.Scalars["String"];
}>;

export type FindBusesByLocationQuery = {
  findBusesByLocation: Array<
    Pick<Types.Bus, "id" | "seats_available"> & {
      stops?: Types.Maybe<
        Array<Pick<Types.BusStop, "id" | "name" | "address" | "departure_time">>
      >;
    }
  >;
};

export const FindBusesByLocationDocument = gql`
  query FindBusesByLocation($location: String!) {
    findBusesByLocation(location: $location) {
      id
      seats_available
      stops {
        id
        name
        address
        departure_time
      }
    }
  }
`;

/**
 * __useFindBusesByLocationQuery__
 *
 * To run a query within a React component, call `useFindBusesByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBusesByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBusesByLocationQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useFindBusesByLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >(FindBusesByLocationDocument, baseOptions);
}
export function useFindBusesByLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >(FindBusesByLocationDocument, baseOptions);
}
export type FindBusesByLocationQueryHookResult = ReturnType<
  typeof useFindBusesByLocationQuery
>;
export type FindBusesByLocationLazyQueryHookResult = ReturnType<
  typeof useFindBusesByLocationLazyQuery
>;
export type FindBusesByLocationQueryResult = ApolloReactCommon.QueryResult<
  FindBusesByLocationQuery,
  FindBusesByLocationQueryVariables
>;
