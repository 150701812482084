/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetExtendedProfileQueryVariables = Types.Exact<{
  user_id: Types.Scalars["Float"];
}>;

export type GetExtendedProfileQuery = {
  getExtendedProfile?: Types.Maybe<{
    scannee?: Types.Maybe<
      Pick<Types.Claim, "user_id"> & {
        fields_HACKTHENORTH2024_EXTENDED_PROFILES: {
          answers: {
            interests: Pick<Types.StringFieldAnswerTypeWrapper, "value">;
          };
        };
      }
    >;
  }>;
};

export const GetExtendedProfileDocument = gql`
  query GetExtendedProfile($user_id: Float!) {
    getExtendedProfile(user_id: $user_id) {
      scannee {
        user_id
        fields_HACKTHENORTH2024_EXTENDED_PROFILES {
          answers {
            interests {
              value
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetExtendedProfileQuery__
 *
 * To run a query within a React component, call `useGetExtendedProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendedProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendedProfileQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetExtendedProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetExtendedProfileQuery,
    GetExtendedProfileQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetExtendedProfileQuery,
    GetExtendedProfileQueryVariables
  >(GetExtendedProfileDocument, baseOptions);
}
export function useGetExtendedProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetExtendedProfileQuery,
    GetExtendedProfileQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetExtendedProfileQuery,
    GetExtendedProfileQueryVariables
  >(GetExtendedProfileDocument, baseOptions);
}
export type GetExtendedProfileQueryHookResult = ReturnType<
  typeof useGetExtendedProfileQuery
>;
export type GetExtendedProfileLazyQueryHookResult = ReturnType<
  typeof useGetExtendedProfileLazyQuery
>;
export type GetExtendedProfileQueryResult = ApolloReactCommon.QueryResult<
  GetExtendedProfileQuery,
  GetExtendedProfileQueryVariables
>;
