/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type GetFavoritedHackersQueryVariables = Types.Exact<{
  eventSlug: Types.Scalars["String"];
  favoritedHackerIds?: Types.Maybe<
    Array<Types.Scalars["Int"]> | Types.Scalars["Int"]
  >;
}>;

export type GetFavoritedHackersQuery = {
  claims: Array<
    Pick<Types.Claim, "id"> & {
      fields_HACKTHENORTH2024_APPLICATIONS: {
        answers: {
          short_name: Pick<Types.StringFieldAnswerTypeWrapper, "value">;
        };
      };
    }
  >;
};

export const GetFavoritedHackersDocument = gql`
  query GetFavoritedHackers($eventSlug: String!, $favoritedHackerIds: [Int!]) {
    claims(
      where: {
        pipeline: { is: { slug: { equals: $eventSlug } } }
        id: { in: $favoritedHackerIds }
      }
    ) {
      id
      fields_HACKTHENORTH2024_APPLICATIONS {
        answers {
          short_name {
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetFavoritedHackersQuery__
 *
 * To run a query within a React component, call `useGetFavoritedHackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritedHackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritedHackersQuery({
 *   variables: {
 *      eventSlug: // value for 'eventSlug'
 *      favoritedHackerIds: // value for 'favoritedHackerIds'
 *   },
 * });
 */
export function useGetFavoritedHackersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFavoritedHackersQuery,
    GetFavoritedHackersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetFavoritedHackersQuery,
    GetFavoritedHackersQueryVariables
  >(GetFavoritedHackersDocument, baseOptions);
}
export function useGetFavoritedHackersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFavoritedHackersQuery,
    GetFavoritedHackersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetFavoritedHackersQuery,
    GetFavoritedHackersQueryVariables
  >(GetFavoritedHackersDocument, baseOptions);
}
export type GetFavoritedHackersQueryHookResult = ReturnType<
  typeof useGetFavoritedHackersQuery
>;
export type GetFavoritedHackersLazyQueryHookResult = ReturnType<
  typeof useGetFavoritedHackersLazyQuery
>;
export type GetFavoritedHackersQueryResult = ApolloReactCommon.QueryResult<
  GetFavoritedHackersQuery,
  GetFavoritedHackersQueryVariables
>;
