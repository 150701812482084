import { Flex, Spacer, Text } from "@hackthenorth/north";
import { Link, TextInput } from "north.js";
import React, { ChangeEvent, useState } from "react";
import { styled } from "twin.macro";

import DateTimePicker from "src/shared/components/DateTimePicker";
import Icon from "src/shared/components/Icon";
import Label from "src/shared/components/Label";
import { Field } from "src/shared/contexts/HackerContext/types";

import { VALIDATORS } from "./constants";
import { SectionHeader } from "./flightForm";

type FlightDetailsPropsT = {
  showError: boolean;
  day: string;
  fields: {
    airport: Field;
    flightNo: Field;
    airline: Field;
    arrivalTime: Field;
  };
  errors: any;
  setErrors: any;
  setResponsesState: any;
  responsesState: any;
};

export const FlightDetails: React.FC<FlightDetailsPropsT> = ({
  showError,
  day,
  fields,
  errors,
  setErrors,
  setResponsesState,
  responsesState,
}) => {
  const isArrival = day === "Friday";
  const arrivalOrDeparture = isArrival ? "arrival" : "departure";

  const timehint = isArrival ? (
    <span>
      We recommend you arrive by <b>6:00 PM EDT</b> on{" "}
      <b>Friday, September 13</b>. Our shuttles only accommodate hackers on
      flights that arrive by 6:00 PM. If your flight arrives{" "}
      <b>after 6:00 PM, you may need to arrange your own transportation</b> to
      Hack the North from the airport. If you are arriving internationally and
      find it easier to arrive shortly after 6:00 PM, please email us at{" "}
      <StyledLink href="mailto:travel@hackthenorth.com">
        travel@hackthenorth.com
      </StyledLink>
    </span>
  ) : (
    <span>
      We recommend you depart{" "}
      <b>no earlier than 7:00 PM EDT on Sunday, September 15</b>. Our shuttles
      only accommodate hackers on flights that
      <b> depart after 7:00 PM from Toronto Pearson Airport</b>. If your flight
      departs before 7:00 PM, you will need to take a taxi to the airport.
    </span>
  );

  const timeCloserTo = isArrival
    ? " 6:00 PM EDT on Friday, September 13"
    : " 7:00 PM EDT on Sunday, September 15";

  const arrivalDate = new Date(responsesState[fields.arrivalTime]);
  const dateError = isArrival
    ? !(
        arrivalDate >= new Date("2024-09-08T18:00:00") &&
        arrivalDate <= new Date("2024-09-14T19:00:00")
      )
    : !(
        arrivalDate >= new Date("2024-09-14T19:00:00") &&
        arrivalDate <= new Date("2024-09-20T19:00:00")
      );

  const [didEdit, setDidEdit] = useState({
    airport: false,
    flightNo: false,
    airline: false,
    time: false,
  });

  return (
    <div>
      <SectionHeader>{day} flight details</SectionHeader>
      <Spacer height={24} />
      <SectionContainer>
        <Label
          value={`${
            day === "Friday"
              ? "What airport are you flying to?"
              : "What airport are you flying from?"
          }`}
        >
          <StyledTextInput
            value={responsesState[fields.airport]}
            hint={"Please enter the airport code (i.e. YYZ)"}
            error={didEdit.airport || showError ? errors[fields.airport] : null}
            placeholder={"Enter your arrival airport"}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDidEdit({ ...didEdit, airport: true });
              const validator = VALIDATORS[fields.airport];
              if (validator !== undefined) {
                setErrors({
                  ...errors,
                  [fields.airport]: validator(e.target.value)
                    ? ""
                    : "Please enter a 3-letter airport code.",
                });
              }
              setResponsesState(fields.airport, e.target.value);
            }}
          />
        </Label>
        <Label
          value={`What is the flight number of your ${
            isArrival ? "arriving" : "departing"
          } flight?`}
        >
          <StyledTextInput
            value={responsesState[fields.flightNo]}
            placeholder={"Enter your flight number"}
            hint={
              "Please enter the full flight number exactly (i.e. if it looks like AC592, please enter AC592 exactly)."
            }
            error={
              didEdit.flightNo || showError ? errors[fields.flightNo] : null
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDidEdit({ ...didEdit, flightNo: true });
              const validator = VALIDATORS[fields.flightNo];
              if (validator !== undefined) {
                setErrors({
                  ...errors,
                  [fields.flightNo]: validator(e.target.value)
                    ? ""
                    : "Please enter a flight number.",
                });
              }
              setResponsesState(fields.flightNo, e.target.value);
            }}
          />
        </Label>
        <Label value={"Which airline will you be flying with?"}>
          <StyledTextInput
            value={responsesState[fields.airline]}
            placeholder={"Enter the full airline name"}
            hint={"Please enter the full airline name."}
            error={didEdit.airline || showError ? errors[fields.airline] : null}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDidEdit({ ...didEdit, airline: true });
              const validator = VALIDATORS[fields.airline];
              if (validator !== undefined) {
                setErrors({
                  ...errors,
                  [fields.airline]: validator(e.target.value)
                    ? ""
                    : "Please enter the airline name.",
                });
              }
              setResponsesState(fields.airline, e.target.value);
            }}
          />
        </Label>
        <Label
          value={`What is your ${arrivalOrDeparture} date and time (in EDT)?`}
          caption={timehint}
        >
          <DateTimePicker
            value={responsesState[fields.arrivalTime]}
            onChange={(date: any) => {
              setDidEdit({ ...didEdit, time: true });
              const validator = VALIDATORS[fields.arrivalTime];
              if (validator !== undefined) {
                setErrors({
                  ...errors,
                  [fields.arrivalTime]: validator(date)
                    ? ""
                    : `Please enter a valid ${arrivalOrDeparture} time.`,
                });
                setResponsesState(fields.arrivalTime, date);
              }
            }}
            error={
              didEdit.time || showError ? errors[fields.arrivalTime] : null
            }
            placeholder={
              isArrival
                ? "Enter your arrival date and time"
                : "Enter your departure date and time"
            }
          />
        </Label>
        {responsesState[fields.arrivalTime] && dateError && (
          <ChangeDateBadge timeCloserTo={timeCloserTo} />
        )}
      </SectionContainer>
    </div>
  );
};

interface ChangeDateBadgeProps {
  timeCloserTo: string;
}

const ChangeDateBadge: React.FC<ChangeDateBadgeProps> = ({ timeCloserTo }) => {
  return (
    <InfoBadge color="primary-light">
      <div style={{ width: "32px", height: "32px" }}>
        <Icon name="info" size="heading" />
      </div>
      <Text mods="big medium blue lh-regular">
        This time is too far from the event to qualify for reimbursement under
        our{" "}
        <Link href="https://hackthenorth.com/travel-guidelines">
          Travel Guidelines
        </Link>
        . Please enter a time closer to
        {timeCloserTo} if you wish to be reimbursed.
      </Text>
    </InfoBadge>
  );
};

const SectionContainer = styled(Flex).attrs({
  column: true,
  justify: "space-between",
})`
  gap: 24px;
`;

const StyledLink = styled(Link)`
  font-size: 12px !important;
  font-weight: bold;
`;

const StyledTextInput = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;

const InfoBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;

  background: ${({ theme }) =>
    theme.globalConstants.color.backgroundPrimaryLight};
`;
