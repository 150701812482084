import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkTextInput,
  PerkTextArea,
  PerkError,
  PerkSelect,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

export const LIGHTNING_CHALLENGE_VALIDATION_FIELDS: (keyof SponsorPerksData)[] =
  [
    "lightning_challenge_description",
    "lightning_challenge_representative_name",
    "lightning_challenge_representative_email",
    "lightning_challenge_time_one",
    "lightning_challenge_time_two",
    "lightning_challenge_time_three",
  ];

export const LightningChallenge: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const challengeDate1 = getState("lightning_challenge_time_one");
  const challengeDate2 = getState("lightning_challenge_time_two");
  const challengeDate3 = getState("lightning_challenge_time_three");

  const challengeDateError = (challengeDate1 === challengeDate2 ||
    challengeDate1 === challengeDate3 ||
    challengeDate2 === challengeDate3) as boolean;

  return (
    <>
      <Header
        title="Lightning Challenge"
        subtitle="This perk is for a 30 to 45-minute Lightning Challenge event during the weekend of Hack the North."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("lightning_challenge_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("lightning_challenge_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.LIGHTNING_CHALLENGE}
        readOnly={isReadOnly}
      >
        <PerkTextArea
          title="Description* (max 250 words)"
          value={getState("lightning_challenge_description") ?? ""}
          placeholder="Description of lightning challenge (max 250 words)"
          wordLimit={250}
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("lightning_challenge_description", e.target.value)
          }
          error={
            error?.lightning_challenge_description && (
              <>This field is required</>
            )
          }
        />
        <Spacer height={32} />

        {challengeDateError && (
          <PerkError
            error={<>Make sure all your time selections are different</>}
          />
        )}

        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("lightning_challenge_time_one", e.target.value)
          }
          value={getState("lightning_challenge_time_one") ?? "ss"}
          label="Time 1*"
          isReadOnly={isReadOnly}
          error={
            error?.lightning_challenge_time_one && <>This field is required</>
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={32} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("lightning_challenge_time_two", e.target.value)
          }
          value={getState("lightning_challenge_time_two") ?? ""}
          label="Time 2*"
          isReadOnly={isReadOnly}
          error={
            error?.lightning_challenge_time_two && <>This field is required</>
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={32} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("lightning_challenge_time_three", e.target.value)
          }
          value={getState("lightning_challenge_time_three") ?? ""}
          label="Time 3*"
          isReadOnly={isReadOnly}
          error={
            error?.lightning_challenge_time_three && <>This field is required</>
          }
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={32} />
        <PerkTextInput
          title="Who will be running the challenge? (name)*"
          value={getState("lightning_challenge_representative_name") ?? ""}
          placeholder="Representative name"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState(
              "lightning_challenge_representative_name",
              e.target.value
            )
          }
          error={
            error?.lightning_challenge_representative_name && (
              <>This field is required</>
            )
          }
        />
        <Spacer height={32} />
        <PerkTextInput
          title="Who will be running the challenge? (email)*"
          value={getState("lightning_challenge_representative_email") ?? ""}
          placeholder="Representative email"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState(
              "lightning_challenge_representative_email",
              e.target.value
            )
          }
          error={
            error?.lightning_challenge_representative_email && (
              <>This field is required</>
            )
          }
        />
      </PerkContainer>
    </>
  );
};
const Description = () => (
  <>
    <TextDescription>
      The Lightning Challenge is a 30 to 45-minute event during the weekend of
      Hack the North. This is an opportunity for you to challenge hackers with a
      fun and interesting prompt. Many sponsors in the past have advertised a
      small prize to incentivize hackers to come out as well!
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Please provide a description of your planned lightning challenge for our
      event schedule, as well as a preferred start time. Once this perk has been
      moved to the Complete section, your time will be finalized.
    </TextDescription>
  </>
);
