/* eslint-disable @typescript-eslint/camelcase */
import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";

import { useUpdateClaimMutation } from "src/api/claims/updateClaim.generated";
import {
  MENTORSHIP_PROFILES_SLUG,
  MENTORSHIP_REQUESTS_SLUG,
} from "src/shared/constants/event";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer, parseState } from "src/shared/utils/hackerapi";

import { useUserContext } from "../UserContext";

import { MentorshipProfileStage } from "./constants";
import { useCreateMentorProfileMutation } from "./graphql/createMentorProfile.generated";
import { useGetClaimedRequestsLazyQuery } from "./graphql/getClaimedRequests.generated";
import { useGetMentorshipIndividualLazyQuery } from "./graphql/getMentorIndividual.generated";
import * as Mentor from "./types";

export interface MentorContextState {
  profile: Mentor.Profile | null;
  isLoading: boolean;
  loadError?: string;
  isUpdating: boolean;
  updateError?: string;
  updateProfile: (
    data: Partial<MentorContextState["profile"]>
  ) => Promise<unknown>;
  updateRequest: (data: MentorshipRequest) => Promise<unknown>; //TODO
  claimedRequests: MentorshipRequest[];
  createProfile: () => Promise<unknown>;
  refetchClaimedRequests: () => void;
  // refetchProfile: () => Promise<unknown>;
}

const DEFAULT_STATE: MentorContextState = {
  profile: null,
  isLoading: false,
  isUpdating: false,
  updateProfile: () => Promise.reject("No parent MentorContext found"),
  updateRequest: () =>
    Promise.reject("No parent MentorshipRequestContextProvider found"),
  claimedRequests: [],
  createProfile: () => Promise.reject("No parent MentorContext found"),
  refetchClaimedRequests: () => {},
  // refetchProfile: () => Promise.reject("No parent MentorContext found"),
};

const MentorContext: React.Context<MentorContextState> =
  createContext(DEFAULT_STATE);

MentorContext.displayName = "MentorContext";

export const useMentorContext = () => useContext(MentorContext);

export const MentorContextProvider: React.FC = ({ children }) => {
  const { id, info } = useUserContext();

  const [getMentorshipIndividualQuery, { data: mentorData, loading, error }] =
    useGetMentorshipIndividualLazyQuery();
  const [
    updateClaimMutation,
    { loading: updatingProfile, error: updateProfileError },
  ] = useUpdateClaimMutation();
  const [
    getClaimedRequestsQuery,
    { data: requestData, loading: requestsLoading, refetch },
  ] = useGetClaimedRequestsLazyQuery();

  const [createProfileMutation, { loading: createProfileLoading }] =
    useCreateMentorProfileMutation();

  useEffect(() => {
    if (id) {
      getMentorshipIndividualQuery({
        variables: {
          slug: MENTORSHIP_PROFILES_SLUG,
          userId: id.toString(),
        },
      });
      getClaimedRequestsQuery({
        variables: {
          slug: MENTORSHIP_REQUESTS_SLUG,
          mentorId: id.toString(),
          stages: [
            MentorshipRequestStage.CLAIMED,
            MentorshipRequestStage.RESOLVED,
          ],
        },
      });
    }
  }, [id, getClaimedRequestsQuery, getMentorshipIndividualQuery]);

  const refetchClaimedRequests: MentorContextState["refetchClaimedRequests"] =
    useCallback(() => {
      if (id && refetch) {
        refetch({
          mentorId: id.toString(),
          stages: [
            MentorshipRequestStage.CLAIMED,
            MentorshipRequestStage.RESOLVED,
          ],
        });
      }
    }, [id, refetch]);

  const createProfile: MentorContextState["createProfile"] = useCallback(() => {
    if (id && info) {
      return createProfileMutation({
        variables: {
          profileData: {
            pipeline_slug: MENTORSHIP_PROFILES_SLUG,
            stage_slug: MentorshipProfileStage.ACTIVE,
            name: info.name,
            answers: [
              {
                slug: "user_id",
                answer: id,
              },
            ],
          },
        },
      });
    }
    return Promise.resolve();
  }, [createProfileMutation, info, id]);

  const profile: MentorContextState["profile"] = useMemo(() => {
    const individualClaim = mentorData?.claims[0];
    const individualFields = individualClaim?.fields;
    return individualClaim && individualFields
      ? {
          user_id: id as number,
          institution: getFieldAnswer(
            individualFields,
            "institution"
          ) as string,
          pronouns: getFieldAnswer(individualFields, "pronouns") as string[],
          other_pronouns: getFieldAnswer(
            individualFields,
            "other_pronouns"
          ) as string,
          mentorship_topics: getFieldAnswer(
            individualFields,
            "mentorship_topics"
          ) as string[],
        }
      : null;
  }, [mentorData, id]);

  const updateProfile: MentorContextState["updateProfile"] = useCallback(
    (data: Partial<MentorContextState["profile"]>) => {
      if (mentorData) {
        const parsedData = parseState(data);
        return updateClaimMutation({
          variables: {
            updatedData: {
              id: mentorData.claims[0].id as number,
              answers: parsedData,
            },
          },
        });
      }
      return Promise.resolve();
    },
    [mentorData, updateClaimMutation]
  );

  const updateRequest: MentorContextState["updateRequest"] = useCallback(
    (data: MentorshipRequest) => {
      const {
        id,
        title,
        description,
        category,
        priority,
        stage_slug,
        mentor_id,
      } = data;
      const answers = parseState({
        title,
        description,
        category,
        priority,
        mentor_id,
      });
      return updateClaimMutation({
        variables: {
          updatedData: {
            id,
            stage_slug,
            answers,
          },
        },
      });
    },
    [updateClaimMutation]
  );

  const claimedRequests: MentorContextState["claimedRequests"] = useMemo(
    () =>
      requestData?.claims.map((request) => ({
        id: request.id,
        title: getFieldAnswer(request.fields, "title"),
        description: getFieldAnswer(request.fields, "description"),
        category: getFieldAnswer(request.fields, "category"),
        priority: getFieldAnswer(request.fields, "priority"),
        created_at: request.created_at,
        reopened: getFieldAnswer(request.fields, "reopened"),
        mentee_name: getFieldAnswer(request.fields, "mentee_name"),
        mentee_id: getFieldAnswer(request.fields, "mentee_id"),
        mentor_id: getFieldAnswer(request.fields, "mentor_id"),
        stage_slug: request.stage.slug,
      })) ?? [],
    [requestData]
  );

  const mentorState = {
    profile,
    isLoading:
      loading || requestsLoading || updatingProfile || createProfileLoading,
    loadError: error?.message,
    isUpdating: updatingProfile,
    updateError: updateProfileError?.message,
    updateProfile,
    updateRequest,
    claimedRequests,
    createProfile,
    // refetchProfile,
    refetchClaimedRequests,
  };

  return (
    <MentorContext.Provider value={mentorState}>
      {children}
    </MentorContext.Provider>
  );
};
