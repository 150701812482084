export const REIMBURSEMENT_MAPPING = {
  states: {
    "British Columbia": 120.0,
    California: 200.0,
    Quebec: 90.0,
    "New York": 115.0,
    Massachusetts: 135.0,
    Alberta: 100.0,
    Indiana: 150.0,
    "New Jersey": 120.0,
    Texas: 180.0,
    Ohio: 150.0,
    Saskatchewan: 105.0,
    Illinois: 135.0,
    Vermont: 135.0,
    Washington: 135.0,
    Connecticut: 150.0,
    Tennessee: 120.0,
    Louisiana: 170.0,
    Missouri: 170.0,
    "Nova Scotia": 90.0,
    "Rhode Island": 108.0,
    "North Carolina": 130.2,
    Kansas: 135.0,
    Wisconsin: 135.0,
    "New Brunswick": 135.0,
    Maryland: 138.0,
    "District of Columbia": 141.0,
    Virginia: 141.0,
    Arizona: 145.5,
    Nevada: 150.0,
    Oklahoma: 150.0,
    Minnesota: 157.5,
    Georgia: 180.0,
    Pennsylvania: 180.0,
    Colorado: 186.0,
  },
  countries: {
    Germany: 280.0,
    "Hong Kong": 900.0,
    India: 1050.0,
    Luxembourg: 315.0,
    Mexico: 315.0,
    Mozambique: 1400.0,
    Netherlands: 236.25,
    Nigeria: 1250.0,
    Poland: 350.0,
    Spain: 200.0,
    Sweden: 262.5,
    Switzerland: 280.0,
    "United Arab Emirates": 800.0,
    Uzbekistan: 1500.0,
  },
};

export const getReimbursementAmount = (location: string | undefined | null) => {
  if (!location) {
    return {
      amount: 0,
    };
  }
  const rsvpLocation = location.toLowerCase();
  const rsvpParsed = rsvpLocation.split(", ");
  if (rsvpParsed.length >= 2) {
    const stateParsed = rsvpParsed[1];
    for (const state of Object.keys(REIMBURSEMENT_MAPPING.states)) {
      if (stateParsed.includes(state.toLowerCase())) {
        const reimbursementAmount = REIMBURSEMENT_MAPPING.states[state];
        return {
          amount: reimbursementAmount,
        };
      }
    }
  }
  for (const country of Object.keys(REIMBURSEMENT_MAPPING.countries)) {
    if (rsvpLocation.includes(country.toLowerCase())) {
      const reimbursementAmount = REIMBURSEMENT_MAPPING.countries[country];
      return {
        amount: reimbursementAmount,
      };
    }
  }
  // if location not in mapping
  return { amount: -1 };
};
