import { DateTime, DurationInput } from "luxon";

import { formatTimeRangeV2 } from "src/shared/utils/formatTime";
import { THackerAPIDatetime } from "src/shared/utils/hackerapi";
import { timesToOptionsExplicitV2 } from "src/shared/utils/react-select";

const LIGHTNING_CHALLENGE_TIMES: [THackerAPIDatetime, DurationInput][] = [
  [DateTime.utc(2024, 9, 14, 13, 0, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 14, 30, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 16, 0, 0).toISO(), { minutes: 30 }],
  [DateTime.utc(2024, 9, 14, 17, 30, 0).toISO(), { minutes: 30 }],
];

export const TIME_OPTIONS = [
  ...timesToOptionsExplicitV2(formatTimeRangeV2, LIGHTNING_CHALLENGE_TIMES),
  { label: "", value: "" },
];
