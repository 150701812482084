/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcademicInstitution = {
  __typename?: "AcademicInstitution";
  address_id?: Maybe<Scalars["Int"]>;
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
  website?: Maybe<Scalars["String"]>;
  _count?: Maybe<AcademicInstitutionCount>;
};

export type AcademicInstitutionCount = {
  __typename?: "AcademicInstitutionCount";
  educations: Scalars["Int"];
};

export type AcademicInstitutionCountEducationsArgs = {
  where?: Maybe<EducationWhereInput>;
};

export type AcademicInstitutionCreateManyAddressInput = {
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
};

export type AcademicInstitutionCreateManyAddressInputEnvelope = {
  data: Array<AcademicInstitutionCreateManyAddressInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type AcademicInstitutionCreateNestedManyWithoutAddressInput = {
  create?: Maybe<Array<AcademicInstitutionCreateWithoutAddressInput>>;
  connectOrCreate?: Maybe<
    Array<AcademicInstitutionCreateOrConnectWithoutAddressInput>
  >;
  createMany?: Maybe<AcademicInstitutionCreateManyAddressInputEnvelope>;
  connect?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
};

export type AcademicInstitutionCreateNestedOneWithoutEducationsInput = {
  create?: Maybe<AcademicInstitutionCreateWithoutEducationsInput>;
  connectOrCreate?: Maybe<AcademicInstitutionCreateOrConnectWithoutEducationsInput>;
  connect?: Maybe<AcademicInstitutionWhereUniqueInput>;
};

export type AcademicInstitutionCreateOrConnectWithoutAddressInput = {
  where: AcademicInstitutionWhereUniqueInput;
  create: AcademicInstitutionCreateWithoutAddressInput;
};

export type AcademicInstitutionCreateOrConnectWithoutEducationsInput = {
  where: AcademicInstitutionWhereUniqueInput;
  create: AcademicInstitutionCreateWithoutEducationsInput;
};

export type AcademicInstitutionCreateWithoutAddressInput = {
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  educations?: Maybe<EducationCreateNestedManyWithoutAcademic_InstitutionInput>;
};

export type AcademicInstitutionCreateWithoutEducationsInput = {
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressCreateNestedOneWithoutAcademic_InstitutionsInput>;
};

export type AcademicInstitutionListRelationFilter = {
  every?: Maybe<AcademicInstitutionWhereInput>;
  some?: Maybe<AcademicInstitutionWhereInput>;
  none?: Maybe<AcademicInstitutionWhereInput>;
};

export type AcademicInstitutionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum AcademicInstitutionOrderByRelevanceFieldEnum {
  Country = "country",
  CountryCode = "country_code",
  Name = "name",
  Website = "website",
}

export type AcademicInstitutionOrderByRelevanceInput = {
  fields: Array<AcademicInstitutionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type AcademicInstitutionOrderByWithRelationAndSearchRelevanceInput = {
  address_id?: Maybe<SortOrderInput>;
  country?: Maybe<SortOrder>;
  country_code?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  institution_type?: Maybe<SortOrder>;
  is_custom?: Maybe<SortOrder>;
  is_enabled?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  website?: Maybe<SortOrderInput>;
  address?: Maybe<AddressOrderByWithRelationAndSearchRelevanceInput>;
  educations?: Maybe<EducationOrderByRelationAggregateInput>;
  _relevance?: Maybe<AcademicInstitutionOrderByRelevanceInput>;
};

export type AcademicInstitutionRelationFilter = {
  is?: Maybe<AcademicInstitutionWhereInput>;
  isNot?: Maybe<AcademicInstitutionWhereInput>;
};

export type AcademicInstitutionScalarWhereInput = {
  AND?: Maybe<Array<AcademicInstitutionScalarWhereInput>>;
  OR?: Maybe<Array<AcademicInstitutionScalarWhereInput>>;
  NOT?: Maybe<Array<AcademicInstitutionScalarWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFilter>;
  is_custom?: Maybe<BoolFilter>;
  is_enabled?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
};

export type AcademicInstitutionUpdateManyMutationInput = {
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFieldUpdateOperationsInput>;
  is_custom?: Maybe<BoolFieldUpdateOperationsInput>;
  is_enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type AcademicInstitutionUpdateManyWithWhereWithoutAddressInput = {
  where: AcademicInstitutionScalarWhereInput;
  data: AcademicInstitutionUpdateManyMutationInput;
};

export type AcademicInstitutionUpdateManyWithoutAddressNestedInput = {
  create?: Maybe<Array<AcademicInstitutionCreateWithoutAddressInput>>;
  connectOrCreate?: Maybe<
    Array<AcademicInstitutionCreateOrConnectWithoutAddressInput>
  >;
  upsert?: Maybe<
    Array<AcademicInstitutionUpsertWithWhereUniqueWithoutAddressInput>
  >;
  createMany?: Maybe<AcademicInstitutionCreateManyAddressInputEnvelope>;
  set?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
  disconnect?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
  delete?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
  connect?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
  update?: Maybe<
    Array<AcademicInstitutionUpdateWithWhereUniqueWithoutAddressInput>
  >;
  updateMany?: Maybe<
    Array<AcademicInstitutionUpdateManyWithWhereWithoutAddressInput>
  >;
  deleteMany?: Maybe<Array<AcademicInstitutionScalarWhereInput>>;
};

export type AcademicInstitutionUpdateOneRequiredWithoutEducationsNestedInput = {
  create?: Maybe<AcademicInstitutionCreateWithoutEducationsInput>;
  connectOrCreate?: Maybe<AcademicInstitutionCreateOrConnectWithoutEducationsInput>;
  upsert?: Maybe<AcademicInstitutionUpsertWithoutEducationsInput>;
  connect?: Maybe<AcademicInstitutionWhereUniqueInput>;
  update?: Maybe<AcademicInstitutionUpdateToOneWithWhereWithoutEducationsInput>;
};

export type AcademicInstitutionUpdateToOneWithWhereWithoutEducationsInput = {
  where?: Maybe<AcademicInstitutionWhereInput>;
  data: AcademicInstitutionUpdateWithoutEducationsInput;
};

export type AcademicInstitutionUpdateWithWhereUniqueWithoutAddressInput = {
  where: AcademicInstitutionWhereUniqueInput;
  data: AcademicInstitutionUpdateWithoutAddressInput;
};

export type AcademicInstitutionUpdateWithoutAddressInput = {
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFieldUpdateOperationsInput>;
  is_custom?: Maybe<BoolFieldUpdateOperationsInput>;
  is_enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  educations?: Maybe<EducationUpdateManyWithoutAcademic_InstitutionNestedInput>;
};

export type AcademicInstitutionUpdateWithoutEducationsInput = {
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFieldUpdateOperationsInput>;
  is_custom?: Maybe<BoolFieldUpdateOperationsInput>;
  is_enabled?: Maybe<BoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  address?: Maybe<AddressUpdateOneWithoutAcademic_InstitutionsNestedInput>;
};

export type AcademicInstitutionUpsertWithWhereUniqueWithoutAddressInput = {
  where: AcademicInstitutionWhereUniqueInput;
  update: AcademicInstitutionUpdateWithoutAddressInput;
  create: AcademicInstitutionCreateWithoutAddressInput;
};

export type AcademicInstitutionUpsertWithoutEducationsInput = {
  update: AcademicInstitutionUpdateWithoutEducationsInput;
  create: AcademicInstitutionCreateWithoutEducationsInput;
  where?: Maybe<AcademicInstitutionWhereInput>;
};

export type AcademicInstitutionWhereInput = {
  AND?: Maybe<Array<AcademicInstitutionWhereInput>>;
  OR?: Maybe<Array<AcademicInstitutionWhereInput>>;
  NOT?: Maybe<Array<AcademicInstitutionWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFilter>;
  is_custom?: Maybe<BoolFilter>;
  is_enabled?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  address?: Maybe<AddressNullableRelationFilter>;
  educations?: Maybe<EducationListRelationFilter>;
};

export type AcademicInstitutionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<AcademicInstitutionWhereInput>>;
  OR?: Maybe<Array<AcademicInstitutionWhereInput>>;
  NOT?: Maybe<Array<AcademicInstitutionWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFilter>;
  is_custom?: Maybe<BoolFilter>;
  is_enabled?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  address?: Maybe<AddressNullableRelationFilter>;
  educations?: Maybe<EducationListRelationFilter>;
};

/** Add a new sponsor company */
export type AddSponsorCompanyInput = {
  company_id: Scalars["Float"];
  tier: Scalars["String"];
  perks: Array<PerkClaimInput>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  primary_contact_email?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
};

export type AddressCreateNestedOneWithoutAcademic_InstitutionsInput = {
  create?: Maybe<AddressCreateWithoutAcademic_InstitutionsInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAcademic_InstitutionsInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressCreateNestedOneWithoutUsers_As_AddressInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_AddressInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_AddressInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput =
  {
    create?: Maybe<AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput>;
    connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_Emergency_Contact_AddressInput>;
    connect?: Maybe<AddressWhereUniqueInput>;
  };

export type AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_Shipping_AddressInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_Shipping_AddressInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
};

export type AddressCreateOrConnectWithoutAcademic_InstitutionsInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutAcademic_InstitutionsInput;
};

export type AddressCreateOrConnectWithoutUsers_As_AddressInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUsers_As_AddressInput;
};

export type AddressCreateOrConnectWithoutUsers_As_Emergency_Contact_AddressInput =
  {
    where: AddressWhereUniqueInput;
    create: AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput;
  };

export type AddressCreateOrConnectWithoutUsers_As_Shipping_AddressInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUsers_As_Shipping_AddressInput;
};

export type AddressCreateWithoutAcademic_InstitutionsInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  zip_postal_code: Scalars["String"];
  users_as_address?: Maybe<UserCreateNestedManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateNestedManyWithoutEmergency_Contact_AddressInput>;
  users_as_shipping_address?: Maybe<UserCreateNestedManyWithoutShipping_AddressInput>;
};

export type AddressCreateWithoutUsers_As_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateNestedManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateNestedManyWithoutEmergency_Contact_AddressInput>;
  users_as_shipping_address?: Maybe<UserCreateNestedManyWithoutShipping_AddressInput>;
};

export type AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateNestedManyWithoutAddressInput>;
  users_as_address?: Maybe<UserCreateNestedManyWithoutAddressInput>;
  users_as_shipping_address?: Maybe<UserCreateNestedManyWithoutShipping_AddressInput>;
};

export type AddressCreateWithoutUsers_As_Shipping_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateNestedManyWithoutAddressInput>;
  users_as_address?: Maybe<UserCreateNestedManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateNestedManyWithoutEmergency_Contact_AddressInput>;
};

export type AddressNullableRelationFilter = {
  is?: Maybe<AddressWhereInput>;
  isNot?: Maybe<AddressWhereInput>;
};

export enum AddressOrderByRelevanceFieldEnum {
  City = "city",
  Country = "country",
  CountryCode = "country_code",
  Line_1 = "line_1",
  Line_2 = "line_2",
  Line_3 = "line_3",
  StateProvince = "state_province",
  ZipPostalCode = "zip_postal_code",
}

export type AddressOrderByRelevanceInput = {
  fields: Array<AddressOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type AddressOrderByWithRelationAndSearchRelevanceInput = {
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  country_code?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrderInput>;
  line_1?: Maybe<SortOrder>;
  line_2?: Maybe<SortOrderInput>;
  line_3?: Maybe<SortOrderInput>;
  longitude?: Maybe<SortOrderInput>;
  state_province?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  zip_postal_code?: Maybe<SortOrder>;
  academic_institutions?: Maybe<AcademicInstitutionOrderByRelationAggregateInput>;
  users_as_address?: Maybe<UserOrderByRelationAggregateInput>;
  users_as_emergency_contact_address?: Maybe<UserOrderByRelationAggregateInput>;
  users_as_shipping_address?: Maybe<UserOrderByRelationAggregateInput>;
  _relevance?: Maybe<AddressOrderByRelevanceInput>;
};

export type AddressUpdateOneWithoutAcademic_InstitutionsNestedInput = {
  create?: Maybe<AddressCreateWithoutAcademic_InstitutionsInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutAcademic_InstitutionsInput>;
  upsert?: Maybe<AddressUpsertWithoutAcademic_InstitutionsInput>;
  disconnect?: Maybe<AddressWhereInput>;
  delete?: Maybe<AddressWhereInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  update?: Maybe<AddressUpdateToOneWithWhereWithoutAcademic_InstitutionsInput>;
};

export type AddressUpdateOneWithoutUsers_As_AddressNestedInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_AddressInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_AddressInput>;
  upsert?: Maybe<AddressUpsertWithoutUsers_As_AddressInput>;
  disconnect?: Maybe<AddressWhereInput>;
  delete?: Maybe<AddressWhereInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  update?: Maybe<AddressUpdateToOneWithWhereWithoutUsers_As_AddressInput>;
};

export type AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput =
  {
    create?: Maybe<AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput>;
    connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_Emergency_Contact_AddressInput>;
    upsert?: Maybe<AddressUpsertWithoutUsers_As_Emergency_Contact_AddressInput>;
    disconnect?: Maybe<AddressWhereInput>;
    delete?: Maybe<AddressWhereInput>;
    connect?: Maybe<AddressWhereUniqueInput>;
    update?: Maybe<AddressUpdateToOneWithWhereWithoutUsers_As_Emergency_Contact_AddressInput>;
  };

export type AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_Shipping_AddressInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutUsers_As_Shipping_AddressInput>;
  upsert?: Maybe<AddressUpsertWithoutUsers_As_Shipping_AddressInput>;
  disconnect?: Maybe<AddressWhereInput>;
  delete?: Maybe<AddressWhereInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  update?: Maybe<AddressUpdateToOneWithWhereWithoutUsers_As_Shipping_AddressInput>;
};

export type AddressUpdateToOneWithWhereWithoutAcademic_InstitutionsInput = {
  where?: Maybe<AddressWhereInput>;
  data: AddressUpdateWithoutAcademic_InstitutionsInput;
};

export type AddressUpdateToOneWithWhereWithoutUsers_As_AddressInput = {
  where?: Maybe<AddressWhereInput>;
  data: AddressUpdateWithoutUsers_As_AddressInput;
};

export type AddressUpdateToOneWithWhereWithoutUsers_As_Emergency_Contact_AddressInput =
  {
    where?: Maybe<AddressWhereInput>;
    data: AddressUpdateWithoutUsers_As_Emergency_Contact_AddressInput;
  };

export type AddressUpdateToOneWithWhereWithoutUsers_As_Shipping_AddressInput = {
  where?: Maybe<AddressWhereInput>;
  data: AddressUpdateWithoutUsers_As_Shipping_AddressInput;
};

export type AddressUpdateWithoutAcademic_InstitutionsInput = {
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  line_1?: Maybe<StringFieldUpdateOperationsInput>;
  line_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  line_3?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  state_province?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  zip_postal_code?: Maybe<StringFieldUpdateOperationsInput>;
  users_as_address?: Maybe<UserUpdateManyWithoutAddressNestedInput>;
  users_as_emergency_contact_address?: Maybe<UserUpdateManyWithoutEmergency_Contact_AddressNestedInput>;
  users_as_shipping_address?: Maybe<UserUpdateManyWithoutShipping_AddressNestedInput>;
};

export type AddressUpdateWithoutUsers_As_AddressInput = {
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  line_1?: Maybe<StringFieldUpdateOperationsInput>;
  line_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  line_3?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  state_province?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  zip_postal_code?: Maybe<StringFieldUpdateOperationsInput>;
  academic_institutions?: Maybe<AcademicInstitutionUpdateManyWithoutAddressNestedInput>;
  users_as_emergency_contact_address?: Maybe<UserUpdateManyWithoutEmergency_Contact_AddressNestedInput>;
  users_as_shipping_address?: Maybe<UserUpdateManyWithoutShipping_AddressNestedInput>;
};

export type AddressUpdateWithoutUsers_As_Emergency_Contact_AddressInput = {
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  line_1?: Maybe<StringFieldUpdateOperationsInput>;
  line_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  line_3?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  state_province?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  zip_postal_code?: Maybe<StringFieldUpdateOperationsInput>;
  academic_institutions?: Maybe<AcademicInstitutionUpdateManyWithoutAddressNestedInput>;
  users_as_address?: Maybe<UserUpdateManyWithoutAddressNestedInput>;
  users_as_shipping_address?: Maybe<UserUpdateManyWithoutShipping_AddressNestedInput>;
};

export type AddressUpdateWithoutUsers_As_Shipping_AddressInput = {
  city?: Maybe<StringFieldUpdateOperationsInput>;
  country?: Maybe<StringFieldUpdateOperationsInput>;
  country_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  latitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  line_1?: Maybe<StringFieldUpdateOperationsInput>;
  line_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  line_3?: Maybe<NullableStringFieldUpdateOperationsInput>;
  longitude?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  state_province?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  zip_postal_code?: Maybe<StringFieldUpdateOperationsInput>;
  academic_institutions?: Maybe<AcademicInstitutionUpdateManyWithoutAddressNestedInput>;
  users_as_address?: Maybe<UserUpdateManyWithoutAddressNestedInput>;
  users_as_emergency_contact_address?: Maybe<UserUpdateManyWithoutEmergency_Contact_AddressNestedInput>;
};

export type AddressUpsertWithoutAcademic_InstitutionsInput = {
  update: AddressUpdateWithoutAcademic_InstitutionsInput;
  create: AddressCreateWithoutAcademic_InstitutionsInput;
  where?: Maybe<AddressWhereInput>;
};

export type AddressUpsertWithoutUsers_As_AddressInput = {
  update: AddressUpdateWithoutUsers_As_AddressInput;
  create: AddressCreateWithoutUsers_As_AddressInput;
  where?: Maybe<AddressWhereInput>;
};

export type AddressUpsertWithoutUsers_As_Emergency_Contact_AddressInput = {
  update: AddressUpdateWithoutUsers_As_Emergency_Contact_AddressInput;
  create: AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput;
  where?: Maybe<AddressWhereInput>;
};

export type AddressUpsertWithoutUsers_As_Shipping_AddressInput = {
  update: AddressUpdateWithoutUsers_As_Shipping_AddressInput;
  create: AddressCreateWithoutUsers_As_Shipping_AddressInput;
  where?: Maybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  line_1?: Maybe<StringFilter>;
  line_2?: Maybe<StringNullableFilter>;
  line_3?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  state_province?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  zip_postal_code?: Maybe<StringFilter>;
  academic_institutions?: Maybe<AcademicInstitutionListRelationFilter>;
  users_as_address?: Maybe<UserListRelationFilter>;
  users_as_emergency_contact_address?: Maybe<UserListRelationFilter>;
  users_as_shipping_address?: Maybe<UserListRelationFilter>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  line_1?: Maybe<StringFilter>;
  line_2?: Maybe<StringNullableFilter>;
  line_3?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  state_province?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  zip_postal_code?: Maybe<StringFilter>;
  academic_institutions?: Maybe<AcademicInstitutionListRelationFilter>;
  users_as_address?: Maybe<UserListRelationFilter>;
  users_as_emergency_contact_address?: Maybe<UserListRelationFilter>;
  users_as_shipping_address?: Maybe<UserListRelationFilter>;
};

export type AggregateStatistics = {
  __typename?: "AggregateStatistics";
  count: Scalars["Float"];
};

export type AuthResponse = {
  __typename?: "AuthResponse";
  user: User;
  accessJwt: Scalars["String"];
  refreshJwt: Scalars["String"];
};

export type AuthTokenCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  expire_time: Scalars["DateTimeISO"];
  id?: Maybe<Scalars["Int"]>;
  token: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type AuthTokenCreateManyUserInputEnvelope = {
  data: Array<AuthTokenCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type AuthTokenCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<AuthTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AuthTokenCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<AuthTokenCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<AuthTokenWhereUniqueInput>>;
};

export type AuthTokenCreateOrConnectWithoutUserInput = {
  where: AuthTokenWhereUniqueInput;
  create: AuthTokenCreateWithoutUserInput;
};

export type AuthTokenCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  expire_time: Scalars["DateTimeISO"];
  token: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type AuthTokenListRelationFilter = {
  every?: Maybe<AuthTokenWhereInput>;
  some?: Maybe<AuthTokenWhereInput>;
  none?: Maybe<AuthTokenWhereInput>;
};

export type AuthTokenOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type AuthTokenScalarWhereInput = {
  AND?: Maybe<Array<AuthTokenScalarWhereInput>>;
  OR?: Maybe<Array<AuthTokenScalarWhereInput>>;
  NOT?: Maybe<Array<AuthTokenScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  expire_time?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  token?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type AuthTokenUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expire_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AuthTokenUpdateManyWithWhereWithoutUserInput = {
  where: AuthTokenScalarWhereInput;
  data: AuthTokenUpdateManyMutationInput;
};

export type AuthTokenUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<AuthTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<AuthTokenCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<AuthTokenUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<AuthTokenCreateManyUserInputEnvelope>;
  set?: Maybe<Array<AuthTokenWhereUniqueInput>>;
  disconnect?: Maybe<Array<AuthTokenWhereUniqueInput>>;
  delete?: Maybe<Array<AuthTokenWhereUniqueInput>>;
  connect?: Maybe<Array<AuthTokenWhereUniqueInput>>;
  update?: Maybe<Array<AuthTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<AuthTokenUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<AuthTokenScalarWhereInput>>;
};

export type AuthTokenUpdateWithWhereUniqueWithoutUserInput = {
  where: AuthTokenWhereUniqueInput;
  data: AuthTokenUpdateWithoutUserInput;
};

export type AuthTokenUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  expire_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type AuthTokenUpsertWithWhereUniqueWithoutUserInput = {
  where: AuthTokenWhereUniqueInput;
  update: AuthTokenUpdateWithoutUserInput;
  create: AuthTokenCreateWithoutUserInput;
};

export type AuthTokenWhereInput = {
  AND?: Maybe<Array<AuthTokenWhereInput>>;
  OR?: Maybe<Array<AuthTokenWhereInput>>;
  NOT?: Maybe<Array<AuthTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  expire_time?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  token?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type AuthTokenWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  token?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<AuthTokenWhereInput>>;
  OR?: Maybe<Array<AuthTokenWhereInput>>;
  NOT?: Maybe<Array<AuthTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  expire_time?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type BadgeScan = {
  __typename?: "BadgeScan";
  id: Scalars["Int"];
  scanned_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  scanning_attendee_id: Scalars["Int"];
  scanned_attendee_id: Scalars["Int"];
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  event_id: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
};

export type BadgeScanCreateManyEventInput = {
  id?: Maybe<Scalars["Int"]>;
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  scanning_attendee_id: Scalars["Int"];
  scanned_attendee_id: Scalars["Int"];
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
};

export type BadgeScanCreateManyEventInputEnvelope = {
  data: Array<BadgeScanCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BadgeScanCreateManyScanned_AttendeeInput = {
  id?: Maybe<Scalars["Int"]>;
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  scanning_attendee_id: Scalars["Int"];
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  event_id: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
};

export type BadgeScanCreateManyScanned_AttendeeInputEnvelope = {
  data: Array<BadgeScanCreateManyScanned_AttendeeInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BadgeScanCreateManyScanning_AttendeeInput = {
  id?: Maybe<Scalars["Int"]>;
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  scanned_attendee_id: Scalars["Int"];
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  event_id: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
};

export type BadgeScanCreateManyScanning_AttendeeInputEnvelope = {
  data: Array<BadgeScanCreateManyScanning_AttendeeInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BadgeScanCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<BadgeScanCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<BadgeScanCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
};

export type BadgeScanCreateNestedManyWithoutScanned_AttendeeInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutScanned_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<BadgeScanCreateOrConnectWithoutScanned_AttendeeInput>
  >;
  createMany?: Maybe<BadgeScanCreateManyScanned_AttendeeInputEnvelope>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
};

export type BadgeScanCreateNestedManyWithoutScanning_AttendeeInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutScanning_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<BadgeScanCreateOrConnectWithoutScanning_AttendeeInput>
  >;
  createMany?: Maybe<BadgeScanCreateManyScanning_AttendeeInputEnvelope>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
};

export type BadgeScanCreateNestedOneWithoutEvent_Activity_ScanInput = {
  create?: Maybe<BadgeScanCreateWithoutEvent_Activity_ScanInput>;
  connectOrCreate?: Maybe<BadgeScanCreateOrConnectWithoutEvent_Activity_ScanInput>;
  connect?: Maybe<BadgeScanWhereUniqueInput>;
};

export type BadgeScanCreateNestedOneWithoutSponsor_ScanInput = {
  create?: Maybe<BadgeScanCreateWithoutSponsor_ScanInput>;
  connectOrCreate?: Maybe<BadgeScanCreateOrConnectWithoutSponsor_ScanInput>;
  connect?: Maybe<BadgeScanWhereUniqueInput>;
};

export type BadgeScanCreateOrConnectWithoutEventInput = {
  where: BadgeScanWhereUniqueInput;
  create: BadgeScanCreateWithoutEventInput;
};

export type BadgeScanCreateOrConnectWithoutEvent_Activity_ScanInput = {
  where: BadgeScanWhereUniqueInput;
  create: BadgeScanCreateWithoutEvent_Activity_ScanInput;
};

export type BadgeScanCreateOrConnectWithoutScanned_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  create: BadgeScanCreateWithoutScanned_AttendeeInput;
};

export type BadgeScanCreateOrConnectWithoutScanning_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  create: BadgeScanCreateWithoutScanning_AttendeeInput;
};

export type BadgeScanCreateOrConnectWithoutSponsor_ScanInput = {
  where: BadgeScanWhereUniqueInput;
  create: BadgeScanCreateWithoutSponsor_ScanInput;
};

export type BadgeScanCreateWithoutEventInput = {
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  scanned_attendee: EventAttendeeCreateNestedOneWithoutInbound_ScansInput;
  scanning_attendee: EventAttendeeCreateNestedOneWithoutOutbound_ScansInput;
  event_activity_scan?: Maybe<EventActivityScanCreateNestedOneWithoutBadge_ScanInput>;
  sponsor_scan?: Maybe<SponsorScanCreateNestedOneWithoutBadge_ScanInput>;
};

export type BadgeScanCreateWithoutEvent_Activity_ScanInput = {
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  event: EventCreateNestedOneWithoutBadge_ScansInput;
  scanned_attendee: EventAttendeeCreateNestedOneWithoutInbound_ScansInput;
  scanning_attendee: EventAttendeeCreateNestedOneWithoutOutbound_ScansInput;
  sponsor_scan?: Maybe<SponsorScanCreateNestedOneWithoutBadge_ScanInput>;
};

export type BadgeScanCreateWithoutScanned_AttendeeInput = {
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  event: EventCreateNestedOneWithoutBadge_ScansInput;
  scanning_attendee: EventAttendeeCreateNestedOneWithoutOutbound_ScansInput;
  event_activity_scan?: Maybe<EventActivityScanCreateNestedOneWithoutBadge_ScanInput>;
  sponsor_scan?: Maybe<SponsorScanCreateNestedOneWithoutBadge_ScanInput>;
};

export type BadgeScanCreateWithoutScanning_AttendeeInput = {
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  event: EventCreateNestedOneWithoutBadge_ScansInput;
  scanned_attendee: EventAttendeeCreateNestedOneWithoutInbound_ScansInput;
  event_activity_scan?: Maybe<EventActivityScanCreateNestedOneWithoutBadge_ScanInput>;
  sponsor_scan?: Maybe<SponsorScanCreateNestedOneWithoutBadge_ScanInput>;
};

export type BadgeScanCreateWithoutSponsor_ScanInput = {
  scanned_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  event: EventCreateNestedOneWithoutBadge_ScansInput;
  scanned_attendee: EventAttendeeCreateNestedOneWithoutInbound_ScansInput;
  scanning_attendee: EventAttendeeCreateNestedOneWithoutOutbound_ScansInput;
  event_activity_scan?: Maybe<EventActivityScanCreateNestedOneWithoutBadge_ScanInput>;
};

export type BadgeScanListRelationFilter = {
  every?: Maybe<BadgeScanWhereInput>;
  some?: Maybe<BadgeScanWhereInput>;
  none?: Maybe<BadgeScanWhereInput>;
};

export type BadgeScanOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BadgeScanRelationFilter = {
  is?: Maybe<BadgeScanWhereInput>;
  isNot?: Maybe<BadgeScanWhereInput>;
};

export type BadgeScanScalarWhereInput = {
  AND?: Maybe<Array<BadgeScanScalarWhereInput>>;
  OR?: Maybe<Array<BadgeScanScalarWhereInput>>;
  NOT?: Maybe<Array<BadgeScanScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  scanned_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  scanning_attendee_id?: Maybe<IntFilter>;
  scanned_attendee_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringFilter>;
  type?: Maybe<EnumBadgeScanTypeFilter>;
  claim_number?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  is_duplicate?: Maybe<BoolFilter>;
};

export enum BadgeScanType {
  Registration = "registration",
  ActivityRedemption = "activity_redemption",
  AttendeeNetworking = "attendee_networking",
  SponsorNetworking = "sponsor_networking",
}

export type BadgeScanUpdateManyMutationInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type BadgeScanUpdateManyWithWhereWithoutEventInput = {
  where: BadgeScanScalarWhereInput;
  data: BadgeScanUpdateManyMutationInput;
};

export type BadgeScanUpdateManyWithWhereWithoutScanned_AttendeeInput = {
  where: BadgeScanScalarWhereInput;
  data: BadgeScanUpdateManyMutationInput;
};

export type BadgeScanUpdateManyWithWhereWithoutScanning_AttendeeInput = {
  where: BadgeScanScalarWhereInput;
  data: BadgeScanUpdateManyMutationInput;
};

export type BadgeScanUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<BadgeScanCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<BadgeScanUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<BadgeScanCreateManyEventInputEnvelope>;
  set?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  delete?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  update?: Maybe<Array<BadgeScanUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<BadgeScanUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<BadgeScanScalarWhereInput>>;
};

export type BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutScanned_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<BadgeScanCreateOrConnectWithoutScanned_AttendeeInput>
  >;
  upsert?: Maybe<
    Array<BadgeScanUpsertWithWhereUniqueWithoutScanned_AttendeeInput>
  >;
  createMany?: Maybe<BadgeScanCreateManyScanned_AttendeeInputEnvelope>;
  set?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  delete?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  update?: Maybe<
    Array<BadgeScanUpdateWithWhereUniqueWithoutScanned_AttendeeInput>
  >;
  updateMany?: Maybe<
    Array<BadgeScanUpdateManyWithWhereWithoutScanned_AttendeeInput>
  >;
  deleteMany?: Maybe<Array<BadgeScanScalarWhereInput>>;
};

export type BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput = {
  create?: Maybe<Array<BadgeScanCreateWithoutScanning_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<BadgeScanCreateOrConnectWithoutScanning_AttendeeInput>
  >;
  upsert?: Maybe<
    Array<BadgeScanUpsertWithWhereUniqueWithoutScanning_AttendeeInput>
  >;
  createMany?: Maybe<BadgeScanCreateManyScanning_AttendeeInputEnvelope>;
  set?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  delete?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  connect?: Maybe<Array<BadgeScanWhereUniqueInput>>;
  update?: Maybe<
    Array<BadgeScanUpdateWithWhereUniqueWithoutScanning_AttendeeInput>
  >;
  updateMany?: Maybe<
    Array<BadgeScanUpdateManyWithWhereWithoutScanning_AttendeeInput>
  >;
  deleteMany?: Maybe<Array<BadgeScanScalarWhereInput>>;
};

export type BadgeScanUpdateOneRequiredWithoutEvent_Activity_ScanNestedInput = {
  create?: Maybe<BadgeScanCreateWithoutEvent_Activity_ScanInput>;
  connectOrCreate?: Maybe<BadgeScanCreateOrConnectWithoutEvent_Activity_ScanInput>;
  upsert?: Maybe<BadgeScanUpsertWithoutEvent_Activity_ScanInput>;
  connect?: Maybe<BadgeScanWhereUniqueInput>;
  update?: Maybe<BadgeScanUpdateToOneWithWhereWithoutEvent_Activity_ScanInput>;
};

export type BadgeScanUpdateOneRequiredWithoutSponsor_ScanNestedInput = {
  create?: Maybe<BadgeScanCreateWithoutSponsor_ScanInput>;
  connectOrCreate?: Maybe<BadgeScanCreateOrConnectWithoutSponsor_ScanInput>;
  upsert?: Maybe<BadgeScanUpsertWithoutSponsor_ScanInput>;
  connect?: Maybe<BadgeScanWhereUniqueInput>;
  update?: Maybe<BadgeScanUpdateToOneWithWhereWithoutSponsor_ScanInput>;
};

export type BadgeScanUpdateToOneWithWhereWithoutEvent_Activity_ScanInput = {
  where?: Maybe<BadgeScanWhereInput>;
  data: BadgeScanUpdateWithoutEvent_Activity_ScanInput;
};

export type BadgeScanUpdateToOneWithWhereWithoutSponsor_ScanInput = {
  where?: Maybe<BadgeScanWhereInput>;
  data: BadgeScanUpdateWithoutSponsor_ScanInput;
};

export type BadgeScanUpdateWithWhereUniqueWithoutEventInput = {
  where: BadgeScanWhereUniqueInput;
  data: BadgeScanUpdateWithoutEventInput;
};

export type BadgeScanUpdateWithWhereUniqueWithoutScanned_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  data: BadgeScanUpdateWithoutScanned_AttendeeInput;
};

export type BadgeScanUpdateWithWhereUniqueWithoutScanning_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  data: BadgeScanUpdateWithoutScanning_AttendeeInput;
};

export type BadgeScanUpdateWithoutEventInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
  scanned_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutInbound_ScansNestedInput>;
  scanning_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutOutbound_ScansNestedInput>;
  event_activity_scan?: Maybe<EventActivityScanUpdateOneWithoutBadge_ScanNestedInput>;
  sponsor_scan?: Maybe<SponsorScanUpdateOneWithoutBadge_ScanNestedInput>;
};

export type BadgeScanUpdateWithoutEvent_Activity_ScanInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBadge_ScansNestedInput>;
  scanned_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutInbound_ScansNestedInput>;
  scanning_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutOutbound_ScansNestedInput>;
  sponsor_scan?: Maybe<SponsorScanUpdateOneWithoutBadge_ScanNestedInput>;
};

export type BadgeScanUpdateWithoutScanned_AttendeeInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBadge_ScansNestedInput>;
  scanning_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutOutbound_ScansNestedInput>;
  event_activity_scan?: Maybe<EventActivityScanUpdateOneWithoutBadge_ScanNestedInput>;
  sponsor_scan?: Maybe<SponsorScanUpdateOneWithoutBadge_ScanNestedInput>;
};

export type BadgeScanUpdateWithoutScanning_AttendeeInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBadge_ScansNestedInput>;
  scanned_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutInbound_ScansNestedInput>;
  event_activity_scan?: Maybe<EventActivityScanUpdateOneWithoutBadge_ScanNestedInput>;
  sponsor_scan?: Maybe<SponsorScanUpdateOneWithoutBadge_ScanNestedInput>;
};

export type BadgeScanUpdateWithoutSponsor_ScanInput = {
  scanned_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBadgeScanTypeFieldUpdateOperationsInput>;
  claim_number?: Maybe<IntFieldUpdateOperationsInput>;
  is_duplicate?: Maybe<BoolFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBadge_ScansNestedInput>;
  scanned_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutInbound_ScansNestedInput>;
  scanning_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutOutbound_ScansNestedInput>;
  event_activity_scan?: Maybe<EventActivityScanUpdateOneWithoutBadge_ScanNestedInput>;
};

export type BadgeScanUpsertWithWhereUniqueWithoutEventInput = {
  where: BadgeScanWhereUniqueInput;
  update: BadgeScanUpdateWithoutEventInput;
  create: BadgeScanCreateWithoutEventInput;
};

export type BadgeScanUpsertWithWhereUniqueWithoutScanned_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  update: BadgeScanUpdateWithoutScanned_AttendeeInput;
  create: BadgeScanCreateWithoutScanned_AttendeeInput;
};

export type BadgeScanUpsertWithWhereUniqueWithoutScanning_AttendeeInput = {
  where: BadgeScanWhereUniqueInput;
  update: BadgeScanUpdateWithoutScanning_AttendeeInput;
  create: BadgeScanCreateWithoutScanning_AttendeeInput;
};

export type BadgeScanUpsertWithoutEvent_Activity_ScanInput = {
  update: BadgeScanUpdateWithoutEvent_Activity_ScanInput;
  create: BadgeScanCreateWithoutEvent_Activity_ScanInput;
  where?: Maybe<BadgeScanWhereInput>;
};

export type BadgeScanUpsertWithoutSponsor_ScanInput = {
  update: BadgeScanUpdateWithoutSponsor_ScanInput;
  create: BadgeScanCreateWithoutSponsor_ScanInput;
  where?: Maybe<BadgeScanWhereInput>;
};

export type BadgeScanWhereInput = {
  AND?: Maybe<Array<BadgeScanWhereInput>>;
  OR?: Maybe<Array<BadgeScanWhereInput>>;
  NOT?: Maybe<Array<BadgeScanWhereInput>>;
  id?: Maybe<IntFilter>;
  scanned_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  scanning_attendee_id?: Maybe<IntFilter>;
  scanned_attendee_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringFilter>;
  type?: Maybe<EnumBadgeScanTypeFilter>;
  claim_number?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  is_duplicate?: Maybe<BoolFilter>;
  event?: Maybe<EventRelationFilter>;
  scanned_attendee?: Maybe<EventAttendeeRelationFilter>;
  scanning_attendee?: Maybe<EventAttendeeRelationFilter>;
  event_activity_scan?: Maybe<EventActivityScanNullableRelationFilter>;
  sponsor_scan?: Maybe<SponsorScanNullableRelationFilter>;
};

export type BadgeScanWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<BadgeScanWhereInput>>;
  OR?: Maybe<Array<BadgeScanWhereInput>>;
  NOT?: Maybe<Array<BadgeScanWhereInput>>;
  scanned_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  scanning_attendee_id?: Maybe<IntFilter>;
  scanned_attendee_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringFilter>;
  type?: Maybe<EnumBadgeScanTypeFilter>;
  claim_number?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  is_duplicate?: Maybe<BoolFilter>;
  event?: Maybe<EventRelationFilter>;
  scanned_attendee?: Maybe<EventAttendeeRelationFilter>;
  scanning_attendee?: Maybe<EventAttendeeRelationFilter>;
  event_activity_scan?: Maybe<EventActivityScanNullableRelationFilter>;
  sponsor_scan?: Maybe<SponsorScanNullableRelationFilter>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Boolean"]>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BooleanFieldAnswerTypeWrapper = {
  __typename?: "BooleanFieldAnswerTypeWrapper";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  value?: Maybe<Scalars["Boolean"]>;
};

export type Bus = {
  __typename?: "Bus";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
  seats_total: Scalars["Int"];
  seats_available: Scalars["Int"];
  event_id: Scalars["Int"];
  _count?: Maybe<BusCount>;
  stops?: Maybe<Array<BusStop>>;
};

export type BusAttendee = {
  __typename?: "BusAttendee";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
  user_id: Scalars["Int"];
  bus_id: Scalars["Int"];
  bus_stop_id?: Maybe<Scalars["Int"]>;
  bus_coordinator_volunteer: Scalars["Boolean"];
};

export type BusAttendeeCreateManyBusInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
  bus_stop_id?: Maybe<Scalars["Int"]>;
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateManyBusInputEnvelope = {
  data: Array<BusAttendeeCreateManyBusInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateManyBus_StopInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
  bus_id: Scalars["Int"];
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateManyBus_StopInputEnvelope = {
  data: Array<BusAttendeeCreateManyBus_StopInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  bus_id: Scalars["Int"];
  bus_stop_id?: Maybe<Scalars["Int"]>;
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateManyUserInputEnvelope = {
  data: Array<BusAttendeeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BusAttendeeCreateNestedManyWithoutBusInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutBusInput>>;
  connectOrCreate?: Maybe<Array<BusAttendeeCreateOrConnectWithoutBusInput>>;
  createMany?: Maybe<BusAttendeeCreateManyBusInputEnvelope>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
};

export type BusAttendeeCreateNestedManyWithoutBus_StopInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutBus_StopInput>>;
  connectOrCreate?: Maybe<
    Array<BusAttendeeCreateOrConnectWithoutBus_StopInput>
  >;
  createMany?: Maybe<BusAttendeeCreateManyBus_StopInputEnvelope>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
};

export type BusAttendeeCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BusAttendeeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<BusAttendeeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
};

export type BusAttendeeCreateOrConnectWithoutBusInput = {
  where: BusAttendeeWhereUniqueInput;
  create: BusAttendeeCreateWithoutBusInput;
};

export type BusAttendeeCreateOrConnectWithoutBus_StopInput = {
  where: BusAttendeeWhereUniqueInput;
  create: BusAttendeeCreateWithoutBus_StopInput;
};

export type BusAttendeeCreateOrConnectWithoutUserInput = {
  where: BusAttendeeWhereUniqueInput;
  create: BusAttendeeCreateWithoutUserInput;
};

export type BusAttendeeCreateWithoutBusInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
  bus_stop?: Maybe<BusStopCreateNestedOneWithoutBus_AttendeesInput>;
  user: UserCreateNestedOneWithoutBus_AttendeesInput;
};

export type BusAttendeeCreateWithoutBus_StopInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
  bus: BusCreateNestedOneWithoutBus_AttendeesInput;
  user: UserCreateNestedOneWithoutBus_AttendeesInput;
};

export type BusAttendeeCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  bus_coordinator_volunteer?: Maybe<Scalars["Boolean"]>;
  bus: BusCreateNestedOneWithoutBus_AttendeesInput;
  bus_stop?: Maybe<BusStopCreateNestedOneWithoutBus_AttendeesInput>;
};

export type BusAttendeeListRelationFilter = {
  every?: Maybe<BusAttendeeWhereInput>;
  some?: Maybe<BusAttendeeWhereInput>;
  none?: Maybe<BusAttendeeWhereInput>;
};

export type BusAttendeeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BusAttendeeScalarWhereInput = {
  AND?: Maybe<Array<BusAttendeeScalarWhereInput>>;
  OR?: Maybe<Array<BusAttendeeScalarWhereInput>>;
  NOT?: Maybe<Array<BusAttendeeScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  bus_id?: Maybe<IntFilter>;
  bus_stop_id?: Maybe<IntNullableFilter>;
  bus_coordinator_volunteer?: Maybe<BoolFilter>;
};

export type BusAttendeeUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus_coordinator_volunteer?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type BusAttendeeUpdateManyWithWhereWithoutBusInput = {
  where: BusAttendeeScalarWhereInput;
  data: BusAttendeeUpdateManyMutationInput;
};

export type BusAttendeeUpdateManyWithWhereWithoutBus_StopInput = {
  where: BusAttendeeScalarWhereInput;
  data: BusAttendeeUpdateManyMutationInput;
};

export type BusAttendeeUpdateManyWithWhereWithoutUserInput = {
  where: BusAttendeeScalarWhereInput;
  data: BusAttendeeUpdateManyMutationInput;
};

export type BusAttendeeUpdateManyWithoutBusNestedInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutBusInput>>;
  connectOrCreate?: Maybe<Array<BusAttendeeCreateOrConnectWithoutBusInput>>;
  upsert?: Maybe<Array<BusAttendeeUpsertWithWhereUniqueWithoutBusInput>>;
  createMany?: Maybe<BusAttendeeCreateManyBusInputEnvelope>;
  set?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  delete?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  update?: Maybe<Array<BusAttendeeUpdateWithWhereUniqueWithoutBusInput>>;
  updateMany?: Maybe<Array<BusAttendeeUpdateManyWithWhereWithoutBusInput>>;
  deleteMany?: Maybe<Array<BusAttendeeScalarWhereInput>>;
};

export type BusAttendeeUpdateManyWithoutBus_StopNestedInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutBus_StopInput>>;
  connectOrCreate?: Maybe<
    Array<BusAttendeeCreateOrConnectWithoutBus_StopInput>
  >;
  upsert?: Maybe<Array<BusAttendeeUpsertWithWhereUniqueWithoutBus_StopInput>>;
  createMany?: Maybe<BusAttendeeCreateManyBus_StopInputEnvelope>;
  set?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  delete?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  update?: Maybe<Array<BusAttendeeUpdateWithWhereUniqueWithoutBus_StopInput>>;
  updateMany?: Maybe<Array<BusAttendeeUpdateManyWithWhereWithoutBus_StopInput>>;
  deleteMany?: Maybe<Array<BusAttendeeScalarWhereInput>>;
};

export type BusAttendeeUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<BusAttendeeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BusAttendeeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<BusAttendeeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BusAttendeeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  delete?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  connect?: Maybe<Array<BusAttendeeWhereUniqueInput>>;
  update?: Maybe<Array<BusAttendeeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BusAttendeeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BusAttendeeScalarWhereInput>>;
};

export type BusAttendeeUpdateWithWhereUniqueWithoutBusInput = {
  where: BusAttendeeWhereUniqueInput;
  data: BusAttendeeUpdateWithoutBusInput;
};

export type BusAttendeeUpdateWithWhereUniqueWithoutBus_StopInput = {
  where: BusAttendeeWhereUniqueInput;
  data: BusAttendeeUpdateWithoutBus_StopInput;
};

export type BusAttendeeUpdateWithWhereUniqueWithoutUserInput = {
  where: BusAttendeeWhereUniqueInput;
  data: BusAttendeeUpdateWithoutUserInput;
};

export type BusAttendeeUpdateWithoutBusInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus_coordinator_volunteer?: Maybe<BoolFieldUpdateOperationsInput>;
  bus_stop?: Maybe<BusStopUpdateOneWithoutBus_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBus_AttendeesNestedInput>;
};

export type BusAttendeeUpdateWithoutBus_StopInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus_coordinator_volunteer?: Maybe<BoolFieldUpdateOperationsInput>;
  bus?: Maybe<BusUpdateOneRequiredWithoutBus_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutBus_AttendeesNestedInput>;
};

export type BusAttendeeUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus_coordinator_volunteer?: Maybe<BoolFieldUpdateOperationsInput>;
  bus?: Maybe<BusUpdateOneRequiredWithoutBus_AttendeesNestedInput>;
  bus_stop?: Maybe<BusStopUpdateOneWithoutBus_AttendeesNestedInput>;
};

export type BusAttendeeUpsertWithWhereUniqueWithoutBusInput = {
  where: BusAttendeeWhereUniqueInput;
  update: BusAttendeeUpdateWithoutBusInput;
  create: BusAttendeeCreateWithoutBusInput;
};

export type BusAttendeeUpsertWithWhereUniqueWithoutBus_StopInput = {
  where: BusAttendeeWhereUniqueInput;
  update: BusAttendeeUpdateWithoutBus_StopInput;
  create: BusAttendeeCreateWithoutBus_StopInput;
};

export type BusAttendeeUpsertWithWhereUniqueWithoutUserInput = {
  where: BusAttendeeWhereUniqueInput;
  update: BusAttendeeUpdateWithoutUserInput;
  create: BusAttendeeCreateWithoutUserInput;
};

export type BusAttendeeWhereInput = {
  AND?: Maybe<Array<BusAttendeeWhereInput>>;
  OR?: Maybe<Array<BusAttendeeWhereInput>>;
  NOT?: Maybe<Array<BusAttendeeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  bus_id?: Maybe<IntFilter>;
  bus_stop_id?: Maybe<IntNullableFilter>;
  bus_coordinator_volunteer?: Maybe<BoolFilter>;
  bus?: Maybe<BusRelationFilter>;
  bus_stop?: Maybe<BusStopNullableRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type BusAttendeeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<BusAttendeeWhereInput>>;
  OR?: Maybe<Array<BusAttendeeWhereInput>>;
  NOT?: Maybe<Array<BusAttendeeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  bus_id?: Maybe<IntFilter>;
  bus_stop_id?: Maybe<IntNullableFilter>;
  bus_coordinator_volunteer?: Maybe<BoolFilter>;
  bus?: Maybe<BusRelationFilter>;
  bus_stop?: Maybe<BusStopNullableRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type BusCount = {
  __typename?: "BusCount";
  stops: Scalars["Int"];
  bus_attendees: Scalars["Int"];
};

export type BusCountStopsArgs = {
  where?: Maybe<BusStopWhereInput>;
};

export type BusCountBus_AttendeesArgs = {
  where?: Maybe<BusAttendeeWhereInput>;
};

export type BusCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  seats_total: Scalars["Int"];
  seats_available?: Maybe<Scalars["Int"]>;
};

export type BusCreateManyEventInputEnvelope = {
  data: Array<BusCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BusCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<BusCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<BusCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<BusCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<BusWhereUniqueInput>>;
};

export type BusCreateNestedOneWithoutBus_AttendeesInput = {
  create?: Maybe<BusCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<BusCreateOrConnectWithoutBus_AttendeesInput>;
  connect?: Maybe<BusWhereUniqueInput>;
};

export type BusCreateNestedOneWithoutStopsInput = {
  create?: Maybe<BusCreateWithoutStopsInput>;
  connectOrCreate?: Maybe<BusCreateOrConnectWithoutStopsInput>;
  connect?: Maybe<BusWhereUniqueInput>;
};

export type BusCreateOrConnectWithoutBus_AttendeesInput = {
  where: BusWhereUniqueInput;
  create: BusCreateWithoutBus_AttendeesInput;
};

export type BusCreateOrConnectWithoutEventInput = {
  where: BusWhereUniqueInput;
  create: BusCreateWithoutEventInput;
};

export type BusCreateOrConnectWithoutStopsInput = {
  where: BusWhereUniqueInput;
  create: BusCreateWithoutStopsInput;
};

export type BusCreateWithoutBus_AttendeesInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  seats_total: Scalars["Int"];
  seats_available?: Maybe<Scalars["Int"]>;
  stops?: Maybe<BusStopCreateNestedManyWithoutBusInput>;
  event: EventCreateNestedOneWithoutBusInput;
};

export type BusCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  seats_total: Scalars["Int"];
  seats_available?: Maybe<Scalars["Int"]>;
  stops?: Maybe<BusStopCreateNestedManyWithoutBusInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutBusInput>;
};

export type BusCreateWithoutStopsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  seats_total: Scalars["Int"];
  seats_available?: Maybe<Scalars["Int"]>;
  event: EventCreateNestedOneWithoutBusInput;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutBusInput>;
};

export type BusListRelationFilter = {
  every?: Maybe<BusWhereInput>;
  some?: Maybe<BusWhereInput>;
  none?: Maybe<BusWhereInput>;
};

export type BusOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BusRelationFilter = {
  is?: Maybe<BusWhereInput>;
  isNot?: Maybe<BusWhereInput>;
};

export type BusScalarWhereInput = {
  AND?: Maybe<Array<BusScalarWhereInput>>;
  OR?: Maybe<Array<BusScalarWhereInput>>;
  NOT?: Maybe<Array<BusScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  seats_total?: Maybe<IntFilter>;
  seats_available?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
};

export type BusStop = {
  __typename?: "BusStop";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  address: Scalars["String"];
  departure_time: Scalars["DateTimeISO"];
  bus_id: Scalars["Int"];
  _count?: Maybe<BusStopCount>;
};

export type BusStopBus_Stops_Bus_Id_Name_KeyCompoundUniqueInput = {
  bus_id: Scalars["Int"];
  name: Scalars["String"];
};

export type BusStopCount = {
  __typename?: "BusStopCount";
  bus_attendees: Scalars["Int"];
};

export type BusStopCountBus_AttendeesArgs = {
  where?: Maybe<BusAttendeeWhereInput>;
};

export type BusStopCreateManyBusInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  address: Scalars["String"];
  departure_time: Scalars["DateTimeISO"];
};

export type BusStopCreateManyBusInputEnvelope = {
  data: Array<BusStopCreateManyBusInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type BusStopCreateNestedManyWithoutBusInput = {
  create?: Maybe<Array<BusStopCreateWithoutBusInput>>;
  connectOrCreate?: Maybe<Array<BusStopCreateOrConnectWithoutBusInput>>;
  createMany?: Maybe<BusStopCreateManyBusInputEnvelope>;
  connect?: Maybe<Array<BusStopWhereUniqueInput>>;
};

export type BusStopCreateNestedOneWithoutBus_AttendeesInput = {
  create?: Maybe<BusStopCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<BusStopCreateOrConnectWithoutBus_AttendeesInput>;
  connect?: Maybe<BusStopWhereUniqueInput>;
};

export type BusStopCreateOrConnectWithoutBusInput = {
  where: BusStopWhereUniqueInput;
  create: BusStopCreateWithoutBusInput;
};

export type BusStopCreateOrConnectWithoutBus_AttendeesInput = {
  where: BusStopWhereUniqueInput;
  create: BusStopCreateWithoutBus_AttendeesInput;
};

export type BusStopCreateWithoutBusInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  address: Scalars["String"];
  departure_time: Scalars["DateTimeISO"];
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutBus_StopInput>;
};

export type BusStopCreateWithoutBus_AttendeesInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  address: Scalars["String"];
  departure_time: Scalars["DateTimeISO"];
  bus: BusCreateNestedOneWithoutStopsInput;
};

export type BusStopListRelationFilter = {
  every?: Maybe<BusStopWhereInput>;
  some?: Maybe<BusStopWhereInput>;
  none?: Maybe<BusStopWhereInput>;
};

export type BusStopNullableRelationFilter = {
  is?: Maybe<BusStopWhereInput>;
  isNot?: Maybe<BusStopWhereInput>;
};

export type BusStopScalarWhereInput = {
  AND?: Maybe<Array<BusStopScalarWhereInput>>;
  OR?: Maybe<Array<BusStopScalarWhereInput>>;
  NOT?: Maybe<Array<BusStopScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  address?: Maybe<StringFilter>;
  departure_time?: Maybe<DateTimeFilter>;
  bus_id?: Maybe<IntFilter>;
};

export type BusStopUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  departure_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BusStopUpdateManyWithWhereWithoutBusInput = {
  where: BusStopScalarWhereInput;
  data: BusStopUpdateManyMutationInput;
};

export type BusStopUpdateManyWithoutBusNestedInput = {
  create?: Maybe<Array<BusStopCreateWithoutBusInput>>;
  connectOrCreate?: Maybe<Array<BusStopCreateOrConnectWithoutBusInput>>;
  upsert?: Maybe<Array<BusStopUpsertWithWhereUniqueWithoutBusInput>>;
  createMany?: Maybe<BusStopCreateManyBusInputEnvelope>;
  set?: Maybe<Array<BusStopWhereUniqueInput>>;
  disconnect?: Maybe<Array<BusStopWhereUniqueInput>>;
  delete?: Maybe<Array<BusStopWhereUniqueInput>>;
  connect?: Maybe<Array<BusStopWhereUniqueInput>>;
  update?: Maybe<Array<BusStopUpdateWithWhereUniqueWithoutBusInput>>;
  updateMany?: Maybe<Array<BusStopUpdateManyWithWhereWithoutBusInput>>;
  deleteMany?: Maybe<Array<BusStopScalarWhereInput>>;
};

export type BusStopUpdateOneWithoutBus_AttendeesNestedInput = {
  create?: Maybe<BusStopCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<BusStopCreateOrConnectWithoutBus_AttendeesInput>;
  upsert?: Maybe<BusStopUpsertWithoutBus_AttendeesInput>;
  disconnect?: Maybe<BusStopWhereInput>;
  delete?: Maybe<BusStopWhereInput>;
  connect?: Maybe<BusStopWhereUniqueInput>;
  update?: Maybe<BusStopUpdateToOneWithWhereWithoutBus_AttendeesInput>;
};

export type BusStopUpdateToOneWithWhereWithoutBus_AttendeesInput = {
  where?: Maybe<BusStopWhereInput>;
  data: BusStopUpdateWithoutBus_AttendeesInput;
};

export type BusStopUpdateWithWhereUniqueWithoutBusInput = {
  where: BusStopWhereUniqueInput;
  data: BusStopUpdateWithoutBusInput;
};

export type BusStopUpdateWithoutBusInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  departure_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutBus_StopNestedInput>;
};

export type BusStopUpdateWithoutBus_AttendeesInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  address?: Maybe<StringFieldUpdateOperationsInput>;
  departure_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  bus?: Maybe<BusUpdateOneRequiredWithoutStopsNestedInput>;
};

export type BusStopUpsertWithWhereUniqueWithoutBusInput = {
  where: BusStopWhereUniqueInput;
  update: BusStopUpdateWithoutBusInput;
  create: BusStopCreateWithoutBusInput;
};

export type BusStopUpsertWithoutBus_AttendeesInput = {
  update: BusStopUpdateWithoutBus_AttendeesInput;
  create: BusStopCreateWithoutBus_AttendeesInput;
  where?: Maybe<BusStopWhereInput>;
};

export type BusStopWhereInput = {
  AND?: Maybe<Array<BusStopWhereInput>>;
  OR?: Maybe<Array<BusStopWhereInput>>;
  NOT?: Maybe<Array<BusStopWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  address?: Maybe<StringFilter>;
  departure_time?: Maybe<DateTimeFilter>;
  bus_id?: Maybe<IntFilter>;
  bus?: Maybe<BusRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
};

export type BusStopWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  bus_stops_bus_id_name_key?: Maybe<BusStopBus_Stops_Bus_Id_Name_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<BusStopWhereInput>>;
  OR?: Maybe<Array<BusStopWhereInput>>;
  NOT?: Maybe<Array<BusStopWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  address?: Maybe<StringFilter>;
  departure_time?: Maybe<DateTimeFilter>;
  bus_id?: Maybe<IntFilter>;
  bus?: Maybe<BusRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
};

export type BusUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  seats_total?: Maybe<IntFieldUpdateOperationsInput>;
  seats_available?: Maybe<IntFieldUpdateOperationsInput>;
};

export type BusUpdateManyWithWhereWithoutEventInput = {
  where: BusScalarWhereInput;
  data: BusUpdateManyMutationInput;
};

export type BusUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<BusCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<BusCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<BusUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<BusCreateManyEventInputEnvelope>;
  set?: Maybe<Array<BusWhereUniqueInput>>;
  disconnect?: Maybe<Array<BusWhereUniqueInput>>;
  delete?: Maybe<Array<BusWhereUniqueInput>>;
  connect?: Maybe<Array<BusWhereUniqueInput>>;
  update?: Maybe<Array<BusUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<BusUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<BusScalarWhereInput>>;
};

export type BusUpdateOneRequiredWithoutBus_AttendeesNestedInput = {
  create?: Maybe<BusCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<BusCreateOrConnectWithoutBus_AttendeesInput>;
  upsert?: Maybe<BusUpsertWithoutBus_AttendeesInput>;
  connect?: Maybe<BusWhereUniqueInput>;
  update?: Maybe<BusUpdateToOneWithWhereWithoutBus_AttendeesInput>;
};

export type BusUpdateOneRequiredWithoutStopsNestedInput = {
  create?: Maybe<BusCreateWithoutStopsInput>;
  connectOrCreate?: Maybe<BusCreateOrConnectWithoutStopsInput>;
  upsert?: Maybe<BusUpsertWithoutStopsInput>;
  connect?: Maybe<BusWhereUniqueInput>;
  update?: Maybe<BusUpdateToOneWithWhereWithoutStopsInput>;
};

export type BusUpdateToOneWithWhereWithoutBus_AttendeesInput = {
  where?: Maybe<BusWhereInput>;
  data: BusUpdateWithoutBus_AttendeesInput;
};

export type BusUpdateToOneWithWhereWithoutStopsInput = {
  where?: Maybe<BusWhereInput>;
  data: BusUpdateWithoutStopsInput;
};

export type BusUpdateWithWhereUniqueWithoutEventInput = {
  where: BusWhereUniqueInput;
  data: BusUpdateWithoutEventInput;
};

export type BusUpdateWithoutBus_AttendeesInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  seats_total?: Maybe<IntFieldUpdateOperationsInput>;
  seats_available?: Maybe<IntFieldUpdateOperationsInput>;
  stops?: Maybe<BusStopUpdateManyWithoutBusNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBusNestedInput>;
};

export type BusUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  seats_total?: Maybe<IntFieldUpdateOperationsInput>;
  seats_available?: Maybe<IntFieldUpdateOperationsInput>;
  stops?: Maybe<BusStopUpdateManyWithoutBusNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutBusNestedInput>;
};

export type BusUpdateWithoutStopsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  seats_total?: Maybe<IntFieldUpdateOperationsInput>;
  seats_available?: Maybe<IntFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutBusNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutBusNestedInput>;
};

export type BusUpsertWithWhereUniqueWithoutEventInput = {
  where: BusWhereUniqueInput;
  update: BusUpdateWithoutEventInput;
  create: BusCreateWithoutEventInput;
};

export type BusUpsertWithoutBus_AttendeesInput = {
  update: BusUpdateWithoutBus_AttendeesInput;
  create: BusCreateWithoutBus_AttendeesInput;
  where?: Maybe<BusWhereInput>;
};

export type BusUpsertWithoutStopsInput = {
  update: BusUpdateWithoutStopsInput;
  create: BusCreateWithoutStopsInput;
  where?: Maybe<BusWhereInput>;
};

export type BusWhereInput = {
  AND?: Maybe<Array<BusWhereInput>>;
  OR?: Maybe<Array<BusWhereInput>>;
  NOT?: Maybe<Array<BusWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  seats_total?: Maybe<IntFilter>;
  seats_available?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  stops?: Maybe<BusStopListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
};

export type BusWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<BusWhereInput>>;
  OR?: Maybe<Array<BusWhereInput>>;
  NOT?: Maybe<Array<BusWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  seats_total?: Maybe<IntFilter>;
  seats_available?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  stops?: Maybe<BusStopListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
};

export type Claim = {
  __typename?: "Claim";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  pipeline_id: Scalars["Int"];
  stage_id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
  user_id: Scalars["Int"];
  _count?: Maybe<ClaimCount>;
  user: User;
  stage: Stage;
  fields: Array<FieldWithAnswer>;
  reviews: Array<Review>;
  fields_HACKTHENORTH2021_REIMBURSEMENTS: TypedClaimsResolver_Hackthenorth2021_Reimbursements_OutputWrapper;
  fields_HACKTHENORTH2021_CONTACT_LISTS: TypedClaimsResolver_Hackthenorth2021_Contact_Lists_OutputWrapper;
  fields_HACKTHENORTH2021_SPONSOR_PERKS: TypedClaimsResolver_Hackthenorth2021_Sponsor_Perks_OutputWrapper;
  fields_HACKTHENORTH2021_APPLICATIONS: TypedClaimsResolver_Hackthenorth2021_Applications_OutputWrapper;
  fields_HACKTHENORTH2021_REVIEWS: TypedClaimsResolver_Hackthenorth2021_Reviews_OutputWrapper;
  fields_HACKTHENORTH2021_MENTORSHIP_PROFILES: TypedClaimsResolver_Hackthenorth2021_Mentorship_Profiles_OutputWrapper;
  fields_HACKTHENORTH2021_MENTORSHIP_REQUESTS: TypedClaimsResolver_Hackthenorth2021_Mentorship_Requests_OutputWrapper;
  fields_HACKTHENORTH2021_TECHNICAL_JARGON: TypedClaimsResolver_Hackthenorth2021_Technical_Jargon_OutputWrapper;
  fields_SAMPLE: TypedClaimsResolver_Sample_OutputWrapper;
  fields_HACKTHENORTH2023_REIMBURSEMENTS: TypedClaimsResolver_Hackthenorth2023_Reimbursements_OutputWrapper;
  fields_HACKTHENORTH2023_CONTACT_LISTS: TypedClaimsResolver_Hackthenorth2023_Contact_Lists_OutputWrapper;
  fields_HACKTHENORTH2023_APPLICATIONS: TypedClaimsResolver_Hackthenorth2023_Applications_OutputWrapper;
  fields_HACKTHENORTH2023_REVIEWS: TypedClaimsResolver_Hackthenorth2023_Reviews_OutputWrapper;
  fields_HACKTHENORTH2023_SPONSOR_PERKS: TypedClaimsResolver_Hackthenorth2023_Sponsor_Perks_OutputWrapper;
  fields_HACKTHENORTH2023_EXTENDED_PROFILES: TypedClaimsResolver_Hackthenorth2023_Extended_Profiles_OutputWrapper;
  fields_HACKTHENORTH2023_SIGNAGE_QUESTIONS: TypedClaimsResolver_Hackthenorth2023_Signage_Questions_OutputWrapper;
  fields_HACKTHENORTH2023_SIGNAGE_RESPONSES: TypedClaimsResolver_Hackthenorth2023_Signage_Responses_OutputWrapper;
  fields_HACKTHENORTH2023_MENTORSHIP_PROFILES: TypedClaimsResolver_Hackthenorth2023_Mentorship_Profiles_OutputWrapper;
  fields_HACKTHENORTH2023_MENTORSHIP_REQUESTS: TypedClaimsResolver_Hackthenorth2023_Mentorship_Requests_OutputWrapper;
  fields_HACKTHENORTH2024_REIMBURSEMENTS: TypedClaimsResolver_Hackthenorth2024_Reimbursements_OutputWrapper;
  fields_HACKTHENORTH2024_SPONSOR_PERKS: TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_OutputWrapper;
  fields_HACKTHENORTH2024_APPLICATIONS: TypedClaimsResolver_Hackthenorth2024_Applications_OutputWrapper;
  fields_HACKTHENORTH2024_CONTACT_LISTS: TypedClaimsResolver_Hackthenorth2024_Contact_Lists_OutputWrapper;
  fields_HACKTHENORTH2024_REVIEWS: TypedClaimsResolver_Hackthenorth2024_Reviews_OutputWrapper;
  fields_HACKTHENORTH2024_MENTORSHIP_REQUESTS: TypedClaimsResolver_Hackthenorth2024_Mentorship_Requests_OutputWrapper;
  fields_HACKTHENORTH2024_EXTENDED_PROFILES: TypedClaimsResolver_Hackthenorth2024_Extended_Profiles_OutputWrapper;
  fields_HACKTHENORTH2024_MENTORSHIP_PROFILES: TypedClaimsResolver_Hackthenorth2024_Mentorship_Profiles_OutputWrapper;
  fields_HACKTHENORTH2022_CONTACT_LISTS: TypedClaimsResolver_Hackthenorth2022_Contact_Lists_OutputWrapper;
  fields_HACKTHENORTH2022_REIMBURSEMENTS: TypedClaimsResolver_Hackthenorth2022_Reimbursements_OutputWrapper;
  fields_HACKTHENORTH2022_APPLICATIONS: TypedClaimsResolver_Hackthenorth2022_Applications_OutputWrapper;
  fields_HACKTHENORTH2022_REVIEWS: TypedClaimsResolver_Hackthenorth2022_Reviews_OutputWrapper;
  fields_HACKTHENORTH2022_MENTORSHIP_REQUESTS: TypedClaimsResolver_Hackthenorth2022_Mentorship_Requests_OutputWrapper;
  fields_HACKTHENORTH2022_SPONSOR_PERKS: TypedClaimsResolver_Hackthenorth2022_Sponsor_Perks_OutputWrapper;
  fields_HACKTHENORTH2022_MENTORSHIP_PROFILES: TypedClaimsResolver_Hackthenorth2022_Mentorship_Profiles_OutputWrapper;
};

export type ClaimCount = {
  __typename?: "ClaimCount";
  collaborators: Scalars["Int"];
  field_answers: Scalars["Int"];
  reviews: Scalars["Int"];
};

export type ClaimCountCollaboratorsArgs = {
  where?: Maybe<CollaboratorWhereInput>;
};

export type ClaimCountField_AnswersArgs = {
  where?: Maybe<FieldAnswerWhereInput>;
};

export type ClaimCountReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
};

/** Create claim data */
export type ClaimCreateInput = {
  name: Scalars["String"];
  stage_id?: Maybe<Scalars["Int"]>;
  stage_slug?: Maybe<Scalars["String"]>;
  pipeline_slug?: Maybe<Scalars["String"]>;
  pipeline_id?: Maybe<Scalars["Int"]>;
  answers?: Maybe<Array<FieldAnswerInput>>;
  files?: Maybe<Array<FileInput>>;
  owner_id?: Maybe<Scalars["Int"]>;
};

export type ClaimCreateManyPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  stage_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type ClaimCreateManyPipelineInputEnvelope = {
  data: Array<ClaimCreateManyPipelineInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ClaimCreateManyStageInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  pipeline_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type ClaimCreateManyStageInputEnvelope = {
  data: Array<ClaimCreateManyStageInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ClaimCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  pipeline_id: Scalars["Int"];
  stage_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type ClaimCreateManyUserInputEnvelope = {
  data: Array<ClaimCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ClaimCreateNestedManyWithoutPipelineInput = {
  create?: Maybe<Array<ClaimCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutPipelineInput>>;
  createMany?: Maybe<ClaimCreateManyPipelineInputEnvelope>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
};

export type ClaimCreateNestedManyWithoutStageInput = {
  create?: Maybe<Array<ClaimCreateWithoutStageInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutStageInput>>;
  createMany?: Maybe<ClaimCreateManyStageInputEnvelope>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
};

export type ClaimCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ClaimCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ClaimCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
};

export type ClaimCreateNestedOneWithoutCollaboratorsInput = {
  create?: Maybe<ClaimCreateWithoutCollaboratorsInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutCollaboratorsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
};

export type ClaimCreateNestedOneWithoutField_AnswersInput = {
  create?: Maybe<ClaimCreateWithoutField_AnswersInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutField_AnswersInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
};

export type ClaimCreateNestedOneWithoutReviewsInput = {
  create?: Maybe<ClaimCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutReviewsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
};

export type ClaimCreateOrConnectWithoutCollaboratorsInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutCollaboratorsInput;
};

export type ClaimCreateOrConnectWithoutField_AnswersInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutField_AnswersInput;
};

export type ClaimCreateOrConnectWithoutPipelineInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutPipelineInput;
};

export type ClaimCreateOrConnectWithoutReviewsInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutReviewsInput;
};

export type ClaimCreateOrConnectWithoutStageInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutStageInput;
};

export type ClaimCreateOrConnectWithoutUserInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutUserInput;
};

export type ClaimCreateWithoutCollaboratorsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  pipeline: PipelineCreateNestedOneWithoutClaimsInput;
  stage: StageCreateNestedOneWithoutClaimsInput;
  user: UserCreateNestedOneWithoutClaimsInput;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutClaimInput>;
};

export type ClaimCreateWithoutField_AnswersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  pipeline: PipelineCreateNestedOneWithoutClaimsInput;
  stage: StageCreateNestedOneWithoutClaimsInput;
  user: UserCreateNestedOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutClaimInput>;
};

export type ClaimCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  stage: StageCreateNestedOneWithoutClaimsInput;
  user: UserCreateNestedOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutClaimInput>;
};

export type ClaimCreateWithoutReviewsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  pipeline: PipelineCreateNestedOneWithoutClaimsInput;
  stage: StageCreateNestedOneWithoutClaimsInput;
  user: UserCreateNestedOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutClaimInput>;
};

export type ClaimCreateWithoutStageInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  pipeline: PipelineCreateNestedOneWithoutClaimsInput;
  user: UserCreateNestedOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutClaimInput>;
};

export type ClaimCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  pipeline: PipelineCreateNestedOneWithoutClaimsInput;
  stage: StageCreateNestedOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutClaimInput>;
};

export type ClaimListRelationFilter = {
  every?: Maybe<ClaimWhereInput>;
  some?: Maybe<ClaimWhereInput>;
  none?: Maybe<ClaimWhereInput>;
};

export type ClaimOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum ClaimOrderByRelevanceFieldEnum {
  Name = "name",
}

export type ClaimOrderByRelevanceInput = {
  fields: Array<ClaimOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type ClaimOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pipeline_id?: Maybe<SortOrder>;
  stage_id?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  user_id?: Maybe<SortOrder>;
  pipeline?: Maybe<PipelineOrderByWithRelationAndSearchRelevanceInput>;
  stage?: Maybe<StageOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  collaborators?: Maybe<CollaboratorOrderByRelationAggregateInput>;
  field_answers?: Maybe<FieldAnswerOrderByRelationAggregateInput>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  _relevance?: Maybe<ClaimOrderByRelevanceInput>;
};

export type ClaimRelationFilter = {
  is?: Maybe<ClaimWhereInput>;
  isNot?: Maybe<ClaimWhereInput>;
};

export enum ClaimScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  Name = "name",
  PipelineId = "pipeline_id",
  StageId = "stage_id",
  UpdatedAt = "updated_at",
  UserId = "user_id",
}

export type ClaimScalarWhereInput = {
  AND?: Maybe<Array<ClaimScalarWhereInput>>;
  OR?: Maybe<Array<ClaimScalarWhereInput>>;
  NOT?: Maybe<Array<ClaimScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  pipeline_id?: Maybe<IntFilter>;
  stage_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

/** Claims Field Answer filter input */
export type ClaimSubscriptionFieldAnswerFilterInput = {
  field_slug: Scalars["String"];
  field_answer_value: Scalars["String"];
};

/** Update claim data */
export type ClaimUpdateInput = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  stage_id?: Maybe<Scalars["Int"]>;
  answers?: Maybe<Array<FieldAnswerInput>>;
  user_id?: Maybe<Scalars["Int"]>;
  files?: Maybe<Array<FileInput>>;
  stage_slug?: Maybe<Scalars["String"]>;
};

export type ClaimUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimUpdateManyWithWhereWithoutPipelineInput = {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
};

export type ClaimUpdateManyWithWhereWithoutStageInput = {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
};

export type ClaimUpdateManyWithWhereWithoutUserInput = {
  where: ClaimScalarWhereInput;
  data: ClaimUpdateManyMutationInput;
};

export type ClaimUpdateManyWithoutPipelineNestedInput = {
  create?: Maybe<Array<ClaimCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutPipelineInput>>;
  upsert?: Maybe<Array<ClaimUpsertWithWhereUniqueWithoutPipelineInput>>;
  createMany?: Maybe<ClaimCreateManyPipelineInputEnvelope>;
  set?: Maybe<Array<ClaimWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClaimWhereUniqueInput>>;
  delete?: Maybe<Array<ClaimWhereUniqueInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  update?: Maybe<Array<ClaimUpdateWithWhereUniqueWithoutPipelineInput>>;
  updateMany?: Maybe<Array<ClaimUpdateManyWithWhereWithoutPipelineInput>>;
  deleteMany?: Maybe<Array<ClaimScalarWhereInput>>;
};

export type ClaimUpdateManyWithoutStageNestedInput = {
  create?: Maybe<Array<ClaimCreateWithoutStageInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutStageInput>>;
  upsert?: Maybe<Array<ClaimUpsertWithWhereUniqueWithoutStageInput>>;
  createMany?: Maybe<ClaimCreateManyStageInputEnvelope>;
  set?: Maybe<Array<ClaimWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClaimWhereUniqueInput>>;
  delete?: Maybe<Array<ClaimWhereUniqueInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  update?: Maybe<Array<ClaimUpdateWithWhereUniqueWithoutStageInput>>;
  updateMany?: Maybe<Array<ClaimUpdateManyWithWhereWithoutStageInput>>;
  deleteMany?: Maybe<Array<ClaimScalarWhereInput>>;
};

export type ClaimUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ClaimCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ClaimUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ClaimCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ClaimWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClaimWhereUniqueInput>>;
  delete?: Maybe<Array<ClaimWhereUniqueInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  update?: Maybe<Array<ClaimUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ClaimUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ClaimScalarWhereInput>>;
};

export type ClaimUpdateOneRequiredWithoutCollaboratorsNestedInput = {
  create?: Maybe<ClaimCreateWithoutCollaboratorsInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutCollaboratorsInput>;
  upsert?: Maybe<ClaimUpsertWithoutCollaboratorsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  update?: Maybe<ClaimUpdateToOneWithWhereWithoutCollaboratorsInput>;
};

export type ClaimUpdateOneRequiredWithoutField_AnswersNestedInput = {
  create?: Maybe<ClaimCreateWithoutField_AnswersInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutField_AnswersInput>;
  upsert?: Maybe<ClaimUpsertWithoutField_AnswersInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  update?: Maybe<ClaimUpdateToOneWithWhereWithoutField_AnswersInput>;
};

export type ClaimUpdateOneRequiredWithoutReviewsNestedInput = {
  create?: Maybe<ClaimCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutReviewsInput>;
  upsert?: Maybe<ClaimUpsertWithoutReviewsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  update?: Maybe<ClaimUpdateToOneWithWhereWithoutReviewsInput>;
};

export type ClaimUpdateToOneWithWhereWithoutCollaboratorsInput = {
  where?: Maybe<ClaimWhereInput>;
  data: ClaimUpdateWithoutCollaboratorsInput;
};

export type ClaimUpdateToOneWithWhereWithoutField_AnswersInput = {
  where?: Maybe<ClaimWhereInput>;
  data: ClaimUpdateWithoutField_AnswersInput;
};

export type ClaimUpdateToOneWithWhereWithoutReviewsInput = {
  where?: Maybe<ClaimWhereInput>;
  data: ClaimUpdateWithoutReviewsInput;
};

export type ClaimUpdateWithWhereUniqueWithoutPipelineInput = {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutPipelineInput;
};

export type ClaimUpdateWithWhereUniqueWithoutStageInput = {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutStageInput;
};

export type ClaimUpdateWithWhereUniqueWithoutUserInput = {
  where: ClaimWhereUniqueInput;
  data: ClaimUpdateWithoutUserInput;
};

export type ClaimUpdateWithoutCollaboratorsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutClaimsNestedInput>;
  stage?: Maybe<StageUpdateOneRequiredWithoutClaimsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClaimsNestedInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutClaimNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpdateWithoutField_AnswersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutClaimsNestedInput>;
  stage?: Maybe<StageUpdateOneRequiredWithoutClaimsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClaimsNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutClaimNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpdateWithoutPipelineInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<StageUpdateOneRequiredWithoutClaimsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClaimsNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutClaimNestedInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutClaimNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpdateWithoutReviewsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutClaimsNestedInput>;
  stage?: Maybe<StageUpdateOneRequiredWithoutClaimsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClaimsNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutClaimNestedInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpdateWithoutStageInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutClaimsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutClaimsNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutClaimNestedInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutClaimNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutClaimsNestedInput>;
  stage?: Maybe<StageUpdateOneRequiredWithoutClaimsNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutClaimNestedInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutClaimNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutClaimNestedInput>;
};

export type ClaimUpsertWithWhereUniqueWithoutPipelineInput = {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutPipelineInput;
  create: ClaimCreateWithoutPipelineInput;
};

export type ClaimUpsertWithWhereUniqueWithoutStageInput = {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutStageInput;
  create: ClaimCreateWithoutStageInput;
};

export type ClaimUpsertWithWhereUniqueWithoutUserInput = {
  where: ClaimWhereUniqueInput;
  update: ClaimUpdateWithoutUserInput;
  create: ClaimCreateWithoutUserInput;
};

export type ClaimUpsertWithoutCollaboratorsInput = {
  update: ClaimUpdateWithoutCollaboratorsInput;
  create: ClaimCreateWithoutCollaboratorsInput;
  where?: Maybe<ClaimWhereInput>;
};

export type ClaimUpsertWithoutField_AnswersInput = {
  update: ClaimUpdateWithoutField_AnswersInput;
  create: ClaimCreateWithoutField_AnswersInput;
  where?: Maybe<ClaimWhereInput>;
};

export type ClaimUpsertWithoutReviewsInput = {
  update: ClaimUpdateWithoutReviewsInput;
  create: ClaimCreateWithoutReviewsInput;
  where?: Maybe<ClaimWhereInput>;
};

export type ClaimWhereInput = {
  AND?: Maybe<Array<ClaimWhereInput>>;
  OR?: Maybe<Array<ClaimWhereInput>>;
  NOT?: Maybe<Array<ClaimWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  pipeline_id?: Maybe<IntFilter>;
  stage_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
  stage?: Maybe<StageRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
};

export type ClaimWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<ClaimWhereInput>>;
  OR?: Maybe<Array<ClaimWhereInput>>;
  NOT?: Maybe<Array<ClaimWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  pipeline_id?: Maybe<IntFilter>;
  stage_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
  stage?: Maybe<StageRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
};

export type Collaborator = {
  __typename?: "Collaborator";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
  user_id: Scalars["Int"];
  claim: Claim;
  user: User;
};

export type CollaboratorCreateManyClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type CollaboratorCreateManyClaimInputEnvelope = {
  data: Array<CollaboratorCreateManyClaimInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type CollaboratorCreateManyUserInput = {
  claim_id: Scalars["Int"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type CollaboratorCreateManyUserInputEnvelope = {
  data: Array<CollaboratorCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type CollaboratorCreateNestedManyWithoutClaimInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutClaimInput>>;
  createMany?: Maybe<CollaboratorCreateManyClaimInputEnvelope>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
};

export type CollaboratorCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CollaboratorCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
};

export type CollaboratorCreateOrConnectWithoutClaimInput = {
  where: CollaboratorWhereUniqueInput;
  create: CollaboratorCreateWithoutClaimInput;
};

export type CollaboratorCreateOrConnectWithoutUserInput = {
  where: CollaboratorWhereUniqueInput;
  create: CollaboratorCreateWithoutUserInput;
};

export type CollaboratorCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutCollaboratorsInput;
};

export type CollaboratorCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  claim: ClaimCreateNestedOneWithoutCollaboratorsInput;
};

export type CollaboratorListRelationFilter = {
  every?: Maybe<CollaboratorWhereInput>;
  some?: Maybe<CollaboratorWhereInput>;
  none?: Maybe<CollaboratorWhereInput>;
};

export type CollaboratorOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type CollaboratorScalarWhereInput = {
  AND?: Maybe<Array<CollaboratorScalarWhereInput>>;
  OR?: Maybe<Array<CollaboratorScalarWhereInput>>;
  NOT?: Maybe<Array<CollaboratorScalarWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type CollaboratorUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type CollaboratorUpdateManyWithWhereWithoutClaimInput = {
  where: CollaboratorScalarWhereInput;
  data: CollaboratorUpdateManyMutationInput;
};

export type CollaboratorUpdateManyWithWhereWithoutUserInput = {
  where: CollaboratorScalarWhereInput;
  data: CollaboratorUpdateManyMutationInput;
};

export type CollaboratorUpdateManyWithoutClaimNestedInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutClaimInput>>;
  upsert?: Maybe<Array<CollaboratorUpsertWithWhereUniqueWithoutClaimInput>>;
  createMany?: Maybe<CollaboratorCreateManyClaimInputEnvelope>;
  set?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  disconnect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  delete?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  update?: Maybe<Array<CollaboratorUpdateWithWhereUniqueWithoutClaimInput>>;
  updateMany?: Maybe<Array<CollaboratorUpdateManyWithWhereWithoutClaimInput>>;
  deleteMany?: Maybe<Array<CollaboratorScalarWhereInput>>;
};

export type CollaboratorUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CollaboratorUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CollaboratorCreateManyUserInputEnvelope>;
  set?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  disconnect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  delete?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  update?: Maybe<Array<CollaboratorUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CollaboratorUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CollaboratorScalarWhereInput>>;
};

export type CollaboratorUpdateWithWhereUniqueWithoutClaimInput = {
  where: CollaboratorWhereUniqueInput;
  data: CollaboratorUpdateWithoutClaimInput;
};

export type CollaboratorUpdateWithWhereUniqueWithoutUserInput = {
  where: CollaboratorWhereUniqueInput;
  data: CollaboratorUpdateWithoutUserInput;
};

export type CollaboratorUpdateWithoutClaimInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutCollaboratorsNestedInput>;
};

export type CollaboratorUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  claim?: Maybe<ClaimUpdateOneRequiredWithoutCollaboratorsNestedInput>;
};

export type CollaboratorUpsertWithWhereUniqueWithoutClaimInput = {
  where: CollaboratorWhereUniqueInput;
  update: CollaboratorUpdateWithoutClaimInput;
  create: CollaboratorCreateWithoutClaimInput;
};

export type CollaboratorUpsertWithWhereUniqueWithoutUserInput = {
  where: CollaboratorWhereUniqueInput;
  update: CollaboratorUpdateWithoutUserInput;
  create: CollaboratorCreateWithoutUserInput;
};

export type CollaboratorWhereInput = {
  AND?: Maybe<Array<CollaboratorWhereInput>>;
  OR?: Maybe<Array<CollaboratorWhereInput>>;
  NOT?: Maybe<Array<CollaboratorWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type CollaboratorWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<CollaboratorWhereInput>>;
  OR?: Maybe<Array<CollaboratorWhereInput>>;
  NOT?: Maybe<Array<CollaboratorWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type Company = {
  __typename?: "Company";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  website: Scalars["String"];
  slug: Scalars["String"];
  _count?: Maybe<CompanyCount>;
};

export type CompanyCount = {
  __typename?: "CompanyCount";
  sponsor_companies: Scalars["Int"];
  users: Scalars["Int"];
};

export type CompanyCountSponsor_CompaniesArgs = {
  where?: Maybe<SponsorCompanyWhereInput>;
};

export type CompanyCountUsersArgs = {
  where?: Maybe<UserWhereInput>;
};

export type CompanyCreateNestedOneWithoutSponsor_CompaniesInput = {
  create?: Maybe<CompanyCreateWithoutSponsor_CompaniesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSponsor_CompaniesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutSponsorsInput = {
  create?: Maybe<CompanyCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSponsorsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutUsersInput = {
  create?: Maybe<CompanyCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type CompanyCreateOrConnectWithoutSponsor_CompaniesInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutSponsor_CompaniesInput;
};

export type CompanyCreateOrConnectWithoutSponsorsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutSponsorsInput;
};

export type CompanyCreateOrConnectWithoutUsersInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutUsersInput;
};

export type CompanyCreateWithoutSponsor_CompaniesInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website: Scalars["String"];
  slug: Scalars["String"];
  sponsors?: Maybe<SponsorCreateNestedOneWithoutCompanyInput>;
  users?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutSponsorsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website: Scalars["String"];
  slug: Scalars["String"];
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutCompanyInput>;
  users?: Maybe<UserCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutUsersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website: Scalars["String"];
  slug: Scalars["String"];
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutCompanyInput>;
  sponsors?: Maybe<SponsorCreateNestedOneWithoutCompanyInput>;
};

export type CompanyNullableRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export enum CompanyOrderByRelevanceFieldEnum {
  Name = "name",
  Twitter = "twitter",
  Website = "website",
  Slug = "slug",
}

export type CompanyOrderByRelevanceInput = {
  fields: Array<CompanyOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type CompanyOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  twitter?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  website?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  sponsor_companies?: Maybe<SponsorCompanyOrderByRelationAggregateInput>;
  sponsors?: Maybe<SponsorOrderByWithRelationAndSearchRelevanceInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  _relevance?: Maybe<CompanyOrderByRelevanceInput>;
};

export type CompanyRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export type CompanyUpdateOneRequiredWithoutSponsor_CompaniesNestedInput = {
  create?: Maybe<CompanyCreateWithoutSponsor_CompaniesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSponsor_CompaniesInput>;
  upsert?: Maybe<CompanyUpsertWithoutSponsor_CompaniesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateToOneWithWhereWithoutSponsor_CompaniesInput>;
};

export type CompanyUpdateOneRequiredWithoutSponsorsNestedInput = {
  create?: Maybe<CompanyCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutSponsorsInput>;
  upsert?: Maybe<CompanyUpsertWithoutSponsorsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateToOneWithWhereWithoutSponsorsInput>;
};

export type CompanyUpdateOneWithoutUsersNestedInput = {
  create?: Maybe<CompanyCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<CompanyUpsertWithoutUsersInput>;
  disconnect?: Maybe<CompanyWhereInput>;
  delete?: Maybe<CompanyWhereInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateToOneWithWhereWithoutUsersInput>;
};

export type CompanyUpdateToOneWithWhereWithoutSponsor_CompaniesInput = {
  where?: Maybe<CompanyWhereInput>;
  data: CompanyUpdateWithoutSponsor_CompaniesInput;
};

export type CompanyUpdateToOneWithWhereWithoutSponsorsInput = {
  where?: Maybe<CompanyWhereInput>;
  data: CompanyUpdateWithoutSponsorsInput;
};

export type CompanyUpdateToOneWithWhereWithoutUsersInput = {
  where?: Maybe<CompanyWhereInput>;
  data: CompanyUpdateWithoutUsersInput;
};

export type CompanyUpdateWithoutSponsor_CompaniesInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  twitter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  sponsors?: Maybe<SponsorUpdateOneWithoutCompanyNestedInput>;
  users?: Maybe<UserUpdateManyWithoutCompanyNestedInput>;
};

export type CompanyUpdateWithoutSponsorsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  twitter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutCompanyNestedInput>;
  users?: Maybe<UserUpdateManyWithoutCompanyNestedInput>;
};

export type CompanyUpdateWithoutUsersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  twitter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutCompanyNestedInput>;
  sponsors?: Maybe<SponsorUpdateOneWithoutCompanyNestedInput>;
};

export type CompanyUpsertWithoutSponsor_CompaniesInput = {
  update: CompanyUpdateWithoutSponsor_CompaniesInput;
  create: CompanyCreateWithoutSponsor_CompaniesInput;
  where?: Maybe<CompanyWhereInput>;
};

export type CompanyUpsertWithoutSponsorsInput = {
  update: CompanyUpdateWithoutSponsorsInput;
  create: CompanyCreateWithoutSponsorsInput;
  where?: Maybe<CompanyWhereInput>;
};

export type CompanyUpsertWithoutUsersInput = {
  update: CompanyUpdateWithoutUsersInput;
  create: CompanyCreateWithoutUsersInput;
  where?: Maybe<CompanyWhereInput>;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  sponsor_companies?: Maybe<SponsorCompanyListRelationFilter>;
  sponsors?: Maybe<SponsorNullableRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringFilter>;
  sponsor_companies?: Maybe<SponsorCompanyListRelationFilter>;
  sponsors?: Maybe<SponsorNullableRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type ConnectionHistoryScans = {
  __typename?: "ConnectionHistoryScans";
  id: Scalars["Int"];
  scanned_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  scanning_attendee_id: Scalars["Int"];
  scanned_attendee_id: Scalars["Int"];
  badge_code: Scalars["String"];
  type: BadgeScanType;
  claim_number: Scalars["Int"];
  event_id: Scalars["Int"];
  is_duplicate: Scalars["Boolean"];
  other_attendee_name?: Maybe<Scalars["String"]>;
  other_attendee_role?: Maybe<Scalars["String"]>;
  got_scanned?: Maybe<Scalars["Boolean"]>;
  sponsor_scan?: Maybe<SponsorScan>;
};

export type CustomEventScheduleUpdateInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  banner_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventScheduleUpdatelinksInput>;
  tags?: Maybe<EventScheduleUpdatetagsInput>;
};

/** Create stage without pipeline id */
export type CustomFieldCreateWithoutPipelineInput = {
  name: Scalars["String"];
  slug: Scalars["String"];
  field_type: Scalars["String"];
  is_required: Scalars["Boolean"];
  permissions?: Maybe<Scalars["JSON"]>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
};

/** Create stage without pipeline id */
export type CustomStageCreateWithoutPipelineInput = {
  name: Scalars["String"];
  slug: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["DateTimeISO"]>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars["DateTimeISO"]>;
  in?: Maybe<Array<Scalars["DateTimeISO"]>>;
  notIn?: Maybe<Array<Scalars["DateTimeISO"]>>;
  lt?: Maybe<Scalars["DateTimeISO"]>;
  lte?: Maybe<Scalars["DateTimeISO"]>;
  gt?: Maybe<Scalars["DateTimeISO"]>;
  gte?: Maybe<Scalars["DateTimeISO"]>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTimeISO"]>;
  in?: Maybe<Array<Scalars["DateTimeISO"]>>;
  notIn?: Maybe<Array<Scalars["DateTimeISO"]>>;
  lt?: Maybe<Scalars["DateTimeISO"]>;
  lte?: Maybe<Scalars["DateTimeISO"]>;
  gt?: Maybe<Scalars["DateTimeISO"]>;
  gte?: Maybe<Scalars["DateTimeISO"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type EducationCreateManyAcademic_InstitutionInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  current_status: Scalars["String"];
  current_year?: Maybe<Scalars["Int"]>;
  degree?: Maybe<Scalars["String"]>;
  graduating_year: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  minor?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type EducationCreateManyAcademic_InstitutionInputEnvelope = {
  data: Array<EducationCreateManyAcademic_InstitutionInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EducationCreateNestedManyWithoutAcademic_InstitutionInput = {
  create?: Maybe<Array<EducationCreateWithoutAcademic_InstitutionInput>>;
  connectOrCreate?: Maybe<
    Array<EducationCreateOrConnectWithoutAcademic_InstitutionInput>
  >;
  createMany?: Maybe<EducationCreateManyAcademic_InstitutionInputEnvelope>;
  connect?: Maybe<Array<EducationWhereUniqueInput>>;
};

export type EducationCreateNestedOneWithoutUsersInput = {
  create?: Maybe<EducationCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<EducationCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<EducationWhereUniqueInput>;
};

export type EducationCreateOrConnectWithoutAcademic_InstitutionInput = {
  where: EducationWhereUniqueInput;
  create: EducationCreateWithoutAcademic_InstitutionInput;
};

export type EducationCreateOrConnectWithoutUsersInput = {
  where: EducationWhereUniqueInput;
  create: EducationCreateWithoutUsersInput;
};

export type EducationCreateWithoutAcademic_InstitutionInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  current_status: Scalars["String"];
  current_year?: Maybe<Scalars["Int"]>;
  degree?: Maybe<Scalars["String"]>;
  graduating_year: Scalars["Int"];
  minor?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  users?: Maybe<UserCreateNestedManyWithoutEducationInput>;
};

export type EducationCreateWithoutUsersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  current_status: Scalars["String"];
  current_year?: Maybe<Scalars["Int"]>;
  degree?: Maybe<Scalars["String"]>;
  graduating_year: Scalars["Int"];
  minor?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  academic_institution: AcademicInstitutionCreateNestedOneWithoutEducationsInput;
};

export type EducationListRelationFilter = {
  every?: Maybe<EducationWhereInput>;
  some?: Maybe<EducationWhereInput>;
  none?: Maybe<EducationWhereInput>;
};

export type EducationNullableRelationFilter = {
  is?: Maybe<EducationWhereInput>;
  isNot?: Maybe<EducationWhereInput>;
};

export type EducationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EducationOrderByRelevanceFieldEnum {
  CurrentStatus = "current_status",
  Degree = "degree",
  Minor = "minor",
  Program = "program",
}

export type EducationOrderByRelevanceInput = {
  fields: Array<EducationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type EducationOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  current_status?: Maybe<SortOrder>;
  current_year?: Maybe<SortOrderInput>;
  degree?: Maybe<SortOrderInput>;
  graduating_year?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  institution_id?: Maybe<SortOrder>;
  minor?: Maybe<SortOrderInput>;
  program?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  academic_institution?: Maybe<AcademicInstitutionOrderByWithRelationAndSearchRelevanceInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  _relevance?: Maybe<EducationOrderByRelevanceInput>;
};

export type EducationScalarWhereInput = {
  AND?: Maybe<Array<EducationScalarWhereInput>>;
  OR?: Maybe<Array<EducationScalarWhereInput>>;
  NOT?: Maybe<Array<EducationScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  current_status?: Maybe<StringFilter>;
  current_year?: Maybe<IntNullableFilter>;
  degree?: Maybe<StringNullableFilter>;
  graduating_year?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  institution_id?: Maybe<IntFilter>;
  minor?: Maybe<StringNullableFilter>;
  program?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type EducationUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  current_status?: Maybe<StringFieldUpdateOperationsInput>;
  current_year?: Maybe<NullableIntFieldUpdateOperationsInput>;
  degree?: Maybe<NullableStringFieldUpdateOperationsInput>;
  graduating_year?: Maybe<IntFieldUpdateOperationsInput>;
  minor?: Maybe<NullableStringFieldUpdateOperationsInput>;
  program?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EducationUpdateManyWithWhereWithoutAcademic_InstitutionInput = {
  where: EducationScalarWhereInput;
  data: EducationUpdateManyMutationInput;
};

export type EducationUpdateManyWithoutAcademic_InstitutionNestedInput = {
  create?: Maybe<Array<EducationCreateWithoutAcademic_InstitutionInput>>;
  connectOrCreate?: Maybe<
    Array<EducationCreateOrConnectWithoutAcademic_InstitutionInput>
  >;
  upsert?: Maybe<
    Array<EducationUpsertWithWhereUniqueWithoutAcademic_InstitutionInput>
  >;
  createMany?: Maybe<EducationCreateManyAcademic_InstitutionInputEnvelope>;
  set?: Maybe<Array<EducationWhereUniqueInput>>;
  disconnect?: Maybe<Array<EducationWhereUniqueInput>>;
  delete?: Maybe<Array<EducationWhereUniqueInput>>;
  connect?: Maybe<Array<EducationWhereUniqueInput>>;
  update?: Maybe<
    Array<EducationUpdateWithWhereUniqueWithoutAcademic_InstitutionInput>
  >;
  updateMany?: Maybe<
    Array<EducationUpdateManyWithWhereWithoutAcademic_InstitutionInput>
  >;
  deleteMany?: Maybe<Array<EducationScalarWhereInput>>;
};

export type EducationUpdateOneWithoutUsersNestedInput = {
  create?: Maybe<EducationCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<EducationCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<EducationUpsertWithoutUsersInput>;
  disconnect?: Maybe<EducationWhereInput>;
  delete?: Maybe<EducationWhereInput>;
  connect?: Maybe<EducationWhereUniqueInput>;
  update?: Maybe<EducationUpdateToOneWithWhereWithoutUsersInput>;
};

export type EducationUpdateToOneWithWhereWithoutUsersInput = {
  where?: Maybe<EducationWhereInput>;
  data: EducationUpdateWithoutUsersInput;
};

export type EducationUpdateWithWhereUniqueWithoutAcademic_InstitutionInput = {
  where: EducationWhereUniqueInput;
  data: EducationUpdateWithoutAcademic_InstitutionInput;
};

export type EducationUpdateWithoutAcademic_InstitutionInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  current_status?: Maybe<StringFieldUpdateOperationsInput>;
  current_year?: Maybe<NullableIntFieldUpdateOperationsInput>;
  degree?: Maybe<NullableStringFieldUpdateOperationsInput>;
  graduating_year?: Maybe<IntFieldUpdateOperationsInput>;
  minor?: Maybe<NullableStringFieldUpdateOperationsInput>;
  program?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutEducationNestedInput>;
};

export type EducationUpdateWithoutUsersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  current_status?: Maybe<StringFieldUpdateOperationsInput>;
  current_year?: Maybe<NullableIntFieldUpdateOperationsInput>;
  degree?: Maybe<NullableStringFieldUpdateOperationsInput>;
  graduating_year?: Maybe<IntFieldUpdateOperationsInput>;
  minor?: Maybe<NullableStringFieldUpdateOperationsInput>;
  program?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  academic_institution?: Maybe<AcademicInstitutionUpdateOneRequiredWithoutEducationsNestedInput>;
};

export type EducationUpsertWithWhereUniqueWithoutAcademic_InstitutionInput = {
  where: EducationWhereUniqueInput;
  update: EducationUpdateWithoutAcademic_InstitutionInput;
  create: EducationCreateWithoutAcademic_InstitutionInput;
};

export type EducationUpsertWithoutUsersInput = {
  update: EducationUpdateWithoutUsersInput;
  create: EducationCreateWithoutUsersInput;
  where?: Maybe<EducationWhereInput>;
};

export type EducationWhereInput = {
  AND?: Maybe<Array<EducationWhereInput>>;
  OR?: Maybe<Array<EducationWhereInput>>;
  NOT?: Maybe<Array<EducationWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  current_status?: Maybe<StringFilter>;
  current_year?: Maybe<IntNullableFilter>;
  degree?: Maybe<StringNullableFilter>;
  graduating_year?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  institution_id?: Maybe<IntFilter>;
  minor?: Maybe<StringNullableFilter>;
  program?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  academic_institution?: Maybe<AcademicInstitutionRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type EducationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<EducationWhereInput>>;
  OR?: Maybe<Array<EducationWhereInput>>;
  NOT?: Maybe<Array<EducationWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  current_status?: Maybe<StringFilter>;
  current_year?: Maybe<IntNullableFilter>;
  degree?: Maybe<StringNullableFilter>;
  graduating_year?: Maybe<IntFilter>;
  institution_id?: Maybe<IntFilter>;
  minor?: Maybe<StringNullableFilter>;
  program?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  academic_institution?: Maybe<AcademicInstitutionRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type Email = {
  __typename?: "Email";
  campaign: Scalars["String"];
  context?: Maybe<Scalars["JSON"]>;
  created_at: Scalars["DateTimeISO"];
  html: Scalars["String"];
  id: Scalars["Int"];
  send_time: Scalars["DateTimeISO"];
  sent: Scalars["Boolean"];
  subject: Scalars["String"];
  to_email: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
};

export type EnumBadgeScanTypeFieldUpdateOperationsInput = {
  set?: Maybe<BadgeScanType>;
};

export type EnumBadgeScanTypeFilter = {
  equals?: Maybe<BadgeScanType>;
  in?: Maybe<Array<BadgeScanType>>;
  notIn?: Maybe<Array<BadgeScanType>>;
  not?: Maybe<NestedEnumBadgeScanTypeFilter>;
};

export type EnumGenderNullableFilter = {
  equals?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  notIn?: Maybe<Array<Gender>>;
  not?: Maybe<NestedEnumGenderNullableFilter>;
};

export type EnumHardwareRequestStatusFieldUpdateOperationsInput = {
  set?: Maybe<HardwareRequestStatus>;
};

export type EnumHardwareRequestStatusFilter = {
  equals?: Maybe<HardwareRequestStatus>;
  in?: Maybe<Array<HardwareRequestStatus>>;
  notIn?: Maybe<Array<HardwareRequestStatus>>;
  not?: Maybe<NestedEnumHardwareRequestStatusFilter>;
};

export type EnumHardwareRequestStatusNullableFilter = {
  equals?: Maybe<HardwareRequestStatus>;
  in?: Maybe<Array<HardwareRequestStatus>>;
  notIn?: Maybe<Array<HardwareRequestStatus>>;
  not?: Maybe<NestedEnumHardwareRequestStatusNullableFilter>;
};

export type EnumHardwareSkuTypeFieldUpdateOperationsInput = {
  set?: Maybe<HardwareSkuType>;
};

export type EnumHardwareSkuTypeFilter = {
  equals?: Maybe<HardwareSkuType>;
  in?: Maybe<Array<HardwareSkuType>>;
  notIn?: Maybe<Array<HardwareSkuType>>;
  not?: Maybe<NestedEnumHardwareSkuTypeFilter>;
};

export type EnumInstitutionTypeEnumFieldUpdateOperationsInput = {
  set?: Maybe<InstitutionTypeEnum>;
};

export type EnumInstitutionTypeEnumFilter = {
  equals?: Maybe<InstitutionTypeEnum>;
  in?: Maybe<Array<InstitutionTypeEnum>>;
  notIn?: Maybe<Array<InstitutionTypeEnum>>;
  not?: Maybe<NestedEnumInstitutionTypeEnumFilter>;
};

export type EnumPersonRoleFieldUpdateOperationsInput = {
  set?: Maybe<PersonRole>;
};

export type EnumPersonRoleFilter = {
  equals?: Maybe<PersonRole>;
  in?: Maybe<Array<PersonRole>>;
  notIn?: Maybe<Array<PersonRole>>;
  not?: Maybe<NestedEnumPersonRoleFilter>;
};

export type EnumRecordTypeEmailFieldUpdateOperationsInput = {
  set?: Maybe<RecordTypeEmail>;
};

export type EnumRecordTypeEmailFilter = {
  equals?: Maybe<RecordTypeEmail>;
  in?: Maybe<Array<RecordTypeEmail>>;
  notIn?: Maybe<Array<RecordTypeEmail>>;
  not?: Maybe<NestedEnumRecordTypeEmailFilter>;
};

export type EnumShiftStatusFieldUpdateOperationsInput = {
  set?: Maybe<ShiftStatus>;
};

export type EnumShiftStatusFilter = {
  equals?: Maybe<ShiftStatus>;
  in?: Maybe<Array<ShiftStatus>>;
  notIn?: Maybe<Array<ShiftStatus>>;
  not?: Maybe<NestedEnumShiftStatusFilter>;
};

export type EnumSponsorCompanyStageFieldUpdateOperationsInput = {
  set?: Maybe<SponsorCompanyStage>;
};

export type EnumSponsorCompanyStageFilter = {
  equals?: Maybe<SponsorCompanyStage>;
  in?: Maybe<Array<SponsorCompanyStage>>;
  notIn?: Maybe<Array<SponsorCompanyStage>>;
  not?: Maybe<NestedEnumSponsorCompanyStageFilter>;
};

export type EnumSponsorCompanyTierFieldUpdateOperationsInput = {
  set?: Maybe<SponsorCompanyTier>;
};

export type EnumSponsorCompanyTierFilter = {
  equals?: Maybe<SponsorCompanyTier>;
  in?: Maybe<Array<SponsorCompanyTier>>;
  notIn?: Maybe<Array<SponsorCompanyTier>>;
  not?: Maybe<NestedEnumSponsorCompanyTierFilter>;
};

export type Event = {
  __typename?: "Event";
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTimeISO"];
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  themes: Array<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  _count?: Maybe<EventCount>;
};

export type EventActivity = {
  __typename?: "EventActivity";
  category?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTimeISO"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at: Scalars["DateTimeISO"];
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
  _count?: Maybe<EventActivityCount>;
};

export type EventActivityCount = {
  __typename?: "EventActivityCount";
  event_activity_redemptions: Scalars["Int"];
  event_activity_scans: Scalars["Int"];
};

export type EventActivityCountEvent_Activity_RedemptionsArgs = {
  where?: Maybe<EventActivityRedemptionWhereInput>;
};

export type EventActivityCountEvent_Activity_ScansArgs = {
  where?: Maybe<EventActivityScanWhereInput>;
};

export type EventActivityCreateInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutEvent_ActivitiesInput;
  event_activity_redemptions?: Maybe<EventActivityRedemptionCreateNestedManyWithoutEvent_ActivityInput>;
  event_activity_scans?: Maybe<EventActivityScanCreateNestedManyWithoutEvent_ActivityInput>;
};

export type EventActivityCreateManyEventInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
};

export type EventActivityCreateManyEventInputEnvelope = {
  data: Array<EventActivityCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventActivityCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<EventActivityCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventActivityCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<EventActivityCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<EventActivityWhereUniqueInput>>;
};

export type EventActivityCreateNestedOneWithoutEvent_Activity_RedemptionsInput =
  {
    create?: Maybe<EventActivityCreateWithoutEvent_Activity_RedemptionsInput>;
    connectOrCreate?: Maybe<EventActivityCreateOrConnectWithoutEvent_Activity_RedemptionsInput>;
    connect?: Maybe<EventActivityWhereUniqueInput>;
  };

export type EventActivityCreateNestedOneWithoutEvent_Activity_ScansInput = {
  create?: Maybe<EventActivityCreateWithoutEvent_Activity_ScansInput>;
  connectOrCreate?: Maybe<EventActivityCreateOrConnectWithoutEvent_Activity_ScansInput>;
  connect?: Maybe<EventActivityWhereUniqueInput>;
};

export type EventActivityCreateOrConnectWithoutEventInput = {
  where: EventActivityWhereUniqueInput;
  create: EventActivityCreateWithoutEventInput;
};

export type EventActivityCreateOrConnectWithoutEvent_Activity_RedemptionsInput =
  {
    where: EventActivityWhereUniqueInput;
    create: EventActivityCreateWithoutEvent_Activity_RedemptionsInput;
  };

export type EventActivityCreateOrConnectWithoutEvent_Activity_ScansInput = {
  where: EventActivityWhereUniqueInput;
  create: EventActivityCreateWithoutEvent_Activity_ScansInput;
};

export type EventActivityCreateWithoutEventInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionCreateNestedManyWithoutEvent_ActivityInput>;
  event_activity_scans?: Maybe<EventActivityScanCreateNestedManyWithoutEvent_ActivityInput>;
};

export type EventActivityCreateWithoutEvent_Activity_RedemptionsInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutEvent_ActivitiesInput;
  event_activity_scans?: Maybe<EventActivityScanCreateNestedManyWithoutEvent_ActivityInput>;
};

export type EventActivityCreateWithoutEvent_Activity_ScansInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug?: Maybe<Scalars["String"]>;
  restrictions_string?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutEvent_ActivitiesInput;
  event_activity_redemptions?: Maybe<EventActivityRedemptionCreateNestedManyWithoutEvent_ActivityInput>;
};

export type EventActivityEvent_Activities_Slug_Event_Id_KeyCompoundUniqueInput =
  {
    slug: Scalars["String"];
    event_id: Scalars["Int"];
  };

export type EventActivityListRelationFilter = {
  every?: Maybe<EventActivityWhereInput>;
  some?: Maybe<EventActivityWhereInput>;
  none?: Maybe<EventActivityWhereInput>;
};

export type EventActivityOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EventActivityOrderByRelevanceFieldEnum {
  Category = "category",
  Location = "location",
  Name = "name",
  Slug = "slug",
  RestrictionsString = "restrictions_string",
}

export type EventActivityOrderByRelevanceInput = {
  fields: Array<EventActivityOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type EventActivityOrderByWithRelationAndSearchRelevanceInput = {
  category?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  end_time?: Maybe<SortOrderInput>;
  event_id?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  location?: Maybe<SortOrderInput>;
  name?: Maybe<SortOrder>;
  restrictions?: Maybe<SortOrderInput>;
  start_time?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  slug?: Maybe<SortOrderInput>;
  restrictions_string?: Maybe<SortOrderInput>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionOrderByRelationAggregateInput>;
  event_activity_scans?: Maybe<EventActivityScanOrderByRelationAggregateInput>;
  _relevance?: Maybe<EventActivityOrderByRelevanceInput>;
};

export type EventActivityRedemption = {
  __typename?: "EventActivityRedemption";
  created_at: Scalars["DateTimeISO"];
  event_activity_id: Scalars["Int"];
  id: Scalars["Int"];
  override_reason?: Maybe<Scalars["String"]>;
  scan_time: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  user_id?: Maybe<Scalars["Int"]>;
  volunteer_id: Scalars["Int"];
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventActivityRedemptionCreateManyEvent_ActivityInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id?: Maybe<Scalars["Int"]>;
  volunteer_id: Scalars["Int"];
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventActivityRedemptionCreateManyEvent_ActivityInputEnvelope = {
  data: Array<EventActivityRedemptionCreateManyEvent_ActivityInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventActivityRedemptionCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_activity_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  volunteer_id: Scalars["Int"];
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventActivityRedemptionCreateManyUserInputEnvelope = {
  data: Array<EventActivityRedemptionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventActivityRedemptionCreateManyVolunteerInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_activity_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id?: Maybe<Scalars["Int"]>;
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventActivityRedemptionCreateManyVolunteerInputEnvelope = {
  data: Array<EventActivityRedemptionCreateManyVolunteerInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventActivityRedemptionCreateNestedManyWithoutEvent_ActivityInput =
  {
    create?: Maybe<
      Array<EventActivityRedemptionCreateWithoutEvent_ActivityInput>
    >;
    connectOrCreate?: Maybe<
      Array<EventActivityRedemptionCreateOrConnectWithoutEvent_ActivityInput>
    >;
    createMany?: Maybe<EventActivityRedemptionCreateManyEvent_ActivityInputEnvelope>;
    connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  };

export type EventActivityRedemptionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<EventActivityRedemptionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
};

export type EventActivityRedemptionCreateNestedManyWithoutVolunteerInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutVolunteerInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutVolunteerInput>
  >;
  createMany?: Maybe<EventActivityRedemptionCreateManyVolunteerInputEnvelope>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
};

export type EventActivityRedemptionCreateOrConnectWithoutEvent_ActivityInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutEvent_ActivityInput;
};

export type EventActivityRedemptionCreateOrConnectWithoutUserInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutUserInput;
};

export type EventActivityRedemptionCreateOrConnectWithoutVolunteerInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutVolunteerInput;
};

export type EventActivityRedemptionCreateWithoutEvent_ActivityInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  user?: Maybe<UserCreateNestedOneWithoutEvent_Activity_Redemptions_UserInput>;
  volunteer: UserCreateNestedOneWithoutEvent_Activity_Redemptions_EventInput;
};

export type EventActivityRedemptionCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  event_activity: EventActivityCreateNestedOneWithoutEvent_Activity_RedemptionsInput;
  volunteer: UserCreateNestedOneWithoutEvent_Activity_Redemptions_EventInput;
};

export type EventActivityRedemptionCreateWithoutVolunteerInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  event_activity: EventActivityCreateNestedOneWithoutEvent_Activity_RedemptionsInput;
  user?: Maybe<UserCreateNestedOneWithoutEvent_Activity_Redemptions_UserInput>;
};

export type EventActivityRedemptionListRelationFilter = {
  every?: Maybe<EventActivityRedemptionWhereInput>;
  some?: Maybe<EventActivityRedemptionWhereInput>;
  none?: Maybe<EventActivityRedemptionWhereInput>;
};

export type EventActivityRedemptionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EventActivityRedemptionScalarWhereInput = {
  AND?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
  OR?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
  NOT?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_activity_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  scan_time?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  volunteer_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
};

export type EventActivityRedemptionUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  scan_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventActivityRedemptionUpdateManyWithWhereWithoutEvent_ActivityInput =
  {
    where: EventActivityRedemptionScalarWhereInput;
    data: EventActivityRedemptionUpdateManyMutationInput;
  };

export type EventActivityRedemptionUpdateManyWithWhereWithoutUserInput = {
  where: EventActivityRedemptionScalarWhereInput;
  data: EventActivityRedemptionUpdateManyMutationInput;
};

export type EventActivityRedemptionUpdateManyWithWhereWithoutVolunteerInput = {
  where: EventActivityRedemptionScalarWhereInput;
  data: EventActivityRedemptionUpdateManyMutationInput;
};

export type EventActivityRedemptionUpdateManyWithoutEvent_ActivityNestedInput =
  {
    create?: Maybe<
      Array<EventActivityRedemptionCreateWithoutEvent_ActivityInput>
    >;
    connectOrCreate?: Maybe<
      Array<EventActivityRedemptionCreateOrConnectWithoutEvent_ActivityInput>
    >;
    upsert?: Maybe<
      Array<EventActivityRedemptionUpsertWithWhereUniqueWithoutEvent_ActivityInput>
    >;
    createMany?: Maybe<EventActivityRedemptionCreateManyEvent_ActivityInputEnvelope>;
    set?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
    disconnect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
    delete?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
    connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
    update?: Maybe<
      Array<EventActivityRedemptionUpdateWithWhereUniqueWithoutEvent_ActivityInput>
    >;
    updateMany?: Maybe<
      Array<EventActivityRedemptionUpdateManyWithWhereWithoutEvent_ActivityInput>
    >;
    deleteMany?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
  };

export type EventActivityRedemptionUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<EventActivityRedemptionUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<EventActivityRedemptionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  update?: Maybe<
    Array<EventActivityRedemptionUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<EventActivityRedemptionUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
};

export type EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutVolunteerInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutVolunteerInput>
  >;
  upsert?: Maybe<
    Array<EventActivityRedemptionUpsertWithWhereUniqueWithoutVolunteerInput>
  >;
  createMany?: Maybe<EventActivityRedemptionCreateManyVolunteerInputEnvelope>;
  set?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  delete?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  update?: Maybe<
    Array<EventActivityRedemptionUpdateWithWhereUniqueWithoutVolunteerInput>
  >;
  updateMany?: Maybe<
    Array<EventActivityRedemptionUpdateManyWithWhereWithoutVolunteerInput>
  >;
  deleteMany?: Maybe<Array<EventActivityRedemptionScalarWhereInput>>;
};

export type EventActivityRedemptionUpdateWithWhereUniqueWithoutEvent_ActivityInput =
  {
    where: EventActivityRedemptionWhereUniqueInput;
    data: EventActivityRedemptionUpdateWithoutEvent_ActivityInput;
  };

export type EventActivityRedemptionUpdateWithWhereUniqueWithoutUserInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  data: EventActivityRedemptionUpdateWithoutUserInput;
};

export type EventActivityRedemptionUpdateWithWhereUniqueWithoutVolunteerInput =
  {
    where: EventActivityRedemptionWhereUniqueInput;
    data: EventActivityRedemptionUpdateWithoutVolunteerInput;
  };

export type EventActivityRedemptionUpdateWithoutEvent_ActivityInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  scan_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutEvent_Activity_Redemptions_UserNestedInput>;
  volunteer?: Maybe<UserUpdateOneRequiredWithoutEvent_Activity_Redemptions_EventNestedInput>;
};

export type EventActivityRedemptionUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  scan_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_activity?: Maybe<EventActivityUpdateOneRequiredWithoutEvent_Activity_RedemptionsNestedInput>;
  volunteer?: Maybe<UserUpdateOneRequiredWithoutEvent_Activity_Redemptions_EventNestedInput>;
};

export type EventActivityRedemptionUpdateWithoutVolunteerInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  scan_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_activity?: Maybe<EventActivityUpdateOneRequiredWithoutEvent_Activity_RedemptionsNestedInput>;
  user?: Maybe<UserUpdateOneWithoutEvent_Activity_Redemptions_UserNestedInput>;
};

export type EventActivityRedemptionUpsertWithWhereUniqueWithoutEvent_ActivityInput =
  {
    where: EventActivityRedemptionWhereUniqueInput;
    update: EventActivityRedemptionUpdateWithoutEvent_ActivityInput;
    create: EventActivityRedemptionCreateWithoutEvent_ActivityInput;
  };

export type EventActivityRedemptionUpsertWithWhereUniqueWithoutUserInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  update: EventActivityRedemptionUpdateWithoutUserInput;
  create: EventActivityRedemptionCreateWithoutUserInput;
};

export type EventActivityRedemptionUpsertWithWhereUniqueWithoutVolunteerInput =
  {
    where: EventActivityRedemptionWhereUniqueInput;
    update: EventActivityRedemptionUpdateWithoutVolunteerInput;
    create: EventActivityRedemptionCreateWithoutVolunteerInput;
  };

export type EventActivityRedemptionWhereInput = {
  AND?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  OR?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  NOT?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_activity_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  scan_time?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  volunteer_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
  event_activity?: Maybe<EventActivityRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  volunteer?: Maybe<UserRelationFilter>;
};

export type EventActivityRedemptionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  OR?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  NOT?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_activity_id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  scan_time?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  volunteer_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
  event_activity?: Maybe<EventActivityRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  volunteer?: Maybe<UserRelationFilter>;
};

export type EventActivityRelationFilter = {
  is?: Maybe<EventActivityWhereInput>;
  isNot?: Maybe<EventActivityWhereInput>;
};

export enum EventActivityScalarFieldEnum {
  Category = "category",
  CreatedAt = "created_at",
  EndTime = "end_time",
  EventId = "event_id",
  Id = "id",
  Location = "location",
  Name = "name",
  Restrictions = "restrictions",
  StartTime = "start_time",
  UpdatedAt = "updated_at",
  Slug = "slug",
  RestrictionsString = "restrictions_string",
}

export type EventActivityScalarWhereInput = {
  AND?: Maybe<Array<EventActivityScalarWhereInput>>;
  OR?: Maybe<Array<EventActivityScalarWhereInput>>;
  NOT?: Maybe<Array<EventActivityScalarWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  restrictions?: Maybe<JsonNullableFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringNullableFilter>;
  restrictions_string?: Maybe<StringNullableFilter>;
};

export type EventActivityScan = {
  __typename?: "EventActivityScan";
  id: Scalars["Int"];
  badge_scan_id: Scalars["Int"];
  event_activity_id: Scalars["Int"];
  override_reason?: Maybe<Scalars["String"]>;
};

export type EventActivityScanCreateManyEvent_ActivityInput = {
  id?: Maybe<Scalars["Int"]>;
  badge_scan_id: Scalars["Int"];
  override_reason?: Maybe<Scalars["String"]>;
};

export type EventActivityScanCreateManyEvent_ActivityInputEnvelope = {
  data: Array<EventActivityScanCreateManyEvent_ActivityInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventActivityScanCreateNestedManyWithoutEvent_ActivityInput = {
  create?: Maybe<Array<EventActivityScanCreateWithoutEvent_ActivityInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityScanCreateOrConnectWithoutEvent_ActivityInput>
  >;
  createMany?: Maybe<EventActivityScanCreateManyEvent_ActivityInputEnvelope>;
  connect?: Maybe<Array<EventActivityScanWhereUniqueInput>>;
};

export type EventActivityScanCreateNestedOneWithoutBadge_ScanInput = {
  create?: Maybe<EventActivityScanCreateWithoutBadge_ScanInput>;
  connectOrCreate?: Maybe<EventActivityScanCreateOrConnectWithoutBadge_ScanInput>;
  connect?: Maybe<EventActivityScanWhereUniqueInput>;
};

export type EventActivityScanCreateOrConnectWithoutBadge_ScanInput = {
  where: EventActivityScanWhereUniqueInput;
  create: EventActivityScanCreateWithoutBadge_ScanInput;
};

export type EventActivityScanCreateOrConnectWithoutEvent_ActivityInput = {
  where: EventActivityScanWhereUniqueInput;
  create: EventActivityScanCreateWithoutEvent_ActivityInput;
};

export type EventActivityScanCreateWithoutBadge_ScanInput = {
  override_reason?: Maybe<Scalars["String"]>;
  event_activity: EventActivityCreateNestedOneWithoutEvent_Activity_ScansInput;
};

export type EventActivityScanCreateWithoutEvent_ActivityInput = {
  override_reason?: Maybe<Scalars["String"]>;
  badge_scan: BadgeScanCreateNestedOneWithoutEvent_Activity_ScanInput;
};

export type EventActivityScanListRelationFilter = {
  every?: Maybe<EventActivityScanWhereInput>;
  some?: Maybe<EventActivityScanWhereInput>;
  none?: Maybe<EventActivityScanWhereInput>;
};

export type EventActivityScanNullableRelationFilter = {
  is?: Maybe<EventActivityScanWhereInput>;
  isNot?: Maybe<EventActivityScanWhereInput>;
};

export type EventActivityScanOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EventActivityScanScalarWhereInput = {
  AND?: Maybe<Array<EventActivityScanScalarWhereInput>>;
  OR?: Maybe<Array<EventActivityScanScalarWhereInput>>;
  NOT?: Maybe<Array<EventActivityScanScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  badge_scan_id?: Maybe<IntFilter>;
  event_activity_id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
};

export type EventActivityScanUpdateManyMutationInput = {
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventActivityScanUpdateManyWithWhereWithoutEvent_ActivityInput = {
  where: EventActivityScanScalarWhereInput;
  data: EventActivityScanUpdateManyMutationInput;
};

export type EventActivityScanUpdateManyWithoutEvent_ActivityNestedInput = {
  create?: Maybe<Array<EventActivityScanCreateWithoutEvent_ActivityInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityScanCreateOrConnectWithoutEvent_ActivityInput>
  >;
  upsert?: Maybe<
    Array<EventActivityScanUpsertWithWhereUniqueWithoutEvent_ActivityInput>
  >;
  createMany?: Maybe<EventActivityScanCreateManyEvent_ActivityInputEnvelope>;
  set?: Maybe<Array<EventActivityScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventActivityScanWhereUniqueInput>>;
  delete?: Maybe<Array<EventActivityScanWhereUniqueInput>>;
  connect?: Maybe<Array<EventActivityScanWhereUniqueInput>>;
  update?: Maybe<
    Array<EventActivityScanUpdateWithWhereUniqueWithoutEvent_ActivityInput>
  >;
  updateMany?: Maybe<
    Array<EventActivityScanUpdateManyWithWhereWithoutEvent_ActivityInput>
  >;
  deleteMany?: Maybe<Array<EventActivityScanScalarWhereInput>>;
};

export type EventActivityScanUpdateOneWithoutBadge_ScanNestedInput = {
  create?: Maybe<EventActivityScanCreateWithoutBadge_ScanInput>;
  connectOrCreate?: Maybe<EventActivityScanCreateOrConnectWithoutBadge_ScanInput>;
  upsert?: Maybe<EventActivityScanUpsertWithoutBadge_ScanInput>;
  disconnect?: Maybe<EventActivityScanWhereInput>;
  delete?: Maybe<EventActivityScanWhereInput>;
  connect?: Maybe<EventActivityScanWhereUniqueInput>;
  update?: Maybe<EventActivityScanUpdateToOneWithWhereWithoutBadge_ScanInput>;
};

export type EventActivityScanUpdateToOneWithWhereWithoutBadge_ScanInput = {
  where?: Maybe<EventActivityScanWhereInput>;
  data: EventActivityScanUpdateWithoutBadge_ScanInput;
};

export type EventActivityScanUpdateWithWhereUniqueWithoutEvent_ActivityInput = {
  where: EventActivityScanWhereUniqueInput;
  data: EventActivityScanUpdateWithoutEvent_ActivityInput;
};

export type EventActivityScanUpdateWithoutBadge_ScanInput = {
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_activity?: Maybe<EventActivityUpdateOneRequiredWithoutEvent_Activity_ScansNestedInput>;
};

export type EventActivityScanUpdateWithoutEvent_ActivityInput = {
  override_reason?: Maybe<NullableStringFieldUpdateOperationsInput>;
  badge_scan?: Maybe<BadgeScanUpdateOneRequiredWithoutEvent_Activity_ScanNestedInput>;
};

export type EventActivityScanUpsertWithWhereUniqueWithoutEvent_ActivityInput = {
  where: EventActivityScanWhereUniqueInput;
  update: EventActivityScanUpdateWithoutEvent_ActivityInput;
  create: EventActivityScanCreateWithoutEvent_ActivityInput;
};

export type EventActivityScanUpsertWithoutBadge_ScanInput = {
  update: EventActivityScanUpdateWithoutBadge_ScanInput;
  create: EventActivityScanCreateWithoutBadge_ScanInput;
  where?: Maybe<EventActivityScanWhereInput>;
};

export type EventActivityScanWhereInput = {
  AND?: Maybe<Array<EventActivityScanWhereInput>>;
  OR?: Maybe<Array<EventActivityScanWhereInput>>;
  NOT?: Maybe<Array<EventActivityScanWhereInput>>;
  id?: Maybe<IntFilter>;
  badge_scan_id?: Maybe<IntFilter>;
  event_activity_id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  badge_scan?: Maybe<BadgeScanRelationFilter>;
  event_activity?: Maybe<EventActivityRelationFilter>;
};

export type EventActivityScanWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  badge_scan_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<EventActivityScanWhereInput>>;
  OR?: Maybe<Array<EventActivityScanWhereInput>>;
  NOT?: Maybe<Array<EventActivityScanWhereInput>>;
  event_activity_id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  badge_scan?: Maybe<BadgeScanRelationFilter>;
  event_activity?: Maybe<EventActivityRelationFilter>;
};

export type EventActivityUpdateInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  restrictions_string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_ActivitiesNestedInput>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionUpdateManyWithoutEvent_ActivityNestedInput>;
  event_activity_scans?: Maybe<EventActivityScanUpdateManyWithoutEvent_ActivityNestedInput>;
};

export type EventActivityUpdateManyMutationInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  restrictions_string?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventActivityUpdateManyWithWhereWithoutEventInput = {
  where: EventActivityScalarWhereInput;
  data: EventActivityUpdateManyMutationInput;
};

export type EventActivityUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<EventActivityCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventActivityCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<EventActivityUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<EventActivityCreateManyEventInputEnvelope>;
  set?: Maybe<Array<EventActivityWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventActivityWhereUniqueInput>>;
  delete?: Maybe<Array<EventActivityWhereUniqueInput>>;
  connect?: Maybe<Array<EventActivityWhereUniqueInput>>;
  update?: Maybe<Array<EventActivityUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<EventActivityUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<EventActivityScalarWhereInput>>;
};

export type EventActivityUpdateOneRequiredWithoutEvent_Activity_RedemptionsNestedInput =
  {
    create?: Maybe<EventActivityCreateWithoutEvent_Activity_RedemptionsInput>;
    connectOrCreate?: Maybe<EventActivityCreateOrConnectWithoutEvent_Activity_RedemptionsInput>;
    upsert?: Maybe<EventActivityUpsertWithoutEvent_Activity_RedemptionsInput>;
    connect?: Maybe<EventActivityWhereUniqueInput>;
    update?: Maybe<EventActivityUpdateToOneWithWhereWithoutEvent_Activity_RedemptionsInput>;
  };

export type EventActivityUpdateOneRequiredWithoutEvent_Activity_ScansNestedInput =
  {
    create?: Maybe<EventActivityCreateWithoutEvent_Activity_ScansInput>;
    connectOrCreate?: Maybe<EventActivityCreateOrConnectWithoutEvent_Activity_ScansInput>;
    upsert?: Maybe<EventActivityUpsertWithoutEvent_Activity_ScansInput>;
    connect?: Maybe<EventActivityWhereUniqueInput>;
    update?: Maybe<EventActivityUpdateToOneWithWhereWithoutEvent_Activity_ScansInput>;
  };

export type EventActivityUpdateToOneWithWhereWithoutEvent_Activity_RedemptionsInput =
  {
    where?: Maybe<EventActivityWhereInput>;
    data: EventActivityUpdateWithoutEvent_Activity_RedemptionsInput;
  };

export type EventActivityUpdateToOneWithWhereWithoutEvent_Activity_ScansInput =
  {
    where?: Maybe<EventActivityWhereInput>;
    data: EventActivityUpdateWithoutEvent_Activity_ScansInput;
  };

export type EventActivityUpdateWithWhereUniqueWithoutEventInput = {
  where: EventActivityWhereUniqueInput;
  data: EventActivityUpdateWithoutEventInput;
};

export type EventActivityUpdateWithoutEventInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  restrictions_string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionUpdateManyWithoutEvent_ActivityNestedInput>;
  event_activity_scans?: Maybe<EventActivityScanUpdateManyWithoutEvent_ActivityNestedInput>;
};

export type EventActivityUpdateWithoutEvent_Activity_RedemptionsInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  restrictions_string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_ActivitiesNestedInput>;
  event_activity_scans?: Maybe<EventActivityScanUpdateManyWithoutEvent_ActivityNestedInput>;
};

export type EventActivityUpdateWithoutEvent_Activity_ScansInput = {
  category?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  restrictions_string?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_ActivitiesNestedInput>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionUpdateManyWithoutEvent_ActivityNestedInput>;
};

export type EventActivityUpsertWithWhereUniqueWithoutEventInput = {
  where: EventActivityWhereUniqueInput;
  update: EventActivityUpdateWithoutEventInput;
  create: EventActivityCreateWithoutEventInput;
};

export type EventActivityUpsertWithoutEvent_Activity_RedemptionsInput = {
  update: EventActivityUpdateWithoutEvent_Activity_RedemptionsInput;
  create: EventActivityCreateWithoutEvent_Activity_RedemptionsInput;
  where?: Maybe<EventActivityWhereInput>;
};

export type EventActivityUpsertWithoutEvent_Activity_ScansInput = {
  update: EventActivityUpdateWithoutEvent_Activity_ScansInput;
  create: EventActivityCreateWithoutEvent_Activity_ScansInput;
  where?: Maybe<EventActivityWhereInput>;
};

export type EventActivityWhereInput = {
  AND?: Maybe<Array<EventActivityWhereInput>>;
  OR?: Maybe<Array<EventActivityWhereInput>>;
  NOT?: Maybe<Array<EventActivityWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  restrictions?: Maybe<JsonNullableFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringNullableFilter>;
  restrictions_string?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_activity_scans?: Maybe<EventActivityScanListRelationFilter>;
};

export type EventActivityWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  event_activities_slug_event_id_key?: Maybe<EventActivityEvent_Activities_Slug_Event_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<EventActivityWhereInput>>;
  OR?: Maybe<Array<EventActivityWhereInput>>;
  NOT?: Maybe<Array<EventActivityWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  restrictions?: Maybe<JsonNullableFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringNullableFilter>;
  restrictions_string?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_activity_scans?: Maybe<EventActivityScanListRelationFilter>;
};

export type EventAttendee = {
  __typename?: "EventAttendee";
  created_at: Scalars["DateTimeISO"];
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  role: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
  user_id: Scalars["Int"];
  badge_code?: Maybe<Scalars["String"]>;
  _count?: Maybe<EventAttendeeCount>;
  user: User;
};

export type EventAttendeeCount = {
  __typename?: "EventAttendeeCount";
  inbound_scans: Scalars["Int"];
  outbound_scans: Scalars["Int"];
  signage_answers: Scalars["Int"];
  volunteer: Scalars["Int"];
  ShiftSignup: Scalars["Int"];
};

export type EventAttendeeCountInbound_ScansArgs = {
  where?: Maybe<BadgeScanWhereInput>;
};

export type EventAttendeeCountOutbound_ScansArgs = {
  where?: Maybe<BadgeScanWhereInput>;
};

export type EventAttendeeCountSignage_AnswersArgs = {
  where?: Maybe<SignageAnswerWhereInput>;
};

export type EventAttendeeCountVolunteerArgs = {
  where?: Maybe<VolunteerWhereInput>;
};

export type EventAttendeeCountShiftSignupArgs = {
  where?: Maybe<ShiftSignupWhereInput>;
};

export type EventAttendeeCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventAttendeeCreateManyEventInputEnvelope = {
  data: Array<EventAttendeeCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventAttendeeCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
};

export type EventAttendeeCreateManyUserInputEnvelope = {
  data: Array<EventAttendeeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventAttendeeCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<EventAttendeeCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
};

export type EventAttendeeCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<EventAttendeeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
};

export type EventAttendeeCreateNestedOneWithoutInbound_ScansInput = {
  create?: Maybe<EventAttendeeCreateWithoutInbound_ScansInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutInbound_ScansInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
};

export type EventAttendeeCreateNestedOneWithoutOutbound_ScansInput = {
  create?: Maybe<EventAttendeeCreateWithoutOutbound_ScansInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutOutbound_ScansInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
};

export type EventAttendeeCreateNestedOneWithoutShiftSignupInput = {
  create?: Maybe<EventAttendeeCreateWithoutShiftSignupInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutShiftSignupInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
};

export type EventAttendeeCreateNestedOneWithoutSignage_AnswersInput = {
  create?: Maybe<EventAttendeeCreateWithoutSignage_AnswersInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutSignage_AnswersInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
};

export type EventAttendeeCreateNestedOneWithoutVolunteerInput = {
  create?: Maybe<EventAttendeeCreateWithoutVolunteerInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutVolunteerInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
};

export type EventAttendeeCreateOrConnectWithoutEventInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutEventInput;
};

export type EventAttendeeCreateOrConnectWithoutInbound_ScansInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutInbound_ScansInput;
};

export type EventAttendeeCreateOrConnectWithoutOutbound_ScansInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutOutbound_ScansInput;
};

export type EventAttendeeCreateOrConnectWithoutShiftSignupInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutShiftSignupInput;
};

export type EventAttendeeCreateOrConnectWithoutSignage_AnswersInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutSignage_AnswersInput;
};

export type EventAttendeeCreateOrConnectWithoutUserInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutUserInput;
};

export type EventAttendeeCreateOrConnectWithoutVolunteerInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutVolunteerInput;
};

export type EventAttendeeCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutInbound_ScansInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutOutbound_ScansInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutShiftSignupInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutSignage_AnswersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  volunteer?: Maybe<VolunteerCreateNestedManyWithoutEvent_AttendeeInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeCreateWithoutVolunteerInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  badge_code?: Maybe<Scalars["String"]>;
  inbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanned_AttendeeInput>;
  outbound_scans?: Maybe<BadgeScanCreateNestedManyWithoutScanning_AttendeeInput>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutRespondentInput>;
  event: EventCreateNestedOneWithoutEvent_AttendeesInput;
  user: UserCreateNestedOneWithoutEvent_AttendeesInput;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput>;
};

export type EventAttendeeEvent_Attendees_Badge_Code_Event_Id_KeyCompoundUniqueInput =
  {
    badge_code: Scalars["String"];
    event_id: Scalars["Int"];
  };

export type EventAttendeeEvent_Attendees_Role_User_Id_Event_Id_KeyCompoundUniqueInput =
  {
    role: Scalars["String"];
    user_id: Scalars["Int"];
    event_id: Scalars["Int"];
  };

export type EventAttendeeInfo = {
  __typename?: "EventAttendeeInfo";
  userId: Scalars["Int"];
  claimStage: Scalars["String"];
  eventAttendeeId: Scalars["Int"];
  role: Scalars["String"];
  badgeCode: Scalars["String"];
  registrationData: EventAttendeeRegistrationInfo;
};

export type EventAttendeeListRelationFilter = {
  every?: Maybe<EventAttendeeWhereInput>;
  some?: Maybe<EventAttendeeWhereInput>;
  none?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum EventAttendeeOrderByRelevanceFieldEnum {
  Role = "role",
  BadgeCode = "badge_code",
}

export type EventAttendeeOrderByRelevanceInput = {
  fields: Array<EventAttendeeOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type EventAttendeeOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  event_id?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  user_id?: Maybe<SortOrder>;
  badge_code?: Maybe<SortOrderInput>;
  inbound_scans?: Maybe<BadgeScanOrderByRelationAggregateInput>;
  outbound_scans?: Maybe<BadgeScanOrderByRelationAggregateInput>;
  signage_answers?: Maybe<SignageAnswerOrderByRelationAggregateInput>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  volunteer?: Maybe<VolunteerOrderByRelationAggregateInput>;
  ShiftSignup?: Maybe<ShiftSignupOrderByRelationAggregateInput>;
  _relevance?: Maybe<EventAttendeeOrderByRelevanceInput>;
};

export type EventAttendeeRegistrationInfo = {
  __typename?: "EventAttendeeRegistrationInfo";
  preferredName: Scalars["String"];
  legalName: Scalars["String"];
  pronouns: Scalars["String"];
  accessibilityNeeds: Scalars["String"];
  shirtType: Scalars["String"];
  shirtSize: Scalars["String"];
  shirtAccommodations: Scalars["String"];
};

export type EventAttendeeRelationFilter = {
  is?: Maybe<EventAttendeeWhereInput>;
  isNot?: Maybe<EventAttendeeWhereInput>;
};

export enum EventAttendeeScalarFieldEnum {
  CreatedAt = "created_at",
  EventId = "event_id",
  Id = "id",
  Role = "role",
  UpdatedAt = "updated_at",
  UserId = "user_id",
  BadgeCode = "badge_code",
}

export type EventAttendeeScalarWhereInput = {
  AND?: Maybe<Array<EventAttendeeScalarWhereInput>>;
  OR?: Maybe<Array<EventAttendeeScalarWhereInput>>;
  NOT?: Maybe<Array<EventAttendeeScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  role?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
};

export type EventAttendeeUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventAttendeeUpdateManyWithWhereWithoutEventInput = {
  where: EventAttendeeScalarWhereInput;
  data: EventAttendeeUpdateManyMutationInput;
};

export type EventAttendeeUpdateManyWithWhereWithoutUserInput = {
  where: EventAttendeeScalarWhereInput;
  data: EventAttendeeUpdateManyMutationInput;
};

export type EventAttendeeUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<EventAttendeeUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<EventAttendeeCreateManyEventInputEnvelope>;
  set?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  delete?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  update?: Maybe<Array<EventAttendeeUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<EventAttendeeUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<EventAttendeeScalarWhereInput>>;
};

export type EventAttendeeUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<EventAttendeeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<EventAttendeeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  delete?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  update?: Maybe<Array<EventAttendeeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<EventAttendeeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<EventAttendeeScalarWhereInput>>;
};

export type EventAttendeeUpdateOneRequiredWithoutInbound_ScansNestedInput = {
  create?: Maybe<EventAttendeeCreateWithoutInbound_ScansInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutInbound_ScansInput>;
  upsert?: Maybe<EventAttendeeUpsertWithoutInbound_ScansInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
  update?: Maybe<EventAttendeeUpdateToOneWithWhereWithoutInbound_ScansInput>;
};

export type EventAttendeeUpdateOneRequiredWithoutOutbound_ScansNestedInput = {
  create?: Maybe<EventAttendeeCreateWithoutOutbound_ScansInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutOutbound_ScansInput>;
  upsert?: Maybe<EventAttendeeUpsertWithoutOutbound_ScansInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
  update?: Maybe<EventAttendeeUpdateToOneWithWhereWithoutOutbound_ScansInput>;
};

export type EventAttendeeUpdateOneRequiredWithoutShiftSignupNestedInput = {
  create?: Maybe<EventAttendeeCreateWithoutShiftSignupInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutShiftSignupInput>;
  upsert?: Maybe<EventAttendeeUpsertWithoutShiftSignupInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
  update?: Maybe<EventAttendeeUpdateToOneWithWhereWithoutShiftSignupInput>;
};

export type EventAttendeeUpdateOneRequiredWithoutSignage_AnswersNestedInput = {
  create?: Maybe<EventAttendeeCreateWithoutSignage_AnswersInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutSignage_AnswersInput>;
  upsert?: Maybe<EventAttendeeUpsertWithoutSignage_AnswersInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
  update?: Maybe<EventAttendeeUpdateToOneWithWhereWithoutSignage_AnswersInput>;
};

export type EventAttendeeUpdateOneRequiredWithoutVolunteerNestedInput = {
  create?: Maybe<EventAttendeeCreateWithoutVolunteerInput>;
  connectOrCreate?: Maybe<EventAttendeeCreateOrConnectWithoutVolunteerInput>;
  upsert?: Maybe<EventAttendeeUpsertWithoutVolunteerInput>;
  connect?: Maybe<EventAttendeeWhereUniqueInput>;
  update?: Maybe<EventAttendeeUpdateToOneWithWhereWithoutVolunteerInput>;
};

export type EventAttendeeUpdateToOneWithWhereWithoutInbound_ScansInput = {
  where?: Maybe<EventAttendeeWhereInput>;
  data: EventAttendeeUpdateWithoutInbound_ScansInput;
};

export type EventAttendeeUpdateToOneWithWhereWithoutOutbound_ScansInput = {
  where?: Maybe<EventAttendeeWhereInput>;
  data: EventAttendeeUpdateWithoutOutbound_ScansInput;
};

export type EventAttendeeUpdateToOneWithWhereWithoutShiftSignupInput = {
  where?: Maybe<EventAttendeeWhereInput>;
  data: EventAttendeeUpdateWithoutShiftSignupInput;
};

export type EventAttendeeUpdateToOneWithWhereWithoutSignage_AnswersInput = {
  where?: Maybe<EventAttendeeWhereInput>;
  data: EventAttendeeUpdateWithoutSignage_AnswersInput;
};

export type EventAttendeeUpdateToOneWithWhereWithoutVolunteerInput = {
  where?: Maybe<EventAttendeeWhereInput>;
  data: EventAttendeeUpdateWithoutVolunteerInput;
};

export type EventAttendeeUpdateWithWhereUniqueWithoutEventInput = {
  where: EventAttendeeWhereUniqueInput;
  data: EventAttendeeUpdateWithoutEventInput;
};

export type EventAttendeeUpdateWithWhereUniqueWithoutUserInput = {
  where: EventAttendeeWhereUniqueInput;
  data: EventAttendeeUpdateWithoutUserInput;
};

export type EventAttendeeUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutInbound_ScansInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutOutbound_ScansInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutShiftSignupInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutSignage_AnswersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  volunteer?: Maybe<VolunteerUpdateManyWithoutEvent_AttendeeNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpdateWithoutVolunteerInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  role?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  badge_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  inbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanned_AttendeeNestedInput>;
  outbound_scans?: Maybe<BadgeScanUpdateManyWithoutScanning_AttendeeNestedInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutRespondentNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput>;
};

export type EventAttendeeUpsertWithWhereUniqueWithoutEventInput = {
  where: EventAttendeeWhereUniqueInput;
  update: EventAttendeeUpdateWithoutEventInput;
  create: EventAttendeeCreateWithoutEventInput;
};

export type EventAttendeeUpsertWithWhereUniqueWithoutUserInput = {
  where: EventAttendeeWhereUniqueInput;
  update: EventAttendeeUpdateWithoutUserInput;
  create: EventAttendeeCreateWithoutUserInput;
};

export type EventAttendeeUpsertWithoutInbound_ScansInput = {
  update: EventAttendeeUpdateWithoutInbound_ScansInput;
  create: EventAttendeeCreateWithoutInbound_ScansInput;
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeUpsertWithoutOutbound_ScansInput = {
  update: EventAttendeeUpdateWithoutOutbound_ScansInput;
  create: EventAttendeeCreateWithoutOutbound_ScansInput;
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeUpsertWithoutShiftSignupInput = {
  update: EventAttendeeUpdateWithoutShiftSignupInput;
  create: EventAttendeeCreateWithoutShiftSignupInput;
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeUpsertWithoutSignage_AnswersInput = {
  update: EventAttendeeUpdateWithoutSignage_AnswersInput;
  create: EventAttendeeCreateWithoutSignage_AnswersInput;
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeUpsertWithoutVolunteerInput = {
  update: EventAttendeeUpdateWithoutVolunteerInput;
  create: EventAttendeeCreateWithoutVolunteerInput;
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeWhereInput = {
  AND?: Maybe<Array<EventAttendeeWhereInput>>;
  OR?: Maybe<Array<EventAttendeeWhereInput>>;
  NOT?: Maybe<Array<EventAttendeeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  role?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
  inbound_scans?: Maybe<BadgeScanListRelationFilter>;
  outbound_scans?: Maybe<BadgeScanListRelationFilter>;
  signage_answers?: Maybe<SignageAnswerListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  volunteer?: Maybe<VolunteerListRelationFilter>;
  ShiftSignup?: Maybe<ShiftSignupListRelationFilter>;
};

export type EventAttendeeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  event_attendees_role_user_id_event_id_key?: Maybe<EventAttendeeEvent_Attendees_Role_User_Id_Event_Id_KeyCompoundUniqueInput>;
  event_attendees_badge_code_event_id_key?: Maybe<EventAttendeeEvent_Attendees_Badge_Code_Event_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<EventAttendeeWhereInput>>;
  OR?: Maybe<Array<EventAttendeeWhereInput>>;
  NOT?: Maybe<Array<EventAttendeeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  role?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  badge_code?: Maybe<StringNullableFilter>;
  inbound_scans?: Maybe<BadgeScanListRelationFilter>;
  outbound_scans?: Maybe<BadgeScanListRelationFilter>;
  signage_answers?: Maybe<SignageAnswerListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  volunteer?: Maybe<VolunteerListRelationFilter>;
  ShiftSignup?: Maybe<ShiftSignupListRelationFilter>;
};

export type EventCount = {
  __typename?: "EventCount";
  badge_scans: Scalars["Int"];
  event_activities: Scalars["Int"];
  event_attendees: Scalars["Int"];
  event_faqs: Scalars["Int"];
  event_schedule: Scalars["Int"];
  event_updates: Scalars["Int"];
  files: Scalars["Int"];
  mail_templates: Scalars["Int"];
  mailing_list: Scalars["Int"];
  mentor_requests: Scalars["Int"];
  mentors: Scalars["Int"];
  pipelines: Scalars["Int"];
  sponsor_companies: Scalars["Int"];
  sponsor_individuals: Scalars["Int"];
  sponsors: Scalars["Int"];
  submissions: Scalars["Int"];
  team_members: Scalars["Int"];
  teams: Scalars["Int"];
  Bus: Scalars["Int"];
  HardwareRequest: Scalars["Int"];
  Shift: Scalars["Int"];
  signage_questions: Scalars["Int"];
};

export type EventCountBadge_ScansArgs = {
  where?: Maybe<BadgeScanWhereInput>;
};

export type EventCountEvent_ActivitiesArgs = {
  where?: Maybe<EventActivityWhereInput>;
};

export type EventCountEvent_AttendeesArgs = {
  where?: Maybe<EventAttendeeWhereInput>;
};

export type EventCountEvent_FaqsArgs = {
  where?: Maybe<EventFaqWhereInput>;
};

export type EventCountEvent_ScheduleArgs = {
  where?: Maybe<EventScheduleWhereInput>;
};

export type EventCountEvent_UpdatesArgs = {
  where?: Maybe<EventUpdateEntryWhereInput>;
};

export type EventCountFilesArgs = {
  where?: Maybe<FileWhereInput>;
};

export type EventCountMail_TemplatesArgs = {
  where?: Maybe<MailTemplateWhereInput>;
};

export type EventCountMailing_ListArgs = {
  where?: Maybe<MailingListWhereInput>;
};

export type EventCountMentor_RequestsArgs = {
  where?: Maybe<MentorRequestWhereInput>;
};

export type EventCountMentorsArgs = {
  where?: Maybe<MentorWhereInput>;
};

export type EventCountPipelinesArgs = {
  where?: Maybe<PipelineWhereInput>;
};

export type EventCountSponsor_CompaniesArgs = {
  where?: Maybe<SponsorCompanyWhereInput>;
};

export type EventCountSponsor_IndividualsArgs = {
  where?: Maybe<SponsorIndividualWhereInput>;
};

export type EventCountSponsorsArgs = {
  where?: Maybe<SponsorWhereInput>;
};

export type EventCountSubmissionsArgs = {
  where?: Maybe<SubmissionWhereInput>;
};

export type EventCountTeam_MembersArgs = {
  where?: Maybe<TeamMemberWhereInput>;
};

export type EventCountTeamsArgs = {
  where?: Maybe<TeamWhereInput>;
};

export type EventCountBusArgs = {
  where?: Maybe<BusWhereInput>;
};

export type EventCountHardwareRequestArgs = {
  where?: Maybe<HardwareRequestWhereInput>;
};

export type EventCountShiftArgs = {
  where?: Maybe<ShiftWhereInput>;
};

export type EventCountSignage_QuestionsArgs = {
  where?: Maybe<SignageQuestionWhereInput>;
};

export type EventCreateInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateNestedOneWithoutBadge_ScansInput = {
  create?: Maybe<EventCreateWithoutBadge_ScansInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutBadge_ScansInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutBusInput = {
  create?: Maybe<EventCreateWithoutBusInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutBusInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutEvent_ActivitiesInput = {
  create?: Maybe<EventCreateWithoutEvent_ActivitiesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_ActivitiesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutEvent_AttendeesInput = {
  create?: Maybe<EventCreateWithoutEvent_AttendeesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_AttendeesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutEvent_ScheduleInput = {
  create?: Maybe<EventCreateWithoutEvent_ScheduleInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_ScheduleInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutEvent_UpdatesInput = {
  create?: Maybe<EventCreateWithoutEvent_UpdatesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_UpdatesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutFilesInput = {
  create?: Maybe<EventCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutFilesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutHardwareRequestInput = {
  create?: Maybe<EventCreateWithoutHardwareRequestInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutHardwareRequestInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutMentor_RequestsInput = {
  create?: Maybe<EventCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutMentor_RequestsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutMentorsInput = {
  create?: Maybe<EventCreateWithoutMentorsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutMentorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutPipelinesInput = {
  create?: Maybe<EventCreateWithoutPipelinesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutPipelinesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutShiftInput = {
  create?: Maybe<EventCreateWithoutShiftInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutShiftInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutSignage_QuestionsInput = {
  create?: Maybe<EventCreateWithoutSignage_QuestionsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSignage_QuestionsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutSponsor_CompaniesInput = {
  create?: Maybe<EventCreateWithoutSponsor_CompaniesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsor_CompaniesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutSponsor_IndividualsInput = {
  create?: Maybe<EventCreateWithoutSponsor_IndividualsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsor_IndividualsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutSponsorsInput = {
  create?: Maybe<EventCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutSubmissionsInput = {
  create?: Maybe<EventCreateWithoutSubmissionsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSubmissionsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutTeam_MembersInput = {
  create?: Maybe<EventCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutTeam_MembersInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateNestedOneWithoutTeamsInput = {
  create?: Maybe<EventCreateWithoutTeamsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutTeamsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
};

export type EventCreateOrConnectWithoutBadge_ScansInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutBadge_ScansInput;
};

export type EventCreateOrConnectWithoutBusInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutBusInput;
};

export type EventCreateOrConnectWithoutEvent_ActivitiesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_ActivitiesInput;
};

export type EventCreateOrConnectWithoutEvent_AttendeesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_AttendeesInput;
};

export type EventCreateOrConnectWithoutEvent_ScheduleInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_ScheduleInput;
};

export type EventCreateOrConnectWithoutEvent_UpdatesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_UpdatesInput;
};

export type EventCreateOrConnectWithoutFilesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutFilesInput;
};

export type EventCreateOrConnectWithoutHardwareRequestInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutHardwareRequestInput;
};

export type EventCreateOrConnectWithoutMentor_RequestsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutMentor_RequestsInput;
};

export type EventCreateOrConnectWithoutMentorsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutMentorsInput;
};

export type EventCreateOrConnectWithoutPipelinesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutPipelinesInput;
};

export type EventCreateOrConnectWithoutShiftInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutShiftInput;
};

export type EventCreateOrConnectWithoutSignage_QuestionsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSignage_QuestionsInput;
};

export type EventCreateOrConnectWithoutSponsor_CompaniesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSponsor_CompaniesInput;
};

export type EventCreateOrConnectWithoutSponsor_IndividualsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSponsor_IndividualsInput;
};

export type EventCreateOrConnectWithoutSponsorsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSponsorsInput;
};

export type EventCreateOrConnectWithoutSubmissionsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSubmissionsInput;
};

export type EventCreateOrConnectWithoutTeam_MembersInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutTeam_MembersInput;
};

export type EventCreateOrConnectWithoutTeamsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutTeamsInput;
};

export type EventCreateWithoutBadge_ScansInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutBusInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutEvent_ActivitiesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutEvent_AttendeesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutEvent_ScheduleInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutEvent_UpdatesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutFilesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutHardwareRequestInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutMentor_RequestsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutMentorsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutPipelinesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutShiftInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutSignage_QuestionsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutSponsor_CompaniesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutSponsor_IndividualsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutSponsorsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutSubmissionsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutTeam_MembersInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreateWithoutTeamsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  website?: Maybe<Scalars["String"]>;
  min_volunteer_shifts?: Maybe<Scalars["Int"]>;
  max_volunteer_shifts?: Maybe<Scalars["Int"]>;
  badge_scans?: Maybe<BadgeScanCreateNestedManyWithoutEventInput>;
  event_activities?: Maybe<EventActivityCreateNestedManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateNestedManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateNestedManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateEntryCreateNestedManyWithoutEventInput>;
  files?: Maybe<FileCreateNestedManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateNestedManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateNestedManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateNestedManyWithoutEventInput>;
  sponsor_companies?: Maybe<SponsorCompanyCreateNestedManyWithoutEventInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateNestedManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateNestedManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutEventInput>;
  Bus?: Maybe<BusCreateNestedManyWithoutEventInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutEventInput>;
  Shift?: Maybe<ShiftCreateNestedManyWithoutEventInput>;
  signage_questions?: Maybe<SignageQuestionCreateNestedManyWithoutEventInput>;
};

export type EventCreatetagsInput = {
  set: Array<Scalars["String"]>;
};

export type EventCreatethemesInput = {
  set: Array<Scalars["String"]>;
};

export type EventFaqCreateManyEventInput = {
  answer: Scalars["String"];
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  links?: Maybe<EventFaqCreatelinksInput>;
  question: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type EventFaqCreateManyEventInputEnvelope = {
  data: Array<EventFaqCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventFaqCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<EventFaqCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventFaqCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<EventFaqCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<EventFaqWhereUniqueInput>>;
};

export type EventFaqCreateOrConnectWithoutEventInput = {
  where: EventFaqWhereUniqueInput;
  create: EventFaqCreateWithoutEventInput;
};

export type EventFaqCreateWithoutEventInput = {
  answer: Scalars["String"];
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  links?: Maybe<EventFaqCreatelinksInput>;
  question: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type EventFaqCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventFaqListRelationFilter = {
  every?: Maybe<EventFaqWhereInput>;
  some?: Maybe<EventFaqWhereInput>;
  none?: Maybe<EventFaqWhereInput>;
};

export type EventFaqOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EventFaqScalarWhereInput = {
  AND?: Maybe<Array<EventFaqScalarWhereInput>>;
  OR?: Maybe<Array<EventFaqScalarWhereInput>>;
  NOT?: Maybe<Array<EventFaqScalarWhereInput>>;
  answer?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  question?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type EventFaqUpdateManyMutationInput = {
  answer?: Maybe<StringFieldUpdateOperationsInput>;
  category?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventFaqUpdatelinksInput>;
  question?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventFaqUpdateManyWithWhereWithoutEventInput = {
  where: EventFaqScalarWhereInput;
  data: EventFaqUpdateManyMutationInput;
};

export type EventFaqUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<EventFaqCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventFaqCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<EventFaqUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<EventFaqCreateManyEventInputEnvelope>;
  set?: Maybe<Array<EventFaqWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventFaqWhereUniqueInput>>;
  delete?: Maybe<Array<EventFaqWhereUniqueInput>>;
  connect?: Maybe<Array<EventFaqWhereUniqueInput>>;
  update?: Maybe<Array<EventFaqUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<EventFaqUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<EventFaqScalarWhereInput>>;
};

export type EventFaqUpdateWithWhereUniqueWithoutEventInput = {
  where: EventFaqWhereUniqueInput;
  data: EventFaqUpdateWithoutEventInput;
};

export type EventFaqUpdateWithoutEventInput = {
  answer?: Maybe<StringFieldUpdateOperationsInput>;
  category?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventFaqUpdatelinksInput>;
  question?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventFaqUpdatelinksInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventFaqUpsertWithWhereUniqueWithoutEventInput = {
  where: EventFaqWhereUniqueInput;
  update: EventFaqUpdateWithoutEventInput;
  create: EventFaqCreateWithoutEventInput;
};

export type EventFaqWhereInput = {
  AND?: Maybe<Array<EventFaqWhereInput>>;
  OR?: Maybe<Array<EventFaqWhereInput>>;
  NOT?: Maybe<Array<EventFaqWhereInput>>;
  answer?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  question?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventFaqWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<EventFaqWhereInput>>;
  OR?: Maybe<Array<EventFaqWhereInput>>;
  NOT?: Maybe<Array<EventFaqWhereInput>>;
  answer?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  question?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventNullableRelationFilter = {
  is?: Maybe<EventWhereInput>;
  isNot?: Maybe<EventWhereInput>;
};

export enum EventOrderByRelevanceFieldEnum {
  ContactEmail = "contact_email",
  CostCurrency = "cost_currency",
  Description = "description",
  JudgingModel = "judging_model",
  Name = "name",
  Slug = "slug",
  SubmissionUrl = "submission_url",
  SwarmEventId = "swarm_event_id",
  Tags = "tags",
  Themes = "themes",
  Timezone = "timezone",
  Website = "website",
}

export type EventOrderByRelevanceInput = {
  fields: Array<EventOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type EventOrderByWithRelationAndSearchRelevanceInput = {
  contact_email?: Maybe<SortOrderInput>;
  cost?: Maybe<SortOrderInput>;
  cost_currency?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  description?: Maybe<SortOrderInput>;
  end_time?: Maybe<SortOrder>;
  has_food?: Maybe<SortOrderInput>;
  has_reimbursements?: Maybe<SortOrderInput>;
  id?: Maybe<SortOrder>;
  judging_model?: Maybe<SortOrderInput>;
  length_hours?: Maybe<SortOrderInput>;
  max_age_restriction?: Maybe<SortOrderInput>;
  min_age_restriction?: Maybe<SortOrderInput>;
  name?: Maybe<SortOrder>;
  size?: Maybe<SortOrderInput>;
  slug?: Maybe<SortOrder>;
  start_time?: Maybe<SortOrder>;
  submission_url?: Maybe<SortOrderInput>;
  swarm_event_id?: Maybe<SortOrderInput>;
  tags?: Maybe<SortOrder>;
  themes?: Maybe<SortOrder>;
  timezone?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  website?: Maybe<SortOrderInput>;
  min_volunteer_shifts?: Maybe<SortOrderInput>;
  max_volunteer_shifts?: Maybe<SortOrderInput>;
  badge_scans?: Maybe<BadgeScanOrderByRelationAggregateInput>;
  event_activities?: Maybe<EventActivityOrderByRelationAggregateInput>;
  event_attendees?: Maybe<EventAttendeeOrderByRelationAggregateInput>;
  event_faqs?: Maybe<EventFaqOrderByRelationAggregateInput>;
  event_schedule?: Maybe<EventScheduleOrderByRelationAggregateInput>;
  event_updates?: Maybe<EventUpdateEntryOrderByRelationAggregateInput>;
  files?: Maybe<FileOrderByRelationAggregateInput>;
  mail_templates?: Maybe<MailTemplateOrderByRelationAggregateInput>;
  mailing_list?: Maybe<MailingListOrderByRelationAggregateInput>;
  mentor_requests?: Maybe<MentorRequestOrderByRelationAggregateInput>;
  mentors?: Maybe<MentorOrderByRelationAggregateInput>;
  pipelines?: Maybe<PipelineOrderByRelationAggregateInput>;
  sponsor_companies?: Maybe<SponsorCompanyOrderByRelationAggregateInput>;
  sponsor_individuals?: Maybe<SponsorIndividualOrderByRelationAggregateInput>;
  sponsors?: Maybe<SponsorOrderByRelationAggregateInput>;
  submissions?: Maybe<SubmissionOrderByRelationAggregateInput>;
  team_members?: Maybe<TeamMemberOrderByRelationAggregateInput>;
  teams?: Maybe<TeamOrderByRelationAggregateInput>;
  Bus?: Maybe<BusOrderByRelationAggregateInput>;
  HardwareRequest?: Maybe<HardwareRequestOrderByRelationAggregateInput>;
  Shift?: Maybe<ShiftOrderByRelationAggregateInput>;
  signage_questions?: Maybe<SignageQuestionOrderByRelationAggregateInput>;
  _relevance?: Maybe<EventOrderByRelevanceInput>;
};

export type EventPlatformId = {
  __typename?: "EventPlatformId";
  event_platform_id: Scalars["String"];
};

export type EventRelationFilter = {
  is?: Maybe<EventWhereInput>;
  isNot?: Maybe<EventWhereInput>;
};

export enum EventScalarFieldEnum {
  ContactEmail = "contact_email",
  Cost = "cost",
  CostCurrency = "cost_currency",
  CreatedAt = "created_at",
  Description = "description",
  EndTime = "end_time",
  HasFood = "has_food",
  HasReimbursements = "has_reimbursements",
  Id = "id",
  JudgingModel = "judging_model",
  LengthHours = "length_hours",
  MaxAgeRestriction = "max_age_restriction",
  MinAgeRestriction = "min_age_restriction",
  Name = "name",
  Size = "size",
  Slug = "slug",
  StartTime = "start_time",
  SubmissionUrl = "submission_url",
  SwarmEventId = "swarm_event_id",
  Tags = "tags",
  Themes = "themes",
  Timezone = "timezone",
  UpdatedAt = "updated_at",
  Website = "website",
  MinVolunteerShifts = "min_volunteer_shifts",
  MaxVolunteerShifts = "max_volunteer_shifts",
}

export type EventScheduleCreateInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  links?: Maybe<EventScheduleCreatelinksInput>;
  location: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  tags?: Maybe<EventScheduleCreatetagsInput>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  banner_link?: Maybe<Scalars["String"]>;
  airtable_id?: Maybe<Scalars["String"]>;
  event_leads?: Maybe<EventScheduleCreateevent_LeadsInput>;
  event: EventCreateNestedOneWithoutEvent_ScheduleInput;
};

export type EventScheduleCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  id?: Maybe<Scalars["Int"]>;
  links?: Maybe<EventScheduleCreatelinksInput>;
  location: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  tags?: Maybe<EventScheduleCreatetagsInput>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  banner_link?: Maybe<Scalars["String"]>;
  airtable_id?: Maybe<Scalars["String"]>;
  event_leads?: Maybe<EventScheduleCreateevent_LeadsInput>;
};

export type EventScheduleCreateManyEventInputEnvelope = {
  data: Array<EventScheduleCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventScheduleCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<EventScheduleCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventScheduleCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<EventScheduleCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<EventScheduleWhereUniqueInput>>;
};

export type EventScheduleCreateOrConnectWithoutEventInput = {
  where: EventScheduleWhereUniqueInput;
  create: EventScheduleCreateWithoutEventInput;
};

export type EventScheduleCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  links?: Maybe<EventScheduleCreatelinksInput>;
  location: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  tags?: Maybe<EventScheduleCreatetagsInput>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  banner_link?: Maybe<Scalars["String"]>;
  airtable_id?: Maybe<Scalars["String"]>;
  event_leads?: Maybe<EventScheduleCreateevent_LeadsInput>;
};

export type EventScheduleCreateevent_LeadsInput = {
  set: Array<Scalars["String"]>;
};

export type EventScheduleCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventScheduleCreatetagsInput = {
  set: Array<Scalars["String"]>;
};

export type EventScheduleListRelationFilter = {
  every?: Maybe<EventScheduleWhereInput>;
  some?: Maybe<EventScheduleWhereInput>;
  none?: Maybe<EventScheduleWhereInput>;
};

export type EventScheduleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EventScheduleScalarWhereInput = {
  AND?: Maybe<Array<EventScheduleScalarWhereInput>>;
  OR?: Maybe<Array<EventScheduleScalarWhereInput>>;
  NOT?: Maybe<Array<EventScheduleScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  tags?: Maybe<StringNullableListFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  banner_link?: Maybe<StringNullableFilter>;
  airtable_id?: Maybe<StringNullableFilter>;
  event_leads?: Maybe<StringNullableListFilter>;
};

export type EventScheduleUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventScheduleUpdatelinksInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  tags?: Maybe<EventScheduleUpdatetagsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  banner_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  airtable_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_leads?: Maybe<EventScheduleUpdateevent_LeadsInput>;
};

export type EventScheduleUpdateManyWithWhereWithoutEventInput = {
  where: EventScheduleScalarWhereInput;
  data: EventScheduleUpdateManyMutationInput;
};

export type EventScheduleUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<EventScheduleCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<EventScheduleCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<EventScheduleUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<EventScheduleCreateManyEventInputEnvelope>;
  set?: Maybe<Array<EventScheduleWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventScheduleWhereUniqueInput>>;
  delete?: Maybe<Array<EventScheduleWhereUniqueInput>>;
  connect?: Maybe<Array<EventScheduleWhereUniqueInput>>;
  update?: Maybe<Array<EventScheduleUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<EventScheduleUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<EventScheduleScalarWhereInput>>;
};

export type EventScheduleUpdateWithWhereUniqueWithoutEventInput = {
  where: EventScheduleWhereUniqueInput;
  data: EventScheduleUpdateWithoutEventInput;
};

export type EventScheduleUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventScheduleUpdatelinksInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  tags?: Maybe<EventScheduleUpdatetagsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  banner_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  airtable_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event_leads?: Maybe<EventScheduleUpdateevent_LeadsInput>;
};

export type EventScheduleUpdateevent_LeadsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventScheduleUpdatelinksInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventScheduleUpdatetagsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventScheduleUpsertWithWhereUniqueWithoutEventInput = {
  where: EventScheduleWhereUniqueInput;
  update: EventScheduleUpdateWithoutEventInput;
  create: EventScheduleCreateWithoutEventInput;
};

export type EventScheduleWhereInput = {
  AND?: Maybe<Array<EventScheduleWhereInput>>;
  OR?: Maybe<Array<EventScheduleWhereInput>>;
  NOT?: Maybe<Array<EventScheduleWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  tags?: Maybe<StringNullableListFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  banner_link?: Maybe<StringNullableFilter>;
  airtable_id?: Maybe<StringNullableFilter>;
  event_leads?: Maybe<StringNullableListFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventScheduleWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  airtable_id?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<EventScheduleWhereInput>>;
  OR?: Maybe<Array<EventScheduleWhereInput>>;
  NOT?: Maybe<Array<EventScheduleWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  tags?: Maybe<StringNullableListFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  banner_link?: Maybe<StringNullableFilter>;
  event_leads?: Maybe<StringNullableListFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventUpdateEntry = {
  __typename?: "EventUpdateEntry";
  category: Scalars["String"];
  created_at: Scalars["DateTimeISO"];
  description: Scalars["String"];
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  links: Array<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  send_time?: Maybe<Scalars["DateTimeISO"]>;
  title: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
};

export type EventUpdateEntryCreateInput = {
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  links?: Maybe<EventUpdateEntryCreatelinksInput>;
  location?: Maybe<Scalars["String"]>;
  send_time?: Maybe<Scalars["DateTimeISO"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event: EventCreateNestedOneWithoutEvent_UpdatesInput;
};

export type EventUpdateEntryCreateManyEventInput = {
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  links?: Maybe<EventUpdateEntryCreatelinksInput>;
  location?: Maybe<Scalars["String"]>;
  send_time?: Maybe<Scalars["DateTimeISO"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type EventUpdateEntryCreateManyEventInputEnvelope = {
  data: Array<EventUpdateEntryCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type EventUpdateEntryCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<EventUpdateEntryCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<EventUpdateEntryCreateOrConnectWithoutEventInput>
  >;
  createMany?: Maybe<EventUpdateEntryCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<EventUpdateEntryWhereUniqueInput>>;
};

export type EventUpdateEntryCreateOrConnectWithoutEventInput = {
  where: EventUpdateEntryWhereUniqueInput;
  create: EventUpdateEntryCreateWithoutEventInput;
};

export type EventUpdateEntryCreateWithoutEventInput = {
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  links?: Maybe<EventUpdateEntryCreatelinksInput>;
  location?: Maybe<Scalars["String"]>;
  send_time?: Maybe<Scalars["DateTimeISO"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type EventUpdateEntryCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventUpdateEntryListRelationFilter = {
  every?: Maybe<EventUpdateEntryWhereInput>;
  some?: Maybe<EventUpdateEntryWhereInput>;
  none?: Maybe<EventUpdateEntryWhereInput>;
};

export type EventUpdateEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EventUpdateEntryScalarWhereInput = {
  AND?: Maybe<Array<EventUpdateEntryScalarWhereInput>>;
  OR?: Maybe<Array<EventUpdateEntryScalarWhereInput>>;
  NOT?: Maybe<Array<EventUpdateEntryScalarWhereInput>>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringNullableFilter>;
  send_time?: Maybe<DateTimeNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type EventUpdateEntryUpdateManyMutationInput = {
  category?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  links?: Maybe<EventUpdateEntryUpdatelinksInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  send_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateEntryUpdateManyWithWhereWithoutEventInput = {
  where: EventUpdateEntryScalarWhereInput;
  data: EventUpdateEntryUpdateManyMutationInput;
};

export type EventUpdateEntryUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<EventUpdateEntryCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<EventUpdateEntryCreateOrConnectWithoutEventInput>
  >;
  upsert?: Maybe<Array<EventUpdateEntryUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<EventUpdateEntryCreateManyEventInputEnvelope>;
  set?: Maybe<Array<EventUpdateEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventUpdateEntryWhereUniqueInput>>;
  delete?: Maybe<Array<EventUpdateEntryWhereUniqueInput>>;
  connect?: Maybe<Array<EventUpdateEntryWhereUniqueInput>>;
  update?: Maybe<Array<EventUpdateEntryUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<
    Array<EventUpdateEntryUpdateManyWithWhereWithoutEventInput>
  >;
  deleteMany?: Maybe<Array<EventUpdateEntryScalarWhereInput>>;
};

export type EventUpdateEntryUpdateWithWhereUniqueWithoutEventInput = {
  where: EventUpdateEntryWhereUniqueInput;
  data: EventUpdateEntryUpdateWithoutEventInput;
};

export type EventUpdateEntryUpdateWithoutEventInput = {
  category?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  links?: Maybe<EventUpdateEntryUpdatelinksInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  send_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventUpdateEntryUpdatelinksInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventUpdateEntryUpsertWithWhereUniqueWithoutEventInput = {
  where: EventUpdateEntryWhereUniqueInput;
  update: EventUpdateEntryUpdateWithoutEventInput;
  create: EventUpdateEntryCreateWithoutEventInput;
};

export type EventUpdateEntryWhereInput = {
  AND?: Maybe<Array<EventUpdateEntryWhereInput>>;
  OR?: Maybe<Array<EventUpdateEntryWhereInput>>;
  NOT?: Maybe<Array<EventUpdateEntryWhereInput>>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringNullableFilter>;
  send_time?: Maybe<DateTimeNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventUpdateEntryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<EventUpdateEntryWhereInput>>;
  OR?: Maybe<Array<EventUpdateEntryWhereInput>>;
  NOT?: Maybe<Array<EventUpdateEntryWhereInput>>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  event_id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringNullableFilter>;
  send_time?: Maybe<DateTimeNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventUpdateOneRequiredWithoutBadge_ScansNestedInput = {
  create?: Maybe<EventCreateWithoutBadge_ScansInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutBadge_ScansInput>;
  upsert?: Maybe<EventUpsertWithoutBadge_ScansInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutBadge_ScansInput>;
};

export type EventUpdateOneRequiredWithoutBusNestedInput = {
  create?: Maybe<EventCreateWithoutBusInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutBusInput>;
  upsert?: Maybe<EventUpsertWithoutBusInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutBusInput>;
};

export type EventUpdateOneRequiredWithoutEvent_ActivitiesNestedInput = {
  create?: Maybe<EventCreateWithoutEvent_ActivitiesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_ActivitiesInput>;
  upsert?: Maybe<EventUpsertWithoutEvent_ActivitiesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutEvent_ActivitiesInput>;
};

export type EventUpdateOneRequiredWithoutEvent_AttendeesNestedInput = {
  create?: Maybe<EventCreateWithoutEvent_AttendeesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutEvent_AttendeesInput>;
  upsert?: Maybe<EventUpsertWithoutEvent_AttendeesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutEvent_AttendeesInput>;
};

export type EventUpdateOneRequiredWithoutHardwareRequestNestedInput = {
  create?: Maybe<EventCreateWithoutHardwareRequestInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutHardwareRequestInput>;
  upsert?: Maybe<EventUpsertWithoutHardwareRequestInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutHardwareRequestInput>;
};

export type EventUpdateOneRequiredWithoutMentor_RequestsNestedInput = {
  create?: Maybe<EventCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutMentor_RequestsInput>;
  upsert?: Maybe<EventUpsertWithoutMentor_RequestsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutMentor_RequestsInput>;
};

export type EventUpdateOneRequiredWithoutMentorsNestedInput = {
  create?: Maybe<EventCreateWithoutMentorsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutMentorsInput>;
  upsert?: Maybe<EventUpsertWithoutMentorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutMentorsInput>;
};

export type EventUpdateOneRequiredWithoutPipelinesNestedInput = {
  create?: Maybe<EventCreateWithoutPipelinesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutPipelinesInput>;
  upsert?: Maybe<EventUpsertWithoutPipelinesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutPipelinesInput>;
};

export type EventUpdateOneRequiredWithoutShiftNestedInput = {
  create?: Maybe<EventCreateWithoutShiftInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutShiftInput>;
  upsert?: Maybe<EventUpsertWithoutShiftInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutShiftInput>;
};

export type EventUpdateOneRequiredWithoutSponsor_CompaniesNestedInput = {
  create?: Maybe<EventCreateWithoutSponsor_CompaniesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsor_CompaniesInput>;
  upsert?: Maybe<EventUpsertWithoutSponsor_CompaniesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutSponsor_CompaniesInput>;
};

export type EventUpdateOneRequiredWithoutSponsor_IndividualsNestedInput = {
  create?: Maybe<EventCreateWithoutSponsor_IndividualsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsor_IndividualsInput>;
  upsert?: Maybe<EventUpsertWithoutSponsor_IndividualsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutSponsor_IndividualsInput>;
};

export type EventUpdateOneRequiredWithoutSponsorsNestedInput = {
  create?: Maybe<EventCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSponsorsInput>;
  upsert?: Maybe<EventUpsertWithoutSponsorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutSponsorsInput>;
};

export type EventUpdateOneRequiredWithoutSubmissionsNestedInput = {
  create?: Maybe<EventCreateWithoutSubmissionsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSubmissionsInput>;
  upsert?: Maybe<EventUpsertWithoutSubmissionsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutSubmissionsInput>;
};

export type EventUpdateOneRequiredWithoutTeam_MembersNestedInput = {
  create?: Maybe<EventCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutTeam_MembersInput>;
  upsert?: Maybe<EventUpsertWithoutTeam_MembersInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutTeam_MembersInput>;
};

export type EventUpdateOneRequiredWithoutTeamsNestedInput = {
  create?: Maybe<EventCreateWithoutTeamsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutTeamsInput>;
  upsert?: Maybe<EventUpsertWithoutTeamsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutTeamsInput>;
};

export type EventUpdateOneWithoutFilesNestedInput = {
  create?: Maybe<EventCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutFilesInput>;
  upsert?: Maybe<EventUpsertWithoutFilesInput>;
  disconnect?: Maybe<EventWhereInput>;
  delete?: Maybe<EventWhereInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutFilesInput>;
};

export type EventUpdateOneWithoutSignage_QuestionsNestedInput = {
  create?: Maybe<EventCreateWithoutSignage_QuestionsInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutSignage_QuestionsInput>;
  upsert?: Maybe<EventUpsertWithoutSignage_QuestionsInput>;
  disconnect?: Maybe<EventWhereInput>;
  delete?: Maybe<EventWhereInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  update?: Maybe<EventUpdateToOneWithWhereWithoutSignage_QuestionsInput>;
};

export type EventUpdateToOneWithWhereWithoutBadge_ScansInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutBadge_ScansInput;
};

export type EventUpdateToOneWithWhereWithoutBusInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutBusInput;
};

export type EventUpdateToOneWithWhereWithoutEvent_ActivitiesInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutEvent_ActivitiesInput;
};

export type EventUpdateToOneWithWhereWithoutEvent_AttendeesInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutEvent_AttendeesInput;
};

export type EventUpdateToOneWithWhereWithoutFilesInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutFilesInput;
};

export type EventUpdateToOneWithWhereWithoutHardwareRequestInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutHardwareRequestInput;
};

export type EventUpdateToOneWithWhereWithoutMentor_RequestsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutMentor_RequestsInput;
};

export type EventUpdateToOneWithWhereWithoutMentorsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutMentorsInput;
};

export type EventUpdateToOneWithWhereWithoutPipelinesInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutPipelinesInput;
};

export type EventUpdateToOneWithWhereWithoutShiftInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutShiftInput;
};

export type EventUpdateToOneWithWhereWithoutSignage_QuestionsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutSignage_QuestionsInput;
};

export type EventUpdateToOneWithWhereWithoutSponsor_CompaniesInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutSponsor_CompaniesInput;
};

export type EventUpdateToOneWithWhereWithoutSponsor_IndividualsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutSponsor_IndividualsInput;
};

export type EventUpdateToOneWithWhereWithoutSponsorsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutSponsorsInput;
};

export type EventUpdateToOneWithWhereWithoutSubmissionsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutSubmissionsInput;
};

export type EventUpdateToOneWithWhereWithoutTeam_MembersInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutTeam_MembersInput;
};

export type EventUpdateToOneWithWhereWithoutTeamsInput = {
  where?: Maybe<EventWhereInput>;
  data: EventUpdateWithoutTeamsInput;
};

export type EventUpdateWithoutBadge_ScansInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutBusInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutEvent_ActivitiesInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutEvent_AttendeesInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutFilesInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutHardwareRequestInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutMentor_RequestsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutMentorsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutPipelinesInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutShiftInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutSignage_QuestionsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutSponsor_CompaniesInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutSponsor_IndividualsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutSponsorsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutSubmissionsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutTeam_MembersInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdateWithoutTeamsInput = {
  contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  cost?: Maybe<NullableIntFieldUpdateOperationsInput>;
  cost_currency?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  has_food?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  has_reimbursements?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  judging_model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  length_hours?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  min_age_restriction?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  swarm_event_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<EventUpdatetagsInput>;
  themes?: Maybe<EventUpdatethemesInput>;
  timezone?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  website?: Maybe<NullableStringFieldUpdateOperationsInput>;
  min_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_volunteer_shifts?: Maybe<NullableIntFieldUpdateOperationsInput>;
  badge_scans?: Maybe<BadgeScanUpdateManyWithoutEventNestedInput>;
  event_activities?: Maybe<EventActivityUpdateManyWithoutEventNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutEventNestedInput>;
  event_faqs?: Maybe<EventFaqUpdateManyWithoutEventNestedInput>;
  event_schedule?: Maybe<EventScheduleUpdateManyWithoutEventNestedInput>;
  event_updates?: Maybe<EventUpdateEntryUpdateManyWithoutEventNestedInput>;
  files?: Maybe<FileUpdateManyWithoutEventNestedInput>;
  mail_templates?: Maybe<MailTemplateUpdateManyWithoutEventNestedInput>;
  mailing_list?: Maybe<MailingListUpdateManyWithoutEventNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutEventNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutEventNestedInput>;
  pipelines?: Maybe<PipelineUpdateManyWithoutEventNestedInput>;
  sponsor_companies?: Maybe<SponsorCompanyUpdateManyWithoutEventNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutEventNestedInput>;
  sponsors?: Maybe<SponsorUpdateManyWithoutEventNestedInput>;
  submissions?: Maybe<SubmissionUpdateManyWithoutEventNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutEventNestedInput>;
  Bus?: Maybe<BusUpdateManyWithoutEventNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutEventNestedInput>;
  Shift?: Maybe<ShiftUpdateManyWithoutEventNestedInput>;
  signage_questions?: Maybe<SignageQuestionUpdateManyWithoutEventNestedInput>;
};

export type EventUpdatetagsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventUpdatethemesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type EventUpsertWithoutBadge_ScansInput = {
  update: EventUpdateWithoutBadge_ScansInput;
  create: EventCreateWithoutBadge_ScansInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutBusInput = {
  update: EventUpdateWithoutBusInput;
  create: EventCreateWithoutBusInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutEvent_ActivitiesInput = {
  update: EventUpdateWithoutEvent_ActivitiesInput;
  create: EventCreateWithoutEvent_ActivitiesInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutEvent_AttendeesInput = {
  update: EventUpdateWithoutEvent_AttendeesInput;
  create: EventCreateWithoutEvent_AttendeesInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutFilesInput = {
  update: EventUpdateWithoutFilesInput;
  create: EventCreateWithoutFilesInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutHardwareRequestInput = {
  update: EventUpdateWithoutHardwareRequestInput;
  create: EventCreateWithoutHardwareRequestInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutMentor_RequestsInput = {
  update: EventUpdateWithoutMentor_RequestsInput;
  create: EventCreateWithoutMentor_RequestsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutMentorsInput = {
  update: EventUpdateWithoutMentorsInput;
  create: EventCreateWithoutMentorsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutPipelinesInput = {
  update: EventUpdateWithoutPipelinesInput;
  create: EventCreateWithoutPipelinesInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutShiftInput = {
  update: EventUpdateWithoutShiftInput;
  create: EventCreateWithoutShiftInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutSignage_QuestionsInput = {
  update: EventUpdateWithoutSignage_QuestionsInput;
  create: EventCreateWithoutSignage_QuestionsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutSponsor_CompaniesInput = {
  update: EventUpdateWithoutSponsor_CompaniesInput;
  create: EventCreateWithoutSponsor_CompaniesInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutSponsor_IndividualsInput = {
  update: EventUpdateWithoutSponsor_IndividualsInput;
  create: EventCreateWithoutSponsor_IndividualsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutSponsorsInput = {
  update: EventUpdateWithoutSponsorsInput;
  create: EventCreateWithoutSponsorsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutSubmissionsInput = {
  update: EventUpdateWithoutSubmissionsInput;
  create: EventCreateWithoutSubmissionsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutTeam_MembersInput = {
  update: EventUpdateWithoutTeam_MembersInput;
  create: EventCreateWithoutTeam_MembersInput;
  where?: Maybe<EventWhereInput>;
};

export type EventUpsertWithoutTeamsInput = {
  update: EventUpdateWithoutTeamsInput;
  create: EventCreateWithoutTeamsInput;
  where?: Maybe<EventWhereInput>;
};

export type EventWhereInput = {
  AND?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  NOT?: Maybe<Array<EventWhereInput>>;
  contact_email?: Maybe<StringNullableFilter>;
  cost?: Maybe<IntNullableFilter>;
  cost_currency?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  has_food?: Maybe<BoolNullableFilter>;
  has_reimbursements?: Maybe<BoolNullableFilter>;
  id?: Maybe<IntFilter>;
  judging_model?: Maybe<StringNullableFilter>;
  length_hours?: Maybe<IntNullableFilter>;
  max_age_restriction?: Maybe<IntNullableFilter>;
  min_age_restriction?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  slug?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  submission_url?: Maybe<StringNullableFilter>;
  swarm_event_id?: Maybe<StringNullableFilter>;
  tags?: Maybe<StringNullableListFilter>;
  themes?: Maybe<StringNullableListFilter>;
  timezone?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  min_volunteer_shifts?: Maybe<IntNullableFilter>;
  max_volunteer_shifts?: Maybe<IntNullableFilter>;
  badge_scans?: Maybe<BadgeScanListRelationFilter>;
  event_activities?: Maybe<EventActivityListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  event_faqs?: Maybe<EventFaqListRelationFilter>;
  event_schedule?: Maybe<EventScheduleListRelationFilter>;
  event_updates?: Maybe<EventUpdateEntryListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  mail_templates?: Maybe<MailTemplateListRelationFilter>;
  mailing_list?: Maybe<MailingListListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  pipelines?: Maybe<PipelineListRelationFilter>;
  sponsor_companies?: Maybe<SponsorCompanyListRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  sponsors?: Maybe<SponsorListRelationFilter>;
  submissions?: Maybe<SubmissionListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
  Bus?: Maybe<BusListRelationFilter>;
  HardwareRequest?: Maybe<HardwareRequestListRelationFilter>;
  Shift?: Maybe<ShiftListRelationFilter>;
  signage_questions?: Maybe<SignageQuestionListRelationFilter>;
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  NOT?: Maybe<Array<EventWhereInput>>;
  contact_email?: Maybe<StringNullableFilter>;
  cost?: Maybe<IntNullableFilter>;
  cost_currency?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  has_food?: Maybe<BoolNullableFilter>;
  has_reimbursements?: Maybe<BoolNullableFilter>;
  judging_model?: Maybe<StringNullableFilter>;
  length_hours?: Maybe<IntNullableFilter>;
  max_age_restriction?: Maybe<IntNullableFilter>;
  min_age_restriction?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  start_time?: Maybe<DateTimeFilter>;
  submission_url?: Maybe<StringNullableFilter>;
  swarm_event_id?: Maybe<StringNullableFilter>;
  tags?: Maybe<StringNullableListFilter>;
  themes?: Maybe<StringNullableListFilter>;
  timezone?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  min_volunteer_shifts?: Maybe<IntNullableFilter>;
  max_volunteer_shifts?: Maybe<IntNullableFilter>;
  badge_scans?: Maybe<BadgeScanListRelationFilter>;
  event_activities?: Maybe<EventActivityListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  event_faqs?: Maybe<EventFaqListRelationFilter>;
  event_schedule?: Maybe<EventScheduleListRelationFilter>;
  event_updates?: Maybe<EventUpdateEntryListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  mail_templates?: Maybe<MailTemplateListRelationFilter>;
  mailing_list?: Maybe<MailingListListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  pipelines?: Maybe<PipelineListRelationFilter>;
  sponsor_companies?: Maybe<SponsorCompanyListRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  sponsors?: Maybe<SponsorListRelationFilter>;
  submissions?: Maybe<SubmissionListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
  Bus?: Maybe<BusListRelationFilter>;
  HardwareRequest?: Maybe<HardwareRequestListRelationFilter>;
  Shift?: Maybe<ShiftListRelationFilter>;
  signage_questions?: Maybe<SignageQuestionListRelationFilter>;
};

export type ExtendedProfileInfo = {
  __typename?: "ExtendedProfileInfo";
  scanner?: Maybe<Claim>;
  scannee?: Maybe<Claim>;
};

export type Field = {
  __typename?: "Field";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
};

export type FieldAnswer = {
  __typename?: "FieldAnswer";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTimeISO"];
  field_id: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
  value: Scalars["JSON"];
};

export type FieldAnswerCreateManyClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  field_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value?: Maybe<Scalars["String"]>;
};

export type FieldAnswerCreateManyClaimInputEnvelope = {
  data: Array<FieldAnswerCreateManyClaimInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FieldAnswerCreateManyFieldInput = {
  claim_id: Scalars["Int"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value?: Maybe<Scalars["String"]>;
};

export type FieldAnswerCreateManyFieldInputEnvelope = {
  data: Array<FieldAnswerCreateManyFieldInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FieldAnswerCreateNestedManyWithoutClaimInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutClaimInput>>;
  createMany?: Maybe<FieldAnswerCreateManyClaimInputEnvelope>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
};

export type FieldAnswerCreateNestedManyWithoutFieldInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutFieldInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutFieldInput>>;
  createMany?: Maybe<FieldAnswerCreateManyFieldInputEnvelope>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
};

export type FieldAnswerCreateOrConnectWithoutClaimInput = {
  where: FieldAnswerWhereUniqueInput;
  create: FieldAnswerCreateWithoutClaimInput;
};

export type FieldAnswerCreateOrConnectWithoutFieldInput = {
  where: FieldAnswerWhereUniqueInput;
  create: FieldAnswerCreateWithoutFieldInput;
};

export type FieldAnswerCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value?: Maybe<Scalars["String"]>;
  field: FieldCreateNestedOneWithoutField_AnswersInput;
};

export type FieldAnswerCreateWithoutFieldInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value?: Maybe<Scalars["String"]>;
  claim: ClaimCreateNestedOneWithoutField_AnswersInput;
};

export type FieldAnswerField_Answers_Claim_Id_Field_Id_KeyCompoundUniqueInput =
  {
    claim_id: Scalars["Int"];
    field_id: Scalars["Int"];
  };

export type FieldAnswerInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  answer?: Maybe<Scalars["JSON"]>;
};

export type FieldAnswerListRelationFilter = {
  every?: Maybe<FieldAnswerWhereInput>;
  some?: Maybe<FieldAnswerWhereInput>;
  none?: Maybe<FieldAnswerWhereInput>;
};

export type FieldAnswerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type FieldAnswerScalarWhereInput = {
  AND?: Maybe<Array<FieldAnswerScalarWhereInput>>;
  OR?: Maybe<Array<FieldAnswerScalarWhereInput>>;
  NOT?: Maybe<Array<FieldAnswerScalarWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  field_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  value?: Maybe<StringNullableFilter>;
};

export type FieldAnswerUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type FieldAnswerUpdateManyWithWhereWithoutClaimInput = {
  where: FieldAnswerScalarWhereInput;
  data: FieldAnswerUpdateManyMutationInput;
};

export type FieldAnswerUpdateManyWithWhereWithoutFieldInput = {
  where: FieldAnswerScalarWhereInput;
  data: FieldAnswerUpdateManyMutationInput;
};

export type FieldAnswerUpdateManyWithoutClaimNestedInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutClaimInput>>;
  upsert?: Maybe<Array<FieldAnswerUpsertWithWhereUniqueWithoutClaimInput>>;
  createMany?: Maybe<FieldAnswerCreateManyClaimInputEnvelope>;
  set?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  update?: Maybe<Array<FieldAnswerUpdateWithWhereUniqueWithoutClaimInput>>;
  updateMany?: Maybe<Array<FieldAnswerUpdateManyWithWhereWithoutClaimInput>>;
  deleteMany?: Maybe<Array<FieldAnswerScalarWhereInput>>;
};

export type FieldAnswerUpdateManyWithoutFieldNestedInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutFieldInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutFieldInput>>;
  upsert?: Maybe<Array<FieldAnswerUpsertWithWhereUniqueWithoutFieldInput>>;
  createMany?: Maybe<FieldAnswerCreateManyFieldInputEnvelope>;
  set?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  update?: Maybe<Array<FieldAnswerUpdateWithWhereUniqueWithoutFieldInput>>;
  updateMany?: Maybe<Array<FieldAnswerUpdateManyWithWhereWithoutFieldInput>>;
  deleteMany?: Maybe<Array<FieldAnswerScalarWhereInput>>;
};

export type FieldAnswerUpdateWithWhereUniqueWithoutClaimInput = {
  where: FieldAnswerWhereUniqueInput;
  data: FieldAnswerUpdateWithoutClaimInput;
};

export type FieldAnswerUpdateWithWhereUniqueWithoutFieldInput = {
  where: FieldAnswerWhereUniqueInput;
  data: FieldAnswerUpdateWithoutFieldInput;
};

export type FieldAnswerUpdateWithoutClaimInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableStringFieldUpdateOperationsInput>;
  field?: Maybe<FieldUpdateOneRequiredWithoutField_AnswersNestedInput>;
};

export type FieldAnswerUpdateWithoutFieldInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<NullableStringFieldUpdateOperationsInput>;
  claim?: Maybe<ClaimUpdateOneRequiredWithoutField_AnswersNestedInput>;
};

export type FieldAnswerUpsertWithWhereUniqueWithoutClaimInput = {
  where: FieldAnswerWhereUniqueInput;
  update: FieldAnswerUpdateWithoutClaimInput;
  create: FieldAnswerCreateWithoutClaimInput;
};

export type FieldAnswerUpsertWithWhereUniqueWithoutFieldInput = {
  where: FieldAnswerWhereUniqueInput;
  update: FieldAnswerUpdateWithoutFieldInput;
  create: FieldAnswerCreateWithoutFieldInput;
};

export type FieldAnswerWhereInput = {
  AND?: Maybe<Array<FieldAnswerWhereInput>>;
  OR?: Maybe<Array<FieldAnswerWhereInput>>;
  NOT?: Maybe<Array<FieldAnswerWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  field_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  value?: Maybe<StringNullableFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  field?: Maybe<FieldRelationFilter>;
};

export type FieldAnswerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  field_answers_claim_id_field_id_key?: Maybe<FieldAnswerField_Answers_Claim_Id_Field_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<FieldAnswerWhereInput>>;
  OR?: Maybe<Array<FieldAnswerWhereInput>>;
  NOT?: Maybe<Array<FieldAnswerWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  field_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  value?: Maybe<StringNullableFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  field?: Maybe<FieldRelationFilter>;
};

export type FieldCount = {
  __typename?: "FieldCount";
  field_answers: Scalars["Int"];
};

export type FieldCountField_AnswersArgs = {
  where?: Maybe<FieldAnswerWhereInput>;
};

/** Create field data */
export type FieldCreateInput = {
  pipeline_slug: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
  field_type: Scalars["String"];
  is_required: Scalars["Boolean"];
  permissions?: Maybe<Scalars["JSON"]>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
};

export type FieldCreateManyPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  fcfs_available?: Maybe<FieldCreatefcfs_AvailableInput>;
  field_type: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  valid_values?: Maybe<FieldCreatevalid_ValuesInput>;
};

export type FieldCreateManyPipelineInputEnvelope = {
  data: Array<FieldCreateManyPipelineInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FieldCreateNestedManyWithoutPipelineInput = {
  create?: Maybe<Array<FieldCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<FieldCreateOrConnectWithoutPipelineInput>>;
  createMany?: Maybe<FieldCreateManyPipelineInputEnvelope>;
  connect?: Maybe<Array<FieldWhereUniqueInput>>;
};

export type FieldCreateNestedOneWithoutField_AnswersInput = {
  create?: Maybe<FieldCreateWithoutField_AnswersInput>;
  connectOrCreate?: Maybe<FieldCreateOrConnectWithoutField_AnswersInput>;
  connect?: Maybe<FieldWhereUniqueInput>;
};

export type FieldCreateOrConnectWithoutField_AnswersInput = {
  where: FieldWhereUniqueInput;
  create: FieldCreateWithoutField_AnswersInput;
};

export type FieldCreateOrConnectWithoutPipelineInput = {
  where: FieldWhereUniqueInput;
  create: FieldCreateWithoutPipelineInput;
};

export type FieldCreateWithoutField_AnswersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  fcfs_available?: Maybe<FieldCreatefcfs_AvailableInput>;
  field_type: Scalars["String"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  valid_values?: Maybe<FieldCreatevalid_ValuesInput>;
  pipeline: PipelineCreateNestedOneWithoutFieldsInput;
};

export type FieldCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  fcfs_available?: Maybe<FieldCreatefcfs_AvailableInput>;
  field_type: Scalars["String"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  valid_values?: Maybe<FieldCreatevalid_ValuesInput>;
  field_answers?: Maybe<FieldAnswerCreateNestedManyWithoutFieldInput>;
};

export type FieldCreatefcfs_AvailableInput = {
  set: Array<Scalars["String"]>;
};

export type FieldCreatevalid_ValuesInput = {
  set: Array<Scalars["String"]>;
};

export type FieldFields_Slug_Pipeline_Id_KeyCompoundUniqueInput = {
  slug: Scalars["String"];
  pipeline_id: Scalars["Int"];
};

export type FieldListRelationFilter = {
  every?: Maybe<FieldWhereInput>;
  some?: Maybe<FieldWhereInput>;
  none?: Maybe<FieldWhereInput>;
};

export type FieldOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type FieldRelationFilter = {
  is?: Maybe<FieldWhereInput>;
  isNot?: Maybe<FieldWhereInput>;
};

export type FieldScalarWhereInput = {
  AND?: Maybe<Array<FieldScalarWhereInput>>;
  OR?: Maybe<Array<FieldScalarWhereInput>>;
  NOT?: Maybe<Array<FieldScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  fcfs_available?: Maybe<StringNullableListFilter>;
  field_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_required?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  slug?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  valid_values?: Maybe<StringNullableListFilter>;
};

/** Update field data */
export type FieldUpdateInput = {
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  field_type?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
  is_required?: Maybe<Scalars["Boolean"]>;
};

export type FieldUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fcfs_available?: Maybe<FieldUpdatefcfs_AvailableInput>;
  field_type?: Maybe<StringFieldUpdateOperationsInput>;
  is_required?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valid_values?: Maybe<FieldUpdatevalid_ValuesInput>;
};

export type FieldUpdateManyWithWhereWithoutPipelineInput = {
  where: FieldScalarWhereInput;
  data: FieldUpdateManyMutationInput;
};

export type FieldUpdateManyWithoutPipelineNestedInput = {
  create?: Maybe<Array<FieldCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<FieldCreateOrConnectWithoutPipelineInput>>;
  upsert?: Maybe<Array<FieldUpsertWithWhereUniqueWithoutPipelineInput>>;
  createMany?: Maybe<FieldCreateManyPipelineInputEnvelope>;
  set?: Maybe<Array<FieldWhereUniqueInput>>;
  disconnect?: Maybe<Array<FieldWhereUniqueInput>>;
  delete?: Maybe<Array<FieldWhereUniqueInput>>;
  connect?: Maybe<Array<FieldWhereUniqueInput>>;
  update?: Maybe<Array<FieldUpdateWithWhereUniqueWithoutPipelineInput>>;
  updateMany?: Maybe<Array<FieldUpdateManyWithWhereWithoutPipelineInput>>;
  deleteMany?: Maybe<Array<FieldScalarWhereInput>>;
};

export type FieldUpdateOneRequiredWithoutField_AnswersNestedInput = {
  create?: Maybe<FieldCreateWithoutField_AnswersInput>;
  connectOrCreate?: Maybe<FieldCreateOrConnectWithoutField_AnswersInput>;
  upsert?: Maybe<FieldUpsertWithoutField_AnswersInput>;
  connect?: Maybe<FieldWhereUniqueInput>;
  update?: Maybe<FieldUpdateToOneWithWhereWithoutField_AnswersInput>;
};

export type FieldUpdateToOneWithWhereWithoutField_AnswersInput = {
  where?: Maybe<FieldWhereInput>;
  data: FieldUpdateWithoutField_AnswersInput;
};

export type FieldUpdateWithWhereUniqueWithoutPipelineInput = {
  where: FieldWhereUniqueInput;
  data: FieldUpdateWithoutPipelineInput;
};

export type FieldUpdateWithoutField_AnswersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fcfs_available?: Maybe<FieldUpdatefcfs_AvailableInput>;
  field_type?: Maybe<StringFieldUpdateOperationsInput>;
  is_required?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valid_values?: Maybe<FieldUpdatevalid_ValuesInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutFieldsNestedInput>;
};

export type FieldUpdateWithoutPipelineInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fcfs_available?: Maybe<FieldUpdatefcfs_AvailableInput>;
  field_type?: Maybe<StringFieldUpdateOperationsInput>;
  is_required?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  valid_values?: Maybe<FieldUpdatevalid_ValuesInput>;
  field_answers?: Maybe<FieldAnswerUpdateManyWithoutFieldNestedInput>;
};

export type FieldUpdatefcfs_AvailableInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type FieldUpdatevalid_ValuesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type FieldUpsertWithWhereUniqueWithoutPipelineInput = {
  where: FieldWhereUniqueInput;
  update: FieldUpdateWithoutPipelineInput;
  create: FieldCreateWithoutPipelineInput;
};

export type FieldUpsertWithoutField_AnswersInput = {
  update: FieldUpdateWithoutField_AnswersInput;
  create: FieldCreateWithoutField_AnswersInput;
  where?: Maybe<FieldWhereInput>;
};

export type FieldWhereInput = {
  AND?: Maybe<Array<FieldWhereInput>>;
  OR?: Maybe<Array<FieldWhereInput>>;
  NOT?: Maybe<Array<FieldWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  fcfs_available?: Maybe<StringNullableListFilter>;
  field_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_required?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  slug?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  valid_values?: Maybe<StringNullableListFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
};

export type FieldWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  fields_slug_pipeline_id_key?: Maybe<FieldFields_Slug_Pipeline_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<FieldWhereInput>>;
  OR?: Maybe<Array<FieldWhereInput>>;
  NOT?: Maybe<Array<FieldWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  fcfs_available?: Maybe<StringNullableListFilter>;
  field_type?: Maybe<StringFilter>;
  is_required?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  slug?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  valid_values?: Maybe<StringNullableListFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
};

export type FieldWithAnswer = {
  __typename?: "FieldWithAnswer";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  answer?: Maybe<FieldAnswer>;
};

export type File = {
  __typename?: "File";
  created_at: Scalars["DateTimeISO"];
  event_slug?: Maybe<Scalars["String"]>;
  file_type: Scalars["String"];
  id: Scalars["Int"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["DateTimeISO"];
  user_id?: Maybe<Scalars["Int"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  download: FileDownload;
};

export type FileCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  file_type: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id?: Maybe<Scalars["Int"]>;
  permissions?: Maybe<Scalars["JSON"]>;
};

export type FileCreateManyEventInputEnvelope = {
  data: Array<FileCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FileCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug?: Maybe<Scalars["String"]>;
  file_type: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  permissions?: Maybe<Scalars["JSON"]>;
};

export type FileCreateManyUserInputEnvelope = {
  data: Array<FileCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FileCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<FileCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<FileCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
};

export type FileCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<FileCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<FileCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
};

export type FileCreateNestedOneWithoutSponsorsInput = {
  create?: Maybe<FileCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutSponsorsInput>;
  connect?: Maybe<FileWhereUniqueInput>;
};

export type FileCreateOrConnectWithoutEventInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutEventInput;
};

export type FileCreateOrConnectWithoutSponsorsInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutSponsorsInput;
};

export type FileCreateOrConnectWithoutUserInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutUserInput;
};

export type FileCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  user?: Maybe<UserCreateNestedOneWithoutFilesInput>;
  sponsors?: Maybe<SponsorCreateNestedOneWithoutFilesInput>;
};

export type FileCreateWithoutSponsorsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventCreateNestedOneWithoutFilesInput>;
  user?: Maybe<UserCreateNestedOneWithoutFilesInput>;
};

export type FileCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventCreateNestedOneWithoutFilesInput>;
  sponsors?: Maybe<SponsorCreateNestedOneWithoutFilesInput>;
};

export type FileDownload = {
  __typename?: "FileDownload";
  uri: Scalars["String"];
  name: Scalars["String"];
  expiry: Scalars["String"];
  is_valid: Scalars["Boolean"];
};

export type FileFieldAnswerType = {
  __typename?: "FileFieldAnswerType";
  id: Scalars["Int"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  uri: Scalars["String"];
  expiry: Scalars["String"];
  is_valid?: Maybe<Scalars["Boolean"]>;
};

export type FileFieldAnswerTypeWrapper = {
  __typename?: "FileFieldAnswerTypeWrapper";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  value?: Maybe<Array<FileFieldAnswerType>>;
};

export type FileInput = {
  fieldId: Scalars["Float"];
  type: Scalars["String"];
  file: Scalars["Upload"];
};

export type FileListRelationFilter = {
  every?: Maybe<FileWhereInput>;
  some?: Maybe<FileWhereInput>;
  none?: Maybe<FileWhereInput>;
};

export type FileNullableRelationFilter = {
  is?: Maybe<FileWhereInput>;
  isNot?: Maybe<FileWhereInput>;
};

export type FileOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum FileOrderByRelevanceFieldEnum {
  EventSlug = "event_slug",
  FileType = "file_type",
  MimeType = "mime_type",
  Name = "name",
  S3Path = "s3_path",
}

export type FileOrderByRelevanceInput = {
  fields: Array<FileOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type FileOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  event_slug?: Maybe<SortOrderInput>;
  file_type?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mime_type?: Maybe<SortOrderInput>;
  name?: Maybe<SortOrderInput>;
  s3_path?: Maybe<SortOrder>;
  size?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  user_id?: Maybe<SortOrderInput>;
  permissions?: Maybe<SortOrderInput>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sponsors?: Maybe<SponsorOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<FileOrderByRelevanceInput>;
};

export enum FileScalarFieldEnum {
  CreatedAt = "created_at",
  EventSlug = "event_slug",
  FileType = "file_type",
  Id = "id",
  MimeType = "mime_type",
  Name = "name",
  S3Path = "s3_path",
  Size = "size",
  UpdatedAt = "updated_at",
  UserId = "user_id",
  Permissions = "permissions",
}

export type FileScalarWhereInput = {
  AND?: Maybe<Array<FileScalarWhereInput>>;
  OR?: Maybe<Array<FileScalarWhereInput>>;
  NOT?: Maybe<Array<FileScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringNullableFilter>;
  file_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  mime_type?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  s3_path?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  permissions?: Maybe<JsonNullableFilter>;
};

export type FileUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  file_type?: Maybe<StringFieldUpdateOperationsInput>;
  mime_type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  s3_path?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
};

export type FileUpdateManyWithWhereWithoutEventInput = {
  where: FileScalarWhereInput;
  data: FileUpdateManyMutationInput;
};

export type FileUpdateManyWithWhereWithoutUserInput = {
  where: FileScalarWhereInput;
  data: FileUpdateManyMutationInput;
};

export type FileUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<FileCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<FileCreateManyEventInputEnvelope>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
};

export type FileUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<FileCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<FileCreateManyUserInputEnvelope>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
};

export type FileUpdateOneWithoutSponsorsNestedInput = {
  create?: Maybe<FileCreateWithoutSponsorsInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutSponsorsInput>;
  upsert?: Maybe<FileUpsertWithoutSponsorsInput>;
  disconnect?: Maybe<FileWhereInput>;
  delete?: Maybe<FileWhereInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  update?: Maybe<FileUpdateToOneWithWhereWithoutSponsorsInput>;
};

export type FileUpdateToOneWithWhereWithoutSponsorsInput = {
  where?: Maybe<FileWhereInput>;
  data: FileUpdateWithoutSponsorsInput;
};

export type FileUpdateWithWhereUniqueWithoutEventInput = {
  where: FileWhereUniqueInput;
  data: FileUpdateWithoutEventInput;
};

export type FileUpdateWithWhereUniqueWithoutUserInput = {
  where: FileWhereUniqueInput;
  data: FileUpdateWithoutUserInput;
};

export type FileUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  file_type?: Maybe<StringFieldUpdateOperationsInput>;
  mime_type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  s3_path?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  user?: Maybe<UserUpdateOneWithoutFilesNestedInput>;
  sponsors?: Maybe<SponsorUpdateOneWithoutFilesNestedInput>;
};

export type FileUpdateWithoutSponsorsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  file_type?: Maybe<StringFieldUpdateOperationsInput>;
  mime_type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  s3_path?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventUpdateOneWithoutFilesNestedInput>;
  user?: Maybe<UserUpdateOneWithoutFilesNestedInput>;
};

export type FileUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  file_type?: Maybe<StringFieldUpdateOperationsInput>;
  mime_type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  s3_path?: Maybe<StringFieldUpdateOperationsInput>;
  size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventUpdateOneWithoutFilesNestedInput>;
  sponsors?: Maybe<SponsorUpdateOneWithoutFilesNestedInput>;
};

export type FileUpsertWithWhereUniqueWithoutEventInput = {
  where: FileWhereUniqueInput;
  update: FileUpdateWithoutEventInput;
  create: FileCreateWithoutEventInput;
};

export type FileUpsertWithWhereUniqueWithoutUserInput = {
  where: FileWhereUniqueInput;
  update: FileUpdateWithoutUserInput;
  create: FileCreateWithoutUserInput;
};

export type FileUpsertWithoutSponsorsInput = {
  update: FileUpdateWithoutSponsorsInput;
  create: FileCreateWithoutSponsorsInput;
  where?: Maybe<FileWhereInput>;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringNullableFilter>;
  file_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  mime_type?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  s3_path?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  event?: Maybe<EventNullableRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  sponsors?: Maybe<SponsorNullableRelationFilter>;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringNullableFilter>;
  file_type?: Maybe<StringFilter>;
  mime_type?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  s3_path?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  event?: Maybe<EventNullableRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  sponsors?: Maybe<SponsorNullableRelationFilter>;
};

export type FirebaseTokenCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value: Scalars["String"];
};

export type FirebaseTokenCreateManyUserInputEnvelope = {
  data: Array<FirebaseTokenCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type FirebaseTokenCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<FirebaseTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<FirebaseTokenCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<FirebaseTokenCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
};

export type FirebaseTokenCreateOrConnectWithoutUserInput = {
  where: FirebaseTokenWhereUniqueInput;
  create: FirebaseTokenCreateWithoutUserInput;
};

export type FirebaseTokenCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  value: Scalars["String"];
};

export type FirebaseTokenListRelationFilter = {
  every?: Maybe<FirebaseTokenWhereInput>;
  some?: Maybe<FirebaseTokenWhereInput>;
  none?: Maybe<FirebaseTokenWhereInput>;
};

export type FirebaseTokenOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type FirebaseTokenScalarWhereInput = {
  AND?: Maybe<Array<FirebaseTokenScalarWhereInput>>;
  OR?: Maybe<Array<FirebaseTokenScalarWhereInput>>;
  NOT?: Maybe<Array<FirebaseTokenScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  value?: Maybe<StringFilter>;
};

export type FirebaseTokenUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FirebaseTokenUpdateManyWithWhereWithoutUserInput = {
  where: FirebaseTokenScalarWhereInput;
  data: FirebaseTokenUpdateManyMutationInput;
};

export type FirebaseTokenUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<FirebaseTokenCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<FirebaseTokenCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<FirebaseTokenUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<FirebaseTokenCreateManyUserInputEnvelope>;
  set?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
  disconnect?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
  delete?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
  connect?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
  update?: Maybe<Array<FirebaseTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<FirebaseTokenUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<FirebaseTokenScalarWhereInput>>;
};

export type FirebaseTokenUpdateWithWhereUniqueWithoutUserInput = {
  where: FirebaseTokenWhereUniqueInput;
  data: FirebaseTokenUpdateWithoutUserInput;
};

export type FirebaseTokenUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type FirebaseTokenUpsertWithWhereUniqueWithoutUserInput = {
  where: FirebaseTokenWhereUniqueInput;
  update: FirebaseTokenUpdateWithoutUserInput;
  create: FirebaseTokenCreateWithoutUserInput;
};

export type FirebaseTokenWhereInput = {
  AND?: Maybe<Array<FirebaseTokenWhereInput>>;
  OR?: Maybe<Array<FirebaseTokenWhereInput>>;
  NOT?: Maybe<Array<FirebaseTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  value?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type FirebaseTokenWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<FirebaseTokenWhereInput>>;
  OR?: Maybe<Array<FirebaseTokenWhereInput>>;
  NOT?: Maybe<Array<FirebaseTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  value?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type ForgottenPasswordCreateManyUserInput = {
  callback_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  is_disabled?: Maybe<Scalars["Boolean"]>;
  token: Scalars["String"];
  token_only: Scalars["Boolean"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type ForgottenPasswordCreateManyUserInputEnvelope = {
  data: Array<ForgottenPasswordCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ForgottenPasswordCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ForgottenPasswordCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<ForgottenPasswordCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<ForgottenPasswordCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
};

export type ForgottenPasswordCreateOrConnectWithoutUserInput = {
  where: ForgottenPasswordWhereUniqueInput;
  create: ForgottenPasswordCreateWithoutUserInput;
};

export type ForgottenPasswordCreateWithoutUserInput = {
  callback_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  is_disabled?: Maybe<Scalars["Boolean"]>;
  token: Scalars["String"];
  token_only: Scalars["Boolean"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type ForgottenPasswordListRelationFilter = {
  every?: Maybe<ForgottenPasswordWhereInput>;
  some?: Maybe<ForgottenPasswordWhereInput>;
  none?: Maybe<ForgottenPasswordWhereInput>;
};

export type ForgottenPasswordOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ForgottenPasswordScalarWhereInput = {
  AND?: Maybe<Array<ForgottenPasswordScalarWhereInput>>;
  OR?: Maybe<Array<ForgottenPasswordScalarWhereInput>>;
  NOT?: Maybe<Array<ForgottenPasswordScalarWhereInput>>;
  callback_url?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_disabled?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
  token_only?: Maybe<BoolFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type ForgottenPasswordUpdateManyMutationInput = {
  callback_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  is_disabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  token_only?: Maybe<BoolFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForgottenPasswordUpdateManyWithWhereWithoutUserInput = {
  where: ForgottenPasswordScalarWhereInput;
  data: ForgottenPasswordUpdateManyMutationInput;
};

export type ForgottenPasswordUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ForgottenPasswordCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<ForgottenPasswordCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<Array<ForgottenPasswordUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ForgottenPasswordCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
  disconnect?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
  delete?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
  connect?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
  update?: Maybe<Array<ForgottenPasswordUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<
    Array<ForgottenPasswordUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<ForgottenPasswordScalarWhereInput>>;
};

export type ForgottenPasswordUpdateWithWhereUniqueWithoutUserInput = {
  where: ForgottenPasswordWhereUniqueInput;
  data: ForgottenPasswordUpdateWithoutUserInput;
};

export type ForgottenPasswordUpdateWithoutUserInput = {
  callback_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  is_disabled?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  token?: Maybe<StringFieldUpdateOperationsInput>;
  token_only?: Maybe<BoolFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForgottenPasswordUpsertWithWhereUniqueWithoutUserInput = {
  where: ForgottenPasswordWhereUniqueInput;
  update: ForgottenPasswordUpdateWithoutUserInput;
  create: ForgottenPasswordCreateWithoutUserInput;
};

export type ForgottenPasswordWhereInput = {
  AND?: Maybe<Array<ForgottenPasswordWhereInput>>;
  OR?: Maybe<Array<ForgottenPasswordWhereInput>>;
  NOT?: Maybe<Array<ForgottenPasswordWhereInput>>;
  callback_url?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_disabled?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
  token_only?: Maybe<BoolFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ForgottenPasswordWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  token?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<ForgottenPasswordWhereInput>>;
  OR?: Maybe<Array<ForgottenPasswordWhereInput>>;
  NOT?: Maybe<Array<ForgottenPasswordWhereInput>>;
  callback_url?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  is_disabled?: Maybe<BoolNullableFilter>;
  token_only?: Maybe<BoolFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export enum Gender {
  Female = "female",
  Male = "male",
  Other = "other",
  PreferNotToSpecify = "prefer_not_to_specify",
}

export type HackerNetworkingPayload = {
  __typename?: "HackerNetworkingPayload";
  name: Scalars["String"];
  role: Scalars["String"];
  bio: Scalars["String"];
  pronouns: Array<Scalars["String"]>;
  school: Scalars["String"];
  facebookLink: Scalars["String"];
  instagramLink: Scalars["String"];
  twitterLink: Scalars["String"];
  linkedinLink: Scalars["String"];
  tiktokLink: Scalars["String"];
  discordTag: Scalars["String"];
  githubLink: Scalars["String"];
  extendedProfile?: Maybe<Claim>;
};

export type HardwareCategory = {
  __typename?: "HardwareCategory";
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
  _count?: Maybe<HardwareCategoryCount>;
  skus: Array<HardwareSku>;
};

export type HardwareCategoryCount = {
  __typename?: "HardwareCategoryCount";
  skus: Scalars["Int"];
};

export type HardwareCategoryCountSkusArgs = {
  where?: Maybe<HardwareSkuWhereInput>;
};

export type HardwareCategoryCreateNestedOneWithoutSkusInput = {
  create?: Maybe<HardwareCategoryCreateWithoutSkusInput>;
  connectOrCreate?: Maybe<HardwareCategoryCreateOrConnectWithoutSkusInput>;
  connect?: Maybe<HardwareCategoryWhereUniqueInput>;
};

export type HardwareCategoryCreateOrConnectWithoutSkusInput = {
  where: HardwareCategoryWhereUniqueInput;
  create: HardwareCategoryCreateWithoutSkusInput;
};

export type HardwareCategoryCreateWithoutSkusInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
};

export enum HardwareCategoryOrderByRelevanceFieldEnum {
  Name = "name",
  ShortDescription = "short_description",
  LongDescription = "long_description",
  Image = "image",
}

export type HardwareCategoryOrderByRelevanceInput = {
  fields: Array<HardwareCategoryOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type HardwareCategoryOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  short_description?: Maybe<SortOrder>;
  long_description?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  skus?: Maybe<HardwareSkuOrderByRelationAggregateInput>;
  _relevance?: Maybe<HardwareCategoryOrderByRelevanceInput>;
};

export type HardwareCategoryRelationFilter = {
  is?: Maybe<HardwareCategoryWhereInput>;
  isNot?: Maybe<HardwareCategoryWhereInput>;
};

export enum HardwareCategoryScalarFieldEnum {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  Id = "id",
  Name = "name",
  ShortDescription = "short_description",
  LongDescription = "long_description",
  Image = "image",
}

export type HardwareCategoryUpdateInput = {
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  short_description?: Maybe<Scalars["String"]>;
  long_description?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
};

export type HardwareCategoryUpdateOneRequiredWithoutSkusNestedInput = {
  create?: Maybe<HardwareCategoryCreateWithoutSkusInput>;
  connectOrCreate?: Maybe<HardwareCategoryCreateOrConnectWithoutSkusInput>;
  upsert?: Maybe<HardwareCategoryUpsertWithoutSkusInput>;
  connect?: Maybe<HardwareCategoryWhereUniqueInput>;
  update?: Maybe<HardwareCategoryUpdateToOneWithWhereWithoutSkusInput>;
};

export type HardwareCategoryUpdateToOneWithWhereWithoutSkusInput = {
  where?: Maybe<HardwareCategoryWhereInput>;
  data: HardwareCategoryUpdateWithoutSkusInput;
};

export type HardwareCategoryUpdateWithoutSkusInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  short_description?: Maybe<StringFieldUpdateOperationsInput>;
  long_description?: Maybe<StringFieldUpdateOperationsInput>;
  image?: Maybe<StringFieldUpdateOperationsInput>;
};

export type HardwareCategoryUpsertWithoutSkusInput = {
  update: HardwareCategoryUpdateWithoutSkusInput;
  create: HardwareCategoryCreateWithoutSkusInput;
  where?: Maybe<HardwareCategoryWhereInput>;
};

export type HardwareCategoryWhereInput = {
  AND?: Maybe<Array<HardwareCategoryWhereInput>>;
  OR?: Maybe<Array<HardwareCategoryWhereInput>>;
  NOT?: Maybe<Array<HardwareCategoryWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  short_description?: Maybe<StringFilter>;
  long_description?: Maybe<StringFilter>;
  image?: Maybe<StringFilter>;
  skus?: Maybe<HardwareSkuListRelationFilter>;
};

export type HardwareCategoryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<HardwareCategoryWhereInput>>;
  OR?: Maybe<Array<HardwareCategoryWhereInput>>;
  NOT?: Maybe<Array<HardwareCategoryWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  short_description?: Maybe<StringFilter>;
  long_description?: Maybe<StringFilter>;
  image?: Maybe<StringFilter>;
  skus?: Maybe<HardwareSkuListRelationFilter>;
};

export type HardwareItem = {
  __typename?: "HardwareItem";
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  notes: Scalars["String"];
  sku_code: Scalars["String"];
  _count?: Maybe<HardwareItemCount>;
};

export type HardwareItemCount = {
  __typename?: "HardwareItemCount";
  hardware_request_item: Scalars["Int"];
};

export type HardwareItemCountHardware_Request_ItemArgs = {
  where?: Maybe<HardwareRequestItemWhereInput>;
};

export type HardwareItemCreateManySkuInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type HardwareItemCreateManySkuInputEnvelope = {
  data: Array<HardwareItemCreateManySkuInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareItemCreateNestedManyWithoutSkuInput = {
  create?: Maybe<Array<HardwareItemCreateWithoutSkuInput>>;
  connectOrCreate?: Maybe<Array<HardwareItemCreateOrConnectWithoutSkuInput>>;
  createMany?: Maybe<HardwareItemCreateManySkuInputEnvelope>;
  connect?: Maybe<Array<HardwareItemWhereUniqueInput>>;
};

export type HardwareItemCreateNestedOneWithoutHardware_Request_ItemInput = {
  create?: Maybe<HardwareItemCreateWithoutHardware_Request_ItemInput>;
  connectOrCreate?: Maybe<HardwareItemCreateOrConnectWithoutHardware_Request_ItemInput>;
  connect?: Maybe<HardwareItemWhereUniqueInput>;
};

export type HardwareItemCreateOrConnectWithoutHardware_Request_ItemInput = {
  where: HardwareItemWhereUniqueInput;
  create: HardwareItemCreateWithoutHardware_Request_ItemInput;
};

export type HardwareItemCreateOrConnectWithoutSkuInput = {
  where: HardwareItemWhereUniqueInput;
  create: HardwareItemCreateWithoutSkuInput;
};

export type HardwareItemCreateWithoutHardware_Request_ItemInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  sku: HardwareSkuCreateNestedOneWithoutItemsInput;
};

export type HardwareItemCreateWithoutSkuInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  hardware_request_item?: Maybe<HardwareRequestItemCreateNestedManyWithoutHardwareItemInput>;
};

export type HardwareItemListRelationFilter = {
  every?: Maybe<HardwareItemWhereInput>;
  some?: Maybe<HardwareItemWhereInput>;
  none?: Maybe<HardwareItemWhereInput>;
};

export type HardwareItemRelationFilter = {
  is?: Maybe<HardwareItemWhereInput>;
  isNot?: Maybe<HardwareItemWhereInput>;
};

export type HardwareItemScalarWhereInput = {
  AND?: Maybe<Array<HardwareItemScalarWhereInput>>;
  OR?: Maybe<Array<HardwareItemScalarWhereInput>>;
  NOT?: Maybe<Array<HardwareItemScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  sku_code?: Maybe<StringFilter>;
};

export type HardwareItemUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
};

export type HardwareItemUpdateManyWithWhereWithoutSkuInput = {
  where: HardwareItemScalarWhereInput;
  data: HardwareItemUpdateManyMutationInput;
};

export type HardwareItemUpdateManyWithoutSkuNestedInput = {
  create?: Maybe<Array<HardwareItemCreateWithoutSkuInput>>;
  connectOrCreate?: Maybe<Array<HardwareItemCreateOrConnectWithoutSkuInput>>;
  upsert?: Maybe<Array<HardwareItemUpsertWithWhereUniqueWithoutSkuInput>>;
  createMany?: Maybe<HardwareItemCreateManySkuInputEnvelope>;
  set?: Maybe<Array<HardwareItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareItemWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareItemWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareItemWhereUniqueInput>>;
  update?: Maybe<Array<HardwareItemUpdateWithWhereUniqueWithoutSkuInput>>;
  updateMany?: Maybe<Array<HardwareItemUpdateManyWithWhereWithoutSkuInput>>;
  deleteMany?: Maybe<Array<HardwareItemScalarWhereInput>>;
};

export type HardwareItemUpdateOneRequiredWithoutHardware_Request_ItemNestedInput =
  {
    create?: Maybe<HardwareItemCreateWithoutHardware_Request_ItemInput>;
    connectOrCreate?: Maybe<HardwareItemCreateOrConnectWithoutHardware_Request_ItemInput>;
    upsert?: Maybe<HardwareItemUpsertWithoutHardware_Request_ItemInput>;
    connect?: Maybe<HardwareItemWhereUniqueInput>;
    update?: Maybe<HardwareItemUpdateToOneWithWhereWithoutHardware_Request_ItemInput>;
  };

export type HardwareItemUpdateToOneWithWhereWithoutHardware_Request_ItemInput =
  {
    where?: Maybe<HardwareItemWhereInput>;
    data: HardwareItemUpdateWithoutHardware_Request_ItemInput;
  };

export type HardwareItemUpdateWithWhereUniqueWithoutSkuInput = {
  where: HardwareItemWhereUniqueInput;
  data: HardwareItemUpdateWithoutSkuInput;
};

export type HardwareItemUpdateWithoutHardware_Request_ItemInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  sku?: Maybe<HardwareSkuUpdateOneRequiredWithoutItemsNestedInput>;
};

export type HardwareItemUpdateWithoutSkuInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  hardware_request_item?: Maybe<HardwareRequestItemUpdateManyWithoutHardwareItemNestedInput>;
};

export type HardwareItemUpsertWithWhereUniqueWithoutSkuInput = {
  where: HardwareItemWhereUniqueInput;
  update: HardwareItemUpdateWithoutSkuInput;
  create: HardwareItemCreateWithoutSkuInput;
};

export type HardwareItemUpsertWithoutHardware_Request_ItemInput = {
  update: HardwareItemUpdateWithoutHardware_Request_ItemInput;
  create: HardwareItemCreateWithoutHardware_Request_ItemInput;
  where?: Maybe<HardwareItemWhereInput>;
};

export type HardwareItemWhereInput = {
  AND?: Maybe<Array<HardwareItemWhereInput>>;
  OR?: Maybe<Array<HardwareItemWhereInput>>;
  NOT?: Maybe<Array<HardwareItemWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  sku_code?: Maybe<StringFilter>;
  sku?: Maybe<HardwareSkuRelationFilter>;
  hardware_request_item?: Maybe<HardwareRequestItemListRelationFilter>;
};

export type HardwareItemWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<HardwareItemWhereInput>>;
  OR?: Maybe<Array<HardwareItemWhereInput>>;
  NOT?: Maybe<Array<HardwareItemWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  notes?: Maybe<StringFilter>;
  sku_code?: Maybe<StringFilter>;
  sku?: Maybe<HardwareSkuRelationFilter>;
  hardware_request_item?: Maybe<HardwareRequestItemListRelationFilter>;
};

export type HardwareRequest = {
  __typename?: "HardwareRequest";
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  notes: Scalars["String"];
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user_id: Scalars["Int"];
  event_id: Scalars["Int"];
  sku_code: Scalars["String"];
  _count?: Maybe<HardwareRequestCount>;
};

export type HardwareRequestCount = {
  __typename?: "HardwareRequestCount";
  hardware_request_history_entry: Scalars["Int"];
  hardware_request_item: Scalars["Int"];
};

export type HardwareRequestCountHardware_Request_History_EntryArgs = {
  where?: Maybe<HardwareRequestHistoryEntryWhereInput>;
};

export type HardwareRequestCountHardware_Request_ItemArgs = {
  where?: Maybe<HardwareRequestItemWhereInput>;
};

export type HardwareRequestCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user_id: Scalars["Int"];
  sku_code: Scalars["String"];
};

export type HardwareRequestCreateManyEventInputEnvelope = {
  data: Array<HardwareRequestCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestCreateManySkuInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user_id: Scalars["Int"];
  event_id: Scalars["Int"];
};

export type HardwareRequestCreateManySkuInputEnvelope = {
  data: Array<HardwareRequestCreateManySkuInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  event_id: Scalars["Int"];
  sku_code: Scalars["String"];
};

export type HardwareRequestCreateManyUserInputEnvelope = {
  data: Array<HardwareRequestCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestCreateOrConnectWithoutEventInput>
  >;
  createMany?: Maybe<HardwareRequestCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
};

export type HardwareRequestCreateNestedManyWithoutSkuInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutSkuInput>>;
  connectOrCreate?: Maybe<Array<HardwareRequestCreateOrConnectWithoutSkuInput>>;
  createMany?: Maybe<HardwareRequestCreateManySkuInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
};

export type HardwareRequestCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<HardwareRequestCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
};

export type HardwareRequestCreateNestedOneWithoutHardware_Request_History_EntryInput =
  {
    create?: Maybe<HardwareRequestCreateWithoutHardware_Request_History_EntryInput>;
    connectOrCreate?: Maybe<HardwareRequestCreateOrConnectWithoutHardware_Request_History_EntryInput>;
    connect?: Maybe<HardwareRequestWhereUniqueInput>;
  };

export type HardwareRequestCreateNestedOneWithoutHardware_Request_ItemInput = {
  create?: Maybe<HardwareRequestCreateWithoutHardware_Request_ItemInput>;
  connectOrCreate?: Maybe<HardwareRequestCreateOrConnectWithoutHardware_Request_ItemInput>;
  connect?: Maybe<HardwareRequestWhereUniqueInput>;
};

export type HardwareRequestCreateOrConnectWithoutEventInput = {
  where: HardwareRequestWhereUniqueInput;
  create: HardwareRequestCreateWithoutEventInput;
};

export type HardwareRequestCreateOrConnectWithoutHardware_Request_History_EntryInput =
  {
    where: HardwareRequestWhereUniqueInput;
    create: HardwareRequestCreateWithoutHardware_Request_History_EntryInput;
  };

export type HardwareRequestCreateOrConnectWithoutHardware_Request_ItemInput = {
  where: HardwareRequestWhereUniqueInput;
  create: HardwareRequestCreateWithoutHardware_Request_ItemInput;
};

export type HardwareRequestCreateOrConnectWithoutSkuInput = {
  where: HardwareRequestWhereUniqueInput;
  create: HardwareRequestCreateWithoutSkuInput;
};

export type HardwareRequestCreateOrConnectWithoutUserInput = {
  where: HardwareRequestWhereUniqueInput;
  create: HardwareRequestCreateWithoutUserInput;
};

export type HardwareRequestCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user: UserCreateNestedOneWithoutHardwareRequestInput;
  sku: HardwareSkuCreateNestedOneWithoutRequestsInput;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutRequestInput>;
  hardware_request_item?: Maybe<HardwareRequestItemCreateNestedManyWithoutHardwareRequestInput>;
};

export type HardwareRequestCreateWithoutHardware_Request_History_EntryInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user: UserCreateNestedOneWithoutHardwareRequestInput;
  event: EventCreateNestedOneWithoutHardwareRequestInput;
  sku: HardwareSkuCreateNestedOneWithoutRequestsInput;
  hardware_request_item?: Maybe<HardwareRequestItemCreateNestedManyWithoutHardwareRequestInput>;
};

export type HardwareRequestCreateWithoutHardware_Request_ItemInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user: UserCreateNestedOneWithoutHardwareRequestInput;
  event: EventCreateNestedOneWithoutHardwareRequestInput;
  sku: HardwareSkuCreateNestedOneWithoutRequestsInput;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutRequestInput>;
};

export type HardwareRequestCreateWithoutSkuInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  user: UserCreateNestedOneWithoutHardwareRequestInput;
  event: EventCreateNestedOneWithoutHardwareRequestInput;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutRequestInput>;
  hardware_request_item?: Maybe<HardwareRequestItemCreateNestedManyWithoutHardwareRequestInput>;
};

export type HardwareRequestCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  notes?: Maybe<Scalars["String"]>;
  status: HardwareRequestStatus;
  quantity: Scalars["Int"];
  event: EventCreateNestedOneWithoutHardwareRequestInput;
  sku: HardwareSkuCreateNestedOneWithoutRequestsInput;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutRequestInput>;
  hardware_request_item?: Maybe<HardwareRequestItemCreateNestedManyWithoutHardwareRequestInput>;
};

export type HardwareRequestHistoryEntryCreateManyModifying_UserInput = {
  time?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<HardwareRequestStatus>;
  notes?: Maybe<Scalars["String"]>;
  request_id: Scalars["Int"];
};

export type HardwareRequestHistoryEntryCreateManyModifying_UserInputEnvelope = {
  data: Array<HardwareRequestHistoryEntryCreateManyModifying_UserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestHistoryEntryCreateManyRequestInput = {
  time?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<HardwareRequestStatus>;
  notes?: Maybe<Scalars["String"]>;
  modifying_user_id: Scalars["Int"];
};

export type HardwareRequestHistoryEntryCreateManyRequestInputEnvelope = {
  data: Array<HardwareRequestHistoryEntryCreateManyRequestInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput =
  {
    create?: Maybe<
      Array<HardwareRequestHistoryEntryCreateWithoutModifying_UserInput>
    >;
    connectOrCreate?: Maybe<
      Array<HardwareRequestHistoryEntryCreateOrConnectWithoutModifying_UserInput>
    >;
    createMany?: Maybe<HardwareRequestHistoryEntryCreateManyModifying_UserInputEnvelope>;
    connect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
  };

export type HardwareRequestHistoryEntryCreateNestedManyWithoutRequestInput = {
  create?: Maybe<Array<HardwareRequestHistoryEntryCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestHistoryEntryCreateOrConnectWithoutRequestInput>
  >;
  createMany?: Maybe<HardwareRequestHistoryEntryCreateManyRequestInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
};

export type HardwareRequestHistoryEntryCreateOrConnectWithoutModifying_UserInput =
  {
    where: HardwareRequestHistoryEntryWhereUniqueInput;
    create: HardwareRequestHistoryEntryCreateWithoutModifying_UserInput;
  };

export type HardwareRequestHistoryEntryCreateOrConnectWithoutRequestInput = {
  where: HardwareRequestHistoryEntryWhereUniqueInput;
  create: HardwareRequestHistoryEntryCreateWithoutRequestInput;
};

export type HardwareRequestHistoryEntryCreateWithoutModifying_UserInput = {
  time?: Maybe<Scalars["DateTimeISO"]>;
  status?: Maybe<HardwareRequestStatus>;
  notes?: Maybe<Scalars["String"]>;
  request: HardwareRequestCreateNestedOneWithoutHardware_Request_History_EntryInput;
};

export type HardwareRequestHistoryEntryCreateWithoutRequestInput = {
  time?: Maybe<Scalars["DateTimeISO"]>;
  status?: Maybe<HardwareRequestStatus>;
  notes?: Maybe<Scalars["String"]>;
  modifying_user: UserCreateNestedOneWithoutHardwareRequestHistoryEntryInput;
};

export type HardwareRequestHistoryEntryListRelationFilter = {
  every?: Maybe<HardwareRequestHistoryEntryWhereInput>;
  some?: Maybe<HardwareRequestHistoryEntryWhereInput>;
  none?: Maybe<HardwareRequestHistoryEntryWhereInput>;
};

export type HardwareRequestHistoryEntryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type HardwareRequestHistoryEntryScalarWhereInput = {
  AND?: Maybe<Array<HardwareRequestHistoryEntryScalarWhereInput>>;
  OR?: Maybe<Array<HardwareRequestHistoryEntryScalarWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestHistoryEntryScalarWhereInput>>;
  time?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  status?: Maybe<EnumHardwareRequestStatusNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  request_id?: Maybe<IntFilter>;
  modifying_user_id?: Maybe<IntFilter>;
};

export type HardwareRequestHistoryEntryUpdateManyMutationInput = {
  time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumHardwareRequestStatusFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type HardwareRequestHistoryEntryUpdateManyWithWhereWithoutModifying_UserInput =
  {
    where: HardwareRequestHistoryEntryScalarWhereInput;
    data: HardwareRequestHistoryEntryUpdateManyMutationInput;
  };

export type HardwareRequestHistoryEntryUpdateManyWithWhereWithoutRequestInput =
  {
    where: HardwareRequestHistoryEntryScalarWhereInput;
    data: HardwareRequestHistoryEntryUpdateManyMutationInput;
  };

export type HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput =
  {
    create?: Maybe<
      Array<HardwareRequestHistoryEntryCreateWithoutModifying_UserInput>
    >;
    connectOrCreate?: Maybe<
      Array<HardwareRequestHistoryEntryCreateOrConnectWithoutModifying_UserInput>
    >;
    upsert?: Maybe<
      Array<HardwareRequestHistoryEntryUpsertWithWhereUniqueWithoutModifying_UserInput>
    >;
    createMany?: Maybe<HardwareRequestHistoryEntryCreateManyModifying_UserInputEnvelope>;
    set?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
    disconnect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
    delete?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
    connect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
    update?: Maybe<
      Array<HardwareRequestHistoryEntryUpdateWithWhereUniqueWithoutModifying_UserInput>
    >;
    updateMany?: Maybe<
      Array<HardwareRequestHistoryEntryUpdateManyWithWhereWithoutModifying_UserInput>
    >;
    deleteMany?: Maybe<Array<HardwareRequestHistoryEntryScalarWhereInput>>;
  };

export type HardwareRequestHistoryEntryUpdateManyWithoutRequestNestedInput = {
  create?: Maybe<Array<HardwareRequestHistoryEntryCreateWithoutRequestInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestHistoryEntryCreateOrConnectWithoutRequestInput>
  >;
  upsert?: Maybe<
    Array<HardwareRequestHistoryEntryUpsertWithWhereUniqueWithoutRequestInput>
  >;
  createMany?: Maybe<HardwareRequestHistoryEntryCreateManyRequestInputEnvelope>;
  set?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestHistoryEntryWhereUniqueInput>>;
  update?: Maybe<
    Array<HardwareRequestHistoryEntryUpdateWithWhereUniqueWithoutRequestInput>
  >;
  updateMany?: Maybe<
    Array<HardwareRequestHistoryEntryUpdateManyWithWhereWithoutRequestInput>
  >;
  deleteMany?: Maybe<Array<HardwareRequestHistoryEntryScalarWhereInput>>;
};

export type HardwareRequestHistoryEntryUpdateWithWhereUniqueWithoutModifying_UserInput =
  {
    where: HardwareRequestHistoryEntryWhereUniqueInput;
    data: HardwareRequestHistoryEntryUpdateWithoutModifying_UserInput;
  };

export type HardwareRequestHistoryEntryUpdateWithWhereUniqueWithoutRequestInput =
  {
    where: HardwareRequestHistoryEntryWhereUniqueInput;
    data: HardwareRequestHistoryEntryUpdateWithoutRequestInput;
  };

export type HardwareRequestHistoryEntryUpdateWithoutModifying_UserInput = {
  time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumHardwareRequestStatusFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  request?: Maybe<HardwareRequestUpdateOneRequiredWithoutHardware_Request_History_EntryNestedInput>;
};

export type HardwareRequestHistoryEntryUpdateWithoutRequestInput = {
  time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumHardwareRequestStatusFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  modifying_user?: Maybe<UserUpdateOneRequiredWithoutHardwareRequestHistoryEntryNestedInput>;
};

export type HardwareRequestHistoryEntryUpsertWithWhereUniqueWithoutModifying_UserInput =
  {
    where: HardwareRequestHistoryEntryWhereUniqueInput;
    update: HardwareRequestHistoryEntryUpdateWithoutModifying_UserInput;
    create: HardwareRequestHistoryEntryCreateWithoutModifying_UserInput;
  };

export type HardwareRequestHistoryEntryUpsertWithWhereUniqueWithoutRequestInput =
  {
    where: HardwareRequestHistoryEntryWhereUniqueInput;
    update: HardwareRequestHistoryEntryUpdateWithoutRequestInput;
    create: HardwareRequestHistoryEntryCreateWithoutRequestInput;
  };

export type HardwareRequestHistoryEntryWhereInput = {
  AND?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  OR?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  time?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  status?: Maybe<EnumHardwareRequestStatusNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  request_id?: Maybe<IntFilter>;
  modifying_user_id?: Maybe<IntFilter>;
  request?: Maybe<HardwareRequestRelationFilter>;
  modifying_user?: Maybe<UserRelationFilter>;
};

export type HardwareRequestHistoryEntryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  OR?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestHistoryEntryWhereInput>>;
  time?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumHardwareRequestStatusNullableFilter>;
  notes?: Maybe<StringNullableFilter>;
  request_id?: Maybe<IntFilter>;
  modifying_user_id?: Maybe<IntFilter>;
  request?: Maybe<HardwareRequestRelationFilter>;
  modifying_user?: Maybe<UserRelationFilter>;
};

export type HardwareRequestItemCreateManyHardwareItemInput = {
  id?: Maybe<Scalars["Int"]>;
  reserved: Scalars["Boolean"];
  request_id: Scalars["Int"];
};

export type HardwareRequestItemCreateManyHardwareItemInputEnvelope = {
  data: Array<HardwareRequestItemCreateManyHardwareItemInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestItemCreateManyHardwareRequestInput = {
  id?: Maybe<Scalars["Int"]>;
  reserved: Scalars["Boolean"];
  item_id: Scalars["Int"];
};

export type HardwareRequestItemCreateManyHardwareRequestInputEnvelope = {
  data: Array<HardwareRequestItemCreateManyHardwareRequestInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HardwareRequestItemCreateNestedManyWithoutHardwareItemInput = {
  create?: Maybe<Array<HardwareRequestItemCreateWithoutHardwareItemInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestItemCreateOrConnectWithoutHardwareItemInput>
  >;
  createMany?: Maybe<HardwareRequestItemCreateManyHardwareItemInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
};

export type HardwareRequestItemCreateNestedManyWithoutHardwareRequestInput = {
  create?: Maybe<Array<HardwareRequestItemCreateWithoutHardwareRequestInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestItemCreateOrConnectWithoutHardwareRequestInput>
  >;
  createMany?: Maybe<HardwareRequestItemCreateManyHardwareRequestInputEnvelope>;
  connect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
};

export type HardwareRequestItemCreateOrConnectWithoutHardwareItemInput = {
  where: HardwareRequestItemWhereUniqueInput;
  create: HardwareRequestItemCreateWithoutHardwareItemInput;
};

export type HardwareRequestItemCreateOrConnectWithoutHardwareRequestInput = {
  where: HardwareRequestItemWhereUniqueInput;
  create: HardwareRequestItemCreateWithoutHardwareRequestInput;
};

export type HardwareRequestItemCreateWithoutHardwareItemInput = {
  reserved: Scalars["Boolean"];
  HardwareRequest: HardwareRequestCreateNestedOneWithoutHardware_Request_ItemInput;
};

export type HardwareRequestItemCreateWithoutHardwareRequestInput = {
  reserved: Scalars["Boolean"];
  HardwareItem: HardwareItemCreateNestedOneWithoutHardware_Request_ItemInput;
};

export type HardwareRequestItemHardware_Request_Item_Item_Id_Request_Id_KeyCompoundUniqueInput =
  {
    item_id: Scalars["Int"];
    request_id: Scalars["Int"];
  };

export type HardwareRequestItemListRelationFilter = {
  every?: Maybe<HardwareRequestItemWhereInput>;
  some?: Maybe<HardwareRequestItemWhereInput>;
  none?: Maybe<HardwareRequestItemWhereInput>;
};

export type HardwareRequestItemScalarWhereInput = {
  AND?: Maybe<Array<HardwareRequestItemScalarWhereInput>>;
  OR?: Maybe<Array<HardwareRequestItemScalarWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestItemScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  reserved?: Maybe<BoolFilter>;
  item_id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
};

export type HardwareRequestItemUpdateManyMutationInput = {
  reserved?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type HardwareRequestItemUpdateManyWithWhereWithoutHardwareItemInput = {
  where: HardwareRequestItemScalarWhereInput;
  data: HardwareRequestItemUpdateManyMutationInput;
};

export type HardwareRequestItemUpdateManyWithWhereWithoutHardwareRequestInput =
  {
    where: HardwareRequestItemScalarWhereInput;
    data: HardwareRequestItemUpdateManyMutationInput;
  };

export type HardwareRequestItemUpdateManyWithoutHardwareItemNestedInput = {
  create?: Maybe<Array<HardwareRequestItemCreateWithoutHardwareItemInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestItemCreateOrConnectWithoutHardwareItemInput>
  >;
  upsert?: Maybe<
    Array<HardwareRequestItemUpsertWithWhereUniqueWithoutHardwareItemInput>
  >;
  createMany?: Maybe<HardwareRequestItemCreateManyHardwareItemInputEnvelope>;
  set?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  update?: Maybe<
    Array<HardwareRequestItemUpdateWithWhereUniqueWithoutHardwareItemInput>
  >;
  updateMany?: Maybe<
    Array<HardwareRequestItemUpdateManyWithWhereWithoutHardwareItemInput>
  >;
  deleteMany?: Maybe<Array<HardwareRequestItemScalarWhereInput>>;
};

export type HardwareRequestItemUpdateManyWithoutHardwareRequestNestedInput = {
  create?: Maybe<Array<HardwareRequestItemCreateWithoutHardwareRequestInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestItemCreateOrConnectWithoutHardwareRequestInput>
  >;
  upsert?: Maybe<
    Array<HardwareRequestItemUpsertWithWhereUniqueWithoutHardwareRequestInput>
  >;
  createMany?: Maybe<HardwareRequestItemCreateManyHardwareRequestInputEnvelope>;
  set?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestItemWhereUniqueInput>>;
  update?: Maybe<
    Array<HardwareRequestItemUpdateWithWhereUniqueWithoutHardwareRequestInput>
  >;
  updateMany?: Maybe<
    Array<HardwareRequestItemUpdateManyWithWhereWithoutHardwareRequestInput>
  >;
  deleteMany?: Maybe<Array<HardwareRequestItemScalarWhereInput>>;
};

export type HardwareRequestItemUpdateWithWhereUniqueWithoutHardwareItemInput = {
  where: HardwareRequestItemWhereUniqueInput;
  data: HardwareRequestItemUpdateWithoutHardwareItemInput;
};

export type HardwareRequestItemUpdateWithWhereUniqueWithoutHardwareRequestInput =
  {
    where: HardwareRequestItemWhereUniqueInput;
    data: HardwareRequestItemUpdateWithoutHardwareRequestInput;
  };

export type HardwareRequestItemUpdateWithoutHardwareItemInput = {
  reserved?: Maybe<BoolFieldUpdateOperationsInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateOneRequiredWithoutHardware_Request_ItemNestedInput>;
};

export type HardwareRequestItemUpdateWithoutHardwareRequestInput = {
  reserved?: Maybe<BoolFieldUpdateOperationsInput>;
  HardwareItem?: Maybe<HardwareItemUpdateOneRequiredWithoutHardware_Request_ItemNestedInput>;
};

export type HardwareRequestItemUpsertWithWhereUniqueWithoutHardwareItemInput = {
  where: HardwareRequestItemWhereUniqueInput;
  update: HardwareRequestItemUpdateWithoutHardwareItemInput;
  create: HardwareRequestItemCreateWithoutHardwareItemInput;
};

export type HardwareRequestItemUpsertWithWhereUniqueWithoutHardwareRequestInput =
  {
    where: HardwareRequestItemWhereUniqueInput;
    update: HardwareRequestItemUpdateWithoutHardwareRequestInput;
    create: HardwareRequestItemCreateWithoutHardwareRequestInput;
  };

export type HardwareRequestItemWhereInput = {
  AND?: Maybe<Array<HardwareRequestItemWhereInput>>;
  OR?: Maybe<Array<HardwareRequestItemWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestItemWhereInput>>;
  id?: Maybe<IntFilter>;
  reserved?: Maybe<BoolFilter>;
  item_id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
  HardwareRequest?: Maybe<HardwareRequestRelationFilter>;
  HardwareItem?: Maybe<HardwareItemRelationFilter>;
};

export type HardwareRequestItemWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  hardware_request_item_item_id_request_id_key?: Maybe<HardwareRequestItemHardware_Request_Item_Item_Id_Request_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<HardwareRequestItemWhereInput>>;
  OR?: Maybe<Array<HardwareRequestItemWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestItemWhereInput>>;
  reserved?: Maybe<BoolFilter>;
  item_id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
  HardwareRequest?: Maybe<HardwareRequestRelationFilter>;
  HardwareItem?: Maybe<HardwareItemRelationFilter>;
};

export type HardwareRequestListRelationFilter = {
  every?: Maybe<HardwareRequestWhereInput>;
  some?: Maybe<HardwareRequestWhereInput>;
  none?: Maybe<HardwareRequestWhereInput>;
};

export type HardwareRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type HardwareRequestRelationFilter = {
  is?: Maybe<HardwareRequestWhereInput>;
  isNot?: Maybe<HardwareRequestWhereInput>;
};

export type HardwareRequestScalarWhereInput = {
  AND?: Maybe<Array<HardwareRequestScalarWhereInput>>;
  OR?: Maybe<Array<HardwareRequestScalarWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  status?: Maybe<EnumHardwareRequestStatusFilter>;
  quantity?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  sku_code?: Maybe<StringFilter>;
};

export enum HardwareRequestStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Ready = "READY",
  Denied = "DENIED",
  Fulfilled = "FULFILLED",
  Returned = "RETURNED",
  Cancelled = "CANCELLED",
}

export type HardwareRequestUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
};

export type HardwareRequestUpdateManyWithWhereWithoutEventInput = {
  where: HardwareRequestScalarWhereInput;
  data: HardwareRequestUpdateManyMutationInput;
};

export type HardwareRequestUpdateManyWithWhereWithoutSkuInput = {
  where: HardwareRequestScalarWhereInput;
  data: HardwareRequestUpdateManyMutationInput;
};

export type HardwareRequestUpdateManyWithWhereWithoutUserInput = {
  where: HardwareRequestScalarWhereInput;
  data: HardwareRequestUpdateManyMutationInput;
};

export type HardwareRequestUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestCreateOrConnectWithoutEventInput>
  >;
  upsert?: Maybe<Array<HardwareRequestUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<HardwareRequestCreateManyEventInputEnvelope>;
  set?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  update?: Maybe<Array<HardwareRequestUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<
    Array<HardwareRequestUpdateManyWithWhereWithoutEventInput>
  >;
  deleteMany?: Maybe<Array<HardwareRequestScalarWhereInput>>;
};

export type HardwareRequestUpdateManyWithoutSkuNestedInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutSkuInput>>;
  connectOrCreate?: Maybe<Array<HardwareRequestCreateOrConnectWithoutSkuInput>>;
  upsert?: Maybe<Array<HardwareRequestUpsertWithWhereUniqueWithoutSkuInput>>;
  createMany?: Maybe<HardwareRequestCreateManySkuInputEnvelope>;
  set?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  update?: Maybe<Array<HardwareRequestUpdateWithWhereUniqueWithoutSkuInput>>;
  updateMany?: Maybe<Array<HardwareRequestUpdateManyWithWhereWithoutSkuInput>>;
  deleteMany?: Maybe<Array<HardwareRequestScalarWhereInput>>;
};

export type HardwareRequestUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<HardwareRequestCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<HardwareRequestCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<Array<HardwareRequestUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<HardwareRequestCreateManyUserInputEnvelope>;
  set?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  delete?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  connect?: Maybe<Array<HardwareRequestWhereUniqueInput>>;
  update?: Maybe<Array<HardwareRequestUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<HardwareRequestUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<HardwareRequestScalarWhereInput>>;
};

export type HardwareRequestUpdateOneRequiredWithoutHardware_Request_History_EntryNestedInput =
  {
    create?: Maybe<HardwareRequestCreateWithoutHardware_Request_History_EntryInput>;
    connectOrCreate?: Maybe<HardwareRequestCreateOrConnectWithoutHardware_Request_History_EntryInput>;
    upsert?: Maybe<HardwareRequestUpsertWithoutHardware_Request_History_EntryInput>;
    connect?: Maybe<HardwareRequestWhereUniqueInput>;
    update?: Maybe<HardwareRequestUpdateToOneWithWhereWithoutHardware_Request_History_EntryInput>;
  };

export type HardwareRequestUpdateOneRequiredWithoutHardware_Request_ItemNestedInput =
  {
    create?: Maybe<HardwareRequestCreateWithoutHardware_Request_ItemInput>;
    connectOrCreate?: Maybe<HardwareRequestCreateOrConnectWithoutHardware_Request_ItemInput>;
    upsert?: Maybe<HardwareRequestUpsertWithoutHardware_Request_ItemInput>;
    connect?: Maybe<HardwareRequestWhereUniqueInput>;
    update?: Maybe<HardwareRequestUpdateToOneWithWhereWithoutHardware_Request_ItemInput>;
  };

export type HardwareRequestUpdateToOneWithWhereWithoutHardware_Request_History_EntryInput =
  {
    where?: Maybe<HardwareRequestWhereInput>;
    data: HardwareRequestUpdateWithoutHardware_Request_History_EntryInput;
  };

export type HardwareRequestUpdateToOneWithWhereWithoutHardware_Request_ItemInput =
  {
    where?: Maybe<HardwareRequestWhereInput>;
    data: HardwareRequestUpdateWithoutHardware_Request_ItemInput;
  };

export type HardwareRequestUpdateWithWhereUniqueWithoutEventInput = {
  where: HardwareRequestWhereUniqueInput;
  data: HardwareRequestUpdateWithoutEventInput;
};

export type HardwareRequestUpdateWithWhereUniqueWithoutSkuInput = {
  where: HardwareRequestWhereUniqueInput;
  data: HardwareRequestUpdateWithoutSkuInput;
};

export type HardwareRequestUpdateWithWhereUniqueWithoutUserInput = {
  where: HardwareRequestWhereUniqueInput;
  data: HardwareRequestUpdateWithoutUserInput;
};

export type HardwareRequestUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  sku?: Maybe<HardwareSkuUpdateOneRequiredWithoutRequestsNestedInput>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutRequestNestedInput>;
  hardware_request_item?: Maybe<HardwareRequestItemUpdateManyWithoutHardwareRequestNestedInput>;
};

export type HardwareRequestUpdateWithoutHardware_Request_History_EntryInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  sku?: Maybe<HardwareSkuUpdateOneRequiredWithoutRequestsNestedInput>;
  hardware_request_item?: Maybe<HardwareRequestItemUpdateManyWithoutHardwareRequestNestedInput>;
};

export type HardwareRequestUpdateWithoutHardware_Request_ItemInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  sku?: Maybe<HardwareSkuUpdateOneRequiredWithoutRequestsNestedInput>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutRequestNestedInput>;
};

export type HardwareRequestUpdateWithoutSkuInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutRequestNestedInput>;
  hardware_request_item?: Maybe<HardwareRequestItemUpdateManyWithoutHardwareRequestNestedInput>;
};

export type HardwareRequestUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumHardwareRequestStatusFieldUpdateOperationsInput>;
  quantity?: Maybe<IntFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutHardwareRequestNestedInput>;
  sku?: Maybe<HardwareSkuUpdateOneRequiredWithoutRequestsNestedInput>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutRequestNestedInput>;
  hardware_request_item?: Maybe<HardwareRequestItemUpdateManyWithoutHardwareRequestNestedInput>;
};

export type HardwareRequestUpsertWithWhereUniqueWithoutEventInput = {
  where: HardwareRequestWhereUniqueInput;
  update: HardwareRequestUpdateWithoutEventInput;
  create: HardwareRequestCreateWithoutEventInput;
};

export type HardwareRequestUpsertWithWhereUniqueWithoutSkuInput = {
  where: HardwareRequestWhereUniqueInput;
  update: HardwareRequestUpdateWithoutSkuInput;
  create: HardwareRequestCreateWithoutSkuInput;
};

export type HardwareRequestUpsertWithWhereUniqueWithoutUserInput = {
  where: HardwareRequestWhereUniqueInput;
  update: HardwareRequestUpdateWithoutUserInput;
  create: HardwareRequestCreateWithoutUserInput;
};

export type HardwareRequestUpsertWithoutHardware_Request_History_EntryInput = {
  update: HardwareRequestUpdateWithoutHardware_Request_History_EntryInput;
  create: HardwareRequestCreateWithoutHardware_Request_History_EntryInput;
  where?: Maybe<HardwareRequestWhereInput>;
};

export type HardwareRequestUpsertWithoutHardware_Request_ItemInput = {
  update: HardwareRequestUpdateWithoutHardware_Request_ItemInput;
  create: HardwareRequestCreateWithoutHardware_Request_ItemInput;
  where?: Maybe<HardwareRequestWhereInput>;
};

export type HardwareRequestWhereInput = {
  AND?: Maybe<Array<HardwareRequestWhereInput>>;
  OR?: Maybe<Array<HardwareRequestWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  status?: Maybe<EnumHardwareRequestStatusFilter>;
  quantity?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  sku_code?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  sku?: Maybe<HardwareSkuRelationFilter>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryListRelationFilter>;
  hardware_request_item?: Maybe<HardwareRequestItemListRelationFilter>;
};

export type HardwareRequestWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<HardwareRequestWhereInput>>;
  OR?: Maybe<Array<HardwareRequestWhereInput>>;
  NOT?: Maybe<Array<HardwareRequestWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  notes?: Maybe<StringFilter>;
  status?: Maybe<EnumHardwareRequestStatusFilter>;
  quantity?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  sku_code?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  sku?: Maybe<HardwareSkuRelationFilter>;
  hardware_request_history_entry?: Maybe<HardwareRequestHistoryEntryListRelationFilter>;
  hardware_request_item?: Maybe<HardwareRequestItemListRelationFilter>;
};

export type HardwareSku = {
  __typename?: "HardwareSku";
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  code: Scalars["String"];
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
  location: Scalars["String"];
  category_id: Scalars["Int"];
  type: HardwareSkuType;
  _count?: Maybe<HardwareSkuCount>;
};

export type HardwareSkuCount = {
  __typename?: "HardwareSkuCount";
  items: Scalars["Int"];
  requests: Scalars["Int"];
};

export type HardwareSkuCountItemsArgs = {
  where?: Maybe<HardwareItemWhereInput>;
};

export type HardwareSkuCountRequestsArgs = {
  where?: Maybe<HardwareRequestWhereInput>;
};

export type HardwareSkuCreateNestedOneWithoutItemsInput = {
  create?: Maybe<HardwareSkuCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<HardwareSkuCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<HardwareSkuWhereUniqueInput>;
};

export type HardwareSkuCreateNestedOneWithoutRequestsInput = {
  create?: Maybe<HardwareSkuCreateWithoutRequestsInput>;
  connectOrCreate?: Maybe<HardwareSkuCreateOrConnectWithoutRequestsInput>;
  connect?: Maybe<HardwareSkuWhereUniqueInput>;
};

export type HardwareSkuCreateOrConnectWithoutItemsInput = {
  where: HardwareSkuWhereUniqueInput;
  create: HardwareSkuCreateWithoutItemsInput;
};

export type HardwareSkuCreateOrConnectWithoutRequestsInput = {
  where: HardwareSkuWhereUniqueInput;
  create: HardwareSkuCreateWithoutRequestsInput;
};

export type HardwareSkuCreateWithoutItemsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  code: Scalars["String"];
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
  location: Scalars["String"];
  type?: Maybe<HardwareSkuType>;
  category: HardwareCategoryCreateNestedOneWithoutSkusInput;
  requests?: Maybe<HardwareRequestCreateNestedManyWithoutSkuInput>;
};

export type HardwareSkuCreateWithoutRequestsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  code: Scalars["String"];
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
  location: Scalars["String"];
  type?: Maybe<HardwareSkuType>;
  category: HardwareCategoryCreateNestedOneWithoutSkusInput;
  items?: Maybe<HardwareItemCreateNestedManyWithoutSkuInput>;
};

export type HardwareSkuListRelationFilter = {
  every?: Maybe<HardwareSkuWhereInput>;
  some?: Maybe<HardwareSkuWhereInput>;
  none?: Maybe<HardwareSkuWhereInput>;
};

export type HardwareSkuOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type HardwareSkuRelationFilter = {
  is?: Maybe<HardwareSkuWhereInput>;
  isNot?: Maybe<HardwareSkuWhereInput>;
};

export enum HardwareSkuType {
  Lottery = "LOTTERY",
  Checkout = "CHECKOUT",
  Free = "FREE",
}

export type HardwareSkuUpdateOneRequiredWithoutItemsNestedInput = {
  create?: Maybe<HardwareSkuCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<HardwareSkuCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<HardwareSkuUpsertWithoutItemsInput>;
  connect?: Maybe<HardwareSkuWhereUniqueInput>;
  update?: Maybe<HardwareSkuUpdateToOneWithWhereWithoutItemsInput>;
};

export type HardwareSkuUpdateOneRequiredWithoutRequestsNestedInput = {
  create?: Maybe<HardwareSkuCreateWithoutRequestsInput>;
  connectOrCreate?: Maybe<HardwareSkuCreateOrConnectWithoutRequestsInput>;
  upsert?: Maybe<HardwareSkuUpsertWithoutRequestsInput>;
  connect?: Maybe<HardwareSkuWhereUniqueInput>;
  update?: Maybe<HardwareSkuUpdateToOneWithWhereWithoutRequestsInput>;
};

export type HardwareSkuUpdateToOneWithWhereWithoutItemsInput = {
  where?: Maybe<HardwareSkuWhereInput>;
  data: HardwareSkuUpdateWithoutItemsInput;
};

export type HardwareSkuUpdateToOneWithWhereWithoutRequestsInput = {
  where?: Maybe<HardwareSkuWhereInput>;
  data: HardwareSkuUpdateWithoutRequestsInput;
};

export type HardwareSkuUpdateWithoutItemsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  short_description?: Maybe<StringFieldUpdateOperationsInput>;
  long_description?: Maybe<StringFieldUpdateOperationsInput>;
  image?: Maybe<StringFieldUpdateOperationsInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumHardwareSkuTypeFieldUpdateOperationsInput>;
  category?: Maybe<HardwareCategoryUpdateOneRequiredWithoutSkusNestedInput>;
  requests?: Maybe<HardwareRequestUpdateManyWithoutSkuNestedInput>;
};

export type HardwareSkuUpdateWithoutRequestsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  code?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  short_description?: Maybe<StringFieldUpdateOperationsInput>;
  long_description?: Maybe<StringFieldUpdateOperationsInput>;
  image?: Maybe<StringFieldUpdateOperationsInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<EnumHardwareSkuTypeFieldUpdateOperationsInput>;
  category?: Maybe<HardwareCategoryUpdateOneRequiredWithoutSkusNestedInput>;
  items?: Maybe<HardwareItemUpdateManyWithoutSkuNestedInput>;
};

export type HardwareSkuUpsertWithoutItemsInput = {
  update: HardwareSkuUpdateWithoutItemsInput;
  create: HardwareSkuCreateWithoutItemsInput;
  where?: Maybe<HardwareSkuWhereInput>;
};

export type HardwareSkuUpsertWithoutRequestsInput = {
  update: HardwareSkuUpdateWithoutRequestsInput;
  create: HardwareSkuCreateWithoutRequestsInput;
  where?: Maybe<HardwareSkuWhereInput>;
};

export type HardwareSkuWhereInput = {
  AND?: Maybe<Array<HardwareSkuWhereInput>>;
  OR?: Maybe<Array<HardwareSkuWhereInput>>;
  NOT?: Maybe<Array<HardwareSkuWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  short_description?: Maybe<StringFilter>;
  long_description?: Maybe<StringFilter>;
  image?: Maybe<StringFilter>;
  location?: Maybe<StringFilter>;
  category_id?: Maybe<IntFilter>;
  type?: Maybe<EnumHardwareSkuTypeFilter>;
  category?: Maybe<HardwareCategoryRelationFilter>;
  items?: Maybe<HardwareItemListRelationFilter>;
  requests?: Maybe<HardwareRequestListRelationFilter>;
};

export type HardwareSkuWhereUniqueInput = {
  code?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<HardwareSkuWhereInput>>;
  OR?: Maybe<Array<HardwareSkuWhereInput>>;
  NOT?: Maybe<Array<HardwareSkuWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  short_description?: Maybe<StringFilter>;
  long_description?: Maybe<StringFilter>;
  image?: Maybe<StringFilter>;
  location?: Maybe<StringFilter>;
  category_id?: Maybe<IntFilter>;
  type?: Maybe<EnumHardwareSkuTypeFilter>;
  category?: Maybe<HardwareCategoryRelationFilter>;
  items?: Maybe<HardwareItemListRelationFilter>;
  requests?: Maybe<HardwareRequestListRelationFilter>;
};

export type HealthResponse = {
  __typename?: "HealthResponse";
  healthy: Scalars["Boolean"];
};

export type HtnBucksAccount = {
  __typename?: "HtnBucksAccount";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  slack_email_address: Scalars["String"];
  _count?: Maybe<HtnBucksAccountCount>;
  user?: Maybe<User>;
  all_transactions: Array<HtnBucksTransaction>;
  sent_transactions: Array<HtnBucksTransaction>;
  received_transactions: Array<HtnBucksTransaction>;
  most_recent_received_transaction?: Maybe<HtnBucksTransaction>;
  most_recent_received_transactions: Array<HtnBucksTransaction>;
  balance: Scalars["Int"];
  calendar_month_balance: Scalars["Int"];
  num_times_sent: Scalars["Int"];
  calendar_month_num_times_sent: Scalars["Int"];
  total_num_times_sent: Scalars["Int"];
};

export type HtnBucksAccountMost_Recent_Received_TransactionsArgs = {
  count: Scalars["Float"];
};

export type HtnBucksAccountCount = {
  __typename?: "HtnBucksAccountCount";
  sent_transactions: Scalars["Int"];
  received_transactions: Scalars["Int"];
};

export type HtnBucksAccountCountSent_TransactionsArgs = {
  where?: Maybe<HtnBucksTransactionWhereInput>;
};

export type HtnBucksAccountCountReceived_TransactionsArgs = {
  where?: Maybe<HtnBucksTransactionWhereInput>;
};

export type HtnBucksAccountCreateNestedOneWithoutReceived_TransactionsInput = {
  create?: Maybe<HtnBucksAccountCreateWithoutReceived_TransactionsInput>;
  connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutReceived_TransactionsInput>;
  connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
};

export type HtnBucksAccountCreateNestedOneWithoutSent_TransactionsInput = {
  create?: Maybe<HtnBucksAccountCreateWithoutSent_TransactionsInput>;
  connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutSent_TransactionsInput>;
  connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
};

export type HtnBucksAccountCreateNestedOneWithoutUserInput = {
  create?: Maybe<HtnBucksAccountCreateWithoutUserInput>;
  connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutUserInput>;
  connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
};

export type HtnBucksAccountCreateOrConnectWithoutReceived_TransactionsInput = {
  where: HtnBucksAccountWhereUniqueInput;
  create: HtnBucksAccountCreateWithoutReceived_TransactionsInput;
};

export type HtnBucksAccountCreateOrConnectWithoutSent_TransactionsInput = {
  where: HtnBucksAccountWhereUniqueInput;
  create: HtnBucksAccountCreateWithoutSent_TransactionsInput;
};

export type HtnBucksAccountCreateOrConnectWithoutUserInput = {
  where: HtnBucksAccountWhereUniqueInput;
  create: HtnBucksAccountCreateWithoutUserInput;
};

export type HtnBucksAccountCreateWithoutReceived_TransactionsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutHtnBucksAccountInput;
  sent_transactions?: Maybe<HtnBucksTransactionCreateNestedManyWithoutSenderInput>;
};

export type HtnBucksAccountCreateWithoutSent_TransactionsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutHtnBucksAccountInput;
  received_transactions?: Maybe<HtnBucksTransactionCreateNestedManyWithoutReceiverInput>;
};

export type HtnBucksAccountCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  sent_transactions?: Maybe<HtnBucksTransactionCreateNestedManyWithoutSenderInput>;
  received_transactions?: Maybe<HtnBucksTransactionCreateNestedManyWithoutReceiverInput>;
};

export type HtnBucksAccountNullableRelationFilter = {
  is?: Maybe<HtnBucksAccountWhereInput>;
  isNot?: Maybe<HtnBucksAccountWhereInput>;
};

export enum HtnBucksAccountOrderByRelevanceFieldEnum {
  SlackEmailAddress = "slack_email_address",
}

export type HtnBucksAccountOrderByRelevanceInput = {
  fields: Array<HtnBucksAccountOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type HtnBucksAccountOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  slack_email_address?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  sent_transactions?: Maybe<HtnBucksTransactionOrderByRelationAggregateInput>;
  received_transactions?: Maybe<HtnBucksTransactionOrderByRelationAggregateInput>;
  _relevance?: Maybe<HtnBucksAccountOrderByRelevanceInput>;
};

export type HtnBucksAccountRelationFilter = {
  is?: Maybe<HtnBucksAccountWhereInput>;
  isNot?: Maybe<HtnBucksAccountWhereInput>;
};

export enum HtnBucksAccountScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  SlackEmailAddress = "slack_email_address",
}

export type HtnBucksAccountUpdateOneRequiredWithoutReceived_TransactionsNestedInput =
  {
    create?: Maybe<HtnBucksAccountCreateWithoutReceived_TransactionsInput>;
    connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutReceived_TransactionsInput>;
    upsert?: Maybe<HtnBucksAccountUpsertWithoutReceived_TransactionsInput>;
    connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
    update?: Maybe<HtnBucksAccountUpdateToOneWithWhereWithoutReceived_TransactionsInput>;
  };

export type HtnBucksAccountUpdateOneRequiredWithoutSent_TransactionsNestedInput =
  {
    create?: Maybe<HtnBucksAccountCreateWithoutSent_TransactionsInput>;
    connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutSent_TransactionsInput>;
    upsert?: Maybe<HtnBucksAccountUpsertWithoutSent_TransactionsInput>;
    connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
    update?: Maybe<HtnBucksAccountUpdateToOneWithWhereWithoutSent_TransactionsInput>;
  };

export type HtnBucksAccountUpdateOneWithoutUserNestedInput = {
  create?: Maybe<HtnBucksAccountCreateWithoutUserInput>;
  connectOrCreate?: Maybe<HtnBucksAccountCreateOrConnectWithoutUserInput>;
  upsert?: Maybe<HtnBucksAccountUpsertWithoutUserInput>;
  disconnect?: Maybe<HtnBucksAccountWhereInput>;
  delete?: Maybe<HtnBucksAccountWhereInput>;
  connect?: Maybe<HtnBucksAccountWhereUniqueInput>;
  update?: Maybe<HtnBucksAccountUpdateToOneWithWhereWithoutUserInput>;
};

export type HtnBucksAccountUpdateToOneWithWhereWithoutReceived_TransactionsInput =
  {
    where?: Maybe<HtnBucksAccountWhereInput>;
    data: HtnBucksAccountUpdateWithoutReceived_TransactionsInput;
  };

export type HtnBucksAccountUpdateToOneWithWhereWithoutSent_TransactionsInput = {
  where?: Maybe<HtnBucksAccountWhereInput>;
  data: HtnBucksAccountUpdateWithoutSent_TransactionsInput;
};

export type HtnBucksAccountUpdateToOneWithWhereWithoutUserInput = {
  where?: Maybe<HtnBucksAccountWhereInput>;
  data: HtnBucksAccountUpdateWithoutUserInput;
};

export type HtnBucksAccountUpdateWithoutReceived_TransactionsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHtnBucksAccountNestedInput>;
  sent_transactions?: Maybe<HtnBucksTransactionUpdateManyWithoutSenderNestedInput>;
};

export type HtnBucksAccountUpdateWithoutSent_TransactionsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutHtnBucksAccountNestedInput>;
  received_transactions?: Maybe<HtnBucksTransactionUpdateManyWithoutReceiverNestedInput>;
};

export type HtnBucksAccountUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sent_transactions?: Maybe<HtnBucksTransactionUpdateManyWithoutSenderNestedInput>;
  received_transactions?: Maybe<HtnBucksTransactionUpdateManyWithoutReceiverNestedInput>;
};

export type HtnBucksAccountUpsertWithoutReceived_TransactionsInput = {
  update: HtnBucksAccountUpdateWithoutReceived_TransactionsInput;
  create: HtnBucksAccountCreateWithoutReceived_TransactionsInput;
  where?: Maybe<HtnBucksAccountWhereInput>;
};

export type HtnBucksAccountUpsertWithoutSent_TransactionsInput = {
  update: HtnBucksAccountUpdateWithoutSent_TransactionsInput;
  create: HtnBucksAccountCreateWithoutSent_TransactionsInput;
  where?: Maybe<HtnBucksAccountWhereInput>;
};

export type HtnBucksAccountUpsertWithoutUserInput = {
  update: HtnBucksAccountUpdateWithoutUserInput;
  create: HtnBucksAccountCreateWithoutUserInput;
  where?: Maybe<HtnBucksAccountWhereInput>;
};

export type HtnBucksAccountWhereInput = {
  AND?: Maybe<Array<HtnBucksAccountWhereInput>>;
  OR?: Maybe<Array<HtnBucksAccountWhereInput>>;
  NOT?: Maybe<Array<HtnBucksAccountWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  slack_email_address?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  sent_transactions?: Maybe<HtnBucksTransactionListRelationFilter>;
  received_transactions?: Maybe<HtnBucksTransactionListRelationFilter>;
};

export type HtnBucksAccountWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slack_email_address?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<HtnBucksAccountWhereInput>>;
  OR?: Maybe<Array<HtnBucksAccountWhereInput>>;
  NOT?: Maybe<Array<HtnBucksAccountWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  sent_transactions?: Maybe<HtnBucksTransactionListRelationFilter>;
  received_transactions?: Maybe<HtnBucksTransactionListRelationFilter>;
};

export type HtnBucksTransaction = {
  __typename?: "HtnBucksTransaction";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  sender_id: Scalars["Int"];
  receiver_id: Scalars["Int"];
  amount: Scalars["Int"];
  message_contents?: Maybe<Scalars["String"]>;
  slack_message_link?: Maybe<Scalars["String"]>;
  sender: HtnBucksAccount;
  receiver: HtnBucksAccount;
};

export type HtnBucksTransactionCreateManyReceiverInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  sender_id: Scalars["Int"];
  amount: Scalars["Int"];
  message_contents?: Maybe<Scalars["String"]>;
  slack_message_link?: Maybe<Scalars["String"]>;
};

export type HtnBucksTransactionCreateManyReceiverInputEnvelope = {
  data: Array<HtnBucksTransactionCreateManyReceiverInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HtnBucksTransactionCreateManySenderInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  receiver_id: Scalars["Int"];
  amount: Scalars["Int"];
  message_contents?: Maybe<Scalars["String"]>;
  slack_message_link?: Maybe<Scalars["String"]>;
};

export type HtnBucksTransactionCreateManySenderInputEnvelope = {
  data: Array<HtnBucksTransactionCreateManySenderInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type HtnBucksTransactionCreateNestedManyWithoutReceiverInput = {
  create?: Maybe<Array<HtnBucksTransactionCreateWithoutReceiverInput>>;
  connectOrCreate?: Maybe<
    Array<HtnBucksTransactionCreateOrConnectWithoutReceiverInput>
  >;
  createMany?: Maybe<HtnBucksTransactionCreateManyReceiverInputEnvelope>;
  connect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
};

export type HtnBucksTransactionCreateNestedManyWithoutSenderInput = {
  create?: Maybe<Array<HtnBucksTransactionCreateWithoutSenderInput>>;
  connectOrCreate?: Maybe<
    Array<HtnBucksTransactionCreateOrConnectWithoutSenderInput>
  >;
  createMany?: Maybe<HtnBucksTransactionCreateManySenderInputEnvelope>;
  connect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
};

export type HtnBucksTransactionCreateOrConnectWithoutReceiverInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  create: HtnBucksTransactionCreateWithoutReceiverInput;
};

export type HtnBucksTransactionCreateOrConnectWithoutSenderInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  create: HtnBucksTransactionCreateWithoutSenderInput;
};

export type HtnBucksTransactionCreateWithoutReceiverInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  amount: Scalars["Int"];
  message_contents?: Maybe<Scalars["String"]>;
  slack_message_link?: Maybe<Scalars["String"]>;
  sender: HtnBucksAccountCreateNestedOneWithoutSent_TransactionsInput;
};

export type HtnBucksTransactionCreateWithoutSenderInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  amount: Scalars["Int"];
  message_contents?: Maybe<Scalars["String"]>;
  slack_message_link?: Maybe<Scalars["String"]>;
  receiver: HtnBucksAccountCreateNestedOneWithoutReceived_TransactionsInput;
};

export type HtnBucksTransactionListRelationFilter = {
  every?: Maybe<HtnBucksTransactionWhereInput>;
  some?: Maybe<HtnBucksTransactionWhereInput>;
  none?: Maybe<HtnBucksTransactionWhereInput>;
};

export type HtnBucksTransactionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum HtnBucksTransactionOrderByRelevanceFieldEnum {
  MessageContents = "message_contents",
  SlackMessageLink = "slack_message_link",
}

export type HtnBucksTransactionOrderByRelevanceInput = {
  fields: Array<HtnBucksTransactionOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type HtnBucksTransactionOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  sender_id?: Maybe<SortOrder>;
  receiver_id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  message_contents?: Maybe<SortOrderInput>;
  slack_message_link?: Maybe<SortOrderInput>;
  sender?: Maybe<HtnBucksAccountOrderByWithRelationAndSearchRelevanceInput>;
  receiver?: Maybe<HtnBucksAccountOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<HtnBucksTransactionOrderByRelevanceInput>;
};

export enum HtnBucksTransactionScalarFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  SenderId = "sender_id",
  ReceiverId = "receiver_id",
  Amount = "amount",
  MessageContents = "message_contents",
  SlackMessageLink = "slack_message_link",
}

export type HtnBucksTransactionScalarWhereInput = {
  AND?: Maybe<Array<HtnBucksTransactionScalarWhereInput>>;
  OR?: Maybe<Array<HtnBucksTransactionScalarWhereInput>>;
  NOT?: Maybe<Array<HtnBucksTransactionScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  sender_id?: Maybe<IntFilter>;
  receiver_id?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  message_contents?: Maybe<StringNullableFilter>;
  slack_message_link?: Maybe<StringNullableFilter>;
};

export type HtnBucksTransactionUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  message_contents?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slack_message_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type HtnBucksTransactionUpdateManyWithWhereWithoutReceiverInput = {
  where: HtnBucksTransactionScalarWhereInput;
  data: HtnBucksTransactionUpdateManyMutationInput;
};

export type HtnBucksTransactionUpdateManyWithWhereWithoutSenderInput = {
  where: HtnBucksTransactionScalarWhereInput;
  data: HtnBucksTransactionUpdateManyMutationInput;
};

export type HtnBucksTransactionUpdateManyWithoutReceiverNestedInput = {
  create?: Maybe<Array<HtnBucksTransactionCreateWithoutReceiverInput>>;
  connectOrCreate?: Maybe<
    Array<HtnBucksTransactionCreateOrConnectWithoutReceiverInput>
  >;
  upsert?: Maybe<
    Array<HtnBucksTransactionUpsertWithWhereUniqueWithoutReceiverInput>
  >;
  createMany?: Maybe<HtnBucksTransactionCreateManyReceiverInputEnvelope>;
  set?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<HtnBucksTransactionUpdateWithWhereUniqueWithoutReceiverInput>
  >;
  updateMany?: Maybe<
    Array<HtnBucksTransactionUpdateManyWithWhereWithoutReceiverInput>
  >;
  deleteMany?: Maybe<Array<HtnBucksTransactionScalarWhereInput>>;
};

export type HtnBucksTransactionUpdateManyWithoutSenderNestedInput = {
  create?: Maybe<Array<HtnBucksTransactionCreateWithoutSenderInput>>;
  connectOrCreate?: Maybe<
    Array<HtnBucksTransactionCreateOrConnectWithoutSenderInput>
  >;
  upsert?: Maybe<
    Array<HtnBucksTransactionUpsertWithWhereUniqueWithoutSenderInput>
  >;
  createMany?: Maybe<HtnBucksTransactionCreateManySenderInputEnvelope>;
  set?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  disconnect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  delete?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  connect?: Maybe<Array<HtnBucksTransactionWhereUniqueInput>>;
  update?: Maybe<
    Array<HtnBucksTransactionUpdateWithWhereUniqueWithoutSenderInput>
  >;
  updateMany?: Maybe<
    Array<HtnBucksTransactionUpdateManyWithWhereWithoutSenderInput>
  >;
  deleteMany?: Maybe<Array<HtnBucksTransactionScalarWhereInput>>;
};

export type HtnBucksTransactionUpdateWithWhereUniqueWithoutReceiverInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  data: HtnBucksTransactionUpdateWithoutReceiverInput;
};

export type HtnBucksTransactionUpdateWithWhereUniqueWithoutSenderInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  data: HtnBucksTransactionUpdateWithoutSenderInput;
};

export type HtnBucksTransactionUpdateWithoutReceiverInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  message_contents?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slack_message_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sender?: Maybe<HtnBucksAccountUpdateOneRequiredWithoutSent_TransactionsNestedInput>;
};

export type HtnBucksTransactionUpdateWithoutSenderInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<IntFieldUpdateOperationsInput>;
  message_contents?: Maybe<NullableStringFieldUpdateOperationsInput>;
  slack_message_link?: Maybe<NullableStringFieldUpdateOperationsInput>;
  receiver?: Maybe<HtnBucksAccountUpdateOneRequiredWithoutReceived_TransactionsNestedInput>;
};

export type HtnBucksTransactionUpsertWithWhereUniqueWithoutReceiverInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  update: HtnBucksTransactionUpdateWithoutReceiverInput;
  create: HtnBucksTransactionCreateWithoutReceiverInput;
};

export type HtnBucksTransactionUpsertWithWhereUniqueWithoutSenderInput = {
  where: HtnBucksTransactionWhereUniqueInput;
  update: HtnBucksTransactionUpdateWithoutSenderInput;
  create: HtnBucksTransactionCreateWithoutSenderInput;
};

export type HtnBucksTransactionWhereInput = {
  AND?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  OR?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  NOT?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  sender_id?: Maybe<IntFilter>;
  receiver_id?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  message_contents?: Maybe<StringNullableFilter>;
  slack_message_link?: Maybe<StringNullableFilter>;
  sender?: Maybe<HtnBucksAccountRelationFilter>;
  receiver?: Maybe<HtnBucksAccountRelationFilter>;
};

export type HtnBucksTransactionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  OR?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  NOT?: Maybe<Array<HtnBucksTransactionWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  sender_id?: Maybe<IntFilter>;
  receiver_id?: Maybe<IntFilter>;
  amount?: Maybe<IntFilter>;
  message_contents?: Maybe<StringNullableFilter>;
  slack_message_link?: Maybe<StringNullableFilter>;
  sender?: Maybe<HtnBucksAccountRelationFilter>;
  receiver?: Maybe<HtnBucksAccountRelationFilter>;
};

export enum InstitutionTypeEnum {
  HighSchool = "high_school",
  MiddleSchool = "middle_school",
  Other = "other",
  PostSecondary = "post_secondary",
}

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Int"]>;
  increment?: Maybe<Scalars["Int"]>;
  decrement?: Maybe<Scalars["Int"]>;
  multiply?: Maybe<Scalars["Int"]>;
  divide?: Maybe<Scalars["Int"]>;
};

export type IntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars["Int"]>>;
  has?: Maybe<Scalars["Int"]>;
  hasEvery?: Maybe<Array<Scalars["Int"]>>;
  hasSome?: Maybe<Array<Scalars["Int"]>>;
  isEmpty?: Maybe<Scalars["Boolean"]>;
};

export type JwtToken = {
  __typename?: "JWTToken";
  accessJwt: Scalars["String"];
  user: User;
};

export type JsonNullableFilter = {
  equals?: Maybe<Scalars["JSON"]>;
  path?: Maybe<Array<Scalars["String"]>>;
  string_contains?: Maybe<Scalars["String"]>;
  string_starts_with?: Maybe<Scalars["String"]>;
  string_ends_with?: Maybe<Scalars["String"]>;
  array_contains?: Maybe<Scalars["JSON"]>;
  array_starts_with?: Maybe<Scalars["JSON"]>;
  array_ends_with?: Maybe<Scalars["JSON"]>;
  lt?: Maybe<Scalars["JSON"]>;
  lte?: Maybe<Scalars["JSON"]>;
  gt?: Maybe<Scalars["JSON"]>;
  gte?: Maybe<Scalars["JSON"]>;
  not?: Maybe<Scalars["JSON"]>;
};

export type MailTemplateCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  html: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  slug: Scalars["String"];
  subject: Scalars["String"];
  trigger_id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MailTemplateCreateManyEventInputEnvelope = {
  data: Array<MailTemplateCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MailTemplateCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<MailTemplateCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MailTemplateCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<MailTemplateCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<MailTemplateWhereUniqueInput>>;
};

export type MailTemplateCreateOrConnectWithoutEventInput = {
  where: MailTemplateWhereUniqueInput;
  create: MailTemplateCreateWithoutEventInput;
};

export type MailTemplateCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description?: Maybe<Scalars["String"]>;
  html: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
  subject: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mail_trigger?: Maybe<MailTriggerCreateNestedOneWithoutMail_TemplatesInput>;
};

export type MailTemplateListRelationFilter = {
  every?: Maybe<MailTemplateWhereInput>;
  some?: Maybe<MailTemplateWhereInput>;
  none?: Maybe<MailTemplateWhereInput>;
};

export type MailTemplateNullableRelationFilter = {
  is?: Maybe<MailTemplateWhereInput>;
  isNot?: Maybe<MailTemplateWhereInput>;
};

export type MailTemplateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type MailTemplateScalarWhereInput = {
  AND?: Maybe<Array<MailTemplateScalarWhereInput>>;
  OR?: Maybe<Array<MailTemplateScalarWhereInput>>;
  NOT?: Maybe<Array<MailTemplateScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  event_id?: Maybe<IntFilter>;
  html?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  subject?: Maybe<StringFilter>;
  trigger_id?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type MailTemplateUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  html?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  subject?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MailTemplateUpdateManyWithWhereWithoutEventInput = {
  where: MailTemplateScalarWhereInput;
  data: MailTemplateUpdateManyMutationInput;
};

export type MailTemplateUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<MailTemplateCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MailTemplateCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<MailTemplateUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<MailTemplateCreateManyEventInputEnvelope>;
  set?: Maybe<Array<MailTemplateWhereUniqueInput>>;
  disconnect?: Maybe<Array<MailTemplateWhereUniqueInput>>;
  delete?: Maybe<Array<MailTemplateWhereUniqueInput>>;
  connect?: Maybe<Array<MailTemplateWhereUniqueInput>>;
  update?: Maybe<Array<MailTemplateUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<MailTemplateUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<MailTemplateScalarWhereInput>>;
};

export type MailTemplateUpdateWithWhereUniqueWithoutEventInput = {
  where: MailTemplateWhereUniqueInput;
  data: MailTemplateUpdateWithoutEventInput;
};

export type MailTemplateUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  html?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  subject?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mail_trigger?: Maybe<MailTriggerUpdateOneWithoutMail_TemplatesNestedInput>;
};

export type MailTemplateUpsertWithWhereUniqueWithoutEventInput = {
  where: MailTemplateWhereUniqueInput;
  update: MailTemplateUpdateWithoutEventInput;
  create: MailTemplateCreateWithoutEventInput;
};

export type MailTemplateWhereInput = {
  AND?: Maybe<Array<MailTemplateWhereInput>>;
  OR?: Maybe<Array<MailTemplateWhereInput>>;
  NOT?: Maybe<Array<MailTemplateWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  event_id?: Maybe<IntFilter>;
  html?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  subject?: Maybe<StringFilter>;
  trigger_id?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  mail_trigger?: Maybe<MailTriggerNullableRelationFilter>;
};

export type MailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  trigger_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<MailTemplateWhereInput>>;
  OR?: Maybe<Array<MailTemplateWhereInput>>;
  NOT?: Maybe<Array<MailTemplateWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  event_id?: Maybe<IntFilter>;
  html?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  subject?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  mail_trigger?: Maybe<MailTriggerNullableRelationFilter>;
};

export type MailTriggerCreateNestedOneWithoutMail_TemplatesInput = {
  create?: Maybe<MailTriggerCreateWithoutMail_TemplatesInput>;
  connectOrCreate?: Maybe<MailTriggerCreateOrConnectWithoutMail_TemplatesInput>;
  connect?: Maybe<MailTriggerWhereUniqueInput>;
};

export type MailTriggerCreateOrConnectWithoutMail_TemplatesInput = {
  where: MailTriggerWhereUniqueInput;
  create: MailTriggerCreateWithoutMail_TemplatesInput;
};

export type MailTriggerCreateWithoutMail_TemplatesInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  record_id: Scalars["Int"];
  record_type: RecordTypeEmail;
  trigger_type: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MailTriggerMail_Triggers_Record_Id_Record_Type_Trigger_Type_KeyCompoundUniqueInput =
  {
    record_id: Scalars["Int"];
    record_type: RecordTypeEmail;
    trigger_type: Scalars["String"];
  };

export type MailTriggerNullableRelationFilter = {
  is?: Maybe<MailTriggerWhereInput>;
  isNot?: Maybe<MailTriggerWhereInput>;
};

export type MailTriggerUpdateOneWithoutMail_TemplatesNestedInput = {
  create?: Maybe<MailTriggerCreateWithoutMail_TemplatesInput>;
  connectOrCreate?: Maybe<MailTriggerCreateOrConnectWithoutMail_TemplatesInput>;
  upsert?: Maybe<MailTriggerUpsertWithoutMail_TemplatesInput>;
  disconnect?: Maybe<MailTriggerWhereInput>;
  delete?: Maybe<MailTriggerWhereInput>;
  connect?: Maybe<MailTriggerWhereUniqueInput>;
  update?: Maybe<MailTriggerUpdateToOneWithWhereWithoutMail_TemplatesInput>;
};

export type MailTriggerUpdateToOneWithWhereWithoutMail_TemplatesInput = {
  where?: Maybe<MailTriggerWhereInput>;
  data: MailTriggerUpdateWithoutMail_TemplatesInput;
};

export type MailTriggerUpdateWithoutMail_TemplatesInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  record_id?: Maybe<IntFieldUpdateOperationsInput>;
  record_type?: Maybe<EnumRecordTypeEmailFieldUpdateOperationsInput>;
  trigger_type?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MailTriggerUpsertWithoutMail_TemplatesInput = {
  update: MailTriggerUpdateWithoutMail_TemplatesInput;
  create: MailTriggerCreateWithoutMail_TemplatesInput;
  where?: Maybe<MailTriggerWhereInput>;
};

export type MailTriggerWhereInput = {
  AND?: Maybe<Array<MailTriggerWhereInput>>;
  OR?: Maybe<Array<MailTriggerWhereInput>>;
  NOT?: Maybe<Array<MailTriggerWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  record_id?: Maybe<IntFilter>;
  record_type?: Maybe<EnumRecordTypeEmailFilter>;
  trigger_type?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  mail_templates?: Maybe<MailTemplateNullableRelationFilter>;
};

export type MailTriggerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mail_triggers_record_id_record_type_trigger_type_key?: Maybe<MailTriggerMail_Triggers_Record_Id_Record_Type_Trigger_Type_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<MailTriggerWhereInput>>;
  OR?: Maybe<Array<MailTriggerWhereInput>>;
  NOT?: Maybe<Array<MailTriggerWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  record_id?: Maybe<IntFilter>;
  record_type?: Maybe<EnumRecordTypeEmailFilter>;
  trigger_type?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  mail_templates?: Maybe<MailTemplateNullableRelationFilter>;
};

export type MailingList = {
  __typename?: "MailingList";
  created_at: Scalars["DateTimeISO"];
  email: Scalars["String"];
  event_slug: Scalars["String"];
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
};

export type MailingListCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MailingListCreateManyEventInputEnvelope = {
  data: Array<MailingListCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MailingListCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<MailingListCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MailingListCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<MailingListCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<MailingListWhereUniqueInput>>;
};

export type MailingListCreateOrConnectWithoutEventInput = {
  where: MailingListWhereUniqueInput;
  create: MailingListCreateWithoutEventInput;
};

export type MailingListCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MailingListListRelationFilter = {
  every?: Maybe<MailingListWhereInput>;
  some?: Maybe<MailingListWhereInput>;
  none?: Maybe<MailingListWhereInput>;
};

export type MailingListMailing_List_Event_Slug_Email_KeyCompoundUniqueInput = {
  event_slug: Scalars["String"];
  email: Scalars["String"];
};

export type MailingListOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum MailingListOrderByRelevanceFieldEnum {
  Email = "email",
  EventSlug = "event_slug",
  Name = "name",
}

export type MailingListOrderByRelevanceInput = {
  fields: Array<MailingListOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type MailingListOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  event_slug?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<MailingListOrderByRelevanceInput>;
};

export enum MailingListScalarFieldEnum {
  CreatedAt = "created_at",
  Email = "email",
  EventSlug = "event_slug",
  Id = "id",
  Name = "name",
  UpdatedAt = "updated_at",
}

export type MailingListScalarWhereInput = {
  AND?: Maybe<Array<MailingListScalarWhereInput>>;
  OR?: Maybe<Array<MailingListScalarWhereInput>>;
  NOT?: Maybe<Array<MailingListScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

/** mailing list signup response */
export type MailingListSignupReturnType = {
  __typename?: "MailingListSignupReturnType";
  email: Scalars["String"];
  alreadySignup?: Maybe<Scalars["Boolean"]>;
};

export type MailingListUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MailingListUpdateManyWithWhereWithoutEventInput = {
  where: MailingListScalarWhereInput;
  data: MailingListUpdateManyMutationInput;
};

export type MailingListUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<MailingListCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MailingListCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<MailingListUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<MailingListCreateManyEventInputEnvelope>;
  set?: Maybe<Array<MailingListWhereUniqueInput>>;
  disconnect?: Maybe<Array<MailingListWhereUniqueInput>>;
  delete?: Maybe<Array<MailingListWhereUniqueInput>>;
  connect?: Maybe<Array<MailingListWhereUniqueInput>>;
  update?: Maybe<Array<MailingListUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<MailingListUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<MailingListScalarWhereInput>>;
};

export type MailingListUpdateWithWhereUniqueWithoutEventInput = {
  where: MailingListWhereUniqueInput;
  data: MailingListUpdateWithoutEventInput;
};

export type MailingListUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MailingListUpsertWithWhereUniqueWithoutEventInput = {
  where: MailingListWhereUniqueInput;
  update: MailingListUpdateWithoutEventInput;
  create: MailingListCreateWithoutEventInput;
};

export type MailingListWhereInput = {
  AND?: Maybe<Array<MailingListWhereInput>>;
  OR?: Maybe<Array<MailingListWhereInput>>;
  NOT?: Maybe<Array<MailingListWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type MailingListWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mailing_list_event_slug_email_key?: Maybe<MailingListMailing_List_Event_Slug_Email_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<MailingListWhereInput>>;
  OR?: Maybe<Array<MailingListWhereInput>>;
  NOT?: Maybe<Array<MailingListWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  event_slug?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export enum MailingPreference {
  Subscribed = "subscribed",
  Unsubscribed = "unsubscribed",
}

export type MailingPreferenceBulkUnsubscribeResult = {
  __typename?: "MailingPreferenceBulkUnsubscribeResult";
  email: Scalars["String"];
  result: Scalars["String"];
};

export type MailingPreferences = {
  __typename?: "MailingPreferences";
  id: Scalars["Int"];
  email: Scalars["String"];
  preference: MailingPreference;
  updated_at: Scalars["DateTimeISO"];
  created_at: Scalars["DateTimeISO"];
};

export type MentorCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type MentorCreateManyEventInputEnvelope = {
  data: Array<MentorCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorCreateManyUserInputEnvelope = {
  data: Array<MentorCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<MentorCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<MentorCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
};

export type MentorCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<MentorCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<MentorCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
};

export type MentorCreateNestedOneWithoutMentor_RequestsInput = {
  create?: Maybe<MentorCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<MentorCreateOrConnectWithoutMentor_RequestsInput>;
  connect?: Maybe<MentorWhereUniqueInput>;
};

export type MentorCreateOrConnectWithoutEventInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutEventInput;
};

export type MentorCreateOrConnectWithoutMentor_RequestsInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutMentor_RequestsInput;
};

export type MentorCreateOrConnectWithoutUserInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutUserInput;
};

export type MentorCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutMentorInput>;
  user: UserCreateNestedOneWithoutMentorsInput;
};

export type MentorCreateWithoutMentor_RequestsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event: EventCreateNestedOneWithoutMentorsInput;
  user: UserCreateNestedOneWithoutMentorsInput;
};

export type MentorCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutMentorInput>;
  event: EventCreateNestedOneWithoutMentorsInput;
};

export type MentorListRelationFilter = {
  every?: Maybe<MentorWhereInput>;
  some?: Maybe<MentorWhereInput>;
  none?: Maybe<MentorWhereInput>;
};

export type MentorMentors_Event_Id_User_Id_KeyCompoundUniqueInput = {
  event_id: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type MentorNullableRelationFilter = {
  is?: Maybe<MentorWhereInput>;
  isNot?: Maybe<MentorWhereInput>;
};

export type MentorOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type MentorRequestCommentCreateManyMentor_RequestInput = {
  author_id: Scalars["Int"];
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorRequestCommentCreateManyMentor_RequestInputEnvelope = {
  data: Array<MentorRequestCommentCreateManyMentor_RequestInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorRequestCommentCreateManyUserInput = {
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  request_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorRequestCommentCreateManyUserInputEnvelope = {
  data: Array<MentorRequestCommentCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorRequestCommentCreateNestedManyWithoutMentor_RequestInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutMentor_RequestInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutMentor_RequestInput>
  >;
  createMany?: Maybe<MentorRequestCommentCreateManyMentor_RequestInputEnvelope>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
};

export type MentorRequestCommentCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<MentorRequestCommentCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
};

export type MentorRequestCommentCreateOrConnectWithoutMentor_RequestInput = {
  where: MentorRequestCommentWhereUniqueInput;
  create: MentorRequestCommentCreateWithoutMentor_RequestInput;
};

export type MentorRequestCommentCreateOrConnectWithoutUserInput = {
  where: MentorRequestCommentWhereUniqueInput;
  create: MentorRequestCommentCreateWithoutUserInput;
};

export type MentorRequestCommentCreateWithoutMentor_RequestInput = {
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCommentCreateWithoutUserInput = {
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_request: MentorRequestCreateNestedOneWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCommentListRelationFilter = {
  every?: Maybe<MentorRequestCommentWhereInput>;
  some?: Maybe<MentorRequestCommentWhereInput>;
  none?: Maybe<MentorRequestCommentWhereInput>;
};

export type MentorRequestCommentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type MentorRequestCommentScalarWhereInput = {
  AND?: Maybe<Array<MentorRequestCommentScalarWhereInput>>;
  OR?: Maybe<Array<MentorRequestCommentScalarWhereInput>>;
  NOT?: Maybe<Array<MentorRequestCommentScalarWhereInput>>;
  author_id?: Maybe<IntFilter>;
  body?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type MentorRequestCommentUpdateManyMutationInput = {
  body?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MentorRequestCommentUpdateManyWithWhereWithoutMentor_RequestInput =
  {
    where: MentorRequestCommentScalarWhereInput;
    data: MentorRequestCommentUpdateManyMutationInput;
  };

export type MentorRequestCommentUpdateManyWithWhereWithoutUserInput = {
  where: MentorRequestCommentScalarWhereInput;
  data: MentorRequestCommentUpdateManyMutationInput;
};

export type MentorRequestCommentUpdateManyWithoutMentor_RequestNestedInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutMentor_RequestInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutMentor_RequestInput>
  >;
  upsert?: Maybe<
    Array<MentorRequestCommentUpsertWithWhereUniqueWithoutMentor_RequestInput>
  >;
  createMany?: Maybe<MentorRequestCommentCreateManyMentor_RequestInputEnvelope>;
  set?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  delete?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  update?: Maybe<
    Array<MentorRequestCommentUpdateWithWhereUniqueWithoutMentor_RequestInput>
  >;
  updateMany?: Maybe<
    Array<MentorRequestCommentUpdateManyWithWhereWithoutMentor_RequestInput>
  >;
  deleteMany?: Maybe<Array<MentorRequestCommentScalarWhereInput>>;
};

export type MentorRequestCommentUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<
    Array<MentorRequestCommentUpsertWithWhereUniqueWithoutUserInput>
  >;
  createMany?: Maybe<MentorRequestCommentCreateManyUserInputEnvelope>;
  set?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  delete?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  update?: Maybe<
    Array<MentorRequestCommentUpdateWithWhereUniqueWithoutUserInput>
  >;
  updateMany?: Maybe<
    Array<MentorRequestCommentUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<MentorRequestCommentScalarWhereInput>>;
};

export type MentorRequestCommentUpdateWithWhereUniqueWithoutMentor_RequestInput =
  {
    where: MentorRequestCommentWhereUniqueInput;
    data: MentorRequestCommentUpdateWithoutMentor_RequestInput;
  };

export type MentorRequestCommentUpdateWithWhereUniqueWithoutUserInput = {
  where: MentorRequestCommentWhereUniqueInput;
  data: MentorRequestCommentUpdateWithoutUserInput;
};

export type MentorRequestCommentUpdateWithoutMentor_RequestInput = {
  body?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentor_Request_CommentsNestedInput>;
};

export type MentorRequestCommentUpdateWithoutUserInput = {
  body?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_request?: Maybe<MentorRequestUpdateOneRequiredWithoutMentor_Request_CommentsNestedInput>;
};

export type MentorRequestCommentUpsertWithWhereUniqueWithoutMentor_RequestInput =
  {
    where: MentorRequestCommentWhereUniqueInput;
    update: MentorRequestCommentUpdateWithoutMentor_RequestInput;
    create: MentorRequestCommentCreateWithoutMentor_RequestInput;
  };

export type MentorRequestCommentUpsertWithWhereUniqueWithoutUserInput = {
  where: MentorRequestCommentWhereUniqueInput;
  update: MentorRequestCommentUpdateWithoutUserInput;
  create: MentorRequestCommentCreateWithoutUserInput;
};

export type MentorRequestCommentWhereInput = {
  AND?: Maybe<Array<MentorRequestCommentWhereInput>>;
  OR?: Maybe<Array<MentorRequestCommentWhereInput>>;
  NOT?: Maybe<Array<MentorRequestCommentWhereInput>>;
  author_id?: Maybe<IntFilter>;
  body?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor_request?: Maybe<MentorRequestRelationFilter>;
};

export type MentorRequestCommentWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<MentorRequestCommentWhereInput>>;
  OR?: Maybe<Array<MentorRequestCommentWhereInput>>;
  NOT?: Maybe<Array<MentorRequestCommentWhereInput>>;
  author_id?: Maybe<IntFilter>;
  body?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  request_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor_request?: Maybe<MentorRequestRelationFilter>;
};

export type MentorRequestCreateManyEventInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  hacker_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  mentor_id?: Maybe<Scalars["Int"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorRequestCreateManyEventInputEnvelope = {
  data: Array<MentorRequestCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorRequestCreateManyMentorInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_id: Scalars["Int"];
  hacker_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorRequestCreateManyMentorInputEnvelope = {
  data: Array<MentorRequestCreateManyMentorInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorRequestCreateManyUserInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_id: Scalars["Int"];
  id?: Maybe<Scalars["Int"]>;
  mentor_id?: Maybe<Scalars["Int"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type MentorRequestCreateManyUserInputEnvelope = {
  data: Array<MentorRequestCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type MentorRequestCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<MentorRequestCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
};

export type MentorRequestCreateNestedManyWithoutMentorInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutMentorInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCreateOrConnectWithoutMentorInput>
  >;
  createMany?: Maybe<MentorRequestCreateManyMentorInputEnvelope>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
};

export type MentorRequestCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<MentorRequestCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
};

export type MentorRequestCreateNestedOneWithoutMentor_Request_CommentsInput = {
  create?: Maybe<MentorRequestCreateWithoutMentor_Request_CommentsInput>;
  connectOrCreate?: Maybe<MentorRequestCreateOrConnectWithoutMentor_Request_CommentsInput>;
  connect?: Maybe<MentorRequestWhereUniqueInput>;
};

export type MentorRequestCreateOrConnectWithoutEventInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutEventInput;
};

export type MentorRequestCreateOrConnectWithoutMentorInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutMentorInput;
};

export type MentorRequestCreateOrConnectWithoutMentor_Request_CommentsInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCreateOrConnectWithoutUserInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutUserInput;
};

export type MentorRequestCreateWithoutEventInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutMentor_RequestInput>;
  user: UserCreateNestedOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateNestedOneWithoutMentor_RequestsInput>;
};

export type MentorRequestCreateWithoutMentorInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutMentor_RequestInput>;
  event: EventCreateNestedOneWithoutMentor_RequestsInput;
  user: UserCreateNestedOneWithoutMentor_RequestsInput;
};

export type MentorRequestCreateWithoutMentor_Request_CommentsInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event: EventCreateNestedOneWithoutMentor_RequestsInput;
  user: UserCreateNestedOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateNestedOneWithoutMentor_RequestsInput>;
};

export type MentorRequestCreateWithoutUserInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutMentor_RequestInput>;
  event: EventCreateNestedOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateNestedOneWithoutMentor_RequestsInput>;
};

export type MentorRequestListRelationFilter = {
  every?: Maybe<MentorRequestWhereInput>;
  some?: Maybe<MentorRequestWhereInput>;
  none?: Maybe<MentorRequestWhereInput>;
};

export type MentorRequestOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type MentorRequestRelationFilter = {
  is?: Maybe<MentorRequestWhereInput>;
  isNot?: Maybe<MentorRequestWhereInput>;
};

export type MentorRequestScalarWhereInput = {
  AND?: Maybe<Array<MentorRequestScalarWhereInput>>;
  OR?: Maybe<Array<MentorRequestScalarWhereInput>>;
  NOT?: Maybe<Array<MentorRequestScalarWhereInput>>;
  anonymous?: Maybe<BoolFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  hacker_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  mentor_id?: Maybe<IntNullableFilter>;
  request?: Maybe<StringFilter>;
  resolved?: Maybe<BoolFilter>;
  tags?: Maybe<JsonNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type MentorRequestUpdateManyMutationInput = {
  anonymous?: Maybe<BoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  request?: Maybe<StringFieldUpdateOperationsInput>;
  resolved?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<Scalars["JSON"]>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MentorRequestUpdateManyWithWhereWithoutEventInput = {
  where: MentorRequestScalarWhereInput;
  data: MentorRequestUpdateManyMutationInput;
};

export type MentorRequestUpdateManyWithWhereWithoutMentorInput = {
  where: MentorRequestScalarWhereInput;
  data: MentorRequestUpdateManyMutationInput;
};

export type MentorRequestUpdateManyWithWhereWithoutUserInput = {
  where: MentorRequestScalarWhereInput;
  data: MentorRequestUpdateManyMutationInput;
};

export type MentorRequestUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<MentorRequestUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<MentorRequestCreateManyEventInputEnvelope>;
  set?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  delete?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  update?: Maybe<Array<MentorRequestUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<MentorRequestUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<MentorRequestScalarWhereInput>>;
};

export type MentorRequestUpdateManyWithoutMentorNestedInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutMentorInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCreateOrConnectWithoutMentorInput>
  >;
  upsert?: Maybe<Array<MentorRequestUpsertWithWhereUniqueWithoutMentorInput>>;
  createMany?: Maybe<MentorRequestCreateManyMentorInputEnvelope>;
  set?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  delete?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  update?: Maybe<Array<MentorRequestUpdateWithWhereUniqueWithoutMentorInput>>;
  updateMany?: Maybe<Array<MentorRequestUpdateManyWithWhereWithoutMentorInput>>;
  deleteMany?: Maybe<Array<MentorRequestScalarWhereInput>>;
};

export type MentorRequestUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<MentorRequestUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<MentorRequestCreateManyUserInputEnvelope>;
  set?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  delete?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  update?: Maybe<Array<MentorRequestUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<MentorRequestUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<MentorRequestScalarWhereInput>>;
};

export type MentorRequestUpdateOneRequiredWithoutMentor_Request_CommentsNestedInput =
  {
    create?: Maybe<MentorRequestCreateWithoutMentor_Request_CommentsInput>;
    connectOrCreate?: Maybe<MentorRequestCreateOrConnectWithoutMentor_Request_CommentsInput>;
    upsert?: Maybe<MentorRequestUpsertWithoutMentor_Request_CommentsInput>;
    connect?: Maybe<MentorRequestWhereUniqueInput>;
    update?: Maybe<MentorRequestUpdateToOneWithWhereWithoutMentor_Request_CommentsInput>;
  };

export type MentorRequestUpdateToOneWithWhereWithoutMentor_Request_CommentsInput =
  {
    where?: Maybe<MentorRequestWhereInput>;
    data: MentorRequestUpdateWithoutMentor_Request_CommentsInput;
  };

export type MentorRequestUpdateWithWhereUniqueWithoutEventInput = {
  where: MentorRequestWhereUniqueInput;
  data: MentorRequestUpdateWithoutEventInput;
};

export type MentorRequestUpdateWithWhereUniqueWithoutMentorInput = {
  where: MentorRequestWhereUniqueInput;
  data: MentorRequestUpdateWithoutMentorInput;
};

export type MentorRequestUpdateWithWhereUniqueWithoutUserInput = {
  where: MentorRequestWhereUniqueInput;
  data: MentorRequestUpdateWithoutUserInput;
};

export type MentorRequestUpdateWithoutEventInput = {
  anonymous?: Maybe<BoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  request?: Maybe<StringFieldUpdateOperationsInput>;
  resolved?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<Scalars["JSON"]>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutMentor_RequestNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
  mentor?: Maybe<MentorUpdateOneWithoutMentor_RequestsNestedInput>;
};

export type MentorRequestUpdateWithoutMentorInput = {
  anonymous?: Maybe<BoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  request?: Maybe<StringFieldUpdateOperationsInput>;
  resolved?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<Scalars["JSON"]>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutMentor_RequestNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
};

export type MentorRequestUpdateWithoutMentor_Request_CommentsInput = {
  anonymous?: Maybe<BoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  request?: Maybe<StringFieldUpdateOperationsInput>;
  resolved?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<Scalars["JSON"]>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
  mentor?: Maybe<MentorUpdateOneWithoutMentor_RequestsNestedInput>;
};

export type MentorRequestUpdateWithoutUserInput = {
  anonymous?: Maybe<BoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  request?: Maybe<StringFieldUpdateOperationsInput>;
  resolved?: Maybe<BoolFieldUpdateOperationsInput>;
  tags?: Maybe<Scalars["JSON"]>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutMentor_RequestNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutMentor_RequestsNestedInput>;
  mentor?: Maybe<MentorUpdateOneWithoutMentor_RequestsNestedInput>;
};

export type MentorRequestUpsertWithWhereUniqueWithoutEventInput = {
  where: MentorRequestWhereUniqueInput;
  update: MentorRequestUpdateWithoutEventInput;
  create: MentorRequestCreateWithoutEventInput;
};

export type MentorRequestUpsertWithWhereUniqueWithoutMentorInput = {
  where: MentorRequestWhereUniqueInput;
  update: MentorRequestUpdateWithoutMentorInput;
  create: MentorRequestCreateWithoutMentorInput;
};

export type MentorRequestUpsertWithWhereUniqueWithoutUserInput = {
  where: MentorRequestWhereUniqueInput;
  update: MentorRequestUpdateWithoutUserInput;
  create: MentorRequestCreateWithoutUserInput;
};

export type MentorRequestUpsertWithoutMentor_Request_CommentsInput = {
  update: MentorRequestUpdateWithoutMentor_Request_CommentsInput;
  create: MentorRequestCreateWithoutMentor_Request_CommentsInput;
  where?: Maybe<MentorRequestWhereInput>;
};

export type MentorRequestWhereInput = {
  AND?: Maybe<Array<MentorRequestWhereInput>>;
  OR?: Maybe<Array<MentorRequestWhereInput>>;
  NOT?: Maybe<Array<MentorRequestWhereInput>>;
  anonymous?: Maybe<BoolFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  hacker_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  mentor_id?: Maybe<IntNullableFilter>;
  request?: Maybe<StringFilter>;
  resolved?: Maybe<BoolFilter>;
  tags?: Maybe<JsonNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor?: Maybe<MentorNullableRelationFilter>;
};

export type MentorRequestWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<MentorRequestWhereInput>>;
  OR?: Maybe<Array<MentorRequestWhereInput>>;
  NOT?: Maybe<Array<MentorRequestWhereInput>>;
  anonymous?: Maybe<BoolFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  hacker_id?: Maybe<IntFilter>;
  mentor_id?: Maybe<IntNullableFilter>;
  request?: Maybe<StringFilter>;
  resolved?: Maybe<BoolFilter>;
  tags?: Maybe<JsonNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor?: Maybe<MentorNullableRelationFilter>;
};

export type MentorScalarWhereInput = {
  AND?: Maybe<Array<MentorScalarWhereInput>>;
  OR?: Maybe<Array<MentorScalarWhereInput>>;
  NOT?: Maybe<Array<MentorScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  skills?: Maybe<JsonNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type MentorUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type MentorUpdateManyWithWhereWithoutEventInput = {
  where: MentorScalarWhereInput;
  data: MentorUpdateManyMutationInput;
};

export type MentorUpdateManyWithWhereWithoutUserInput = {
  where: MentorScalarWhereInput;
  data: MentorUpdateManyMutationInput;
};

export type MentorUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<MentorCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<MentorUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<MentorCreateManyEventInputEnvelope>;
  set?: Maybe<Array<MentorWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorWhereUniqueInput>>;
  delete?: Maybe<Array<MentorWhereUniqueInput>>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
  update?: Maybe<Array<MentorUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<MentorUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<MentorScalarWhereInput>>;
};

export type MentorUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<MentorCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<MentorUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<MentorCreateManyUserInputEnvelope>;
  set?: Maybe<Array<MentorWhereUniqueInput>>;
  disconnect?: Maybe<Array<MentorWhereUniqueInput>>;
  delete?: Maybe<Array<MentorWhereUniqueInput>>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
  update?: Maybe<Array<MentorUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<MentorUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<MentorScalarWhereInput>>;
};

export type MentorUpdateOneWithoutMentor_RequestsNestedInput = {
  create?: Maybe<MentorCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<MentorCreateOrConnectWithoutMentor_RequestsInput>;
  upsert?: Maybe<MentorUpsertWithoutMentor_RequestsInput>;
  disconnect?: Maybe<MentorWhereInput>;
  delete?: Maybe<MentorWhereInput>;
  connect?: Maybe<MentorWhereUniqueInput>;
  update?: Maybe<MentorUpdateToOneWithWhereWithoutMentor_RequestsInput>;
};

export type MentorUpdateToOneWithWhereWithoutMentor_RequestsInput = {
  where?: Maybe<MentorWhereInput>;
  data: MentorUpdateWithoutMentor_RequestsInput;
};

export type MentorUpdateWithWhereUniqueWithoutEventInput = {
  where: MentorWhereUniqueInput;
  data: MentorUpdateWithoutEventInput;
};

export type MentorUpdateWithWhereUniqueWithoutUserInput = {
  where: MentorWhereUniqueInput;
  data: MentorUpdateWithoutUserInput;
};

export type MentorUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutMentorNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentorsNestedInput>;
};

export type MentorUpdateWithoutMentor_RequestsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutMentorsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMentorsNestedInput>;
};

export type MentorUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutMentorNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutMentorsNestedInput>;
};

export type MentorUpsertWithWhereUniqueWithoutEventInput = {
  where: MentorWhereUniqueInput;
  update: MentorUpdateWithoutEventInput;
  create: MentorCreateWithoutEventInput;
};

export type MentorUpsertWithWhereUniqueWithoutUserInput = {
  where: MentorWhereUniqueInput;
  update: MentorUpdateWithoutUserInput;
  create: MentorCreateWithoutUserInput;
};

export type MentorUpsertWithoutMentor_RequestsInput = {
  update: MentorUpdateWithoutMentor_RequestsInput;
  create: MentorCreateWithoutMentor_RequestsInput;
  where?: Maybe<MentorWhereInput>;
};

export type MentorWhereInput = {
  AND?: Maybe<Array<MentorWhereInput>>;
  OR?: Maybe<Array<MentorWhereInput>>;
  NOT?: Maybe<Array<MentorWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  skills?: Maybe<JsonNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type MentorWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mentors_event_id_user_id_key?: Maybe<MentorMentors_Event_Id_User_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<MentorWhereInput>>;
  OR?: Maybe<Array<MentorWhereInput>>;
  NOT?: Maybe<Array<MentorWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  skills?: Maybe<JsonNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type Mutation = {
  __typename?: "Mutation";
  createVolunteer: Array<Volunteer>;
  updateVolunteer: Array<Volunteer>;
  deleteVolunteer: Array<VolunteerCategory>;
  createVolunteerCategory: Array<VolunteerCategory>;
  updateVolunteerCategory: Array<VolunteerCategory>;
  updateUserEmail: User;
  createUser: User;
  createNonHackerUserAndEventAttendee: User;
  deleteUser: Success;
  deleteUserByEmail: Success;
  generateRandomPassword: Success;
  updatePlatformId: User;
  updateStage: Stage;
  createStage: Stage;
  createSponsorIndividual: JwtToken;
  createSponsorIndividual_Organizer: JwtToken;
  loginSponsorIndividual: JwtToken;
  updateSponsorIndividual: SponsorIndividual;
  deleteSponsorIndividual: Success;
  createSponsorCompany: Scalars["String"];
  updateSponsorCompany: SponsorCompany;
  deleteSponsorCompany: Success;
  sendSlackReviewMessage: Scalars["Boolean"];
  /** Create a signage question */
  createSignageQuestion: SignageQuestion;
  /** Deletes the signage question for the given id and its question answers. This should be used by organizers only. */
  deleteSignageQuestionUNSAFE: SignageQuestion;
  /** Deletes a signage answer for the responder */
  deleteSignageAnswer: SignageAnswer;
  /** Creates a new signage answer or updates an existing one if an answer already exists. Caller must be authenticated. */
  answerSignageQuestion: SignageAnswer;
  /** Recalculates and updates the counter for total responses submitted across all questions. */
  recalculateResponsesSubmitted: Scalars["String"];
  /** Recalculates and updates the counter for checked-in attendees. */
  recalculatecheckedInAttendees: Scalars["String"];
  /** Recalculates and updates the counter for participants who have interacted with any signage question. */
  recalculateParticipatingHackers: Scalars["String"];
  /** Recalculates and updates graph statistics for engagement over time. */
  recalculateGraphStats: Scalars["String"];
  processAirtable: Success;
  addScheduleItem: ScheduleItem;
  updateScheduleItem: ScheduleItem;
  deleteScheduleItem: ScheduleItem;
  createReview: Review;
  updateReview: Review;
  deleteReview: Review;
  updatePipeline: Pipeline;
  createPipeline: Pipeline;
  setUnsubscribedMailingPreference: MailingPreferences;
  setBulkUnsubscribedMailingPreference: Array<MailingPreferenceBulkUnsubscribeResult>;
  setSubscribedMailingPreference: MailingPreferences;
  removeFromMailingList: Array<MailingList>;
  addToMailingList: MailingListSignupReturnType;
  createInstitution: AcademicInstitution;
  deleteInstitution: AcademicInstitution;
  /** Reset HTN Bucks transactions */
  deleteHtnBucksTransactions: Success;
  /** Sends HTN Bucks from one user to a list of users */
  giftHtnBucks: Success;
  createHardwareSku: HardwareSku;
  updateHardwareSku: HardwareSku;
  /** Unsafe operation that deletes hardware SKU regardless if there's items associated with it. Associated items will be deleted as well. */
  unsafeDeleteHardwareSku: HardwareSku;
  /** Organizer only endpoint to create a hardware request. Use for edge cases. */
  createHardwareRequestByOrganizer: HardwareRequest;
  /** Organizer only endpoint to update a hardware request. Use for edge cases. */
  updateHardwareRequestByOrganizer: HardwareRequest;
  /** Endpoint for hackers to create new requests. */
  createHardwareRequestByHacker: HardwareRequest;
  updateHardwareRequestByHacker: HardwareRequest;
  approveHackerHardwareRequest: HardwareRequest;
  /** Organizer endpoint to return the hardware from a hardware request. */
  returnHackerHardwareRequest: HardwareRequest;
  /** Use in case of partial return. Request not marked as returned, but the return of individual items is noted. */
  markSpecificItemsAsReturned: Success;
  /** Organizer endpoint to change the status of a hardware request. */
  changeHardwareStatus: HardwareRequest;
  createHardwareItem: HardwareItem;
  updateHardwareItem: HardwareItem;
  deleteHardwareItem: HardwareItem;
  createHardwareCategory: HardwareCategory;
  updateHardwareCategory: HardwareCategory;
  deleteHardwareCategory: HardwareCategory;
  /** URL to a csv which seeds hardware info.  */
  seedHardwareInfo: Success;
  deleteClaim: Success;
  deleteClaims: Success;
  createClaim: Claim;
  createSponsorPerk: Success;
  updateClaim: Claim;
  moveClaims: Array<Claim>;
  upload: File;
  createField: Field;
  updateField: Field;
  deleteField: Field;
  deleteFieldUNSAFE: Field;
  deleteFieldAnswer: FieldAnswer;
  upsertExtendedProfile: Claim;
  createEvent: Event;
  updateEvent: Event;
  deleteEvent: Success;
  createEventUpdate: EventUpdateEntry;
  createEventAttendee: EventAttendee;
  deleteEventAttendee: EventAttendee;
  registerBadgeCodeToEventAttendee: EventAttendee;
  redeemEventActivityScan: BadgeScan;
  redeemEventActivity: EventActivityRedemption;
  createEventActivity: EventActivity;
  updateEventActivity: EventActivity;
  deleteEventActivity: EventActivity;
  createEmail: Email;
  confirmSent: Array<Email>;
  deleteEmails: Array<Email>;
  createCompany: Company;
  updateCompany: Company;
  deleteCompany: Company;
  createCollaborator: Collaborator;
  deleteCollaborator: Collaborator;
  createBus: Bus;
  /** Update a bus by id, specifying only fields that have changed. */
  updateBus: Bus;
  deleteBusById: Success;
  deleteBusByName: Success;
  addUserToBus: BusAttendee;
  removeUserFromBus: Bus;
  createBusStop: Bus;
  /** Update a bus stop by id, specifying only fields that have changed. */
  updateBusStop: BusStop;
  deleteBusStop: Success;
  registerSelfToBus: BusAttendee;
  unregisterSelfFromBus: Bus;
  handleNetworkingScan: NetworkingScanResponse;
  loginWithPassword: AuthResponse;
  loginWithJWT: AuthResponse;
  loginWithGoogleAuthCode: AuthResponse;
  refreshTokens: AuthResponse;
  getB64EncodedServiceToken: Scalars["String"];
  impersonateUser: JwtToken;
  resetPassword: UpdatePasswordSuccess;
  inviteToResetPassword: UpdatePasswordSuccess;
  changePassword: UpdatePasswordSuccess;
};

export type MutationCreateVolunteerArgs = {
  data: VolunteerCreateInput;
};

export type MutationUpdateVolunteerArgs = {
  data: VolunteerUpdateInput;
  where: VolunteerWhereInput;
};

export type MutationDeleteVolunteerArgs = {
  id: Scalars["Int"];
};

export type MutationCreateVolunteerCategoryArgs = {
  data: VolunteerCategoryCreateInput;
};

export type MutationUpdateVolunteerCategoryArgs = {
  data: VolunteerCategoryUpdateInput;
  where: VolunteerCategoryWhereInput;
};

export type MutationUpdateUserEmailArgs = {
  email: Scalars["String"];
  id: Scalars["Float"];
};

export type MutationCreateUserArgs = {
  password: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationCreateNonHackerUserAndEventAttendeeArgs = {
  password?: Maybe<Scalars["String"]>;
  eventSlug?: Scalars["String"];
  roles: Array<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Float"];
};

export type MutationDeleteUserByEmailArgs = {
  email: Scalars["String"];
};

export type MutationGenerateRandomPasswordArgs = {
  email: Scalars["String"];
};

export type MutationUpdatePlatformIdArgs = {
  event_platform_id: Scalars["String"];
};

export type MutationUpdateStageArgs = {
  data: StageUpdateInput;
};

export type MutationCreateStageArgs = {
  data: StageCreateInput;
};

export type MutationCreateSponsorIndividualArgs = {
  sponsor_jwt: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationCreateSponsorIndividual_OrganizerArgs = {
  sponsor_jwt: Scalars["String"];
  email: Scalars["String"];
};

export type MutationLoginSponsorIndividualArgs = {
  sponsor_jwt: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
};

export type MutationUpdateSponsorIndividualArgs = {
  data: SponsorIndividualUpdateInput;
  where: SponsorIndividualWhereUniqueEmailInput;
};

export type MutationDeleteSponsorIndividualArgs = {
  sponsor_company_slug: Scalars["String"];
  email: Scalars["String"];
};

export type MutationCreateSponsorCompanyArgs = {
  data: AddSponsorCompanyInput;
};

export type MutationUpdateSponsorCompanyArgs = {
  data: SponsorCompanyUpdateInput;
  where: SponsorCompanyWhereUniqueInput;
};

export type MutationDeleteSponsorCompanyArgs = {
  slug: Scalars["String"];
};

export type MutationSendSlackReviewMessageArgs = {
  message: Scalars["String"];
};

export type MutationCreateSignageQuestionArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<Scalars["String"]>>;
  type: Scalars["String"];
  title: Scalars["String"];
};

export type MutationDeleteSignageQuestionUnsafeArgs = {
  question_id: Scalars["Float"];
};

export type MutationDeleteSignageAnswerArgs = {
  answer_id: Scalars["Float"];
};

export type MutationAnswerSignageQuestionArgs = {
  answer: Scalars["String"];
  question_id: Scalars["Float"];
};

export type MutationAddScheduleItemArgs = {
  data: EventScheduleCreateInput;
};

export type MutationUpdateScheduleItemArgs = {
  where: EventScheduleWhereUniqueInput;
  data: CustomEventScheduleUpdateInput;
};

export type MutationDeleteScheduleItemArgs = {
  where: EventScheduleWhereUniqueInput;
};

export type MutationCreateReviewArgs = {
  note?: Maybe<Scalars["String"]>;
  original_stage_id: Scalars["Float"];
  claim_id: Scalars["Float"];
};

export type MutationUpdateReviewArgs = {
  claim_id?: Maybe<Scalars["Float"]>;
  reviewer_id?: Maybe<Scalars["Float"]>;
  id: Scalars["Float"];
};

export type MutationDeleteReviewArgs = {
  id: Scalars["Float"];
};

export type MutationUpdatePipelineArgs = {
  data: PipelineUpdateInput;
};

export type MutationCreatePipelineArgs = {
  data: PipelineCreateInput;
};

export type MutationSetUnsubscribedMailingPreferenceArgs = {
  email: Scalars["String"];
};

export type MutationSetBulkUnsubscribedMailingPreferenceArgs = {
  emails: Array<Scalars["String"]>;
};

export type MutationSetSubscribedMailingPreferenceArgs = {
  email: Scalars["String"];
};

export type MutationRemoveFromMailingListArgs = {
  event_slug?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type MutationAddToMailingListArgs = {
  event_slug: Scalars["String"];
  email: Scalars["String"];
};

export type MutationCreateInstitutionArgs = {
  website?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  is_enabled: Scalars["Boolean"];
  is_custom: Scalars["Boolean"];
  institution_type: InstitutionTypeEnum;
  country_code: Scalars["String"];
  country: Scalars["String"];
};

export type MutationDeleteInstitutionArgs = {
  id: Scalars["Float"];
};

export type MutationDeleteHtnBucksTransactionsArgs = {
  where?: Maybe<HtnBucksTransactionWhereInput>;
};

export type MutationGiftHtnBucksArgs = {
  slack_message_link?: Maybe<Scalars["String"]>;
  message_contents?: Maybe<Scalars["String"]>;
  amount: Scalars["Int"];
  receiver_emails: Array<Scalars["String"]>;
  sender_email: Scalars["String"];
};

export type MutationCreateHardwareSkuArgs = {
  code: Scalars["String"];
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
  location: Scalars["String"];
  category_id: Scalars["Float"];
};

export type MutationUpdateHardwareSkuArgs = {
  code: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  short_description?: Maybe<Scalars["String"]>;
  long_description?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  category_id?: Maybe<Scalars["Float"]>;
};

export type MutationUnsafeDeleteHardwareSkuArgs = {
  code: Scalars["String"];
};

export type MutationCreateHardwareRequestByOrganizerArgs = {
  event_id: Scalars["Float"];
  sku_code: Scalars["String"];
  quantity: Scalars["Float"];
  user_id: Scalars["Float"];
  notes?: Maybe<Scalars["String"]>;
  status?: Maybe<HardwareRequestStatus>;
};

export type MutationUpdateHardwareRequestByOrganizerArgs = {
  id: Scalars["Float"];
  quantity?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  status?: Maybe<HardwareRequestStatus>;
  event_id?: Maybe<Scalars["Float"]>;
  sku_code?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["Float"]>;
};

export type MutationCreateHardwareRequestByHackerArgs = {
  event_id: Scalars["Float"];
  sku_code: Scalars["String"];
  quantity: Scalars["Float"];
  notes?: Maybe<Scalars["String"]>;
};

export type MutationUpdateHardwareRequestByHackerArgs = {
  id: Scalars["Float"];
  status?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type MutationApproveHackerHardwareRequestArgs = {
  id: Scalars["Float"];
};

export type MutationReturnHackerHardwareRequestArgs = {
  id: Scalars["Float"];
};

export type MutationMarkSpecificItemsAsReturnedArgs = {
  request_id: Scalars["Float"];
  item_ids: Array<Scalars["Int"]>;
};

export type MutationChangeHardwareStatusArgs = {
  status: HardwareRequestStatus;
  id: Scalars["Float"];
};

export type MutationCreateHardwareItemArgs = {
  notes?: Maybe<Scalars["String"]>;
  sku_code: Scalars["String"];
};

export type MutationUpdateHardwareItemArgs = {
  notes?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
};

export type MutationDeleteHardwareItemArgs = {
  id: Scalars["Float"];
};

export type MutationCreateHardwareCategoryArgs = {
  name: Scalars["String"];
  short_description: Scalars["String"];
  long_description: Scalars["String"];
  image: Scalars["String"];
};

export type MutationUpdateHardwareCategoryArgs = {
  data: HardwareCategoryUpdateInput;
};

export type MutationDeleteHardwareCategoryArgs = {
  id: Scalars["Float"];
};

export type MutationSeedHardwareInfoArgs = {
  url: Scalars["String"];
};

export type MutationDeleteClaimArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
};

export type MutationCreateClaimArgs = {
  data: ClaimCreateInput;
};

export type MutationCreateSponsorPerkArgs = {
  perks: Array<PerkClaimInput>;
  sponsor_company_slug: Scalars["String"];
};

export type MutationUpdateClaimArgs = {
  data: ClaimUpdateInput;
};

export type MutationMoveClaimsArgs = {
  toStage: Scalars["Float"];
  where: ClaimWhereInput;
};

export type MutationUploadArgs = {
  type: Scalars["String"];
  event_slug: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationCreateFieldArgs = {
  data: FieldCreateInput;
};

export type MutationUpdateFieldArgs = {
  data: FieldUpdateInput;
};

export type MutationDeleteFieldArgs = {
  id: Scalars["Float"];
};

export type MutationDeleteFieldUnsafeArgs = {
  id: Scalars["Float"];
};

export type MutationDeleteFieldAnswerArgs = {
  id: Scalars["Float"];
};

export type MutationUpsertExtendedProfileArgs = {
  profile_photo?: Maybe<Scalars["Upload"]>;
  interests?: Maybe<Scalars["String"]>;
  user_id?: Maybe<Scalars["Float"]>;
};

export type MutationCreateEventArgs = {
  data: EventCreateInput;
};

export type MutationUpdateEventArgs = {
  data: UpdateEventInput;
};

export type MutationDeleteEventArgs = {
  slug: Scalars["String"];
};

export type MutationCreateEventUpdateArgs = {
  data: EventUpdateEntryCreateInput;
};

export type MutationCreateEventAttendeeArgs = {
  role: Scalars["String"];
  event_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationDeleteEventAttendeeArgs = {
  role: Scalars["String"];
  event_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationRegisterBadgeCodeToEventAttendeeArgs = {
  event_attendee_id: Scalars["Float"];
  user_id: Scalars["Float"];
  badge_code: Scalars["String"];
};

export type MutationRedeemEventActivityScanArgs = {
  event_activity_id: Scalars["Float"];
  badge_code: Scalars["String"];
};

export type MutationRedeemEventActivityArgs = {
  event_activity_id: Scalars["Float"];
  badge_code: Scalars["String"];
};

export type MutationCreateEventActivityArgs = {
  data: EventActivityCreateInput;
};

export type MutationUpdateEventActivityArgs = {
  data: EventActivityUpdateInput;
  where: EventActivityWhereUniqueInput;
};

export type MutationDeleteEventActivityArgs = {
  id: Scalars["Float"];
};

export type MutationCreateEmailArgs = {
  sent?: Maybe<Scalars["Boolean"]>;
  sendTime: Scalars["DateTimeISO"];
  toEmail: Scalars["String"];
  subject: Scalars["String"];
  html: Scalars["String"];
  campaign: Scalars["String"];
};

export type MutationConfirmSentArgs = {
  sent: Scalars["Boolean"];
  id: Scalars["Float"];
};

export type MutationDeleteEmailsArgs = {
  email: Scalars["String"];
};

export type MutationCreateCompanyArgs = {
  twitter?: Maybe<Scalars["String"]>;
  website: Scalars["String"];
  name: Scalars["String"];
};

export type MutationUpdateCompanyArgs = {
  twitter?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars["Float"];
};

export type MutationCreateCollaboratorArgs = {
  pipeline_id?: Maybe<Scalars["Float"]>;
  claim_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationDeleteCollaboratorArgs = {
  claim_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationCreateBusArgs = {
  event_slug?: Maybe<Scalars["String"]>;
  seats_total: Scalars["Float"];
  name: Scalars["String"];
};

export type MutationUpdateBusArgs = {
  event_id?: Maybe<Scalars["Float"]>;
  seats_total?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
};

export type MutationDeleteBusByIdArgs = {
  id: Scalars["Float"];
};

export type MutationDeleteBusByNameArgs = {
  name: Scalars["String"];
};

export type MutationAddUserToBusArgs = {
  bus_stop_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationRemoveUserFromBusArgs = {
  user_id: Scalars["Float"];
  id: Scalars["Float"];
};

export type MutationCreateBusStopArgs = {
  name: Scalars["String"];
  address: Scalars["String"];
  departure_time: Scalars["DateTimeISO"];
  bus: BusCreateNestedOneWithoutStopsInput;
};

export type MutationUpdateBusStopArgs = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  departure_time?: Maybe<Scalars["DateTimeISO"]>;
  bus?: Maybe<BusUpdateOneRequiredWithoutStopsNestedInput>;
};

export type MutationDeleteBusStopArgs = {
  id: Scalars["Float"];
};

export type MutationRegisterSelfToBusArgs = {
  busStopId: Scalars["Float"];
};

export type MutationUnregisterSelfFromBusArgs = {
  busId: Scalars["Float"];
};

export type MutationHandleNetworkingScanArgs = {
  badge_code: Scalars["String"];
};

export type MutationLoginWithPasswordArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  password: Scalars["String"];
  email: Scalars["String"];
};

export type MutationLoginWithJwtArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  jwt: Scalars["String"];
};

export type MutationLoginWithGoogleAuthCodeArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  code: Scalars["String"];
};

export type MutationRefreshTokensArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  refreshJwt: Scalars["String"];
};

export type MutationGetB64EncodedServiceTokenArgs = {
  expiresIn?: Scalars["Float"];
  args: UserWhereUniqueInput;
};

export type MutationImpersonateUserArgs = {
  expiresIn?: Scalars["Float"];
  args: UserWhereUniqueInput;
};

export type MutationResetPasswordArgs = {
  callback_url: Scalars["String"];
  email: Scalars["String"];
};

export type MutationInviteToResetPasswordArgs = {
  callback_url: Scalars["String"];
  email: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  token: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars["DateTimeISO"]>;
  in?: Maybe<Array<Scalars["DateTimeISO"]>>;
  notIn?: Maybe<Array<Scalars["DateTimeISO"]>>;
  lt?: Maybe<Scalars["DateTimeISO"]>;
  lte?: Maybe<Scalars["DateTimeISO"]>;
  gt?: Maybe<Scalars["DateTimeISO"]>;
  gte?: Maybe<Scalars["DateTimeISO"]>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTimeISO"]>;
  in?: Maybe<Array<Scalars["DateTimeISO"]>>;
  notIn?: Maybe<Array<Scalars["DateTimeISO"]>>;
  lt?: Maybe<Scalars["DateTimeISO"]>;
  lte?: Maybe<Scalars["DateTimeISO"]>;
  gt?: Maybe<Scalars["DateTimeISO"]>;
  gte?: Maybe<Scalars["DateTimeISO"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedEnumBadgeScanTypeFilter = {
  equals?: Maybe<BadgeScanType>;
  in?: Maybe<Array<BadgeScanType>>;
  notIn?: Maybe<Array<BadgeScanType>>;
  not?: Maybe<NestedEnumBadgeScanTypeFilter>;
};

export type NestedEnumGenderNullableFilter = {
  equals?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  notIn?: Maybe<Array<Gender>>;
  not?: Maybe<NestedEnumGenderNullableFilter>;
};

export type NestedEnumHardwareRequestStatusFilter = {
  equals?: Maybe<HardwareRequestStatus>;
  in?: Maybe<Array<HardwareRequestStatus>>;
  notIn?: Maybe<Array<HardwareRequestStatus>>;
  not?: Maybe<NestedEnumHardwareRequestStatusFilter>;
};

export type NestedEnumHardwareRequestStatusNullableFilter = {
  equals?: Maybe<HardwareRequestStatus>;
  in?: Maybe<Array<HardwareRequestStatus>>;
  notIn?: Maybe<Array<HardwareRequestStatus>>;
  not?: Maybe<NestedEnumHardwareRequestStatusNullableFilter>;
};

export type NestedEnumHardwareSkuTypeFilter = {
  equals?: Maybe<HardwareSkuType>;
  in?: Maybe<Array<HardwareSkuType>>;
  notIn?: Maybe<Array<HardwareSkuType>>;
  not?: Maybe<NestedEnumHardwareSkuTypeFilter>;
};

export type NestedEnumInstitutionTypeEnumFilter = {
  equals?: Maybe<InstitutionTypeEnum>;
  in?: Maybe<Array<InstitutionTypeEnum>>;
  notIn?: Maybe<Array<InstitutionTypeEnum>>;
  not?: Maybe<NestedEnumInstitutionTypeEnumFilter>;
};

export type NestedEnumPersonRoleFilter = {
  equals?: Maybe<PersonRole>;
  in?: Maybe<Array<PersonRole>>;
  notIn?: Maybe<Array<PersonRole>>;
  not?: Maybe<NestedEnumPersonRoleFilter>;
};

export type NestedEnumRecordTypeEmailFilter = {
  equals?: Maybe<RecordTypeEmail>;
  in?: Maybe<Array<RecordTypeEmail>>;
  notIn?: Maybe<Array<RecordTypeEmail>>;
  not?: Maybe<NestedEnumRecordTypeEmailFilter>;
};

export type NestedEnumShiftStatusFilter = {
  equals?: Maybe<ShiftStatus>;
  in?: Maybe<Array<ShiftStatus>>;
  notIn?: Maybe<Array<ShiftStatus>>;
  not?: Maybe<NestedEnumShiftStatusFilter>;
};

export type NestedEnumSponsorCompanyStageFilter = {
  equals?: Maybe<SponsorCompanyStage>;
  in?: Maybe<Array<SponsorCompanyStage>>;
  notIn?: Maybe<Array<SponsorCompanyStage>>;
  not?: Maybe<NestedEnumSponsorCompanyStageFilter>;
};

export type NestedEnumSponsorCompanyTierFilter = {
  equals?: Maybe<SponsorCompanyTier>;
  in?: Maybe<Array<SponsorCompanyTier>>;
  notIn?: Maybe<Array<SponsorCompanyTier>>;
  not?: Maybe<NestedEnumSponsorCompanyTierFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NetworkingScanResponse = {
  __typename?: "NetworkingScanResponse";
  hackerNetworkingPayload?: Maybe<HackerNetworkingPayload>;
  sponsorNetworkingPayload?: Maybe<SponsorNetworkingPayload>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Boolean"]>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["DateTimeISO"]>;
};

export type NullableEnumGenderFieldUpdateOperationsInput = {
  set?: Maybe<Gender>;
};

export type NullableEnumHardwareRequestStatusFieldUpdateOperationsInput = {
  set?: Maybe<HardwareRequestStatus>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Float"]>;
  increment?: Maybe<Scalars["Float"]>;
  decrement?: Maybe<Scalars["Float"]>;
  multiply?: Maybe<Scalars["Float"]>;
  divide?: Maybe<Scalars["Float"]>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["Int"]>;
  increment?: Maybe<Scalars["Int"]>;
  decrement?: Maybe<Scalars["Int"]>;
  multiply?: Maybe<Scalars["Int"]>;
  divide?: Maybe<Scalars["Int"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export enum NullsOrder {
  First = "first",
  Last = "last",
}

export type NumberFieldAnswerTypeWrapper = {
  __typename?: "NumberFieldAnswerTypeWrapper";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  value?: Maybe<Scalars["Float"]>;
};

export type OutlineDocument = {
  __typename?: "OutlineDocument";
  id: Scalars["String"];
  url: Scalars["String"];
  title: Scalars["String"];
  text: Scalars["String"];
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  publishedAt: Scalars["String"];
};

export type PerkClaimInput = {
  slug: Scalars["String"];
  answer?: Maybe<Scalars["JSON"]>;
};

export type PersonCreateManyUserInput = {
  company?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email_hashes?: Maybe<PersonCreateemail_HashesInput>;
  emails?: Maybe<PersonCreateemailsInput>;
  family_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  given_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interests?: Maybe<PersonCreateinterestsInput>;
  middle_names?: Maybe<Scalars["String"]>;
  phone_number: Scalars["String"];
  relationship?: Maybe<Scalars["String"]>;
  role: PersonRole;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type PersonCreateManyUserInputEnvelope = {
  data: Array<PersonCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type PersonCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<PersonCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<PersonCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<PersonCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
};

export type PersonCreateOrConnectWithoutUserInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutUserInput;
};

export type PersonCreateWithoutUserInput = {
  company?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  email_hashes?: Maybe<PersonCreateemail_HashesInput>;
  emails?: Maybe<PersonCreateemailsInput>;
  family_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  given_name?: Maybe<Scalars["String"]>;
  interests?: Maybe<PersonCreateinterestsInput>;
  middle_names?: Maybe<Scalars["String"]>;
  phone_number: Scalars["String"];
  relationship?: Maybe<Scalars["String"]>;
  role: PersonRole;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type PersonCreateemail_HashesInput = {
  set: Array<Scalars["String"]>;
};

export type PersonCreateemailsInput = {
  set: Array<Scalars["String"]>;
};

export type PersonCreateinterestsInput = {
  set: Array<Scalars["String"]>;
};

export type PersonListRelationFilter = {
  every?: Maybe<PersonWhereInput>;
  some?: Maybe<PersonWhereInput>;
  none?: Maybe<PersonWhereInput>;
};

export type PersonOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PersonRole {
  EmergencyContact = "emergency_contact",
  Guest = "guest",
  Judge = "judge",
  Mentor = "mentor",
  Organizer = "organizer",
  Recruiter = "recruiter",
  Speaker = "speaker",
  Sponsor = "sponsor",
  Volunteer = "volunteer",
}

export type PersonScalarWhereInput = {
  AND?: Maybe<Array<PersonScalarWhereInput>>;
  OR?: Maybe<Array<PersonScalarWhereInput>>;
  NOT?: Maybe<Array<PersonScalarWhereInput>>;
  company?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emails?: Maybe<StringNullableListFilter>;
  family_name?: Maybe<StringNullableFilter>;
  full_name?: Maybe<StringNullableFilter>;
  given_name?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  interests?: Maybe<StringNullableListFilter>;
  middle_names?: Maybe<StringNullableFilter>;
  phone_number?: Maybe<StringFilter>;
  relationship?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumPersonRoleFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
};

export type PersonUpdateManyMutationInput = {
  company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email_hashes?: Maybe<PersonUpdateemail_HashesInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  family_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  given_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  interests?: Maybe<PersonUpdateinterestsInput>;
  middle_names?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone_number?: Maybe<StringFieldUpdateOperationsInput>;
  relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumPersonRoleFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PersonUpdateManyWithWhereWithoutUserInput = {
  where: PersonScalarWhereInput;
  data: PersonUpdateManyMutationInput;
};

export type PersonUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<PersonCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<PersonCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<PersonUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<PersonCreateManyUserInputEnvelope>;
  set?: Maybe<Array<PersonWhereUniqueInput>>;
  disconnect?: Maybe<Array<PersonWhereUniqueInput>>;
  delete?: Maybe<Array<PersonWhereUniqueInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
  update?: Maybe<Array<PersonUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<PersonUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<PersonScalarWhereInput>>;
};

export type PersonUpdateWithWhereUniqueWithoutUserInput = {
  where: PersonWhereUniqueInput;
  data: PersonUpdateWithoutUserInput;
};

export type PersonUpdateWithoutUserInput = {
  company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email_hashes?: Maybe<PersonUpdateemail_HashesInput>;
  emails?: Maybe<PersonUpdateemailsInput>;
  family_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  full_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  given_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  interests?: Maybe<PersonUpdateinterestsInput>;
  middle_names?: Maybe<NullableStringFieldUpdateOperationsInput>;
  phone_number?: Maybe<StringFieldUpdateOperationsInput>;
  relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumPersonRoleFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PersonUpdateemail_HashesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type PersonUpdateemailsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type PersonUpdateinterestsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type PersonUpsertWithWhereUniqueWithoutUserInput = {
  where: PersonWhereUniqueInput;
  update: PersonUpdateWithoutUserInput;
  create: PersonCreateWithoutUserInput;
};

export type PersonWhereInput = {
  AND?: Maybe<Array<PersonWhereInput>>;
  OR?: Maybe<Array<PersonWhereInput>>;
  NOT?: Maybe<Array<PersonWhereInput>>;
  company?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emails?: Maybe<StringNullableListFilter>;
  family_name?: Maybe<StringNullableFilter>;
  full_name?: Maybe<StringNullableFilter>;
  given_name?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  interests?: Maybe<StringNullableListFilter>;
  middle_names?: Maybe<StringNullableFilter>;
  phone_number?: Maybe<StringFilter>;
  relationship?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumPersonRoleFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  user?: Maybe<UserNullableRelationFilter>;
};

export type PersonWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<PersonWhereInput>>;
  OR?: Maybe<Array<PersonWhereInput>>;
  NOT?: Maybe<Array<PersonWhereInput>>;
  company?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emails?: Maybe<StringNullableListFilter>;
  family_name?: Maybe<StringNullableFilter>;
  full_name?: Maybe<StringNullableFilter>;
  given_name?: Maybe<StringNullableFilter>;
  interests?: Maybe<StringNullableListFilter>;
  middle_names?: Maybe<StringNullableFilter>;
  phone_number?: Maybe<StringFilter>;
  relationship?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumPersonRoleFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  user?: Maybe<UserNullableRelationFilter>;
};

export type Pipeline = {
  __typename?: "Pipeline";
  created_at: Scalars["DateTimeISO"];
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  id: Scalars["Int"];
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships: Array<Scalars["String"]>;
  slug: Scalars["String"];
  stage_order: Array<Scalars["Int"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  _count?: Maybe<PipelineCount>;
  count: Scalars["Float"];
  stages: Array<Stage>;
  fields: Array<Field>;
};

export type PipelineCount = {
  __typename?: "PipelineCount";
  claims: Scalars["Int"];
  fields: Scalars["Int"];
  stages: Scalars["Int"];
};

export type PipelineCountClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
};

export type PipelineCountFieldsArgs = {
  where?: Maybe<FieldWhereInput>;
};

export type PipelineCountStagesArgs = {
  where?: Maybe<StageWhereInput>;
};

/** Create pipeline data */
export type PipelineCreateInput = {
  slug: Scalars["String"];
  description: Scalars["String"];
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  event_slug: Scalars["String"];
  stages: Array<CustomStageCreateWithoutPipelineInput>;
  fields: Array<CustomFieldCreateWithoutPipelineInput>;
  type: Scalars["String"];
  stage_order?: Maybe<Array<Scalars["Int"]>>;
};

export type PipelineCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  slug: Scalars["String"];
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type PipelineCreateManyEventInputEnvelope = {
  data: Array<PipelineCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type PipelineCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<PipelineCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<PipelineCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<PipelineCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<PipelineWhereUniqueInput>>;
};

export type PipelineCreateNestedOneWithoutClaimsInput = {
  create?: Maybe<PipelineCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutClaimsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
};

export type PipelineCreateNestedOneWithoutFieldsInput = {
  create?: Maybe<PipelineCreateWithoutFieldsInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutFieldsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
};

export type PipelineCreateNestedOneWithoutStagesInput = {
  create?: Maybe<PipelineCreateWithoutStagesInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutStagesInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
};

export type PipelineCreateOrConnectWithoutClaimsInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutClaimsInput;
};

export type PipelineCreateOrConnectWithoutEventInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutEventInput;
};

export type PipelineCreateOrConnectWithoutFieldsInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutFieldsInput;
};

export type PipelineCreateOrConnectWithoutStagesInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutStagesInput;
};

export type PipelineCreateWithoutClaimsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  slug: Scalars["String"];
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  fields?: Maybe<FieldCreateNestedManyWithoutPipelineInput>;
  event: EventCreateNestedOneWithoutPipelinesInput;
  stages?: Maybe<StageCreateNestedManyWithoutPipelineInput>;
};

export type PipelineCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  slug: Scalars["String"];
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  claims?: Maybe<ClaimCreateNestedManyWithoutPipelineInput>;
  fields?: Maybe<FieldCreateNestedManyWithoutPipelineInput>;
  stages?: Maybe<StageCreateNestedManyWithoutPipelineInput>;
};

export type PipelineCreateWithoutFieldsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  slug: Scalars["String"];
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  claims?: Maybe<ClaimCreateNestedManyWithoutPipelineInput>;
  event: EventCreateNestedOneWithoutPipelinesInput;
  stages?: Maybe<StageCreateNestedManyWithoutPipelineInput>;
};

export type PipelineCreateWithoutStagesInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTimeISO"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  slug: Scalars["String"];
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  claims?: Maybe<ClaimCreateNestedManyWithoutPipelineInput>;
  fields?: Maybe<FieldCreateNestedManyWithoutPipelineInput>;
  event: EventCreateNestedOneWithoutPipelinesInput;
};

export type PipelineCreaterelationshipsInput = {
  set: Array<Scalars["String"]>;
};

export type PipelineCreatestage_OrderInput = {
  set: Array<Scalars["Int"]>;
};

export type PipelineListRelationFilter = {
  every?: Maybe<PipelineWhereInput>;
  some?: Maybe<PipelineWhereInput>;
  none?: Maybe<PipelineWhereInput>;
};

export type PipelineOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum PipelineOrderByRelevanceFieldEnum {
  Description = "description",
  EventSlug = "event_slug",
  Name = "name",
  Relationships = "relationships",
  Slug = "slug",
  Type = "type",
}

export type PipelineOrderByRelevanceInput = {
  fields: Array<PipelineOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type PipelineOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  end_time?: Maybe<SortOrderInput>;
  event_slug?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  max_num_claims?: Maybe<SortOrderInput>;
  max_num_collaborators?: Maybe<SortOrderInput>;
  name?: Maybe<SortOrder>;
  permissions?: Maybe<SortOrderInput>;
  relationships?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  stage_order?: Maybe<SortOrder>;
  start_time?: Maybe<SortOrderInput>;
  type?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  claims?: Maybe<ClaimOrderByRelationAggregateInput>;
  fields?: Maybe<FieldOrderByRelationAggregateInput>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  stages?: Maybe<StageOrderByRelationAggregateInput>;
  _relevance?: Maybe<PipelineOrderByRelevanceInput>;
};

export type PipelineRelationFilter = {
  is?: Maybe<PipelineWhereInput>;
  isNot?: Maybe<PipelineWhereInput>;
};

export enum PipelineScalarFieldEnum {
  CreatedAt = "created_at",
  Description = "description",
  EndTime = "end_time",
  EventSlug = "event_slug",
  Id = "id",
  MaxNumClaims = "max_num_claims",
  MaxNumCollaborators = "max_num_collaborators",
  Name = "name",
  Permissions = "permissions",
  Relationships = "relationships",
  Slug = "slug",
  StageOrder = "stage_order",
  StartTime = "start_time",
  Type = "type",
  UpdatedAt = "updated_at",
}

export type PipelineScalarWhereInput = {
  AND?: Maybe<Array<PipelineScalarWhereInput>>;
  OR?: Maybe<Array<PipelineScalarWhereInput>>;
  NOT?: Maybe<Array<PipelineScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  max_num_claims?: Maybe<IntNullableFilter>;
  max_num_collaborators?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  relationships?: Maybe<StringNullableListFilter>;
  slug?: Maybe<StringFilter>;
  stage_order?: Maybe<IntNullableListFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

/** Update pipeline data */
export type PipelineUpdateInput = {
  slug: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  type?: Maybe<Scalars["String"]>;
  stage_order?: Maybe<Array<Scalars["Int"]>>;
};

export type PipelineUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  max_num_claims?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_num_collaborators?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineUpdaterelationshipsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  stage_order?: Maybe<PipelineUpdatestage_OrderInput>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PipelineUpdateManyWithWhereWithoutEventInput = {
  where: PipelineScalarWhereInput;
  data: PipelineUpdateManyMutationInput;
};

export type PipelineUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<PipelineCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<PipelineCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<PipelineUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<PipelineCreateManyEventInputEnvelope>;
  set?: Maybe<Array<PipelineWhereUniqueInput>>;
  disconnect?: Maybe<Array<PipelineWhereUniqueInput>>;
  delete?: Maybe<Array<PipelineWhereUniqueInput>>;
  connect?: Maybe<Array<PipelineWhereUniqueInput>>;
  update?: Maybe<Array<PipelineUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<PipelineUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<PipelineScalarWhereInput>>;
};

export type PipelineUpdateOneRequiredWithoutClaimsNestedInput = {
  create?: Maybe<PipelineCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutClaimsInput>;
  upsert?: Maybe<PipelineUpsertWithoutClaimsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  update?: Maybe<PipelineUpdateToOneWithWhereWithoutClaimsInput>;
};

export type PipelineUpdateOneRequiredWithoutFieldsNestedInput = {
  create?: Maybe<PipelineCreateWithoutFieldsInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutFieldsInput>;
  upsert?: Maybe<PipelineUpsertWithoutFieldsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  update?: Maybe<PipelineUpdateToOneWithWhereWithoutFieldsInput>;
};

export type PipelineUpdateOneRequiredWithoutStagesNestedInput = {
  create?: Maybe<PipelineCreateWithoutStagesInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutStagesInput>;
  upsert?: Maybe<PipelineUpsertWithoutStagesInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  update?: Maybe<PipelineUpdateToOneWithWhereWithoutStagesInput>;
};

export type PipelineUpdateToOneWithWhereWithoutClaimsInput = {
  where?: Maybe<PipelineWhereInput>;
  data: PipelineUpdateWithoutClaimsInput;
};

export type PipelineUpdateToOneWithWhereWithoutFieldsInput = {
  where?: Maybe<PipelineWhereInput>;
  data: PipelineUpdateWithoutFieldsInput;
};

export type PipelineUpdateToOneWithWhereWithoutStagesInput = {
  where?: Maybe<PipelineWhereInput>;
  data: PipelineUpdateWithoutStagesInput;
};

export type PipelineUpdateWithWhereUniqueWithoutEventInput = {
  where: PipelineWhereUniqueInput;
  data: PipelineUpdateWithoutEventInput;
};

export type PipelineUpdateWithoutClaimsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  max_num_claims?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_num_collaborators?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineUpdaterelationshipsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  stage_order?: Maybe<PipelineUpdatestage_OrderInput>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  fields?: Maybe<FieldUpdateManyWithoutPipelineNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutPipelinesNestedInput>;
  stages?: Maybe<StageUpdateManyWithoutPipelineNestedInput>;
};

export type PipelineUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  max_num_claims?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_num_collaborators?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineUpdaterelationshipsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  stage_order?: Maybe<PipelineUpdatestage_OrderInput>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  claims?: Maybe<ClaimUpdateManyWithoutPipelineNestedInput>;
  fields?: Maybe<FieldUpdateManyWithoutPipelineNestedInput>;
  stages?: Maybe<StageUpdateManyWithoutPipelineNestedInput>;
};

export type PipelineUpdateWithoutFieldsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  max_num_claims?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_num_collaborators?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineUpdaterelationshipsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  stage_order?: Maybe<PipelineUpdatestage_OrderInput>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  claims?: Maybe<ClaimUpdateManyWithoutPipelineNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutPipelinesNestedInput>;
  stages?: Maybe<StageUpdateManyWithoutPipelineNestedInput>;
};

export type PipelineUpdateWithoutStagesInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  end_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  max_num_claims?: Maybe<NullableIntFieldUpdateOperationsInput>;
  max_num_collaborators?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<PipelineUpdaterelationshipsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  stage_order?: Maybe<PipelineUpdatestage_OrderInput>;
  start_time?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  claims?: Maybe<ClaimUpdateManyWithoutPipelineNestedInput>;
  fields?: Maybe<FieldUpdateManyWithoutPipelineNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutPipelinesNestedInput>;
};

export type PipelineUpdaterelationshipsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type PipelineUpdatestage_OrderInput = {
  set?: Maybe<Array<Scalars["Int"]>>;
  push?: Maybe<Array<Scalars["Int"]>>;
};

export type PipelineUpsertWithWhereUniqueWithoutEventInput = {
  where: PipelineWhereUniqueInput;
  update: PipelineUpdateWithoutEventInput;
  create: PipelineCreateWithoutEventInput;
};

export type PipelineUpsertWithoutClaimsInput = {
  update: PipelineUpdateWithoutClaimsInput;
  create: PipelineCreateWithoutClaimsInput;
  where?: Maybe<PipelineWhereInput>;
};

export type PipelineUpsertWithoutFieldsInput = {
  update: PipelineUpdateWithoutFieldsInput;
  create: PipelineCreateWithoutFieldsInput;
  where?: Maybe<PipelineWhereInput>;
};

export type PipelineUpsertWithoutStagesInput = {
  update: PipelineUpdateWithoutStagesInput;
  create: PipelineCreateWithoutStagesInput;
  where?: Maybe<PipelineWhereInput>;
};

export type PipelineWhereInput = {
  AND?: Maybe<Array<PipelineWhereInput>>;
  OR?: Maybe<Array<PipelineWhereInput>>;
  NOT?: Maybe<Array<PipelineWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  max_num_claims?: Maybe<IntNullableFilter>;
  max_num_collaborators?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  relationships?: Maybe<StringNullableListFilter>;
  slug?: Maybe<StringFilter>;
  stage_order?: Maybe<IntNullableListFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  fields?: Maybe<FieldListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  stages?: Maybe<StageListRelationFilter>;
};

export type PipelineWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<PipelineWhereInput>>;
  OR?: Maybe<Array<PipelineWhereInput>>;
  NOT?: Maybe<Array<PipelineWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_slug?: Maybe<StringFilter>;
  max_num_claims?: Maybe<IntNullableFilter>;
  max_num_collaborators?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  relationships?: Maybe<StringNullableListFilter>;
  stage_order?: Maybe<IntNullableListFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  fields?: Maybe<FieldListRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  stages?: Maybe<StageListRelationFilter>;
};

export type PrizeCreateManySponsorInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  prize: Scalars["String"];
  quantity?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type PrizeCreateManySponsorInputEnvelope = {
  data: Array<PrizeCreateManySponsorInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type PrizeCreateNestedManyWithoutSponsorInput = {
  create?: Maybe<Array<PrizeCreateWithoutSponsorInput>>;
  connectOrCreate?: Maybe<Array<PrizeCreateOrConnectWithoutSponsorInput>>;
  createMany?: Maybe<PrizeCreateManySponsorInputEnvelope>;
  connect?: Maybe<Array<PrizeWhereUniqueInput>>;
};

export type PrizeCreateOrConnectWithoutSponsorInput = {
  where: PrizeWhereUniqueInput;
  create: PrizeCreateWithoutSponsorInput;
};

export type PrizeCreateWithoutSponsorInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  prize: Scalars["String"];
  quantity?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type PrizeListRelationFilter = {
  every?: Maybe<PrizeWhereInput>;
  some?: Maybe<PrizeWhereInput>;
  none?: Maybe<PrizeWhereInput>;
};

export type PrizeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PrizeScalarWhereInput = {
  AND?: Maybe<Array<PrizeScalarWhereInput>>;
  OR?: Maybe<Array<PrizeScalarWhereInput>>;
  NOT?: Maybe<Array<PrizeScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  prize?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type PrizeUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  prize?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrizeUpdateManyWithWhereWithoutSponsorInput = {
  where: PrizeScalarWhereInput;
  data: PrizeUpdateManyMutationInput;
};

export type PrizeUpdateManyWithoutSponsorNestedInput = {
  create?: Maybe<Array<PrizeCreateWithoutSponsorInput>>;
  connectOrCreate?: Maybe<Array<PrizeCreateOrConnectWithoutSponsorInput>>;
  upsert?: Maybe<Array<PrizeUpsertWithWhereUniqueWithoutSponsorInput>>;
  createMany?: Maybe<PrizeCreateManySponsorInputEnvelope>;
  set?: Maybe<Array<PrizeWhereUniqueInput>>;
  disconnect?: Maybe<Array<PrizeWhereUniqueInput>>;
  delete?: Maybe<Array<PrizeWhereUniqueInput>>;
  connect?: Maybe<Array<PrizeWhereUniqueInput>>;
  update?: Maybe<Array<PrizeUpdateWithWhereUniqueWithoutSponsorInput>>;
  updateMany?: Maybe<Array<PrizeUpdateManyWithWhereWithoutSponsorInput>>;
  deleteMany?: Maybe<Array<PrizeScalarWhereInput>>;
};

export type PrizeUpdateWithWhereUniqueWithoutSponsorInput = {
  where: PrizeWhereUniqueInput;
  data: PrizeUpdateWithoutSponsorInput;
};

export type PrizeUpdateWithoutSponsorInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  prize?: Maybe<StringFieldUpdateOperationsInput>;
  quantity?: Maybe<NullableIntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrizeUpsertWithWhereUniqueWithoutSponsorInput = {
  where: PrizeWhereUniqueInput;
  update: PrizeUpdateWithoutSponsorInput;
  create: PrizeCreateWithoutSponsorInput;
};

export type PrizeWhereInput = {
  AND?: Maybe<Array<PrizeWhereInput>>;
  OR?: Maybe<Array<PrizeWhereInput>>;
  NOT?: Maybe<Array<PrizeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  prize?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
};

export type PrizeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<PrizeWhereInput>>;
  OR?: Maybe<Array<PrizeWhereInput>>;
  NOT?: Maybe<Array<PrizeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  prize?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
};

export type Query = {
  __typename?: "Query";
  volunteer: Array<Volunteer>;
  volunteerCategories: Array<VolunteerCategory>;
  user: User;
  me: User;
  users: Array<User>;
  eventPlatformId: EventPlatformId;
  checkSponsorExists: Scalars["Boolean"];
  sponsorIndividual: SponsorIndividual;
  sponsorIndividualsByEvent: Array<SponsorIndividual>;
  sponsorCompany: SponsorCompany;
  sponsorCompaniesByEvent: Array<SponsorCompany>;
  /** Returns all the signage questions, along with their answered state for the user */
  getSignageQuestionsAndUserAnswer: Array<SignageQuestion>;
  /** Returns all the signage questions, along with their answered state for the user */
  getSignageQuestions: Array<SignageQuestion>;
  /** Returns the signage question for the given id along with the answer the user has for the given question */
  getSignageQuestionById: SignageQuestion;
  /** Gets all the signage answers for a given question. This query uses a redis cache that is synced every minute so the results might be slightly stale */
  getSignageQuestionAnswers: Array<SignageAnswer>;
  /** The query to retrieve the multi-line graph data by hour for charts.htn */
  getSignageStatsByHour: Scalars["String"];
  /** Query for retrieving a summary of key event statistics */
  getSignageCountStats: Scalars["String"];
  eventSchedule: Array<ScheduleItem>;
  review: Review;
  reviews: Array<Review>;
  /** Get a leaderboard of reviewers sorted by the number of reviews they have completed. */
  appReviewLeaderboard: Array<ReviewLeaderboardUser>;
  pipeline: Pipeline;
  pipelines: Array<Pipeline>;
  sampleEvents: Array<TEvent>;
  sampleEvent: TEvent;
  mailingListEntries: Array<MailingList>;
  getUnsubscribedMailingPreferencesList: Array<MailingPreferences>;
  institution: AcademicInstitution;
  institutions: Array<AcademicInstitution>;
  /** Get the HTN Bucks account for the given slack email address */
  htnBucksAccount: HtnBucksAccount;
  /** Get all HTN Bucks transactions */
  htnBucksTransactions: Array<HtnBucksTransaction>;
  /** Get all HTN Bucks accounts */
  htnBucksAccounts: Array<HtnBucksAccount>;
  healthy: HealthResponse;
  hardwareSku: HardwareSku;
  getHardwareSkus: Array<HardwareSku>;
  hardwareRequests: Array<HardwareRequest>;
  /** for users to view their own requests */
  myHardwareRequests: Array<HardwareRequest>;
  hardwareRequest: HardwareRequest;
  searchHardwareRequests: HardwareRequest;
  hardwareItem: HardwareItem;
  hardwareItems: Array<HardwareItem>;
  getHardwareCategoryById: HardwareCategory;
  /** Endpoint to get hardware categories and information about them. Uses Prisma where syntax for the fitlering args. Eg. to get all categories, pass in {}. To get the category with name `hello`, use {name: `hello`}. */
  getHardwareCategories: Array<HardwareCategory>;
  claim: Claim;
  claims: Array<Claim>;
  getClaimStatistics: AggregateStatistics;
  claimsByCollaborator: Array<Claim>;
  travelReimbursementAmount: ReimbursementAmount;
  file: File;
  files: Array<File>;
  outlineDocument: OutlineDocument;
  field: Field;
  getExtendedProfiles: Array<Claim>;
  getExtendedProfile?: Maybe<ExtendedProfileInfo>;
  event: Event;
  events: Array<Event>;
  eventUpdates: Array<EventUpdateEntry>;
  eventAttendee: EventAttendee;
  eventAttendees: Array<EventAttendee>;
  findEventAttendeeByEmail: EventAttendeeInfo;
  findEventAttendeeByBadgeCode: EventAttendeeInfo;
  findUserActivityScansForEventActivityByUserId: Array<EventActivityScan>;
  findUserActivityScansForEventActivityByBadgeCode: Array<EventActivityScan>;
  findUserActivityScansByUserId: Array<EventActivityScan>;
  findUserActivityScansByBadgeCode: Array<EventActivityScan>;
  findAllActivityScansByEventActivityId: Array<EventActivityScan>;
  findAllRedemptionsByBadgeCode: Array<EventActivityRedemption>;
  findAllRedemptionsByUserId: Array<EventActivityRedemption>;
  findAllRedemptionsByEventActivityId: Array<EventActivityRedemption>;
  findUserRedemptionsForEventActivity: Array<EventActivityRedemption>;
  findEventActivityById: EventActivity;
  findUniqueEventActivity: EventActivity;
  findManyEventActivity: Array<EventActivity>;
  emails: Array<Email>;
  company: Company;
  companies: Array<Company>;
  collaborators: Array<Collaborator>;
  getBusAttendeesByBusId: Array<BusAttendee>;
  getBusAttendeesByBusName: Array<BusAttendee>;
  /** Return a buses and all its stops using its name. */
  findBusByName: Bus;
  /** Return a buses and all its stops using its id. */
  findBusById: Bus;
  /** Return all buses that match the given a location. */
  findBusesByLocation: Array<Bus>;
  /** Return all buses for an event. */
  findBusesByEvent: Array<Bus>;
  /** Return all buses that the current user is registered to for an event. */
  findMyBusesByEvent: Array<Bus>;
  busStop: BusStop;
  getUserNetworkingScans: Array<ConnectionHistoryScans>;
  fetchNetworkingScan: NetworkingScanResponse;
  /** Issue a new Sponsor JWT. Only organizers are authorized to perform this query. */
  issueSponsorJWT: Scalars["String"];
};

export type QueryVolunteerArgs = {
  where: VolunteerWhereInput;
};

export type QueryVolunteerCategoriesArgs = {
  where: VolunteerCategoryWhereInput;
};

export type QueryUserArgs = {
  id: Scalars["Float"];
};

export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<UserWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<UserScalarFieldEnum>>;
};

export type QueryEventPlatformIdArgs = {
  user_id: Scalars["Float"];
};

export type QueryCheckSponsorExistsArgs = {
  email: Scalars["String"];
  jwt: Scalars["String"];
};

export type QuerySponsorIndividualArgs = {
  email: Scalars["String"];
};

export type QuerySponsorIndividualsByEventArgs = {
  event_slug: Scalars["String"];
};

export type QuerySponsorCompanyArgs = {
  slug: Scalars["String"];
};

export type QuerySponsorCompaniesByEventArgs = {
  event_slug: Scalars["String"];
};

export type QueryGetSignageQuestionByIdArgs = {
  question_id: Scalars["Float"];
};

export type QueryGetSignageQuestionAnswersArgs = {
  refresh?: Maybe<Scalars["Boolean"]>;
  offset?: Scalars["Float"];
  limit?: Scalars["Float"];
  question_id: Scalars["Float"];
};

export type QueryEventScheduleArgs = {
  where?: Maybe<EventScheduleWhereInput>;
};

export type QueryReviewArgs = {
  id: Scalars["Float"];
};

export type QueryAppReviewLeaderboardArgs = {
  reviewer_emails: Array<Scalars["String"]>;
};

export type QueryPipelineArgs = {
  slug: Scalars["String"];
};

export type QueryPipelinesArgs = {
  where?: Maybe<PipelineWhereInput>;
  orderBy?: Maybe<Array<PipelineOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<PipelineWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<PipelineScalarFieldEnum>>;
};

export type QuerySampleEventArgs = {
  id: Scalars["Float"];
};

export type QueryMailingListEntriesArgs = {
  where?: Maybe<MailingListWhereInput>;
  orderBy?: Maybe<Array<MailingListOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<MailingListWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<MailingListScalarFieldEnum>>;
};

export type QueryInstitutionArgs = {
  id: Scalars["Float"];
};

export type QueryHtnBucksAccountArgs = {
  where: HtnBucksAccountWhereUniqueInput;
};

export type QueryHtnBucksTransactionsArgs = {
  where?: Maybe<HtnBucksTransactionWhereInput>;
  orderBy?: Maybe<
    Array<HtnBucksTransactionOrderByWithRelationAndSearchRelevanceInput>
  >;
  cursor?: Maybe<HtnBucksTransactionWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<HtnBucksTransactionScalarFieldEnum>>;
};

export type QueryHtnBucksAccountsArgs = {
  timeFrame?: Maybe<TimeFrame>;
  sortBy?: Maybe<SortOptions>;
  where?: Maybe<HtnBucksAccountWhereInput>;
  orderBy?: Maybe<
    Array<HtnBucksAccountOrderByWithRelationAndSearchRelevanceInput>
  >;
  cursor?: Maybe<HtnBucksAccountWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<HtnBucksAccountScalarFieldEnum>>;
};

export type QueryHardwareSkuArgs = {
  code: Scalars["String"];
};

export type QueryGetHardwareSkusArgs = {
  where: HardwareSkuWhereInput;
};

export type QueryHardwareRequestsArgs = {
  where: HardwareRequestWhereInput;
};

export type QueryMyHardwareRequestsArgs = {
  where: HardwareRequestWhereInput;
};

export type QueryHardwareRequestArgs = {
  id: Scalars["Float"];
};

export type QuerySearchHardwareRequestsArgs = {
  query: Scalars["String"];
};

export type QueryHardwareItemArgs = {
  id: Scalars["Float"];
};

export type QueryHardwareItemsArgs = {
  where: HardwareItemWhereInput;
};

export type QueryGetHardwareCategoryByIdArgs = {
  id: Scalars["Int"];
};

export type QueryGetHardwareCategoriesArgs = {
  where?: Maybe<HardwareCategoryWhereInput>;
  orderBy?: Maybe<
    Array<HardwareCategoryOrderByWithRelationAndSearchRelevanceInput>
  >;
  cursor?: Maybe<HardwareCategoryWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<HardwareCategoryScalarFieldEnum>>;
};

export type QueryClaimArgs = {
  id: Scalars["Int"];
};

export type QueryClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
  orderBy?: Maybe<Array<ClaimOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<ClaimWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<ClaimScalarFieldEnum>>;
};

export type QueryGetClaimStatisticsArgs = {
  where?: Maybe<ClaimWhereInput>;
  orderBy?: Maybe<Array<ClaimOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<ClaimWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<ClaimScalarFieldEnum>>;
};

export type QueryClaimsByCollaboratorArgs = {
  pipeline_slug: Scalars["String"];
  user_id: Scalars["Float"];
};

export type QueryTravelReimbursementAmountArgs = {
  claim_id: Scalars["Int"];
};

export type QueryFileArgs = {
  id: Scalars["Float"];
};

export type QueryFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<Array<FileOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<FileWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<FileScalarFieldEnum>>;
};

export type QueryOutlineDocumentArgs = {
  share_id: Scalars["String"];
};

export type QueryFieldArgs = {
  id: Scalars["Float"];
};

export type QueryGetExtendedProfileArgs = {
  user_id: Scalars["Float"];
};

export type QueryEventArgs = {
  slug: Scalars["String"];
};

export type QueryEventsArgs = {
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<Array<EventOrderByWithRelationAndSearchRelevanceInput>>;
  cursor?: Maybe<EventWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<EventScalarFieldEnum>>;
};

export type QueryEventAttendeeArgs = {
  where: EventAttendeeWhereUniqueInput;
};

export type QueryEventAttendeesArgs = {
  where?: Maybe<EventAttendeeWhereInput>;
  orderBy?: Maybe<
    Array<EventAttendeeOrderByWithRelationAndSearchRelevanceInput>
  >;
  cursor?: Maybe<EventAttendeeWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<EventAttendeeScalarFieldEnum>>;
};

export type QueryFindEventAttendeeByEmailArgs = {
  event_id?: Scalars["Float"];
  email: Scalars["String"];
};

export type QueryFindEventAttendeeByBadgeCodeArgs = {
  event_id?: Scalars["Float"];
  badge_code: Scalars["String"];
};

export type QueryFindUserActivityScansForEventActivityByUserIdArgs = {
  include_duplicates: Scalars["Boolean"];
  event_activity_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type QueryFindUserActivityScansForEventActivityByBadgeCodeArgs = {
  include_duplicates: Scalars["Boolean"];
  event_activity_id: Scalars["Float"];
  badge_code: Scalars["String"];
};

export type QueryFindUserActivityScansByUserIdArgs = {
  user_id: Scalars["Float"];
};

export type QueryFindUserActivityScansByBadgeCodeArgs = {
  badge_code: Scalars["String"];
};

export type QueryFindAllActivityScansByEventActivityIdArgs = {
  event_activity_id: Scalars["Float"];
};

export type QueryFindAllRedemptionsByBadgeCodeArgs = {
  badge_code: Scalars["String"];
};

export type QueryFindAllRedemptionsByUserIdArgs = {
  user_id: Scalars["Float"];
};

export type QueryFindAllRedemptionsByEventActivityIdArgs = {
  event_activity_id: Scalars["Float"];
};

export type QueryFindUserRedemptionsForEventActivityArgs = {
  event_activity_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type QueryFindEventActivityByIdArgs = {
  id: Scalars["Float"];
};

export type QueryFindUniqueEventActivityArgs = {
  where: EventActivityWhereUniqueInput;
};

export type QueryFindManyEventActivityArgs = {
  where?: Maybe<EventActivityWhereInput>;
  orderBy?: Maybe<
    Array<EventActivityOrderByWithRelationAndSearchRelevanceInput>
  >;
  cursor?: Maybe<EventActivityWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<EventActivityScalarFieldEnum>>;
};

export type QueryEmailsArgs = {
  email: Scalars["String"];
};

export type QueryCompanyArgs = {
  id: Scalars["Float"];
};

export type QueryCollaboratorsArgs = {
  claim_id: Scalars["Float"];
};

export type QueryGetBusAttendeesByBusIdArgs = {
  id: Scalars["Float"];
};

export type QueryGetBusAttendeesByBusNameArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type QueryFindBusByNameArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type QueryFindBusByIdArgs = {
  id: Scalars["Float"];
};

export type QueryFindBusesByLocationArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
  location: Scalars["String"];
};

export type QueryFindBusesByEventArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
};

export type QueryFindMyBusesByEventArgs = {
  eventSlug?: Maybe<Scalars["String"]>;
};

export type QueryBusStopArgs = {
  where: BusStopWhereUniqueInput;
};

export type QueryFetchNetworkingScanArgs = {
  badge_scan_id: Scalars["Float"];
};

export type QueryIssueSponsorJwtArgs = {
  sponsor_company_slug: Scalars["String"];
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export enum RecordTypeEmail {
  Claim = "claim",
  Event = "event",
  File = "file",
  Pipeline = "pipeline",
  User = "user",
}

export type ReimbursementAmount = {
  __typename?: "ReimbursementAmount";
  amount: Scalars["Float"];
};

export type RepresentativeCreateManySponsorInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type RepresentativeCreateManySponsorInputEnvelope = {
  data: Array<RepresentativeCreateManySponsorInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type RepresentativeCreateManyUserInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  sponsor_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type RepresentativeCreateManyUserInputEnvelope = {
  data: Array<RepresentativeCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type RepresentativeCreateNestedManyWithoutSponsorInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutSponsorInput>>;
  connectOrCreate?: Maybe<
    Array<RepresentativeCreateOrConnectWithoutSponsorInput>
  >;
  createMany?: Maybe<RepresentativeCreateManySponsorInputEnvelope>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
};

export type RepresentativeCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<RepresentativeCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<RepresentativeCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
};

export type RepresentativeCreateOrConnectWithoutSponsorInput = {
  where: RepresentativeWhereUniqueInput;
  create: RepresentativeCreateWithoutSponsorInput;
};

export type RepresentativeCreateOrConnectWithoutUserInput = {
  where: RepresentativeWhereUniqueInput;
  create: RepresentativeCreateWithoutUserInput;
};

export type RepresentativeCreateWithoutSponsorInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutRepresentativesInput;
};

export type RepresentativeCreateWithoutUserInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  sponsor: SponsorCreateNestedOneWithoutRepresentativesInput;
};

export type RepresentativeCreateskillsInput = {
  set: Array<Scalars["String"]>;
};

export type RepresentativeListRelationFilter = {
  every?: Maybe<RepresentativeWhereInput>;
  some?: Maybe<RepresentativeWhereInput>;
  none?: Maybe<RepresentativeWhereInput>;
};

export type RepresentativeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type RepresentativeScalarWhereInput = {
  AND?: Maybe<Array<RepresentativeScalarWhereInput>>;
  OR?: Maybe<Array<RepresentativeScalarWhereInput>>;
  NOT?: Maybe<Array<RepresentativeScalarWhereInput>>;
  activated?: Maybe<BoolNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  is_poc?: Maybe<BoolNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  skills?: Maybe<StringNullableListFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type RepresentativeUpdateManyMutationInput = {
  activated?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_poc?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  skills?: Maybe<RepresentativeUpdateskillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type RepresentativeUpdateManyWithWhereWithoutSponsorInput = {
  where: RepresentativeScalarWhereInput;
  data: RepresentativeUpdateManyMutationInput;
};

export type RepresentativeUpdateManyWithWhereWithoutUserInput = {
  where: RepresentativeScalarWhereInput;
  data: RepresentativeUpdateManyMutationInput;
};

export type RepresentativeUpdateManyWithoutSponsorNestedInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutSponsorInput>>;
  connectOrCreate?: Maybe<
    Array<RepresentativeCreateOrConnectWithoutSponsorInput>
  >;
  upsert?: Maybe<Array<RepresentativeUpsertWithWhereUniqueWithoutSponsorInput>>;
  createMany?: Maybe<RepresentativeCreateManySponsorInputEnvelope>;
  set?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  disconnect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  delete?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  update?: Maybe<Array<RepresentativeUpdateWithWhereUniqueWithoutSponsorInput>>;
  updateMany?: Maybe<
    Array<RepresentativeUpdateManyWithWhereWithoutSponsorInput>
  >;
  deleteMany?: Maybe<Array<RepresentativeScalarWhereInput>>;
};

export type RepresentativeUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<RepresentativeCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<RepresentativeUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<RepresentativeCreateManyUserInputEnvelope>;
  set?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  disconnect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  delete?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  update?: Maybe<Array<RepresentativeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<RepresentativeUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<RepresentativeScalarWhereInput>>;
};

export type RepresentativeUpdateWithWhereUniqueWithoutSponsorInput = {
  where: RepresentativeWhereUniqueInput;
  data: RepresentativeUpdateWithoutSponsorInput;
};

export type RepresentativeUpdateWithWhereUniqueWithoutUserInput = {
  where: RepresentativeWhereUniqueInput;
  data: RepresentativeUpdateWithoutUserInput;
};

export type RepresentativeUpdateWithoutSponsorInput = {
  activated?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_poc?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  skills?: Maybe<RepresentativeUpdateskillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutRepresentativesNestedInput>;
};

export type RepresentativeUpdateWithoutUserInput = {
  activated?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_poc?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  skills?: Maybe<RepresentativeUpdateskillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sponsor?: Maybe<SponsorUpdateOneRequiredWithoutRepresentativesNestedInput>;
};

export type RepresentativeUpdateskillsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type RepresentativeUpsertWithWhereUniqueWithoutSponsorInput = {
  where: RepresentativeWhereUniqueInput;
  update: RepresentativeUpdateWithoutSponsorInput;
  create: RepresentativeCreateWithoutSponsorInput;
};

export type RepresentativeUpsertWithWhereUniqueWithoutUserInput = {
  where: RepresentativeWhereUniqueInput;
  update: RepresentativeUpdateWithoutUserInput;
  create: RepresentativeCreateWithoutUserInput;
};

export type RepresentativeWhereInput = {
  AND?: Maybe<Array<RepresentativeWhereInput>>;
  OR?: Maybe<Array<RepresentativeWhereInput>>;
  NOT?: Maybe<Array<RepresentativeWhereInput>>;
  activated?: Maybe<BoolNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  is_poc?: Maybe<BoolNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  skills?: Maybe<StringNullableListFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type RepresentativeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<RepresentativeWhereInput>>;
  OR?: Maybe<Array<RepresentativeWhereInput>>;
  NOT?: Maybe<Array<RepresentativeWhereInput>>;
  activated?: Maybe<BoolNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  is_poc?: Maybe<BoolNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  skills?: Maybe<StringNullableListFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type Review = {
  __typename?: "Review";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  note?: Maybe<Scalars["String"]>;
  reviewer_id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
};

export type ReviewCreateManyClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  reviewer_id: Scalars["Int"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type ReviewCreateManyClaimInputEnvelope = {
  data: Array<ReviewCreateManyClaimInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ReviewCreateManyUserInput = {
  claim_id: Scalars["Int"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type ReviewCreateManyUserInputEnvelope = {
  data: Array<ReviewCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ReviewCreateNestedManyWithoutClaimInput = {
  create?: Maybe<Array<ReviewCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutClaimInput>>;
  createMany?: Maybe<ReviewCreateManyClaimInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
};

export type ReviewCreateOrConnectWithoutClaimInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewCreateWithoutClaimInput;
};

export type ReviewCreateOrConnectWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewCreateWithoutUserInput;
};

export type ReviewCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user: UserCreateNestedOneWithoutReviewsInput;
};

export type ReviewCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  claim: ClaimCreateNestedOneWithoutReviewsInput;
};

export type ReviewLeaderboardUser = {
  __typename?: "ReviewLeaderboardUser";
  user_id: Scalars["Float"];
  user_name: Scalars["String"];
  user_email: Scalars["String"];
  reviews_count_total: Scalars["Float"];
  reviews_count_24h: Scalars["Float"];
};

export type ReviewListRelationFilter = {
  every?: Maybe<ReviewWhereInput>;
  some?: Maybe<ReviewWhereInput>;
  none?: Maybe<ReviewWhereInput>;
};

export type ReviewOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ReviewScalarWhereInput = {
  AND?: Maybe<Array<ReviewScalarWhereInput>>;
  OR?: Maybe<Array<ReviewScalarWhereInput>>;
  NOT?: Maybe<Array<ReviewScalarWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  note?: Maybe<StringNullableFilter>;
  reviewer_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type ReviewUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReviewUpdateManyWithWhereWithoutClaimInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUpdateManyMutationInput;
};

export type ReviewUpdateManyWithWhereWithoutUserInput = {
  where: ReviewScalarWhereInput;
  data: ReviewUpdateManyMutationInput;
};

export type ReviewUpdateManyWithoutClaimNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutClaimInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutClaimInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutClaimInput>>;
  createMany?: Maybe<ReviewCreateManyClaimInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutClaimInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutClaimInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<ReviewUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<ReviewCreateManyUserInputEnvelope>;
  set?: Maybe<Array<ReviewWhereUniqueInput>>;
  disconnect?: Maybe<Array<ReviewWhereUniqueInput>>;
  delete?: Maybe<Array<ReviewWhereUniqueInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  update?: Maybe<Array<ReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<ReviewUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<ReviewScalarWhereInput>>;
};

export type ReviewUpdateWithWhereUniqueWithoutClaimInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUpdateWithoutClaimInput;
};

export type ReviewUpdateWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  data: ReviewUpdateWithoutUserInput;
};

export type ReviewUpdateWithoutClaimInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutReviewsNestedInput>;
};

export type ReviewUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  note?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  claim?: Maybe<ClaimUpdateOneRequiredWithoutReviewsNestedInput>;
};

export type ReviewUpsertWithWhereUniqueWithoutClaimInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUpdateWithoutClaimInput;
  create: ReviewCreateWithoutClaimInput;
};

export type ReviewUpsertWithWhereUniqueWithoutUserInput = {
  where: ReviewWhereUniqueInput;
  update: ReviewUpdateWithoutUserInput;
  create: ReviewCreateWithoutUserInput;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  note?: Maybe<StringNullableFilter>;
  reviewer_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  note?: Maybe<StringNullableFilter>;
  reviewer_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ScheduleItem = {
  __typename?: "ScheduleItem";
  created_at: Scalars["DateTimeISO"];
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTimeISO"];
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  links: Array<Scalars["String"]>;
  location: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  tags: Array<Scalars["String"]>;
  title: Scalars["String"];
  updated_at: Scalars["DateTimeISO"];
  banner_link?: Maybe<Scalars["String"]>;
  airtable_id?: Maybe<Scalars["String"]>;
  event_leads: Array<Scalars["String"]>;
};

export type ShiftCreateManyEventInput = {
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  end_time: Scalars["DateTimeISO"];
  max_signups?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  organizer_responsible_user_id?: Maybe<Scalars["Int"]>;
};

export type ShiftCreateManyEventInputEnvelope = {
  data: Array<ShiftCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ShiftCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<ShiftCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<ShiftCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<ShiftCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<ShiftWhereUniqueInput>>;
};

export type ShiftCreateNestedOneWithoutShiftSignupInput = {
  create?: Maybe<ShiftCreateWithoutShiftSignupInput>;
  connectOrCreate?: Maybe<ShiftCreateOrConnectWithoutShiftSignupInput>;
  connect?: Maybe<ShiftWhereUniqueInput>;
};

export type ShiftCreateNestedOneWithoutVolunteerShiftInput = {
  create?: Maybe<ShiftCreateWithoutVolunteerShiftInput>;
  connectOrCreate?: Maybe<ShiftCreateOrConnectWithoutVolunteerShiftInput>;
  connect?: Maybe<ShiftWhereUniqueInput>;
};

export type ShiftCreateOrConnectWithoutEventInput = {
  where: ShiftWhereUniqueInput;
  create: ShiftCreateWithoutEventInput;
};

export type ShiftCreateOrConnectWithoutShiftSignupInput = {
  where: ShiftWhereUniqueInput;
  create: ShiftCreateWithoutShiftSignupInput;
};

export type ShiftCreateOrConnectWithoutVolunteerShiftInput = {
  where: ShiftWhereUniqueInput;
  create: ShiftCreateWithoutVolunteerShiftInput;
};

export type ShiftCreateWithoutEventInput = {
  name: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  end_time: Scalars["DateTimeISO"];
  max_signups?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  organizer_responsible_user_id?: Maybe<Scalars["Int"]>;
  VolunteerShift?: Maybe<VolunteerShiftCreateNestedManyWithoutShiftInput>;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutShiftInput>;
};

export type ShiftCreateWithoutShiftSignupInput = {
  name: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  end_time: Scalars["DateTimeISO"];
  max_signups?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  organizer_responsible_user_id?: Maybe<Scalars["Int"]>;
  event: EventCreateNestedOneWithoutShiftInput;
  VolunteerShift?: Maybe<VolunteerShiftCreateNestedManyWithoutShiftInput>;
};

export type ShiftCreateWithoutVolunteerShiftInput = {
  name: Scalars["String"];
  start_time: Scalars["DateTimeISO"];
  end_time: Scalars["DateTimeISO"];
  max_signups?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  organizer_responsible_user_id?: Maybe<Scalars["Int"]>;
  event: EventCreateNestedOneWithoutShiftInput;
  ShiftSignup?: Maybe<ShiftSignupCreateNestedManyWithoutShiftInput>;
};

export type ShiftHistoryCreateManyModified_ByInput = {
  id?: Maybe<Scalars["Int"]>;
  shift_signup_id: Scalars["Int"];
  status: ShiftStatus;
};

export type ShiftHistoryCreateManyModified_ByInputEnvelope = {
  data: Array<ShiftHistoryCreateManyModified_ByInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ShiftHistoryCreateManyShift_SignupInput = {
  id?: Maybe<Scalars["Int"]>;
  modified_by_user_id: Scalars["Int"];
  status: ShiftStatus;
};

export type ShiftHistoryCreateManyShift_SignupInputEnvelope = {
  data: Array<ShiftHistoryCreateManyShift_SignupInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ShiftHistoryCreateNestedManyWithoutModified_ByInput = {
  create?: Maybe<Array<ShiftHistoryCreateWithoutModified_ByInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftHistoryCreateOrConnectWithoutModified_ByInput>
  >;
  createMany?: Maybe<ShiftHistoryCreateManyModified_ByInputEnvelope>;
  connect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
};

export type ShiftHistoryCreateNestedManyWithoutShift_SignupInput = {
  create?: Maybe<Array<ShiftHistoryCreateWithoutShift_SignupInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftHistoryCreateOrConnectWithoutShift_SignupInput>
  >;
  createMany?: Maybe<ShiftHistoryCreateManyShift_SignupInputEnvelope>;
  connect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
};

export type ShiftHistoryCreateOrConnectWithoutModified_ByInput = {
  where: ShiftHistoryWhereUniqueInput;
  create: ShiftHistoryCreateWithoutModified_ByInput;
};

export type ShiftHistoryCreateOrConnectWithoutShift_SignupInput = {
  where: ShiftHistoryWhereUniqueInput;
  create: ShiftHistoryCreateWithoutShift_SignupInput;
};

export type ShiftHistoryCreateWithoutModified_ByInput = {
  status: ShiftStatus;
  shift_signup: ShiftSignupCreateNestedOneWithoutHistory_EntriesInput;
};

export type ShiftHistoryCreateWithoutShift_SignupInput = {
  status: ShiftStatus;
  modified_by: UserCreateNestedOneWithoutShift_History_ModificationsInput;
};

export type ShiftHistoryListRelationFilter = {
  every?: Maybe<ShiftHistoryWhereInput>;
  some?: Maybe<ShiftHistoryWhereInput>;
  none?: Maybe<ShiftHistoryWhereInput>;
};

export type ShiftHistoryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ShiftHistoryScalarWhereInput = {
  AND?: Maybe<Array<ShiftHistoryScalarWhereInput>>;
  OR?: Maybe<Array<ShiftHistoryScalarWhereInput>>;
  NOT?: Maybe<Array<ShiftHistoryScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  modified_by_user_id?: Maybe<IntFilter>;
  shift_signup_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
};

export type ShiftHistoryUpdateManyMutationInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
};

export type ShiftHistoryUpdateManyWithWhereWithoutModified_ByInput = {
  where: ShiftHistoryScalarWhereInput;
  data: ShiftHistoryUpdateManyMutationInput;
};

export type ShiftHistoryUpdateManyWithWhereWithoutShift_SignupInput = {
  where: ShiftHistoryScalarWhereInput;
  data: ShiftHistoryUpdateManyMutationInput;
};

export type ShiftHistoryUpdateManyWithoutModified_ByNestedInput = {
  create?: Maybe<Array<ShiftHistoryCreateWithoutModified_ByInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftHistoryCreateOrConnectWithoutModified_ByInput>
  >;
  upsert?: Maybe<
    Array<ShiftHistoryUpsertWithWhereUniqueWithoutModified_ByInput>
  >;
  createMany?: Maybe<ShiftHistoryCreateManyModified_ByInputEnvelope>;
  set?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  update?: Maybe<
    Array<ShiftHistoryUpdateWithWhereUniqueWithoutModified_ByInput>
  >;
  updateMany?: Maybe<
    Array<ShiftHistoryUpdateManyWithWhereWithoutModified_ByInput>
  >;
  deleteMany?: Maybe<Array<ShiftHistoryScalarWhereInput>>;
};

export type ShiftHistoryUpdateManyWithoutShift_SignupNestedInput = {
  create?: Maybe<Array<ShiftHistoryCreateWithoutShift_SignupInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftHistoryCreateOrConnectWithoutShift_SignupInput>
  >;
  upsert?: Maybe<
    Array<ShiftHistoryUpsertWithWhereUniqueWithoutShift_SignupInput>
  >;
  createMany?: Maybe<ShiftHistoryCreateManyShift_SignupInputEnvelope>;
  set?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  delete?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  connect?: Maybe<Array<ShiftHistoryWhereUniqueInput>>;
  update?: Maybe<
    Array<ShiftHistoryUpdateWithWhereUniqueWithoutShift_SignupInput>
  >;
  updateMany?: Maybe<
    Array<ShiftHistoryUpdateManyWithWhereWithoutShift_SignupInput>
  >;
  deleteMany?: Maybe<Array<ShiftHistoryScalarWhereInput>>;
};

export type ShiftHistoryUpdateWithWhereUniqueWithoutModified_ByInput = {
  where: ShiftHistoryWhereUniqueInput;
  data: ShiftHistoryUpdateWithoutModified_ByInput;
};

export type ShiftHistoryUpdateWithWhereUniqueWithoutShift_SignupInput = {
  where: ShiftHistoryWhereUniqueInput;
  data: ShiftHistoryUpdateWithoutShift_SignupInput;
};

export type ShiftHistoryUpdateWithoutModified_ByInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
  shift_signup?: Maybe<ShiftSignupUpdateOneRequiredWithoutHistory_EntriesNestedInput>;
};

export type ShiftHistoryUpdateWithoutShift_SignupInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
  modified_by?: Maybe<UserUpdateOneRequiredWithoutShift_History_ModificationsNestedInput>;
};

export type ShiftHistoryUpsertWithWhereUniqueWithoutModified_ByInput = {
  where: ShiftHistoryWhereUniqueInput;
  update: ShiftHistoryUpdateWithoutModified_ByInput;
  create: ShiftHistoryCreateWithoutModified_ByInput;
};

export type ShiftHistoryUpsertWithWhereUniqueWithoutShift_SignupInput = {
  where: ShiftHistoryWhereUniqueInput;
  update: ShiftHistoryUpdateWithoutShift_SignupInput;
  create: ShiftHistoryCreateWithoutShift_SignupInput;
};

export type ShiftHistoryWhereInput = {
  AND?: Maybe<Array<ShiftHistoryWhereInput>>;
  OR?: Maybe<Array<ShiftHistoryWhereInput>>;
  NOT?: Maybe<Array<ShiftHistoryWhereInput>>;
  id?: Maybe<IntFilter>;
  modified_by_user_id?: Maybe<IntFilter>;
  shift_signup_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
  modified_by?: Maybe<UserRelationFilter>;
  shift_signup?: Maybe<ShiftSignupRelationFilter>;
};

export type ShiftHistoryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<ShiftHistoryWhereInput>>;
  OR?: Maybe<Array<ShiftHistoryWhereInput>>;
  NOT?: Maybe<Array<ShiftHistoryWhereInput>>;
  modified_by_user_id?: Maybe<IntFilter>;
  shift_signup_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
  modified_by?: Maybe<UserRelationFilter>;
  shift_signup?: Maybe<ShiftSignupRelationFilter>;
};

export type ShiftListRelationFilter = {
  every?: Maybe<ShiftWhereInput>;
  some?: Maybe<ShiftWhereInput>;
  none?: Maybe<ShiftWhereInput>;
};

export type ShiftOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ShiftRelationFilter = {
  is?: Maybe<ShiftWhereInput>;
  isNot?: Maybe<ShiftWhereInput>;
};

export type ShiftScalarWhereInput = {
  AND?: Maybe<Array<ShiftScalarWhereInput>>;
  OR?: Maybe<Array<ShiftScalarWhereInput>>;
  NOT?: Maybe<Array<ShiftScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  max_signups?: Maybe<IntNullableFilter>;
  location?: Maybe<StringNullableFilter>;
  organizer_responsible_user_id?: Maybe<IntNullableFilter>;
};

export type ShiftSignupCreateManyEvent_AttendeeInput = {
  id?: Maybe<Scalars["Int"]>;
  shift_id: Scalars["Int"];
  status: ShiftStatus;
};

export type ShiftSignupCreateManyEvent_AttendeeInputEnvelope = {
  data: Array<ShiftSignupCreateManyEvent_AttendeeInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ShiftSignupCreateManyShiftInput = {
  id?: Maybe<Scalars["Int"]>;
  event_attendee_id: Scalars["Int"];
  status: ShiftStatus;
};

export type ShiftSignupCreateManyShiftInputEnvelope = {
  data: Array<ShiftSignupCreateManyShiftInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type ShiftSignupCreateNestedManyWithoutEvent_AttendeeInput = {
  create?: Maybe<Array<ShiftSignupCreateWithoutEvent_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftSignupCreateOrConnectWithoutEvent_AttendeeInput>
  >;
  createMany?: Maybe<ShiftSignupCreateManyEvent_AttendeeInputEnvelope>;
  connect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
};

export type ShiftSignupCreateNestedManyWithoutShiftInput = {
  create?: Maybe<Array<ShiftSignupCreateWithoutShiftInput>>;
  connectOrCreate?: Maybe<Array<ShiftSignupCreateOrConnectWithoutShiftInput>>;
  createMany?: Maybe<ShiftSignupCreateManyShiftInputEnvelope>;
  connect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
};

export type ShiftSignupCreateNestedOneWithoutHistory_EntriesInput = {
  create?: Maybe<ShiftSignupCreateWithoutHistory_EntriesInput>;
  connectOrCreate?: Maybe<ShiftSignupCreateOrConnectWithoutHistory_EntriesInput>;
  connect?: Maybe<ShiftSignupWhereUniqueInput>;
};

export type ShiftSignupCreateOrConnectWithoutEvent_AttendeeInput = {
  where: ShiftSignupWhereUniqueInput;
  create: ShiftSignupCreateWithoutEvent_AttendeeInput;
};

export type ShiftSignupCreateOrConnectWithoutHistory_EntriesInput = {
  where: ShiftSignupWhereUniqueInput;
  create: ShiftSignupCreateWithoutHistory_EntriesInput;
};

export type ShiftSignupCreateOrConnectWithoutShiftInput = {
  where: ShiftSignupWhereUniqueInput;
  create: ShiftSignupCreateWithoutShiftInput;
};

export type ShiftSignupCreateWithoutEvent_AttendeeInput = {
  status: ShiftStatus;
  shift: ShiftCreateNestedOneWithoutShiftSignupInput;
  history_entries?: Maybe<ShiftHistoryCreateNestedManyWithoutShift_SignupInput>;
};

export type ShiftSignupCreateWithoutHistory_EntriesInput = {
  status: ShiftStatus;
  event_attendee: EventAttendeeCreateNestedOneWithoutShiftSignupInput;
  shift: ShiftCreateNestedOneWithoutShiftSignupInput;
};

export type ShiftSignupCreateWithoutShiftInput = {
  status: ShiftStatus;
  event_attendee: EventAttendeeCreateNestedOneWithoutShiftSignupInput;
  history_entries?: Maybe<ShiftHistoryCreateNestedManyWithoutShift_SignupInput>;
};

export type ShiftSignupEvent_Attendee_IdShift_IdCompoundUniqueInput = {
  event_attendee_id: Scalars["Int"];
  shift_id: Scalars["Int"];
};

export type ShiftSignupListRelationFilter = {
  every?: Maybe<ShiftSignupWhereInput>;
  some?: Maybe<ShiftSignupWhereInput>;
  none?: Maybe<ShiftSignupWhereInput>;
};

export type ShiftSignupOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ShiftSignupRelationFilter = {
  is?: Maybe<ShiftSignupWhereInput>;
  isNot?: Maybe<ShiftSignupWhereInput>;
};

export type ShiftSignupScalarWhereInput = {
  AND?: Maybe<Array<ShiftSignupScalarWhereInput>>;
  OR?: Maybe<Array<ShiftSignupScalarWhereInput>>;
  NOT?: Maybe<Array<ShiftSignupScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  event_attendee_id?: Maybe<IntFilter>;
  shift_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
};

export type ShiftSignupUpdateManyMutationInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
};

export type ShiftSignupUpdateManyWithWhereWithoutEvent_AttendeeInput = {
  where: ShiftSignupScalarWhereInput;
  data: ShiftSignupUpdateManyMutationInput;
};

export type ShiftSignupUpdateManyWithWhereWithoutShiftInput = {
  where: ShiftSignupScalarWhereInput;
  data: ShiftSignupUpdateManyMutationInput;
};

export type ShiftSignupUpdateManyWithoutEvent_AttendeeNestedInput = {
  create?: Maybe<Array<ShiftSignupCreateWithoutEvent_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<ShiftSignupCreateOrConnectWithoutEvent_AttendeeInput>
  >;
  upsert?: Maybe<
    Array<ShiftSignupUpsertWithWhereUniqueWithoutEvent_AttendeeInput>
  >;
  createMany?: Maybe<ShiftSignupCreateManyEvent_AttendeeInputEnvelope>;
  set?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  delete?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  connect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  update?: Maybe<
    Array<ShiftSignupUpdateWithWhereUniqueWithoutEvent_AttendeeInput>
  >;
  updateMany?: Maybe<
    Array<ShiftSignupUpdateManyWithWhereWithoutEvent_AttendeeInput>
  >;
  deleteMany?: Maybe<Array<ShiftSignupScalarWhereInput>>;
};

export type ShiftSignupUpdateManyWithoutShiftNestedInput = {
  create?: Maybe<Array<ShiftSignupCreateWithoutShiftInput>>;
  connectOrCreate?: Maybe<Array<ShiftSignupCreateOrConnectWithoutShiftInput>>;
  upsert?: Maybe<Array<ShiftSignupUpsertWithWhereUniqueWithoutShiftInput>>;
  createMany?: Maybe<ShiftSignupCreateManyShiftInputEnvelope>;
  set?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  delete?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  connect?: Maybe<Array<ShiftSignupWhereUniqueInput>>;
  update?: Maybe<Array<ShiftSignupUpdateWithWhereUniqueWithoutShiftInput>>;
  updateMany?: Maybe<Array<ShiftSignupUpdateManyWithWhereWithoutShiftInput>>;
  deleteMany?: Maybe<Array<ShiftSignupScalarWhereInput>>;
};

export type ShiftSignupUpdateOneRequiredWithoutHistory_EntriesNestedInput = {
  create?: Maybe<ShiftSignupCreateWithoutHistory_EntriesInput>;
  connectOrCreate?: Maybe<ShiftSignupCreateOrConnectWithoutHistory_EntriesInput>;
  upsert?: Maybe<ShiftSignupUpsertWithoutHistory_EntriesInput>;
  connect?: Maybe<ShiftSignupWhereUniqueInput>;
  update?: Maybe<ShiftSignupUpdateToOneWithWhereWithoutHistory_EntriesInput>;
};

export type ShiftSignupUpdateToOneWithWhereWithoutHistory_EntriesInput = {
  where?: Maybe<ShiftSignupWhereInput>;
  data: ShiftSignupUpdateWithoutHistory_EntriesInput;
};

export type ShiftSignupUpdateWithWhereUniqueWithoutEvent_AttendeeInput = {
  where: ShiftSignupWhereUniqueInput;
  data: ShiftSignupUpdateWithoutEvent_AttendeeInput;
};

export type ShiftSignupUpdateWithWhereUniqueWithoutShiftInput = {
  where: ShiftSignupWhereUniqueInput;
  data: ShiftSignupUpdateWithoutShiftInput;
};

export type ShiftSignupUpdateWithoutEvent_AttendeeInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
  shift?: Maybe<ShiftUpdateOneRequiredWithoutShiftSignupNestedInput>;
  history_entries?: Maybe<ShiftHistoryUpdateManyWithoutShift_SignupNestedInput>;
};

export type ShiftSignupUpdateWithoutHistory_EntriesInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
  event_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutShiftSignupNestedInput>;
  shift?: Maybe<ShiftUpdateOneRequiredWithoutShiftSignupNestedInput>;
};

export type ShiftSignupUpdateWithoutShiftInput = {
  status?: Maybe<EnumShiftStatusFieldUpdateOperationsInput>;
  event_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutShiftSignupNestedInput>;
  history_entries?: Maybe<ShiftHistoryUpdateManyWithoutShift_SignupNestedInput>;
};

export type ShiftSignupUpsertWithWhereUniqueWithoutEvent_AttendeeInput = {
  where: ShiftSignupWhereUniqueInput;
  update: ShiftSignupUpdateWithoutEvent_AttendeeInput;
  create: ShiftSignupCreateWithoutEvent_AttendeeInput;
};

export type ShiftSignupUpsertWithWhereUniqueWithoutShiftInput = {
  where: ShiftSignupWhereUniqueInput;
  update: ShiftSignupUpdateWithoutShiftInput;
  create: ShiftSignupCreateWithoutShiftInput;
};

export type ShiftSignupUpsertWithoutHistory_EntriesInput = {
  update: ShiftSignupUpdateWithoutHistory_EntriesInput;
  create: ShiftSignupCreateWithoutHistory_EntriesInput;
  where?: Maybe<ShiftSignupWhereInput>;
};

export type ShiftSignupWhereInput = {
  AND?: Maybe<Array<ShiftSignupWhereInput>>;
  OR?: Maybe<Array<ShiftSignupWhereInput>>;
  NOT?: Maybe<Array<ShiftSignupWhereInput>>;
  id?: Maybe<IntFilter>;
  event_attendee_id?: Maybe<IntFilter>;
  shift_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
  event_attendee?: Maybe<EventAttendeeRelationFilter>;
  shift?: Maybe<ShiftRelationFilter>;
  history_entries?: Maybe<ShiftHistoryListRelationFilter>;
};

export type ShiftSignupWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  event_attendee_id_shift_id?: Maybe<ShiftSignupEvent_Attendee_IdShift_IdCompoundUniqueInput>;
  AND?: Maybe<Array<ShiftSignupWhereInput>>;
  OR?: Maybe<Array<ShiftSignupWhereInput>>;
  NOT?: Maybe<Array<ShiftSignupWhereInput>>;
  event_attendee_id?: Maybe<IntFilter>;
  shift_id?: Maybe<IntFilter>;
  status?: Maybe<EnumShiftStatusFilter>;
  event_attendee?: Maybe<EventAttendeeRelationFilter>;
  shift?: Maybe<ShiftRelationFilter>;
  history_entries?: Maybe<ShiftHistoryListRelationFilter>;
};

export enum ShiftStatus {
  Registered = "REGISTERED",
  Cancelled = "CANCELLED",
}

export type ShiftUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  max_signups?: Maybe<NullableIntFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  organizer_responsible_user_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type ShiftUpdateManyWithWhereWithoutEventInput = {
  where: ShiftScalarWhereInput;
  data: ShiftUpdateManyMutationInput;
};

export type ShiftUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<ShiftCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<ShiftCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<ShiftUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<ShiftCreateManyEventInputEnvelope>;
  set?: Maybe<Array<ShiftWhereUniqueInput>>;
  disconnect?: Maybe<Array<ShiftWhereUniqueInput>>;
  delete?: Maybe<Array<ShiftWhereUniqueInput>>;
  connect?: Maybe<Array<ShiftWhereUniqueInput>>;
  update?: Maybe<Array<ShiftUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<ShiftUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<ShiftScalarWhereInput>>;
};

export type ShiftUpdateOneRequiredWithoutShiftSignupNestedInput = {
  create?: Maybe<ShiftCreateWithoutShiftSignupInput>;
  connectOrCreate?: Maybe<ShiftCreateOrConnectWithoutShiftSignupInput>;
  upsert?: Maybe<ShiftUpsertWithoutShiftSignupInput>;
  connect?: Maybe<ShiftWhereUniqueInput>;
  update?: Maybe<ShiftUpdateToOneWithWhereWithoutShiftSignupInput>;
};

export type ShiftUpdateOneRequiredWithoutVolunteerShiftNestedInput = {
  create?: Maybe<ShiftCreateWithoutVolunteerShiftInput>;
  connectOrCreate?: Maybe<ShiftCreateOrConnectWithoutVolunteerShiftInput>;
  upsert?: Maybe<ShiftUpsertWithoutVolunteerShiftInput>;
  connect?: Maybe<ShiftWhereUniqueInput>;
  update?: Maybe<ShiftUpdateToOneWithWhereWithoutVolunteerShiftInput>;
};

export type ShiftUpdateToOneWithWhereWithoutShiftSignupInput = {
  where?: Maybe<ShiftWhereInput>;
  data: ShiftUpdateWithoutShiftSignupInput;
};

export type ShiftUpdateToOneWithWhereWithoutVolunteerShiftInput = {
  where?: Maybe<ShiftWhereInput>;
  data: ShiftUpdateWithoutVolunteerShiftInput;
};

export type ShiftUpdateWithWhereUniqueWithoutEventInput = {
  where: ShiftWhereUniqueInput;
  data: ShiftUpdateWithoutEventInput;
};

export type ShiftUpdateWithoutEventInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  max_signups?: Maybe<NullableIntFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  organizer_responsible_user_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  VolunteerShift?: Maybe<VolunteerShiftUpdateManyWithoutShiftNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutShiftNestedInput>;
};

export type ShiftUpdateWithoutShiftSignupInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  max_signups?: Maybe<NullableIntFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  organizer_responsible_user_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutShiftNestedInput>;
  VolunteerShift?: Maybe<VolunteerShiftUpdateManyWithoutShiftNestedInput>;
};

export type ShiftUpdateWithoutVolunteerShiftInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  max_signups?: Maybe<NullableIntFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  organizer_responsible_user_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutShiftNestedInput>;
  ShiftSignup?: Maybe<ShiftSignupUpdateManyWithoutShiftNestedInput>;
};

export type ShiftUpsertWithWhereUniqueWithoutEventInput = {
  where: ShiftWhereUniqueInput;
  update: ShiftUpdateWithoutEventInput;
  create: ShiftCreateWithoutEventInput;
};

export type ShiftUpsertWithoutShiftSignupInput = {
  update: ShiftUpdateWithoutShiftSignupInput;
  create: ShiftCreateWithoutShiftSignupInput;
  where?: Maybe<ShiftWhereInput>;
};

export type ShiftUpsertWithoutVolunteerShiftInput = {
  update: ShiftUpdateWithoutVolunteerShiftInput;
  create: ShiftCreateWithoutVolunteerShiftInput;
  where?: Maybe<ShiftWhereInput>;
};

export type ShiftWhereInput = {
  AND?: Maybe<Array<ShiftWhereInput>>;
  OR?: Maybe<Array<ShiftWhereInput>>;
  NOT?: Maybe<Array<ShiftWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  max_signups?: Maybe<IntNullableFilter>;
  location?: Maybe<StringNullableFilter>;
  organizer_responsible_user_id?: Maybe<IntNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  VolunteerShift?: Maybe<VolunteerShiftListRelationFilter>;
  ShiftSignup?: Maybe<ShiftSignupListRelationFilter>;
};

export type ShiftWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<ShiftWhereInput>>;
  OR?: Maybe<Array<ShiftWhereInput>>;
  NOT?: Maybe<Array<ShiftWhereInput>>;
  name?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  max_signups?: Maybe<IntNullableFilter>;
  location?: Maybe<StringNullableFilter>;
  organizer_responsible_user_id?: Maybe<IntNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  VolunteerShift?: Maybe<VolunteerShiftListRelationFilter>;
  ShiftSignup?: Maybe<ShiftSignupListRelationFilter>;
};

export type SignageAnswer = {
  __typename?: "SignageAnswer";
  answer_id: Scalars["Int"];
  respondent_id: Scalars["Int"];
  question_id: Scalars["Int"];
  answered_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  first_name: Scalars["String"];
  value: Scalars["String"];
};

export type SignageAnswerCreateManyRespondentInput = {
  answer_id?: Maybe<Scalars["Int"]>;
  question_id: Scalars["Int"];
  answered_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name: Scalars["String"];
  value: Scalars["String"];
};

export type SignageAnswerCreateManyRespondentInputEnvelope = {
  data: Array<SignageAnswerCreateManyRespondentInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SignageAnswerCreateManySignage_QuestionInput = {
  answer_id?: Maybe<Scalars["Int"]>;
  respondent_id: Scalars["Int"];
  answered_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name: Scalars["String"];
  value: Scalars["String"];
};

export type SignageAnswerCreateManySignage_QuestionInputEnvelope = {
  data: Array<SignageAnswerCreateManySignage_QuestionInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SignageAnswerCreateNestedManyWithoutRespondentInput = {
  create?: Maybe<Array<SignageAnswerCreateWithoutRespondentInput>>;
  connectOrCreate?: Maybe<
    Array<SignageAnswerCreateOrConnectWithoutRespondentInput>
  >;
  createMany?: Maybe<SignageAnswerCreateManyRespondentInputEnvelope>;
  connect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
};

export type SignageAnswerCreateNestedManyWithoutSignage_QuestionInput = {
  create?: Maybe<Array<SignageAnswerCreateWithoutSignage_QuestionInput>>;
  connectOrCreate?: Maybe<
    Array<SignageAnswerCreateOrConnectWithoutSignage_QuestionInput>
  >;
  createMany?: Maybe<SignageAnswerCreateManySignage_QuestionInputEnvelope>;
  connect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
};

export type SignageAnswerCreateOrConnectWithoutRespondentInput = {
  where: SignageAnswerWhereUniqueInput;
  create: SignageAnswerCreateWithoutRespondentInput;
};

export type SignageAnswerCreateOrConnectWithoutSignage_QuestionInput = {
  where: SignageAnswerWhereUniqueInput;
  create: SignageAnswerCreateWithoutSignage_QuestionInput;
};

export type SignageAnswerCreateWithoutRespondentInput = {
  answered_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name: Scalars["String"];
  value: Scalars["String"];
  signage_question: SignageQuestionCreateNestedOneWithoutSignage_AnswersInput;
};

export type SignageAnswerCreateWithoutSignage_QuestionInput = {
  answered_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name: Scalars["String"];
  value: Scalars["String"];
  respondent: EventAttendeeCreateNestedOneWithoutSignage_AnswersInput;
};

export type SignageAnswerListRelationFilter = {
  every?: Maybe<SignageAnswerWhereInput>;
  some?: Maybe<SignageAnswerWhereInput>;
  none?: Maybe<SignageAnswerWhereInput>;
};

export type SignageAnswerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SignageAnswerScalarWhereInput = {
  AND?: Maybe<Array<SignageAnswerScalarWhereInput>>;
  OR?: Maybe<Array<SignageAnswerScalarWhereInput>>;
  NOT?: Maybe<Array<SignageAnswerScalarWhereInput>>;
  answer_id?: Maybe<IntFilter>;
  respondent_id?: Maybe<IntFilter>;
  question_id?: Maybe<IntFilter>;
  answered_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  first_name?: Maybe<StringFilter>;
  value?: Maybe<StringFilter>;
};

export type SignageAnswerUpdateManyMutationInput = {
  answered_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SignageAnswerUpdateManyWithWhereWithoutRespondentInput = {
  where: SignageAnswerScalarWhereInput;
  data: SignageAnswerUpdateManyMutationInput;
};

export type SignageAnswerUpdateManyWithWhereWithoutSignage_QuestionInput = {
  where: SignageAnswerScalarWhereInput;
  data: SignageAnswerUpdateManyMutationInput;
};

export type SignageAnswerUpdateManyWithoutRespondentNestedInput = {
  create?: Maybe<Array<SignageAnswerCreateWithoutRespondentInput>>;
  connectOrCreate?: Maybe<
    Array<SignageAnswerCreateOrConnectWithoutRespondentInput>
  >;
  upsert?: Maybe<
    Array<SignageAnswerUpsertWithWhereUniqueWithoutRespondentInput>
  >;
  createMany?: Maybe<SignageAnswerCreateManyRespondentInputEnvelope>;
  set?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  update?: Maybe<
    Array<SignageAnswerUpdateWithWhereUniqueWithoutRespondentInput>
  >;
  updateMany?: Maybe<
    Array<SignageAnswerUpdateManyWithWhereWithoutRespondentInput>
  >;
  deleteMany?: Maybe<Array<SignageAnswerScalarWhereInput>>;
};

export type SignageAnswerUpdateManyWithoutSignage_QuestionNestedInput = {
  create?: Maybe<Array<SignageAnswerCreateWithoutSignage_QuestionInput>>;
  connectOrCreate?: Maybe<
    Array<SignageAnswerCreateOrConnectWithoutSignage_QuestionInput>
  >;
  upsert?: Maybe<
    Array<SignageAnswerUpsertWithWhereUniqueWithoutSignage_QuestionInput>
  >;
  createMany?: Maybe<SignageAnswerCreateManySignage_QuestionInputEnvelope>;
  set?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  disconnect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  delete?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  connect?: Maybe<Array<SignageAnswerWhereUniqueInput>>;
  update?: Maybe<
    Array<SignageAnswerUpdateWithWhereUniqueWithoutSignage_QuestionInput>
  >;
  updateMany?: Maybe<
    Array<SignageAnswerUpdateManyWithWhereWithoutSignage_QuestionInput>
  >;
  deleteMany?: Maybe<Array<SignageAnswerScalarWhereInput>>;
};

export type SignageAnswerUpdateWithWhereUniqueWithoutRespondentInput = {
  where: SignageAnswerWhereUniqueInput;
  data: SignageAnswerUpdateWithoutRespondentInput;
};

export type SignageAnswerUpdateWithWhereUniqueWithoutSignage_QuestionInput = {
  where: SignageAnswerWhereUniqueInput;
  data: SignageAnswerUpdateWithoutSignage_QuestionInput;
};

export type SignageAnswerUpdateWithoutRespondentInput = {
  answered_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  signage_question?: Maybe<SignageQuestionUpdateOneRequiredWithoutSignage_AnswersNestedInput>;
};

export type SignageAnswerUpdateWithoutSignage_QuestionInput = {
  answered_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
  respondent?: Maybe<EventAttendeeUpdateOneRequiredWithoutSignage_AnswersNestedInput>;
};

export type SignageAnswerUpsertWithWhereUniqueWithoutRespondentInput = {
  where: SignageAnswerWhereUniqueInput;
  update: SignageAnswerUpdateWithoutRespondentInput;
  create: SignageAnswerCreateWithoutRespondentInput;
};

export type SignageAnswerUpsertWithWhereUniqueWithoutSignage_QuestionInput = {
  where: SignageAnswerWhereUniqueInput;
  update: SignageAnswerUpdateWithoutSignage_QuestionInput;
  create: SignageAnswerCreateWithoutSignage_QuestionInput;
};

export type SignageAnswerWhereInput = {
  AND?: Maybe<Array<SignageAnswerWhereInput>>;
  OR?: Maybe<Array<SignageAnswerWhereInput>>;
  NOT?: Maybe<Array<SignageAnswerWhereInput>>;
  answer_id?: Maybe<IntFilter>;
  respondent_id?: Maybe<IntFilter>;
  question_id?: Maybe<IntFilter>;
  answered_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  first_name?: Maybe<StringFilter>;
  value?: Maybe<StringFilter>;
  signage_question?: Maybe<SignageQuestionRelationFilter>;
  respondent?: Maybe<EventAttendeeRelationFilter>;
};

export type SignageAnswerWhereUniqueInput = {
  answer_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<SignageAnswerWhereInput>>;
  OR?: Maybe<Array<SignageAnswerWhereInput>>;
  NOT?: Maybe<Array<SignageAnswerWhereInput>>;
  respondent_id?: Maybe<IntFilter>;
  question_id?: Maybe<IntFilter>;
  answered_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  first_name?: Maybe<StringFilter>;
  value?: Maybe<StringFilter>;
  signage_question?: Maybe<SignageQuestionRelationFilter>;
  respondent?: Maybe<EventAttendeeRelationFilter>;
};

export type SignageQuestion = {
  __typename?: "SignageQuestion";
  question_id: Scalars["Int"];
  space_id?: Maybe<Scalars["Int"]>;
  event_id?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  options: Array<Scalars["String"]>;
  workshop_name?: Maybe<Scalars["String"]>;
  _count?: Maybe<SignageQuestionCount>;
  signage_answers?: Maybe<Array<SignageAnswer>>;
  signage_question_tags?: Maybe<Array<SignageQuestionTag>>;
  answersCount: Scalars["Int"];
};

export type SignageQuestionCount = {
  __typename?: "SignageQuestionCount";
  signage_answers: Scalars["Int"];
};

export type SignageQuestionCountSignage_AnswersArgs = {
  where?: Maybe<SignageAnswerWhereInput>;
};

export type SignageQuestionCreateManyEventInput = {
  question_id?: Maybe<Scalars["Int"]>;
  space_id?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  options?: Maybe<SignageQuestionCreateoptionsInput>;
  workshop_name?: Maybe<Scalars["String"]>;
};

export type SignageQuestionCreateManyEventInputEnvelope = {
  data: Array<SignageQuestionCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SignageQuestionCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<SignageQuestionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SignageQuestionCreateOrConnectWithoutEventInput>
  >;
  createMany?: Maybe<SignageQuestionCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<SignageQuestionWhereUniqueInput>>;
};

export type SignageQuestionCreateNestedOneWithoutSignage_AnswersInput = {
  create?: Maybe<SignageQuestionCreateWithoutSignage_AnswersInput>;
  connectOrCreate?: Maybe<SignageQuestionCreateOrConnectWithoutSignage_AnswersInput>;
  connect?: Maybe<SignageQuestionWhereUniqueInput>;
};

export type SignageQuestionCreateOrConnectWithoutEventInput = {
  where: SignageQuestionWhereUniqueInput;
  create: SignageQuestionCreateWithoutEventInput;
};

export type SignageQuestionCreateOrConnectWithoutSignage_AnswersInput = {
  where: SignageQuestionWhereUniqueInput;
  create: SignageQuestionCreateWithoutSignage_AnswersInput;
};

export type SignageQuestionCreateWithoutEventInput = {
  space_id?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  options?: Maybe<SignageQuestionCreateoptionsInput>;
  workshop_name?: Maybe<Scalars["String"]>;
  signage_answers?: Maybe<SignageAnswerCreateNestedManyWithoutSignage_QuestionInput>;
};

export type SignageQuestionCreateWithoutSignage_AnswersInput = {
  space_id?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  options?: Maybe<SignageQuestionCreateoptionsInput>;
  workshop_name?: Maybe<Scalars["String"]>;
  event?: Maybe<EventCreateNestedOneWithoutSignage_QuestionsInput>;
};

export type SignageQuestionCreateoptionsInput = {
  set: Array<Scalars["String"]>;
};

export type SignageQuestionListRelationFilter = {
  every?: Maybe<SignageQuestionWhereInput>;
  some?: Maybe<SignageQuestionWhereInput>;
  none?: Maybe<SignageQuestionWhereInput>;
};

export type SignageQuestionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SignageQuestionRelationFilter = {
  is?: Maybe<SignageQuestionWhereInput>;
  isNot?: Maybe<SignageQuestionWhereInput>;
};

export type SignageQuestionScalarWhereInput = {
  AND?: Maybe<Array<SignageQuestionScalarWhereInput>>;
  OR?: Maybe<Array<SignageQuestionScalarWhereInput>>;
  NOT?: Maybe<Array<SignageQuestionScalarWhereInput>>;
  question_id?: Maybe<IntFilter>;
  space_id?: Maybe<IntNullableFilter>;
  event_id?: Maybe<IntNullableFilter>;
  title?: Maybe<StringFilter>;
  location?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  options?: Maybe<StringNullableListFilter>;
  workshop_name?: Maybe<StringNullableFilter>;
};

export type SignageQuestionTag = {
  __typename?: "SignageQuestionTag";
  id: Scalars["Int"];
  question_id: Scalars["Int"];
  respondent_id: Scalars["Int"];
  stage: Scalars["String"];
};

export type SignageQuestionUpdateManyMutationInput = {
  space_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<SignageQuestionUpdateoptionsInput>;
  workshop_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SignageQuestionUpdateManyWithWhereWithoutEventInput = {
  where: SignageQuestionScalarWhereInput;
  data: SignageQuestionUpdateManyMutationInput;
};

export type SignageQuestionUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<SignageQuestionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SignageQuestionCreateOrConnectWithoutEventInput>
  >;
  upsert?: Maybe<Array<SignageQuestionUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<SignageQuestionCreateManyEventInputEnvelope>;
  set?: Maybe<Array<SignageQuestionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SignageQuestionWhereUniqueInput>>;
  delete?: Maybe<Array<SignageQuestionWhereUniqueInput>>;
  connect?: Maybe<Array<SignageQuestionWhereUniqueInput>>;
  update?: Maybe<Array<SignageQuestionUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<
    Array<SignageQuestionUpdateManyWithWhereWithoutEventInput>
  >;
  deleteMany?: Maybe<Array<SignageQuestionScalarWhereInput>>;
};

export type SignageQuestionUpdateOneRequiredWithoutSignage_AnswersNestedInput =
  {
    create?: Maybe<SignageQuestionCreateWithoutSignage_AnswersInput>;
    connectOrCreate?: Maybe<SignageQuestionCreateOrConnectWithoutSignage_AnswersInput>;
    upsert?: Maybe<SignageQuestionUpsertWithoutSignage_AnswersInput>;
    connect?: Maybe<SignageQuestionWhereUniqueInput>;
    update?: Maybe<SignageQuestionUpdateToOneWithWhereWithoutSignage_AnswersInput>;
  };

export type SignageQuestionUpdateToOneWithWhereWithoutSignage_AnswersInput = {
  where?: Maybe<SignageQuestionWhereInput>;
  data: SignageQuestionUpdateWithoutSignage_AnswersInput;
};

export type SignageQuestionUpdateWithWhereUniqueWithoutEventInput = {
  where: SignageQuestionWhereUniqueInput;
  data: SignageQuestionUpdateWithoutEventInput;
};

export type SignageQuestionUpdateWithoutEventInput = {
  space_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<SignageQuestionUpdateoptionsInput>;
  workshop_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  signage_answers?: Maybe<SignageAnswerUpdateManyWithoutSignage_QuestionNestedInput>;
};

export type SignageQuestionUpdateWithoutSignage_AnswersInput = {
  space_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  options?: Maybe<SignageQuestionUpdateoptionsInput>;
  workshop_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneWithoutSignage_QuestionsNestedInput>;
};

export type SignageQuestionUpdateoptionsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type SignageQuestionUpsertWithWhereUniqueWithoutEventInput = {
  where: SignageQuestionWhereUniqueInput;
  update: SignageQuestionUpdateWithoutEventInput;
  create: SignageQuestionCreateWithoutEventInput;
};

export type SignageQuestionUpsertWithoutSignage_AnswersInput = {
  update: SignageQuestionUpdateWithoutSignage_AnswersInput;
  create: SignageQuestionCreateWithoutSignage_AnswersInput;
  where?: Maybe<SignageQuestionWhereInput>;
};

export type SignageQuestionWhereInput = {
  AND?: Maybe<Array<SignageQuestionWhereInput>>;
  OR?: Maybe<Array<SignageQuestionWhereInput>>;
  NOT?: Maybe<Array<SignageQuestionWhereInput>>;
  question_id?: Maybe<IntFilter>;
  space_id?: Maybe<IntNullableFilter>;
  event_id?: Maybe<IntNullableFilter>;
  title?: Maybe<StringFilter>;
  location?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  options?: Maybe<StringNullableListFilter>;
  workshop_name?: Maybe<StringNullableFilter>;
  event?: Maybe<EventNullableRelationFilter>;
  signage_answers?: Maybe<SignageAnswerListRelationFilter>;
};

export type SignageQuestionWhereUniqueInput = {
  question_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<SignageQuestionWhereInput>>;
  OR?: Maybe<Array<SignageQuestionWhereInput>>;
  NOT?: Maybe<Array<SignageQuestionWhereInput>>;
  space_id?: Maybe<IntNullableFilter>;
  event_id?: Maybe<IntNullableFilter>;
  title?: Maybe<StringFilter>;
  location?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  options?: Maybe<StringNullableListFilter>;
  workshop_name?: Maybe<StringNullableFilter>;
  event?: Maybe<EventNullableRelationFilter>;
  signage_answers?: Maybe<SignageAnswerListRelationFilter>;
};

/** Options for sorting HTN Bucks accounts for leaderboard */
export enum SortOptions {
  Balance = "BALANCE",
  NumTimesSent = "NUM_TIMES_SENT",
}

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type SortOrderInput = {
  sort: SortOrder;
  nulls?: Maybe<NullsOrder>;
};

export type SponsorCompany = {
  __typename?: "SponsorCompany";
  id: Scalars["Int"];
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  company_id: Scalars["Int"];
  event_slug: Scalars["String"];
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted: Scalars["Boolean"];
  coc_accepted: Scalars["Boolean"];
  _count?: Maybe<SponsorCompanyCount>;
};

export type SponsorCompanyCount = {
  __typename?: "SponsorCompanyCount";
  sponsor_individuals: Scalars["Int"];
  sponsor_scans: Scalars["Int"];
};

export type SponsorCompanyCountSponsor_IndividualsArgs = {
  where?: Maybe<SponsorIndividualWhereInput>;
};

export type SponsorCompanyCountSponsor_ScansArgs = {
  where?: Maybe<SponsorScanWhereInput>;
};

export type SponsorCompanyCreateManyCompanyInput = {
  id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
};

export type SponsorCompanyCreateManyCompanyInputEnvelope = {
  data: Array<SponsorCompanyCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorCompanyCreateManyEventInput = {
  id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  company_id: Scalars["Int"];
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
};

export type SponsorCompanyCreateManyEventInputEnvelope = {
  data: Array<SponsorCompanyCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorCompanyCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<SponsorCompanyCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorCompanyCreateOrConnectWithoutCompanyInput>
  >;
  createMany?: Maybe<SponsorCompanyCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
};

export type SponsorCompanyCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<SponsorCompanyCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorCompanyCreateOrConnectWithoutEventInput>
  >;
  createMany?: Maybe<SponsorCompanyCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
};

export type SponsorCompanyCreateNestedOneWithoutSponsor_IndividualsInput = {
  create?: Maybe<SponsorCompanyCreateWithoutSponsor_IndividualsInput>;
  connectOrCreate?: Maybe<SponsorCompanyCreateOrConnectWithoutSponsor_IndividualsInput>;
  connect?: Maybe<SponsorCompanyWhereUniqueInput>;
};

export type SponsorCompanyCreateNestedOneWithoutSponsor_ScansInput = {
  create?: Maybe<SponsorCompanyCreateWithoutSponsor_ScansInput>;
  connectOrCreate?: Maybe<SponsorCompanyCreateOrConnectWithoutSponsor_ScansInput>;
  connect?: Maybe<SponsorCompanyWhereUniqueInput>;
};

export type SponsorCompanyCreateOrConnectWithoutCompanyInput = {
  where: SponsorCompanyWhereUniqueInput;
  create: SponsorCompanyCreateWithoutCompanyInput;
};

export type SponsorCompanyCreateOrConnectWithoutEventInput = {
  where: SponsorCompanyWhereUniqueInput;
  create: SponsorCompanyCreateWithoutEventInput;
};

export type SponsorCompanyCreateOrConnectWithoutSponsor_IndividualsInput = {
  where: SponsorCompanyWhereUniqueInput;
  create: SponsorCompanyCreateWithoutSponsor_IndividualsInput;
};

export type SponsorCompanyCreateOrConnectWithoutSponsor_ScansInput = {
  where: SponsorCompanyWhereUniqueInput;
  create: SponsorCompanyCreateWithoutSponsor_ScansInput;
};

export type SponsorCompanyCreateWithoutCompanyInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
  event: EventCreateNestedOneWithoutSponsor_CompaniesInput;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutSponsor_CompanyInput>;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_CompanyInput>;
};

export type SponsorCompanyCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
  company: CompanyCreateNestedOneWithoutSponsor_CompaniesInput;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutSponsor_CompanyInput>;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_CompanyInput>;
};

export type SponsorCompanyCreateWithoutSponsor_IndividualsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
  company: CompanyCreateNestedOneWithoutSponsor_CompaniesInput;
  event: EventCreateNestedOneWithoutSponsor_CompaniesInput;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_CompanyInput>;
};

export type SponsorCompanyCreateWithoutSponsor_ScansInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  stage: SponsorCompanyStage;
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  invite_code?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  tier: SponsorCompanyTier;
  slug: Scalars["String"];
  tc_accepted?: Maybe<Scalars["Boolean"]>;
  coc_accepted?: Maybe<Scalars["Boolean"]>;
  company: CompanyCreateNestedOneWithoutSponsor_CompaniesInput;
  event: EventCreateNestedOneWithoutSponsor_CompaniesInput;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutSponsor_CompanyInput>;
};

export type SponsorCompanyListRelationFilter = {
  every?: Maybe<SponsorCompanyWhereInput>;
  some?: Maybe<SponsorCompanyWhereInput>;
  none?: Maybe<SponsorCompanyWhereInput>;
};

export type SponsorCompanyOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SponsorCompanyRelationFilter = {
  is?: Maybe<SponsorCompanyWhereInput>;
  isNot?: Maybe<SponsorCompanyWhereInput>;
};

export type SponsorCompanyScalarWhereInput = {
  AND?: Maybe<Array<SponsorCompanyScalarWhereInput>>;
  OR?: Maybe<Array<SponsorCompanyScalarWhereInput>>;
  NOT?: Maybe<Array<SponsorCompanyScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  company_id?: Maybe<IntFilter>;
  event_slug?: Maybe<StringFilter>;
  stage?: Maybe<EnumSponsorCompanyStageFilter>;
  primary_contact_email?: Maybe<StringNullableFilter>;
  primary_contact_name?: Maybe<StringNullableFilter>;
  invite_code?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  tier?: Maybe<EnumSponsorCompanyTierFilter>;
  slug?: Maybe<StringFilter>;
  tc_accepted?: Maybe<BoolFilter>;
  coc_accepted?: Maybe<BoolFilter>;
};

export type SponsorCompanySponsor_Companies_Event_Slug_Company_Id_KeyCompoundUniqueInput =
  {
    event_slug: Scalars["String"];
    company_id: Scalars["Int"];
  };

export enum SponsorCompanyStage {
  Pending = "Pending",
  Active = "Active",
}

export enum SponsorCompanyTier {
  Gold = "gold",
  Silver = "silver",
  Bronze = "bronze",
  Startup = "startup",
  Partner = "partner",
}

export type SponsorCompanyUpdateInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutSponsor_CompanyNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_CompanyNestedInput>;
};

export type SponsorCompanyUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type SponsorCompanyUpdateManyWithWhereWithoutCompanyInput = {
  where: SponsorCompanyScalarWhereInput;
  data: SponsorCompanyUpdateManyMutationInput;
};

export type SponsorCompanyUpdateManyWithWhereWithoutEventInput = {
  where: SponsorCompanyScalarWhereInput;
  data: SponsorCompanyUpdateManyMutationInput;
};

export type SponsorCompanyUpdateManyWithoutCompanyNestedInput = {
  create?: Maybe<Array<SponsorCompanyCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorCompanyCreateOrConnectWithoutCompanyInput>
  >;
  upsert?: Maybe<Array<SponsorCompanyUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<SponsorCompanyCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  update?: Maybe<Array<SponsorCompanyUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<
    Array<SponsorCompanyUpdateManyWithWhereWithoutCompanyInput>
  >;
  deleteMany?: Maybe<Array<SponsorCompanyScalarWhereInput>>;
};

export type SponsorCompanyUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<SponsorCompanyCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorCompanyCreateOrConnectWithoutEventInput>
  >;
  upsert?: Maybe<Array<SponsorCompanyUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<SponsorCompanyCreateManyEventInputEnvelope>;
  set?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorCompanyWhereUniqueInput>>;
  update?: Maybe<Array<SponsorCompanyUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<SponsorCompanyUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<SponsorCompanyScalarWhereInput>>;
};

export type SponsorCompanyUpdateOneRequiredWithoutSponsor_IndividualsNestedInput =
  {
    create?: Maybe<SponsorCompanyCreateWithoutSponsor_IndividualsInput>;
    connectOrCreate?: Maybe<SponsorCompanyCreateOrConnectWithoutSponsor_IndividualsInput>;
    upsert?: Maybe<SponsorCompanyUpsertWithoutSponsor_IndividualsInput>;
    connect?: Maybe<SponsorCompanyWhereUniqueInput>;
    update?: Maybe<SponsorCompanyUpdateToOneWithWhereWithoutSponsor_IndividualsInput>;
  };

export type SponsorCompanyUpdateOneRequiredWithoutSponsor_ScansNestedInput = {
  create?: Maybe<SponsorCompanyCreateWithoutSponsor_ScansInput>;
  connectOrCreate?: Maybe<SponsorCompanyCreateOrConnectWithoutSponsor_ScansInput>;
  upsert?: Maybe<SponsorCompanyUpsertWithoutSponsor_ScansInput>;
  connect?: Maybe<SponsorCompanyWhereUniqueInput>;
  update?: Maybe<SponsorCompanyUpdateToOneWithWhereWithoutSponsor_ScansInput>;
};

export type SponsorCompanyUpdateToOneWithWhereWithoutSponsor_IndividualsInput =
  {
    where?: Maybe<SponsorCompanyWhereInput>;
    data: SponsorCompanyUpdateWithoutSponsor_IndividualsInput;
  };

export type SponsorCompanyUpdateToOneWithWhereWithoutSponsor_ScansInput = {
  where?: Maybe<SponsorCompanyWhereInput>;
  data: SponsorCompanyUpdateWithoutSponsor_ScansInput;
};

export type SponsorCompanyUpdateWithWhereUniqueWithoutCompanyInput = {
  where: SponsorCompanyWhereUniqueInput;
  data: SponsorCompanyUpdateWithoutCompanyInput;
};

export type SponsorCompanyUpdateWithWhereUniqueWithoutEventInput = {
  where: SponsorCompanyWhereUniqueInput;
  data: SponsorCompanyUpdateWithoutEventInput;
};

export type SponsorCompanyUpdateWithoutCompanyInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutSponsor_CompanyNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_CompanyNestedInput>;
};

export type SponsorCompanyUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutSponsor_CompanyNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_CompanyNestedInput>;
};

export type SponsorCompanyUpdateWithoutSponsor_IndividualsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_CompanyNestedInput>;
};

export type SponsorCompanyUpdateWithoutSponsor_ScansInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stage?: Maybe<EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  primary_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invite_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  logo?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tier?: Maybe<EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  tc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  coc_accepted?: Maybe<BoolFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_CompaniesNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutSponsor_CompanyNestedInput>;
};

export type SponsorCompanyUpsertWithWhereUniqueWithoutCompanyInput = {
  where: SponsorCompanyWhereUniqueInput;
  update: SponsorCompanyUpdateWithoutCompanyInput;
  create: SponsorCompanyCreateWithoutCompanyInput;
};

export type SponsorCompanyUpsertWithWhereUniqueWithoutEventInput = {
  where: SponsorCompanyWhereUniqueInput;
  update: SponsorCompanyUpdateWithoutEventInput;
  create: SponsorCompanyCreateWithoutEventInput;
};

export type SponsorCompanyUpsertWithoutSponsor_IndividualsInput = {
  update: SponsorCompanyUpdateWithoutSponsor_IndividualsInput;
  create: SponsorCompanyCreateWithoutSponsor_IndividualsInput;
  where?: Maybe<SponsorCompanyWhereInput>;
};

export type SponsorCompanyUpsertWithoutSponsor_ScansInput = {
  update: SponsorCompanyUpdateWithoutSponsor_ScansInput;
  create: SponsorCompanyCreateWithoutSponsor_ScansInput;
  where?: Maybe<SponsorCompanyWhereInput>;
};

export type SponsorCompanyWhereInput = {
  AND?: Maybe<Array<SponsorCompanyWhereInput>>;
  OR?: Maybe<Array<SponsorCompanyWhereInput>>;
  NOT?: Maybe<Array<SponsorCompanyWhereInput>>;
  id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  company_id?: Maybe<IntFilter>;
  event_slug?: Maybe<StringFilter>;
  stage?: Maybe<EnumSponsorCompanyStageFilter>;
  primary_contact_email?: Maybe<StringNullableFilter>;
  primary_contact_name?: Maybe<StringNullableFilter>;
  invite_code?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  tier?: Maybe<EnumSponsorCompanyTierFilter>;
  slug?: Maybe<StringFilter>;
  tc_accepted?: Maybe<BoolFilter>;
  coc_accepted?: Maybe<BoolFilter>;
  company?: Maybe<CompanyRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
};

export type SponsorCompanyWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sponsor_companies_event_slug_company_id_key?: Maybe<SponsorCompanySponsor_Companies_Event_Slug_Company_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<SponsorCompanyWhereInput>>;
  OR?: Maybe<Array<SponsorCompanyWhereInput>>;
  NOT?: Maybe<Array<SponsorCompanyWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  company_id?: Maybe<IntFilter>;
  event_slug?: Maybe<StringFilter>;
  stage?: Maybe<EnumSponsorCompanyStageFilter>;
  primary_contact_email?: Maybe<StringNullableFilter>;
  primary_contact_name?: Maybe<StringNullableFilter>;
  invite_code?: Maybe<StringNullableFilter>;
  logo?: Maybe<StringNullableFilter>;
  tier?: Maybe<EnumSponsorCompanyTierFilter>;
  tc_accepted?: Maybe<BoolFilter>;
  coc_accepted?: Maybe<BoolFilter>;
  company?: Maybe<CompanyRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
};

export type SponsorCreateManyEventInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  company_id: Scalars["Int"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  feature_description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_id?: Maybe<Scalars["Int"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type SponsorCreateManyEventInputEnvelope = {
  data: Array<SponsorCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<SponsorCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<SponsorCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<SponsorCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<SponsorWhereUniqueInput>>;
};

export type SponsorCreateNestedOneWithoutCompanyInput = {
  create?: Maybe<SponsorCreateWithoutCompanyInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutCompanyInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
};

export type SponsorCreateNestedOneWithoutFilesInput = {
  create?: Maybe<SponsorCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutFilesInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
};

export type SponsorCreateNestedOneWithoutRepresentativesInput = {
  create?: Maybe<SponsorCreateWithoutRepresentativesInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutRepresentativesInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
};

export type SponsorCreateOrConnectWithoutCompanyInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutCompanyInput;
};

export type SponsorCreateOrConnectWithoutEventInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutEventInput;
};

export type SponsorCreateOrConnectWithoutFilesInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutFilesInput;
};

export type SponsorCreateOrConnectWithoutRepresentativesInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutRepresentativesInput;
};

export type SponsorCreateWithoutCompanyInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  prizes?: Maybe<PrizeCreateNestedManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutSponsorInput>;
  event: EventCreateNestedOneWithoutSponsorsInput;
  files?: Maybe<FileCreateNestedOneWithoutSponsorsInput>;
};

export type SponsorCreateWithoutEventInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  prizes?: Maybe<PrizeCreateNestedManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutSponsorInput>;
  company: CompanyCreateNestedOneWithoutSponsorsInput;
  files?: Maybe<FileCreateNestedOneWithoutSponsorsInput>;
};

export type SponsorCreateWithoutFilesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  prizes?: Maybe<PrizeCreateNestedManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutSponsorInput>;
  company: CompanyCreateNestedOneWithoutSponsorsInput;
  event: EventCreateNestedOneWithoutSponsorsInput;
};

export type SponsorCreateWithoutRepresentativesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  prizes?: Maybe<PrizeCreateNestedManyWithoutSponsorInput>;
  company: CompanyCreateNestedOneWithoutSponsorsInput;
  event: EventCreateNestedOneWithoutSponsorsInput;
  files?: Maybe<FileCreateNestedOneWithoutSponsorsInput>;
};

export type SponsorIndividual = {
  __typename?: "SponsorIndividual";
  id: Scalars["Int"];
  created_at: Scalars["DateTimeISO"];
  updated_at: Scalars["DateTimeISO"];
  event_slug: Scalars["String"];
  user_id: Scalars["Int"];
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  sponsor_company_slug: Scalars["String"];
  preferred_name?: Maybe<Scalars["String"]>;
  _count?: Maybe<SponsorIndividualCount>;
};

export type SponsorIndividualCount = {
  __typename?: "SponsorIndividualCount";
  sponsor_scans: Scalars["Int"];
};

export type SponsorIndividualCountSponsor_ScansArgs = {
  where?: Maybe<SponsorScanWhereInput>;
};

export type SponsorIndividualCreateManyEventInput = {
  id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  sponsor_company_slug: Scalars["String"];
  preferred_name?: Maybe<Scalars["String"]>;
};

export type SponsorIndividualCreateManyEventInputEnvelope = {
  data: Array<SponsorIndividualCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorIndividualCreateManySponsor_CompanyInput = {
  id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  user_id: Scalars["Int"];
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
};

export type SponsorIndividualCreateManySponsor_CompanyInputEnvelope = {
  data: Array<SponsorIndividualCreateManySponsor_CompanyInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorIndividualCreateManyUserInput = {
  id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  sponsor_company_slug: Scalars["String"];
  preferred_name?: Maybe<Scalars["String"]>;
};

export type SponsorIndividualCreateManyUserInputEnvelope = {
  data: Array<SponsorIndividualCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorIndividualCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutEventInput>
  >;
  createMany?: Maybe<SponsorIndividualCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
};

export type SponsorIndividualCreateNestedManyWithoutSponsor_CompanyInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutSponsor_CompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutSponsor_CompanyInput>
  >;
  createMany?: Maybe<SponsorIndividualCreateManySponsor_CompanyInputEnvelope>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
};

export type SponsorIndividualCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutUserInput>
  >;
  createMany?: Maybe<SponsorIndividualCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
};

export type SponsorIndividualCreateNestedOneWithoutSponsor_ScansInput = {
  create?: Maybe<SponsorIndividualCreateWithoutSponsor_ScansInput>;
  connectOrCreate?: Maybe<SponsorIndividualCreateOrConnectWithoutSponsor_ScansInput>;
  connect?: Maybe<SponsorIndividualWhereUniqueInput>;
};

export type SponsorIndividualCreateOrConnectWithoutEventInput = {
  where: SponsorIndividualWhereUniqueInput;
  create: SponsorIndividualCreateWithoutEventInput;
};

export type SponsorIndividualCreateOrConnectWithoutSponsor_CompanyInput = {
  where: SponsorIndividualWhereUniqueInput;
  create: SponsorIndividualCreateWithoutSponsor_CompanyInput;
};

export type SponsorIndividualCreateOrConnectWithoutSponsor_ScansInput = {
  where: SponsorIndividualWhereUniqueInput;
  create: SponsorIndividualCreateWithoutSponsor_ScansInput;
};

export type SponsorIndividualCreateOrConnectWithoutUserInput = {
  where: SponsorIndividualWhereUniqueInput;
  create: SponsorIndividualCreateWithoutUserInput;
};

export type SponsorIndividualCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  sponsor_company: SponsorCompanyCreateNestedOneWithoutSponsor_IndividualsInput;
  user: UserCreateNestedOneWithoutSponsor_IndividualsInput;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_IndividualInput>;
};

export type SponsorIndividualCreateWithoutSponsor_CompanyInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutSponsor_IndividualsInput;
  user: UserCreateNestedOneWithoutSponsor_IndividualsInput;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_IndividualInput>;
};

export type SponsorIndividualCreateWithoutSponsor_ScansInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutSponsor_IndividualsInput;
  sponsor_company: SponsorCompanyCreateNestedOneWithoutSponsor_IndividualsInput;
  user: UserCreateNestedOneWithoutSponsor_IndividualsInput;
};

export type SponsorIndividualCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role_in_company?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutSponsor_IndividualsInput;
  sponsor_company: SponsorCompanyCreateNestedOneWithoutSponsor_IndividualsInput;
  sponsor_scans?: Maybe<SponsorScanCreateNestedManyWithoutSponsor_IndividualInput>;
};

export type SponsorIndividualListRelationFilter = {
  every?: Maybe<SponsorIndividualWhereInput>;
  some?: Maybe<SponsorIndividualWhereInput>;
  none?: Maybe<SponsorIndividualWhereInput>;
};

export type SponsorIndividualNullableRelationFilter = {
  is?: Maybe<SponsorIndividualWhereInput>;
  isNot?: Maybe<SponsorIndividualWhereInput>;
};

export type SponsorIndividualOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SponsorIndividualScalarWhereInput = {
  AND?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
  OR?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
  NOT?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  user_id?: Maybe<IntFilter>;
  first_name?: Maybe<StringNullableFilter>;
  last_name?: Maybe<StringNullableFilter>;
  role_in_company?: Maybe<StringNullableFilter>;
  sponsor_company_slug?: Maybe<StringFilter>;
  preferred_name?: Maybe<StringNullableFilter>;
};

export type SponsorIndividualSponsor_Individuals_Sponsor_Company_Slug_User_Id_KeyCompoundUniqueInput =
  {
    sponsor_company_slug: Scalars["String"];
    user_id: Scalars["Int"];
  };

export type SponsorIndividualSponsor_Individuals_User_Id_Event_Slug_KeyCompoundUniqueInput =
  {
    user_id: Scalars["Int"];
    event_slug: Scalars["String"];
  };

export type SponsorIndividualUpdateInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_IndividualNestedInput>;
};

export type SponsorIndividualUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SponsorIndividualUpdateManyWithWhereWithoutEventInput = {
  where: SponsorIndividualScalarWhereInput;
  data: SponsorIndividualUpdateManyMutationInput;
};

export type SponsorIndividualUpdateManyWithWhereWithoutSponsor_CompanyInput = {
  where: SponsorIndividualScalarWhereInput;
  data: SponsorIndividualUpdateManyMutationInput;
};

export type SponsorIndividualUpdateManyWithWhereWithoutUserInput = {
  where: SponsorIndividualScalarWhereInput;
  data: SponsorIndividualUpdateManyMutationInput;
};

export type SponsorIndividualUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutEventInput>
  >;
  upsert?: Maybe<
    Array<SponsorIndividualUpsertWithWhereUniqueWithoutEventInput>
  >;
  createMany?: Maybe<SponsorIndividualCreateManyEventInputEnvelope>;
  set?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  update?: Maybe<
    Array<SponsorIndividualUpdateWithWhereUniqueWithoutEventInput>
  >;
  updateMany?: Maybe<
    Array<SponsorIndividualUpdateManyWithWhereWithoutEventInput>
  >;
  deleteMany?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
};

export type SponsorIndividualUpdateManyWithoutSponsor_CompanyNestedInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutSponsor_CompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutSponsor_CompanyInput>
  >;
  upsert?: Maybe<
    Array<SponsorIndividualUpsertWithWhereUniqueWithoutSponsor_CompanyInput>
  >;
  createMany?: Maybe<SponsorIndividualCreateManySponsor_CompanyInputEnvelope>;
  set?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  update?: Maybe<
    Array<SponsorIndividualUpdateWithWhereUniqueWithoutSponsor_CompanyInput>
  >;
  updateMany?: Maybe<
    Array<SponsorIndividualUpdateManyWithWhereWithoutSponsor_CompanyInput>
  >;
  deleteMany?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
};

export type SponsorIndividualUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<SponsorIndividualCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorIndividualCreateOrConnectWithoutUserInput>
  >;
  upsert?: Maybe<Array<SponsorIndividualUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<SponsorIndividualCreateManyUserInputEnvelope>;
  set?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorIndividualWhereUniqueInput>>;
  update?: Maybe<Array<SponsorIndividualUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<
    Array<SponsorIndividualUpdateManyWithWhereWithoutUserInput>
  >;
  deleteMany?: Maybe<Array<SponsorIndividualScalarWhereInput>>;
};

export type SponsorIndividualUpdateOneWithoutSponsor_ScansNestedInput = {
  create?: Maybe<SponsorIndividualCreateWithoutSponsor_ScansInput>;
  connectOrCreate?: Maybe<SponsorIndividualCreateOrConnectWithoutSponsor_ScansInput>;
  upsert?: Maybe<SponsorIndividualUpsertWithoutSponsor_ScansInput>;
  disconnect?: Maybe<SponsorIndividualWhereInput>;
  delete?: Maybe<SponsorIndividualWhereInput>;
  connect?: Maybe<SponsorIndividualWhereUniqueInput>;
  update?: Maybe<SponsorIndividualUpdateToOneWithWhereWithoutSponsor_ScansInput>;
};

export type SponsorIndividualUpdateToOneWithWhereWithoutSponsor_ScansInput = {
  where?: Maybe<SponsorIndividualWhereInput>;
  data: SponsorIndividualUpdateWithoutSponsor_ScansInput;
};

export type SponsorIndividualUpdateWithWhereUniqueWithoutEventInput = {
  where: SponsorIndividualWhereUniqueInput;
  data: SponsorIndividualUpdateWithoutEventInput;
};

export type SponsorIndividualUpdateWithWhereUniqueWithoutSponsor_CompanyInput =
  {
    where: SponsorIndividualWhereUniqueInput;
    data: SponsorIndividualUpdateWithoutSponsor_CompanyInput;
  };

export type SponsorIndividualUpdateWithWhereUniqueWithoutUserInput = {
  where: SponsorIndividualWhereUniqueInput;
  data: SponsorIndividualUpdateWithoutUserInput;
};

export type SponsorIndividualUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_IndividualNestedInput>;
};

export type SponsorIndividualUpdateWithoutSponsor_CompanyInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_IndividualNestedInput>;
};

export type SponsorIndividualUpdateWithoutSponsor_ScansInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
};

export type SponsorIndividualUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role_in_company?: Maybe<NullableStringFieldUpdateOperationsInput>;
  preferred_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_IndividualsNestedInput>;
  sponsor_scans?: Maybe<SponsorScanUpdateManyWithoutSponsor_IndividualNestedInput>;
};

export type SponsorIndividualUpsertWithWhereUniqueWithoutEventInput = {
  where: SponsorIndividualWhereUniqueInput;
  update: SponsorIndividualUpdateWithoutEventInput;
  create: SponsorIndividualCreateWithoutEventInput;
};

export type SponsorIndividualUpsertWithWhereUniqueWithoutSponsor_CompanyInput =
  {
    where: SponsorIndividualWhereUniqueInput;
    update: SponsorIndividualUpdateWithoutSponsor_CompanyInput;
    create: SponsorIndividualCreateWithoutSponsor_CompanyInput;
  };

export type SponsorIndividualUpsertWithWhereUniqueWithoutUserInput = {
  where: SponsorIndividualWhereUniqueInput;
  update: SponsorIndividualUpdateWithoutUserInput;
  create: SponsorIndividualCreateWithoutUserInput;
};

export type SponsorIndividualUpsertWithoutSponsor_ScansInput = {
  update: SponsorIndividualUpdateWithoutSponsor_ScansInput;
  create: SponsorIndividualCreateWithoutSponsor_ScansInput;
  where?: Maybe<SponsorIndividualWhereInput>;
};

export type SponsorIndividualWhereInput = {
  AND?: Maybe<Array<SponsorIndividualWhereInput>>;
  OR?: Maybe<Array<SponsorIndividualWhereInput>>;
  NOT?: Maybe<Array<SponsorIndividualWhereInput>>;
  id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  user_id?: Maybe<IntFilter>;
  first_name?: Maybe<StringNullableFilter>;
  last_name?: Maybe<StringNullableFilter>;
  role_in_company?: Maybe<StringNullableFilter>;
  sponsor_company_slug?: Maybe<StringFilter>;
  preferred_name?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  sponsor_company?: Maybe<SponsorCompanyRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
};

export type SponsorIndividualWhereUniqueEmailInput = {
  email: Scalars["String"];
};

export type SponsorIndividualWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  sponsor_individuals_sponsor_company_slug_user_id_key?: Maybe<SponsorIndividualSponsor_Individuals_Sponsor_Company_Slug_User_Id_KeyCompoundUniqueInput>;
  sponsor_individuals_user_id_event_slug_key?: Maybe<SponsorIndividualSponsor_Individuals_User_Id_Event_Slug_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<SponsorIndividualWhereInput>>;
  OR?: Maybe<Array<SponsorIndividualWhereInput>>;
  NOT?: Maybe<Array<SponsorIndividualWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  user_id?: Maybe<IntFilter>;
  first_name?: Maybe<StringNullableFilter>;
  last_name?: Maybe<StringNullableFilter>;
  role_in_company?: Maybe<StringNullableFilter>;
  sponsor_company_slug?: Maybe<StringFilter>;
  preferred_name?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  sponsor_company?: Maybe<SponsorCompanyRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
};

export type SponsorListRelationFilter = {
  every?: Maybe<SponsorWhereInput>;
  some?: Maybe<SponsorWhereInput>;
  none?: Maybe<SponsorWhereInput>;
};

export type SponsorNetworkingPayload = {
  __typename?: "SponsorNetworkingPayload";
  attendeeName: Scalars["String"];
  sponsorCompanyName: Scalars["String"];
  sponsorCompanyId: Scalars["Int"];
  alreadyScanned: Scalars["Boolean"];
  extendedProfile?: Maybe<Claim>;
};

export type SponsorNullableRelationFilter = {
  is?: Maybe<SponsorWhereInput>;
  isNot?: Maybe<SponsorWhereInput>;
};

export type SponsorOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum SponsorOrderByRelevanceFieldEnum {
  FeatureDescription = "feature_description",
  Tier = "tier",
}

export type SponsorOrderByRelevanceInput = {
  fields: Array<SponsorOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type SponsorOrderByWithRelationAndSearchRelevanceInput = {
  available_perks?: Maybe<SortOrderInput>;
  company_id?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  event_id?: Maybe<SortOrder>;
  feature_description?: Maybe<SortOrderInput>;
  id?: Maybe<SortOrder>;
  invoice_id?: Maybe<SortOrderInput>;
  poc_accepted_code_of_conduct?: Maybe<SortOrderInput>;
  tier?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  prizes?: Maybe<PrizeOrderByRelationAggregateInput>;
  representatives?: Maybe<RepresentativeOrderByRelationAggregateInput>;
  company?: Maybe<CompanyOrderByWithRelationAndSearchRelevanceInput>;
  event?: Maybe<EventOrderByWithRelationAndSearchRelevanceInput>;
  files?: Maybe<FileOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<SponsorOrderByRelevanceInput>;
};

export type SponsorRelationFilter = {
  is?: Maybe<SponsorWhereInput>;
  isNot?: Maybe<SponsorWhereInput>;
};

export type SponsorScalarWhereInput = {
  AND?: Maybe<Array<SponsorScalarWhereInput>>;
  OR?: Maybe<Array<SponsorScalarWhereInput>>;
  NOT?: Maybe<Array<SponsorScalarWhereInput>>;
  available_perks?: Maybe<JsonNullableFilter>;
  company_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  feature_description?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  invoice_id?: Maybe<IntNullableFilter>;
  poc_accepted_code_of_conduct?: Maybe<BoolNullableFilter>;
  tier?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type SponsorScan = {
  __typename?: "SponsorScan";
  id: Scalars["Int"];
  badge_scan_id: Scalars["Int"];
  sponsor_individual_id?: Maybe<Scalars["Int"]>;
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
  sponsor_company_id: Scalars["Int"];
};

export type SponsorScanCreateManySponsor_CompanyInput = {
  id?: Maybe<Scalars["Int"]>;
  badge_scan_id: Scalars["Int"];
  sponsor_individual_id?: Maybe<Scalars["Int"]>;
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
};

export type SponsorScanCreateManySponsor_CompanyInputEnvelope = {
  data: Array<SponsorScanCreateManySponsor_CompanyInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorScanCreateManySponsor_IndividualInput = {
  id?: Maybe<Scalars["Int"]>;
  badge_scan_id: Scalars["Int"];
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
  sponsor_company_id: Scalars["Int"];
};

export type SponsorScanCreateManySponsor_IndividualInputEnvelope = {
  data: Array<SponsorScanCreateManySponsor_IndividualInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SponsorScanCreateNestedManyWithoutSponsor_CompanyInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutSponsor_CompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorScanCreateOrConnectWithoutSponsor_CompanyInput>
  >;
  createMany?: Maybe<SponsorScanCreateManySponsor_CompanyInputEnvelope>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
};

export type SponsorScanCreateNestedManyWithoutSponsor_IndividualInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutSponsor_IndividualInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorScanCreateOrConnectWithoutSponsor_IndividualInput>
  >;
  createMany?: Maybe<SponsorScanCreateManySponsor_IndividualInputEnvelope>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
};

export type SponsorScanCreateNestedOneWithoutBadge_ScanInput = {
  create?: Maybe<SponsorScanCreateWithoutBadge_ScanInput>;
  connectOrCreate?: Maybe<SponsorScanCreateOrConnectWithoutBadge_ScanInput>;
  connect?: Maybe<SponsorScanWhereUniqueInput>;
};

export type SponsorScanCreateOrConnectWithoutBadge_ScanInput = {
  where: SponsorScanWhereUniqueInput;
  create: SponsorScanCreateWithoutBadge_ScanInput;
};

export type SponsorScanCreateOrConnectWithoutSponsor_CompanyInput = {
  where: SponsorScanWhereUniqueInput;
  create: SponsorScanCreateWithoutSponsor_CompanyInput;
};

export type SponsorScanCreateOrConnectWithoutSponsor_IndividualInput = {
  where: SponsorScanWhereUniqueInput;
  create: SponsorScanCreateWithoutSponsor_IndividualInput;
};

export type SponsorScanCreateWithoutBadge_ScanInput = {
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
  sponsor_company: SponsorCompanyCreateNestedOneWithoutSponsor_ScansInput;
  sponsor_individual?: Maybe<SponsorIndividualCreateNestedOneWithoutSponsor_ScansInput>;
};

export type SponsorScanCreateWithoutSponsor_CompanyInput = {
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
  badge_scan: BadgeScanCreateNestedOneWithoutSponsor_ScanInput;
  sponsor_individual?: Maybe<SponsorIndividualCreateNestedOneWithoutSponsor_ScansInput>;
};

export type SponsorScanCreateWithoutSponsor_IndividualInput = {
  sponsor_initiated_scan: Scalars["Boolean"];
  company_name: Scalars["String"];
  badge_scan: BadgeScanCreateNestedOneWithoutSponsor_ScanInput;
  sponsor_company: SponsorCompanyCreateNestedOneWithoutSponsor_ScansInput;
};

export type SponsorScanListRelationFilter = {
  every?: Maybe<SponsorScanWhereInput>;
  some?: Maybe<SponsorScanWhereInput>;
  none?: Maybe<SponsorScanWhereInput>;
};

export type SponsorScanNullableRelationFilter = {
  is?: Maybe<SponsorScanWhereInput>;
  isNot?: Maybe<SponsorScanWhereInput>;
};

export type SponsorScanScalarWhereInput = {
  AND?: Maybe<Array<SponsorScanScalarWhereInput>>;
  OR?: Maybe<Array<SponsorScanScalarWhereInput>>;
  NOT?: Maybe<Array<SponsorScanScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  badge_scan_id?: Maybe<IntFilter>;
  sponsor_individual_id?: Maybe<IntNullableFilter>;
  sponsor_initiated_scan?: Maybe<BoolFilter>;
  company_name?: Maybe<StringFilter>;
  sponsor_company_id?: Maybe<IntFilter>;
};

export type SponsorScanUpdateManyMutationInput = {
  sponsor_initiated_scan?: Maybe<BoolFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SponsorScanUpdateManyWithWhereWithoutSponsor_CompanyInput = {
  where: SponsorScanScalarWhereInput;
  data: SponsorScanUpdateManyMutationInput;
};

export type SponsorScanUpdateManyWithWhereWithoutSponsor_IndividualInput = {
  where: SponsorScanScalarWhereInput;
  data: SponsorScanUpdateManyMutationInput;
};

export type SponsorScanUpdateManyWithoutSponsor_CompanyNestedInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutSponsor_CompanyInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorScanCreateOrConnectWithoutSponsor_CompanyInput>
  >;
  upsert?: Maybe<
    Array<SponsorScanUpsertWithWhereUniqueWithoutSponsor_CompanyInput>
  >;
  createMany?: Maybe<SponsorScanCreateManySponsor_CompanyInputEnvelope>;
  set?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  update?: Maybe<
    Array<SponsorScanUpdateWithWhereUniqueWithoutSponsor_CompanyInput>
  >;
  updateMany?: Maybe<
    Array<SponsorScanUpdateManyWithWhereWithoutSponsor_CompanyInput>
  >;
  deleteMany?: Maybe<Array<SponsorScanScalarWhereInput>>;
};

export type SponsorScanUpdateManyWithoutSponsor_IndividualNestedInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutSponsor_IndividualInput>>;
  connectOrCreate?: Maybe<
    Array<SponsorScanCreateOrConnectWithoutSponsor_IndividualInput>
  >;
  upsert?: Maybe<
    Array<SponsorScanUpsertWithWhereUniqueWithoutSponsor_IndividualInput>
  >;
  createMany?: Maybe<SponsorScanCreateManySponsor_IndividualInputEnvelope>;
  set?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  update?: Maybe<
    Array<SponsorScanUpdateWithWhereUniqueWithoutSponsor_IndividualInput>
  >;
  updateMany?: Maybe<
    Array<SponsorScanUpdateManyWithWhereWithoutSponsor_IndividualInput>
  >;
  deleteMany?: Maybe<Array<SponsorScanScalarWhereInput>>;
};

export type SponsorScanUpdateOneWithoutBadge_ScanNestedInput = {
  create?: Maybe<SponsorScanCreateWithoutBadge_ScanInput>;
  connectOrCreate?: Maybe<SponsorScanCreateOrConnectWithoutBadge_ScanInput>;
  upsert?: Maybe<SponsorScanUpsertWithoutBadge_ScanInput>;
  disconnect?: Maybe<SponsorScanWhereInput>;
  delete?: Maybe<SponsorScanWhereInput>;
  connect?: Maybe<SponsorScanWhereUniqueInput>;
  update?: Maybe<SponsorScanUpdateToOneWithWhereWithoutBadge_ScanInput>;
};

export type SponsorScanUpdateToOneWithWhereWithoutBadge_ScanInput = {
  where?: Maybe<SponsorScanWhereInput>;
  data: SponsorScanUpdateWithoutBadge_ScanInput;
};

export type SponsorScanUpdateWithWhereUniqueWithoutSponsor_CompanyInput = {
  where: SponsorScanWhereUniqueInput;
  data: SponsorScanUpdateWithoutSponsor_CompanyInput;
};

export type SponsorScanUpdateWithWhereUniqueWithoutSponsor_IndividualInput = {
  where: SponsorScanWhereUniqueInput;
  data: SponsorScanUpdateWithoutSponsor_IndividualInput;
};

export type SponsorScanUpdateWithoutBadge_ScanInput = {
  sponsor_initiated_scan?: Maybe<BoolFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_ScansNestedInput>;
  sponsor_individual?: Maybe<SponsorIndividualUpdateOneWithoutSponsor_ScansNestedInput>;
};

export type SponsorScanUpdateWithoutSponsor_CompanyInput = {
  sponsor_initiated_scan?: Maybe<BoolFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  badge_scan?: Maybe<BadgeScanUpdateOneRequiredWithoutSponsor_ScanNestedInput>;
  sponsor_individual?: Maybe<SponsorIndividualUpdateOneWithoutSponsor_ScansNestedInput>;
};

export type SponsorScanUpdateWithoutSponsor_IndividualInput = {
  sponsor_initiated_scan?: Maybe<BoolFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  badge_scan?: Maybe<BadgeScanUpdateOneRequiredWithoutSponsor_ScanNestedInput>;
  sponsor_company?: Maybe<SponsorCompanyUpdateOneRequiredWithoutSponsor_ScansNestedInput>;
};

export type SponsorScanUpsertWithWhereUniqueWithoutSponsor_CompanyInput = {
  where: SponsorScanWhereUniqueInput;
  update: SponsorScanUpdateWithoutSponsor_CompanyInput;
  create: SponsorScanCreateWithoutSponsor_CompanyInput;
};

export type SponsorScanUpsertWithWhereUniqueWithoutSponsor_IndividualInput = {
  where: SponsorScanWhereUniqueInput;
  update: SponsorScanUpdateWithoutSponsor_IndividualInput;
  create: SponsorScanCreateWithoutSponsor_IndividualInput;
};

export type SponsorScanUpsertWithoutBadge_ScanInput = {
  update: SponsorScanUpdateWithoutBadge_ScanInput;
  create: SponsorScanCreateWithoutBadge_ScanInput;
  where?: Maybe<SponsorScanWhereInput>;
};

export type SponsorScanWhereInput = {
  AND?: Maybe<Array<SponsorScanWhereInput>>;
  OR?: Maybe<Array<SponsorScanWhereInput>>;
  NOT?: Maybe<Array<SponsorScanWhereInput>>;
  id?: Maybe<IntFilter>;
  badge_scan_id?: Maybe<IntFilter>;
  sponsor_individual_id?: Maybe<IntNullableFilter>;
  sponsor_initiated_scan?: Maybe<BoolFilter>;
  company_name?: Maybe<StringFilter>;
  sponsor_company_id?: Maybe<IntFilter>;
  badge_scan?: Maybe<BadgeScanRelationFilter>;
  sponsor_company?: Maybe<SponsorCompanyRelationFilter>;
  sponsor_individual?: Maybe<SponsorIndividualNullableRelationFilter>;
};

export type SponsorScanWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  badge_scan_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<SponsorScanWhereInput>>;
  OR?: Maybe<Array<SponsorScanWhereInput>>;
  NOT?: Maybe<Array<SponsorScanWhereInput>>;
  sponsor_individual_id?: Maybe<IntNullableFilter>;
  sponsor_initiated_scan?: Maybe<BoolFilter>;
  company_name?: Maybe<StringFilter>;
  sponsor_company_id?: Maybe<IntFilter>;
  badge_scan?: Maybe<BadgeScanRelationFilter>;
  sponsor_company?: Maybe<SponsorCompanyRelationFilter>;
  sponsor_individual?: Maybe<SponsorIndividualNullableRelationFilter>;
};

export type SponsorSponsors_Event_Id_Company_Id_KeyCompoundUniqueInput = {
  event_id: Scalars["Int"];
  company_id: Scalars["Int"];
};

export type SponsorUpdateManyMutationInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  feature_description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  poc_accepted_code_of_conduct?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tier?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SponsorUpdateManyWithWhereWithoutEventInput = {
  where: SponsorScalarWhereInput;
  data: SponsorUpdateManyMutationInput;
};

export type SponsorUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<SponsorCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<SponsorCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<SponsorUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<SponsorCreateManyEventInputEnvelope>;
  set?: Maybe<Array<SponsorWhereUniqueInput>>;
  disconnect?: Maybe<Array<SponsorWhereUniqueInput>>;
  delete?: Maybe<Array<SponsorWhereUniqueInput>>;
  connect?: Maybe<Array<SponsorWhereUniqueInput>>;
  update?: Maybe<Array<SponsorUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<SponsorUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<SponsorScalarWhereInput>>;
};

export type SponsorUpdateOneRequiredWithoutRepresentativesNestedInput = {
  create?: Maybe<SponsorCreateWithoutRepresentativesInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutRepresentativesInput>;
  upsert?: Maybe<SponsorUpsertWithoutRepresentativesInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  update?: Maybe<SponsorUpdateToOneWithWhereWithoutRepresentativesInput>;
};

export type SponsorUpdateOneWithoutCompanyNestedInput = {
  create?: Maybe<SponsorCreateWithoutCompanyInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutCompanyInput>;
  upsert?: Maybe<SponsorUpsertWithoutCompanyInput>;
  disconnect?: Maybe<SponsorWhereInput>;
  delete?: Maybe<SponsorWhereInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  update?: Maybe<SponsorUpdateToOneWithWhereWithoutCompanyInput>;
};

export type SponsorUpdateOneWithoutFilesNestedInput = {
  create?: Maybe<SponsorCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutFilesInput>;
  upsert?: Maybe<SponsorUpsertWithoutFilesInput>;
  disconnect?: Maybe<SponsorWhereInput>;
  delete?: Maybe<SponsorWhereInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  update?: Maybe<SponsorUpdateToOneWithWhereWithoutFilesInput>;
};

export type SponsorUpdateToOneWithWhereWithoutCompanyInput = {
  where?: Maybe<SponsorWhereInput>;
  data: SponsorUpdateWithoutCompanyInput;
};

export type SponsorUpdateToOneWithWhereWithoutFilesInput = {
  where?: Maybe<SponsorWhereInput>;
  data: SponsorUpdateWithoutFilesInput;
};

export type SponsorUpdateToOneWithWhereWithoutRepresentativesInput = {
  where?: Maybe<SponsorWhereInput>;
  data: SponsorUpdateWithoutRepresentativesInput;
};

export type SponsorUpdateWithWhereUniqueWithoutEventInput = {
  where: SponsorWhereUniqueInput;
  data: SponsorUpdateWithoutEventInput;
};

export type SponsorUpdateWithoutCompanyInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  feature_description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  poc_accepted_code_of_conduct?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tier?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  prizes?: Maybe<PrizeUpdateManyWithoutSponsorNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutSponsorNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsorsNestedInput>;
  files?: Maybe<FileUpdateOneWithoutSponsorsNestedInput>;
};

export type SponsorUpdateWithoutEventInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  feature_description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  poc_accepted_code_of_conduct?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tier?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  prizes?: Maybe<PrizeUpdateManyWithoutSponsorNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutSponsorNestedInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsorsNestedInput>;
  files?: Maybe<FileUpdateOneWithoutSponsorsNestedInput>;
};

export type SponsorUpdateWithoutFilesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  feature_description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  poc_accepted_code_of_conduct?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tier?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  prizes?: Maybe<PrizeUpdateManyWithoutSponsorNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutSponsorNestedInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsorsNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsorsNestedInput>;
};

export type SponsorUpdateWithoutRepresentativesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  feature_description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  poc_accepted_code_of_conduct?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  tier?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  prizes?: Maybe<PrizeUpdateManyWithoutSponsorNestedInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutSponsorsNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSponsorsNestedInput>;
  files?: Maybe<FileUpdateOneWithoutSponsorsNestedInput>;
};

export type SponsorUpsertWithWhereUniqueWithoutEventInput = {
  where: SponsorWhereUniqueInput;
  update: SponsorUpdateWithoutEventInput;
  create: SponsorCreateWithoutEventInput;
};

export type SponsorUpsertWithoutCompanyInput = {
  update: SponsorUpdateWithoutCompanyInput;
  create: SponsorCreateWithoutCompanyInput;
  where?: Maybe<SponsorWhereInput>;
};

export type SponsorUpsertWithoutFilesInput = {
  update: SponsorUpdateWithoutFilesInput;
  create: SponsorCreateWithoutFilesInput;
  where?: Maybe<SponsorWhereInput>;
};

export type SponsorUpsertWithoutRepresentativesInput = {
  update: SponsorUpdateWithoutRepresentativesInput;
  create: SponsorCreateWithoutRepresentativesInput;
  where?: Maybe<SponsorWhereInput>;
};

export type SponsorWhereInput = {
  AND?: Maybe<Array<SponsorWhereInput>>;
  OR?: Maybe<Array<SponsorWhereInput>>;
  NOT?: Maybe<Array<SponsorWhereInput>>;
  available_perks?: Maybe<JsonNullableFilter>;
  company_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  feature_description?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  invoice_id?: Maybe<IntNullableFilter>;
  poc_accepted_code_of_conduct?: Maybe<BoolNullableFilter>;
  tier?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  prizes?: Maybe<PrizeListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  company?: Maybe<CompanyRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  files?: Maybe<FileNullableRelationFilter>;
};

export type SponsorWhereUniqueInput = {
  company_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_id?: Maybe<Scalars["Int"]>;
  sponsors_event_id_company_id_key?: Maybe<SponsorSponsors_Event_Id_Company_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<SponsorWhereInput>>;
  OR?: Maybe<Array<SponsorWhereInput>>;
  NOT?: Maybe<Array<SponsorWhereInput>>;
  available_perks?: Maybe<JsonNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  feature_description?: Maybe<StringNullableFilter>;
  poc_accepted_code_of_conduct?: Maybe<BoolNullableFilter>;
  tier?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  prizes?: Maybe<PrizeListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  company?: Maybe<CompanyRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  files?: Maybe<FileNullableRelationFilter>;
};

export type Stage = {
  __typename?: "Stage";
  created_at: Scalars["DateTimeISO"];
  id: Scalars["Int"];
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  updated_at: Scalars["DateTimeISO"];
  slug: Scalars["String"];
  _count?: Maybe<StageCount>;
  count: Scalars["Int"];
};

export type StageCount = {
  __typename?: "StageCount";
  claims: Scalars["Int"];
};

export type StageCountClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
};

/** Create stages */
export type StageCreateInput = {
  name: Scalars["String"];
  pipeline_id: Scalars["Float"];
  slug: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
};

export type StageCreateManyPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug: Scalars["String"];
};

export type StageCreateManyPipelineInputEnvelope = {
  data: Array<StageCreateManyPipelineInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type StageCreateNestedManyWithoutPipelineInput = {
  create?: Maybe<Array<StageCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<StageCreateOrConnectWithoutPipelineInput>>;
  createMany?: Maybe<StageCreateManyPipelineInputEnvelope>;
  connect?: Maybe<Array<StageWhereUniqueInput>>;
};

export type StageCreateNestedOneWithoutClaimsInput = {
  create?: Maybe<StageCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<StageCreateOrConnectWithoutClaimsInput>;
  connect?: Maybe<StageWhereUniqueInput>;
};

export type StageCreateOrConnectWithoutClaimsInput = {
  where: StageWhereUniqueInput;
  create: StageCreateWithoutClaimsInput;
};

export type StageCreateOrConnectWithoutPipelineInput = {
  where: StageWhereUniqueInput;
  create: StageCreateWithoutPipelineInput;
};

export type StageCreateWithoutClaimsInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug: Scalars["String"];
  pipeline: PipelineCreateNestedOneWithoutStagesInput;
};

export type StageCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  slug: Scalars["String"];
  claims?: Maybe<ClaimCreateNestedManyWithoutStageInput>;
};

export type StageListRelationFilter = {
  every?: Maybe<StageWhereInput>;
  some?: Maybe<StageWhereInput>;
  none?: Maybe<StageWhereInput>;
};

export type StageOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum StageOrderByRelevanceFieldEnum {
  Name = "name",
  Slug = "slug",
}

export type StageOrderByRelevanceInput = {
  fields: Array<StageOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type StageOrderByWithRelationAndSearchRelevanceInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  permissions?: Maybe<SortOrderInput>;
  pipeline_id?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  slug?: Maybe<SortOrder>;
  claims?: Maybe<ClaimOrderByRelationAggregateInput>;
  pipeline?: Maybe<PipelineOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<StageOrderByRelevanceInput>;
};

export type StageRelationFilter = {
  is?: Maybe<StageWhereInput>;
  isNot?: Maybe<StageWhereInput>;
};

export type StageScalarWhereInput = {
  AND?: Maybe<Array<StageScalarWhereInput>>;
  OR?: Maybe<Array<StageScalarWhereInput>>;
  NOT?: Maybe<Array<StageScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringFilter>;
};

export type StageStages_Slug_Pipeline_Id_KeyCompoundUniqueInput = {
  slug: Scalars["String"];
  pipeline_id: Scalars["Int"];
};

/** Update stages data */
export type StageUpdateInput = {
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
};

export type StageUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
};

export type StageUpdateManyWithWhereWithoutPipelineInput = {
  where: StageScalarWhereInput;
  data: StageUpdateManyMutationInput;
};

export type StageUpdateManyWithoutPipelineNestedInput = {
  create?: Maybe<Array<StageCreateWithoutPipelineInput>>;
  connectOrCreate?: Maybe<Array<StageCreateOrConnectWithoutPipelineInput>>;
  upsert?: Maybe<Array<StageUpsertWithWhereUniqueWithoutPipelineInput>>;
  createMany?: Maybe<StageCreateManyPipelineInputEnvelope>;
  set?: Maybe<Array<StageWhereUniqueInput>>;
  disconnect?: Maybe<Array<StageWhereUniqueInput>>;
  delete?: Maybe<Array<StageWhereUniqueInput>>;
  connect?: Maybe<Array<StageWhereUniqueInput>>;
  update?: Maybe<Array<StageUpdateWithWhereUniqueWithoutPipelineInput>>;
  updateMany?: Maybe<Array<StageUpdateManyWithWhereWithoutPipelineInput>>;
  deleteMany?: Maybe<Array<StageScalarWhereInput>>;
};

export type StageUpdateOneRequiredWithoutClaimsNestedInput = {
  create?: Maybe<StageCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<StageCreateOrConnectWithoutClaimsInput>;
  upsert?: Maybe<StageUpsertWithoutClaimsInput>;
  connect?: Maybe<StageWhereUniqueInput>;
  update?: Maybe<StageUpdateToOneWithWhereWithoutClaimsInput>;
};

export type StageUpdateToOneWithWhereWithoutClaimsInput = {
  where?: Maybe<StageWhereInput>;
  data: StageUpdateWithoutClaimsInput;
};

export type StageUpdateWithWhereUniqueWithoutPipelineInput = {
  where: StageWhereUniqueInput;
  data: StageUpdateWithoutPipelineInput;
};

export type StageUpdateWithoutClaimsInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  pipeline?: Maybe<PipelineUpdateOneRequiredWithoutStagesNestedInput>;
};

export type StageUpdateWithoutPipelineInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  slug?: Maybe<StringFieldUpdateOperationsInput>;
  claims?: Maybe<ClaimUpdateManyWithoutStageNestedInput>;
};

export type StageUpsertWithWhereUniqueWithoutPipelineInput = {
  where: StageWhereUniqueInput;
  update: StageUpdateWithoutPipelineInput;
  create: StageCreateWithoutPipelineInput;
};

export type StageUpsertWithoutClaimsInput = {
  update: StageUpdateWithoutClaimsInput;
  create: StageCreateWithoutClaimsInput;
  where?: Maybe<StageWhereInput>;
};

export type StageWhereInput = {
  AND?: Maybe<Array<StageWhereInput>>;
  OR?: Maybe<Array<StageWhereInput>>;
  NOT?: Maybe<Array<StageWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
};

export type StageWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  stages_slug_pipeline_id_key?: Maybe<StageStages_Slug_Pipeline_Id_KeyCompoundUniqueInput>;
  AND?: Maybe<Array<StageWhereInput>>;
  OR?: Maybe<Array<StageWhereInput>>;
  NOT?: Maybe<Array<StageWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  slug?: Maybe<StringFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
};

export type StringArrayFieldAnswerTypeWrapper = {
  __typename?: "StringArrayFieldAnswerTypeWrapper";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  value?: Maybe<Array<Scalars["String"]>>;
};

export type StringFieldAnswerTypeWrapper = {
  __typename?: "StringFieldAnswerTypeWrapper";
  created_at: Scalars["DateTimeISO"];
  fcfs_available: Array<Scalars["String"]>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  valid_values: Array<Scalars["String"]>;
  _count?: Maybe<FieldCount>;
  value?: Maybe<Scalars["String"]>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export type StringFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  search?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars["String"]>>;
  has?: Maybe<Scalars["String"]>;
  hasEvery?: Maybe<Array<Scalars["String"]>>;
  hasSome?: Maybe<Array<Scalars["String"]>>;
  isEmpty?: Maybe<Scalars["Boolean"]>;
};

export type SubmissionCreateManyEventInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  id?: Maybe<Scalars["Int"]>;
  location?: Maybe<Scalars["String"]>;
  devpost_name: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  devpost_url: Scalars["String"];
  categories?: Maybe<SubmissionCreatecategoriesInput>;
  pitch_type: Scalars["String"];
  team_id?: Maybe<Scalars["String"]>;
  video_url?: Maybe<Scalars["String"]>;
};

export type SubmissionCreateManyEventInputEnvelope = {
  data: Array<SubmissionCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type SubmissionCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<SubmissionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<SubmissionCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<SubmissionCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<SubmissionWhereUniqueInput>>;
};

export type SubmissionCreateNestedOneWithoutTeamInput = {
  create?: Maybe<SubmissionCreateWithoutTeamInput>;
  connectOrCreate?: Maybe<SubmissionCreateOrConnectWithoutTeamInput>;
  connect?: Maybe<SubmissionWhereUniqueInput>;
};

export type SubmissionCreateNestedOneWithoutUser_SubmissionsInput = {
  create?: Maybe<SubmissionCreateWithoutUser_SubmissionsInput>;
  connectOrCreate?: Maybe<SubmissionCreateOrConnectWithoutUser_SubmissionsInput>;
  connect?: Maybe<SubmissionWhereUniqueInput>;
};

export type SubmissionCreateOrConnectWithoutEventInput = {
  where: SubmissionWhereUniqueInput;
  create: SubmissionCreateWithoutEventInput;
};

export type SubmissionCreateOrConnectWithoutTeamInput = {
  where: SubmissionWhereUniqueInput;
  create: SubmissionCreateWithoutTeamInput;
};

export type SubmissionCreateOrConnectWithoutUser_SubmissionsInput = {
  where: SubmissionWhereUniqueInput;
  create: SubmissionCreateWithoutUser_SubmissionsInput;
};

export type SubmissionCreateWithoutEventInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  devpost_name: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  devpost_url: Scalars["String"];
  categories?: Maybe<SubmissionCreatecategoriesInput>;
  pitch_type: Scalars["String"];
  video_url?: Maybe<Scalars["String"]>;
  team?: Maybe<TeamCreateNestedOneWithoutSubmissionInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutSubmissionInput>;
};

export type SubmissionCreateWithoutTeamInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  devpost_name: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  devpost_url: Scalars["String"];
  categories?: Maybe<SubmissionCreatecategoriesInput>;
  pitch_type: Scalars["String"];
  video_url?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutSubmissionsInput;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutSubmissionInput>;
};

export type SubmissionCreateWithoutUser_SubmissionsInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  location?: Maybe<Scalars["String"]>;
  devpost_name: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  devpost_url: Scalars["String"];
  categories?: Maybe<SubmissionCreatecategoriesInput>;
  pitch_type: Scalars["String"];
  video_url?: Maybe<Scalars["String"]>;
  event: EventCreateNestedOneWithoutSubmissionsInput;
  team?: Maybe<TeamCreateNestedOneWithoutSubmissionInput>;
};

export type SubmissionCreatecategoriesInput = {
  set: Array<Scalars["String"]>;
};

export type SubmissionCreaterepository_UrlsInput = {
  set: Array<Scalars["String"]>;
};

export type SubmissionListRelationFilter = {
  every?: Maybe<SubmissionWhereInput>;
  some?: Maybe<SubmissionWhereInput>;
  none?: Maybe<SubmissionWhereInput>;
};

export type SubmissionNullableRelationFilter = {
  is?: Maybe<SubmissionWhereInput>;
  isNot?: Maybe<SubmissionWhereInput>;
};

export type SubmissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type SubmissionRelationFilter = {
  is?: Maybe<SubmissionWhereInput>;
  isNot?: Maybe<SubmissionWhereInput>;
};

export type SubmissionScalarWhereInput = {
  AND?: Maybe<Array<SubmissionScalarWhereInput>>;
  OR?: Maybe<Array<SubmissionScalarWhereInput>>;
  NOT?: Maybe<Array<SubmissionScalarWhereInput>>;
  contact_info?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  devpost_name?: Maybe<StringFilter>;
  repository_urls?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  devpost_url?: Maybe<StringFilter>;
  categories?: Maybe<StringNullableListFilter>;
  pitch_type?: Maybe<StringFilter>;
  team_id?: Maybe<StringNullableFilter>;
  video_url?: Maybe<StringNullableFilter>;
};

export type SubmissionUpdateManyMutationInput = {
  contact_info?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  devpost_name?: Maybe<StringFieldUpdateOperationsInput>;
  repository_urls?: Maybe<SubmissionUpdaterepository_UrlsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devpost_url?: Maybe<StringFieldUpdateOperationsInput>;
  categories?: Maybe<SubmissionUpdatecategoriesInput>;
  pitch_type?: Maybe<StringFieldUpdateOperationsInput>;
  video_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type SubmissionUpdateManyWithWhereWithoutEventInput = {
  where: SubmissionScalarWhereInput;
  data: SubmissionUpdateManyMutationInput;
};

export type SubmissionUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<SubmissionCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<SubmissionCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<SubmissionUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<SubmissionCreateManyEventInputEnvelope>;
  set?: Maybe<Array<SubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<SubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<SubmissionWhereUniqueInput>>;
  update?: Maybe<Array<SubmissionUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<SubmissionUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<SubmissionScalarWhereInput>>;
};

export type SubmissionUpdateOneRequiredWithoutUser_SubmissionsNestedInput = {
  create?: Maybe<SubmissionCreateWithoutUser_SubmissionsInput>;
  connectOrCreate?: Maybe<SubmissionCreateOrConnectWithoutUser_SubmissionsInput>;
  upsert?: Maybe<SubmissionUpsertWithoutUser_SubmissionsInput>;
  connect?: Maybe<SubmissionWhereUniqueInput>;
  update?: Maybe<SubmissionUpdateToOneWithWhereWithoutUser_SubmissionsInput>;
};

export type SubmissionUpdateOneWithoutTeamNestedInput = {
  create?: Maybe<SubmissionCreateWithoutTeamInput>;
  connectOrCreate?: Maybe<SubmissionCreateOrConnectWithoutTeamInput>;
  upsert?: Maybe<SubmissionUpsertWithoutTeamInput>;
  disconnect?: Maybe<SubmissionWhereInput>;
  delete?: Maybe<SubmissionWhereInput>;
  connect?: Maybe<SubmissionWhereUniqueInput>;
  update?: Maybe<SubmissionUpdateToOneWithWhereWithoutTeamInput>;
};

export type SubmissionUpdateToOneWithWhereWithoutTeamInput = {
  where?: Maybe<SubmissionWhereInput>;
  data: SubmissionUpdateWithoutTeamInput;
};

export type SubmissionUpdateToOneWithWhereWithoutUser_SubmissionsInput = {
  where?: Maybe<SubmissionWhereInput>;
  data: SubmissionUpdateWithoutUser_SubmissionsInput;
};

export type SubmissionUpdateWithWhereUniqueWithoutEventInput = {
  where: SubmissionWhereUniqueInput;
  data: SubmissionUpdateWithoutEventInput;
};

export type SubmissionUpdateWithoutEventInput = {
  contact_info?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  devpost_name?: Maybe<StringFieldUpdateOperationsInput>;
  repository_urls?: Maybe<SubmissionUpdaterepository_UrlsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devpost_url?: Maybe<StringFieldUpdateOperationsInput>;
  categories?: Maybe<SubmissionUpdatecategoriesInput>;
  pitch_type?: Maybe<StringFieldUpdateOperationsInput>;
  video_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  team?: Maybe<TeamUpdateOneWithoutSubmissionNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutSubmissionNestedInput>;
};

export type SubmissionUpdateWithoutTeamInput = {
  contact_info?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  devpost_name?: Maybe<StringFieldUpdateOperationsInput>;
  repository_urls?: Maybe<SubmissionUpdaterepository_UrlsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devpost_url?: Maybe<StringFieldUpdateOperationsInput>;
  categories?: Maybe<SubmissionUpdatecategoriesInput>;
  pitch_type?: Maybe<StringFieldUpdateOperationsInput>;
  video_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSubmissionsNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutSubmissionNestedInput>;
};

export type SubmissionUpdateWithoutUser_SubmissionsInput = {
  contact_info?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  devpost_name?: Maybe<StringFieldUpdateOperationsInput>;
  repository_urls?: Maybe<SubmissionUpdaterepository_UrlsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  devpost_url?: Maybe<StringFieldUpdateOperationsInput>;
  categories?: Maybe<SubmissionUpdatecategoriesInput>;
  pitch_type?: Maybe<StringFieldUpdateOperationsInput>;
  video_url?: Maybe<NullableStringFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutSubmissionsNestedInput>;
  team?: Maybe<TeamUpdateOneWithoutSubmissionNestedInput>;
};

export type SubmissionUpdatecategoriesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type SubmissionUpdaterepository_UrlsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type SubmissionUpsertWithWhereUniqueWithoutEventInput = {
  where: SubmissionWhereUniqueInput;
  update: SubmissionUpdateWithoutEventInput;
  create: SubmissionCreateWithoutEventInput;
};

export type SubmissionUpsertWithoutTeamInput = {
  update: SubmissionUpdateWithoutTeamInput;
  create: SubmissionCreateWithoutTeamInput;
  where?: Maybe<SubmissionWhereInput>;
};

export type SubmissionUpsertWithoutUser_SubmissionsInput = {
  update: SubmissionUpdateWithoutUser_SubmissionsInput;
  create: SubmissionCreateWithoutUser_SubmissionsInput;
  where?: Maybe<SubmissionWhereInput>;
};

export type SubmissionWhereInput = {
  AND?: Maybe<Array<SubmissionWhereInput>>;
  OR?: Maybe<Array<SubmissionWhereInput>>;
  NOT?: Maybe<Array<SubmissionWhereInput>>;
  contact_info?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  devpost_name?: Maybe<StringFilter>;
  repository_urls?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  devpost_url?: Maybe<StringFilter>;
  categories?: Maybe<StringNullableListFilter>;
  pitch_type?: Maybe<StringFilter>;
  team_id?: Maybe<StringNullableFilter>;
  video_url?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  team?: Maybe<TeamNullableRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
};

export type SubmissionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  devpost_url?: Maybe<Scalars["String"]>;
  team_id?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<SubmissionWhereInput>>;
  OR?: Maybe<Array<SubmissionWhereInput>>;
  NOT?: Maybe<Array<SubmissionWhereInput>>;
  contact_info?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  devpost_name?: Maybe<StringFilter>;
  repository_urls?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  categories?: Maybe<StringNullableListFilter>;
  pitch_type?: Maybe<StringFilter>;
  video_url?: Maybe<StringNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  team?: Maybe<TeamNullableRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
};

export type Subscription = {
  __typename?: "Subscription";
  eventPlatformIdSubscription: User;
  /** Subscription for real-time updates on the total number of responses submitted. */
  responsesSubmittedSubscription: Scalars["Float"];
  /** Subscription for real-time updates on the count of checked-in attendees at the event. */
  checkedInAttendeesSubscription: Scalars["Float"];
  /** Subscription for real-time updates on the count of participants interacting with signage questions. */
  participatingHackersSubscription: Scalars["Float"];
  claimUpserts: Claim;
  eventUpdatesSubscription: EventUpdateEntry;
};

export type SubscriptionClaimUpsertsArgs = {
  field_answer_filter?: Maybe<Array<ClaimSubscriptionFieldAnswerFilterInput>>;
  user_ids?: Maybe<Array<Scalars["Float"]>>;
  stage_ids?: Maybe<Array<Scalars["Float"]>>;
  pipeline_id: Scalars["Float"];
};

export type Success = {
  __typename?: "Success";
  status: Scalars["String"];
};

export type TEvent = {
  __typename?: "TEvent";
  id: Scalars["Float"];
  name: Scalars["String"];
  event_type: Scalars["String"];
  permission?: Maybe<Scalars["String"]>;
  start_time: Scalars["Float"];
  end_time: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  speakers: Array<TSpeaker>;
  public_url?: Maybe<Scalars["String"]>;
  private_url: Scalars["String"];
  related_events: Array<Scalars["Int"]>;
};

export type TSpeaker = {
  __typename?: "TSpeaker";
  name: Scalars["String"];
  profile_pic?: Maybe<Scalars["String"]>;
};

export type TeamCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  created_by_id?: Maybe<Scalars["Int"]>;
  disabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type TeamCreateManyEventInputEnvelope = {
  data: Array<TeamCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type TeamCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  disabled: Scalars["Boolean"];
  event_slug: Scalars["String"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type TeamCreateManyUserInputEnvelope = {
  data: Array<TeamCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type TeamCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<TeamCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<TeamCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
};

export type TeamCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<TeamCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<TeamCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
};

export type TeamCreateNestedOneWithoutSubmissionInput = {
  create?: Maybe<TeamCreateWithoutSubmissionInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutSubmissionInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
};

export type TeamCreateNestedOneWithoutTeam_MembersInput = {
  create?: Maybe<TeamCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutTeam_MembersInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
};

export type TeamCreateOrConnectWithoutEventInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutEventInput;
};

export type TeamCreateOrConnectWithoutSubmissionInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutSubmissionInput;
};

export type TeamCreateOrConnectWithoutTeam_MembersInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutTeam_MembersInput;
};

export type TeamCreateOrConnectWithoutUserInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutUserInput;
};

export type TeamCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  disabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  submission?: Maybe<SubmissionCreateNestedOneWithoutTeamInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutTeamInput>;
  user?: Maybe<UserCreateNestedOneWithoutTeamsInput>;
};

export type TeamCreateWithoutSubmissionInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  disabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutTeamInput>;
  user?: Maybe<UserCreateNestedOneWithoutTeamsInput>;
  event: EventCreateNestedOneWithoutTeamsInput;
};

export type TeamCreateWithoutTeam_MembersInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  disabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  submission?: Maybe<SubmissionCreateNestedOneWithoutTeamInput>;
  user?: Maybe<UserCreateNestedOneWithoutTeamsInput>;
  event: EventCreateNestedOneWithoutTeamsInput;
};

export type TeamCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  disabled: Scalars["Boolean"];
  id?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  submission?: Maybe<SubmissionCreateNestedOneWithoutTeamInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutTeamInput>;
  event: EventCreateNestedOneWithoutTeamsInput;
};

export type TeamListRelationFilter = {
  every?: Maybe<TeamWhereInput>;
  some?: Maybe<TeamWhereInput>;
  none?: Maybe<TeamWhereInput>;
};

export type TeamMemberCreateManyEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  team_id: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type TeamMemberCreateManyEventInputEnvelope = {
  data: Array<TeamMemberCreateManyEventInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type TeamMemberCreateManyTeamInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  user_id: Scalars["Int"];
};

export type TeamMemberCreateManyTeamInputEnvelope = {
  data: Array<TeamMemberCreateManyTeamInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type TeamMemberCreateManyUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  event_slug: Scalars["String"];
  team_id: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type TeamMemberCreateManyUserInputEnvelope = {
  data: Array<TeamMemberCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type TeamMemberCreateNestedManyWithoutEventInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutEventInput>>;
  createMany?: Maybe<TeamMemberCreateManyEventInputEnvelope>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
};

export type TeamMemberCreateNestedManyWithoutTeamInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutTeamInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutTeamInput>>;
  createMany?: Maybe<TeamMemberCreateManyTeamInputEnvelope>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
};

export type TeamMemberCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<TeamMemberCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
};

export type TeamMemberCreateOrConnectWithoutEventInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutEventInput;
};

export type TeamMemberCreateOrConnectWithoutTeamInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutTeamInput;
};

export type TeamMemberCreateOrConnectWithoutUserInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutUserInput;
};

export type TeamMemberCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  team: TeamCreateNestedOneWithoutTeam_MembersInput;
  user: UserCreateNestedOneWithoutTeam_MembersInput;
};

export type TeamMemberCreateWithoutTeamInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event: EventCreateNestedOneWithoutTeam_MembersInput;
  user: UserCreateNestedOneWithoutTeam_MembersInput;
};

export type TeamMemberCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event: EventCreateNestedOneWithoutTeam_MembersInput;
  team: TeamCreateNestedOneWithoutTeam_MembersInput;
};

export type TeamMemberListRelationFilter = {
  every?: Maybe<TeamMemberWhereInput>;
  some?: Maybe<TeamMemberWhereInput>;
  none?: Maybe<TeamMemberWhereInput>;
};

export type TeamMemberOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TeamMemberScalarWhereInput = {
  AND?: Maybe<Array<TeamMemberScalarWhereInput>>;
  OR?: Maybe<Array<TeamMemberScalarWhereInput>>;
  NOT?: Maybe<Array<TeamMemberScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
};

export type TeamMemberUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamMemberUpdateManyWithWhereWithoutEventInput = {
  where: TeamMemberScalarWhereInput;
  data: TeamMemberUpdateManyMutationInput;
};

export type TeamMemberUpdateManyWithWhereWithoutTeamInput = {
  where: TeamMemberScalarWhereInput;
  data: TeamMemberUpdateManyMutationInput;
};

export type TeamMemberUpdateManyWithWhereWithoutUserInput = {
  where: TeamMemberScalarWhereInput;
  data: TeamMemberUpdateManyMutationInput;
};

export type TeamMemberUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<TeamMemberUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<TeamMemberCreateManyEventInputEnvelope>;
  set?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  delete?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  update?: Maybe<Array<TeamMemberUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<TeamMemberUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<TeamMemberScalarWhereInput>>;
};

export type TeamMemberUpdateManyWithoutTeamNestedInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutTeamInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutTeamInput>>;
  upsert?: Maybe<Array<TeamMemberUpsertWithWhereUniqueWithoutTeamInput>>;
  createMany?: Maybe<TeamMemberCreateManyTeamInputEnvelope>;
  set?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  delete?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  update?: Maybe<Array<TeamMemberUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: Maybe<Array<TeamMemberUpdateManyWithWhereWithoutTeamInput>>;
  deleteMany?: Maybe<Array<TeamMemberScalarWhereInput>>;
};

export type TeamMemberUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<TeamMemberUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<TeamMemberCreateManyUserInputEnvelope>;
  set?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  disconnect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  delete?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  update?: Maybe<Array<TeamMemberUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<TeamMemberUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<TeamMemberScalarWhereInput>>;
};

export type TeamMemberUpdateWithWhereUniqueWithoutEventInput = {
  where: TeamMemberWhereUniqueInput;
  data: TeamMemberUpdateWithoutEventInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutTeamInput = {
  where: TeamMemberWhereUniqueInput;
  data: TeamMemberUpdateWithoutTeamInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutUserInput = {
  where: TeamMemberWhereUniqueInput;
  data: TeamMemberUpdateWithoutUserInput;
};

export type TeamMemberUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  team?: Maybe<TeamUpdateOneRequiredWithoutTeam_MembersNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutTeam_MembersNestedInput>;
};

export type TeamMemberUpdateWithoutTeamInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutTeam_MembersNestedInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutTeam_MembersNestedInput>;
};

export type TeamMemberUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutTeam_MembersNestedInput>;
  team?: Maybe<TeamUpdateOneRequiredWithoutTeam_MembersNestedInput>;
};

export type TeamMemberUpsertWithWhereUniqueWithoutEventInput = {
  where: TeamMemberWhereUniqueInput;
  update: TeamMemberUpdateWithoutEventInput;
  create: TeamMemberCreateWithoutEventInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutTeamInput = {
  where: TeamMemberWhereUniqueInput;
  update: TeamMemberUpdateWithoutTeamInput;
  create: TeamMemberCreateWithoutTeamInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutUserInput = {
  where: TeamMemberWhereUniqueInput;
  update: TeamMemberUpdateWithoutUserInput;
  create: TeamMemberCreateWithoutUserInput;
};

export type TeamMemberUser_IdEvent_SlugCompoundUniqueInput = {
  user_id: Scalars["Int"];
  event_slug: Scalars["String"];
};

export type TeamMemberWhereInput = {
  AND?: Maybe<Array<TeamMemberWhereInput>>;
  OR?: Maybe<Array<TeamMemberWhereInput>>;
  NOT?: Maybe<Array<TeamMemberWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  event?: Maybe<EventRelationFilter>;
  team?: Maybe<TeamRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type TeamMemberWhereUniqueInput = {
  user_id_event_slug?: Maybe<TeamMemberUser_IdEvent_SlugCompoundUniqueInput>;
  AND?: Maybe<Array<TeamMemberWhereInput>>;
  OR?: Maybe<Array<TeamMemberWhereInput>>;
  NOT?: Maybe<Array<TeamMemberWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  event?: Maybe<EventRelationFilter>;
  team?: Maybe<TeamRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type TeamNullableRelationFilter = {
  is?: Maybe<TeamWhereInput>;
  isNot?: Maybe<TeamWhereInput>;
};

export type TeamOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type TeamRelationFilter = {
  is?: Maybe<TeamWhereInput>;
  isNot?: Maybe<TeamWhereInput>;
};

export type TeamScalarWhereInput = {
  AND?: Maybe<Array<TeamScalarWhereInput>>;
  OR?: Maybe<Array<TeamScalarWhereInput>>;
  NOT?: Maybe<Array<TeamScalarWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
};

export type TeamUpdateManyMutationInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithWhereWithoutEventInput = {
  where: TeamScalarWhereInput;
  data: TeamUpdateManyMutationInput;
};

export type TeamUpdateManyWithWhereWithoutUserInput = {
  where: TeamScalarWhereInput;
  data: TeamUpdateManyMutationInput;
};

export type TeamUpdateManyWithoutEventNestedInput = {
  create?: Maybe<Array<TeamCreateWithoutEventInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithoutEventInput>>;
  upsert?: Maybe<Array<TeamUpsertWithWhereUniqueWithoutEventInput>>;
  createMany?: Maybe<TeamCreateManyEventInputEnvelope>;
  set?: Maybe<Array<TeamWhereUniqueInput>>;
  disconnect?: Maybe<Array<TeamWhereUniqueInput>>;
  delete?: Maybe<Array<TeamWhereUniqueInput>>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
  update?: Maybe<Array<TeamUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: Maybe<Array<TeamUpdateManyWithWhereWithoutEventInput>>;
  deleteMany?: Maybe<Array<TeamScalarWhereInput>>;
};

export type TeamUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<TeamCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<TeamUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<TeamCreateManyUserInputEnvelope>;
  set?: Maybe<Array<TeamWhereUniqueInput>>;
  disconnect?: Maybe<Array<TeamWhereUniqueInput>>;
  delete?: Maybe<Array<TeamWhereUniqueInput>>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
  update?: Maybe<Array<TeamUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<TeamUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<TeamScalarWhereInput>>;
};

export type TeamUpdateOneRequiredWithoutTeam_MembersNestedInput = {
  create?: Maybe<TeamCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutTeam_MembersInput>;
  upsert?: Maybe<TeamUpsertWithoutTeam_MembersInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
  update?: Maybe<TeamUpdateToOneWithWhereWithoutTeam_MembersInput>;
};

export type TeamUpdateOneWithoutSubmissionNestedInput = {
  create?: Maybe<TeamCreateWithoutSubmissionInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutSubmissionInput>;
  upsert?: Maybe<TeamUpsertWithoutSubmissionInput>;
  disconnect?: Maybe<TeamWhereInput>;
  delete?: Maybe<TeamWhereInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
  update?: Maybe<TeamUpdateToOneWithWhereWithoutSubmissionInput>;
};

export type TeamUpdateToOneWithWhereWithoutSubmissionInput = {
  where?: Maybe<TeamWhereInput>;
  data: TeamUpdateWithoutSubmissionInput;
};

export type TeamUpdateToOneWithWhereWithoutTeam_MembersInput = {
  where?: Maybe<TeamWhereInput>;
  data: TeamUpdateWithoutTeam_MembersInput;
};

export type TeamUpdateWithWhereUniqueWithoutEventInput = {
  where: TeamWhereUniqueInput;
  data: TeamUpdateWithoutEventInput;
};

export type TeamUpdateWithWhereUniqueWithoutUserInput = {
  where: TeamWhereUniqueInput;
  data: TeamUpdateWithoutUserInput;
};

export type TeamUpdateWithoutEventInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission?: Maybe<SubmissionUpdateOneWithoutTeamNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutTeamNestedInput>;
  user?: Maybe<UserUpdateOneWithoutTeamsNestedInput>;
};

export type TeamUpdateWithoutSubmissionInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutTeamNestedInput>;
  user?: Maybe<UserUpdateOneWithoutTeamsNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutTeamsNestedInput>;
};

export type TeamUpdateWithoutTeam_MembersInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission?: Maybe<SubmissionUpdateOneWithoutTeamNestedInput>;
  user?: Maybe<UserUpdateOneWithoutTeamsNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutTeamsNestedInput>;
};

export type TeamUpdateWithoutUserInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  disabled?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  submission?: Maybe<SubmissionUpdateOneWithoutTeamNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutTeamNestedInput>;
  event?: Maybe<EventUpdateOneRequiredWithoutTeamsNestedInput>;
};

export type TeamUpsertWithWhereUniqueWithoutEventInput = {
  where: TeamWhereUniqueInput;
  update: TeamUpdateWithoutEventInput;
  create: TeamCreateWithoutEventInput;
};

export type TeamUpsertWithWhereUniqueWithoutUserInput = {
  where: TeamWhereUniqueInput;
  update: TeamUpdateWithoutUserInput;
  create: TeamCreateWithoutUserInput;
};

export type TeamUpsertWithoutSubmissionInput = {
  update: TeamUpdateWithoutSubmissionInput;
  create: TeamCreateWithoutSubmissionInput;
  where?: Maybe<TeamWhereInput>;
};

export type TeamUpsertWithoutTeam_MembersInput = {
  update: TeamUpdateWithoutTeam_MembersInput;
  create: TeamCreateWithoutTeam_MembersInput;
  where?: Maybe<TeamWhereInput>;
};

export type TeamWhereInput = {
  AND?: Maybe<Array<TeamWhereInput>>;
  OR?: Maybe<Array<TeamWhereInput>>;
  NOT?: Maybe<Array<TeamWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  submission?: Maybe<SubmissionNullableRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type TeamWhereUniqueInput = {
  id?: Maybe<Scalars["String"]>;
  AND?: Maybe<Array<TeamWhereInput>>;
  OR?: Maybe<Array<TeamWhereInput>>;
  NOT?: Maybe<Array<TeamWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  event_slug?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  submission?: Maybe<SubmissionNullableRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  user?: Maybe<UserNullableRelationFilter>;
  event?: Maybe<EventRelationFilter>;
};

/** Options for time frame of HTN Bucks leaderboard */
export enum TimeFrame {
  Month = "MONTH",
  Total = "TOTAL",
}

export type TravelInformationCreateNestedOneWithoutUsersInput = {
  create?: Maybe<TravelInformationCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<TravelInformationCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<TravelInformationWhereUniqueInput>;
};

export type TravelInformationCreateOrConnectWithoutUsersInput = {
  where: TravelInformationWhereUniqueInput;
  create: TravelInformationCreateWithoutUsersInput;
};

export type TravelInformationCreateWithoutUsersInput = {
  citizenship_country?: Maybe<Scalars["String"]>;
  closest_airport?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  current_city?: Maybe<Scalars["String"]>;
  expiry_date?: Maybe<Scalars["DateTimeISO"]>;
  issue_date?: Maybe<Scalars["DateTimeISO"]>;
  passport_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  visa_country?: Maybe<Scalars["String"]>;
  visa_type?: Maybe<Scalars["String"]>;
};

export type TravelInformationNullableRelationFilter = {
  is?: Maybe<TravelInformationWhereInput>;
  isNot?: Maybe<TravelInformationWhereInput>;
};

export enum TravelInformationOrderByRelevanceFieldEnum {
  CitizenshipCountry = "citizenship_country",
  ClosestAirport = "closest_airport",
  CurrentCity = "current_city",
  PassportNumber = "passport_number",
  VisaCountry = "visa_country",
  VisaType = "visa_type",
}

export type TravelInformationOrderByRelevanceInput = {
  fields: Array<TravelInformationOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type TravelInformationOrderByWithRelationAndSearchRelevanceInput = {
  citizenship_country?: Maybe<SortOrderInput>;
  closest_airport?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  current_city?: Maybe<SortOrderInput>;
  expiry_date?: Maybe<SortOrderInput>;
  id?: Maybe<SortOrder>;
  issue_date?: Maybe<SortOrderInput>;
  passport_number?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  visa_country?: Maybe<SortOrderInput>;
  visa_type?: Maybe<SortOrderInput>;
  users?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<TravelInformationOrderByRelevanceInput>;
};

export type TravelInformationUpdateOneWithoutUsersNestedInput = {
  create?: Maybe<TravelInformationCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<TravelInformationCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<TravelInformationUpsertWithoutUsersInput>;
  disconnect?: Maybe<TravelInformationWhereInput>;
  delete?: Maybe<TravelInformationWhereInput>;
  connect?: Maybe<TravelInformationWhereUniqueInput>;
  update?: Maybe<TravelInformationUpdateToOneWithWhereWithoutUsersInput>;
};

export type TravelInformationUpdateToOneWithWhereWithoutUsersInput = {
  where?: Maybe<TravelInformationWhereInput>;
  data: TravelInformationUpdateWithoutUsersInput;
};

export type TravelInformationUpdateWithoutUsersInput = {
  citizenship_country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  closest_airport?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  current_city?: Maybe<NullableStringFieldUpdateOperationsInput>;
  expiry_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  issue_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  passport_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  visa_country?: Maybe<NullableStringFieldUpdateOperationsInput>;
  visa_type?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type TravelInformationUpsertWithoutUsersInput = {
  update: TravelInformationUpdateWithoutUsersInput;
  create: TravelInformationCreateWithoutUsersInput;
  where?: Maybe<TravelInformationWhereInput>;
};

export type TravelInformationWhereInput = {
  AND?: Maybe<Array<TravelInformationWhereInput>>;
  OR?: Maybe<Array<TravelInformationWhereInput>>;
  NOT?: Maybe<Array<TravelInformationWhereInput>>;
  citizenship_country?: Maybe<StringNullableFilter>;
  closest_airport?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  current_city?: Maybe<StringNullableFilter>;
  expiry_date?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<IntFilter>;
  issue_date?: Maybe<DateTimeNullableFilter>;
  passport_number?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  visa_country?: Maybe<StringNullableFilter>;
  visa_type?: Maybe<StringNullableFilter>;
  users?: Maybe<UserNullableRelationFilter>;
};

export type TravelInformationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<TravelInformationWhereInput>>;
  OR?: Maybe<Array<TravelInformationWhereInput>>;
  NOT?: Maybe<Array<TravelInformationWhereInput>>;
  citizenship_country?: Maybe<StringNullableFilter>;
  closest_airport?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  current_city?: Maybe<StringNullableFilter>;
  expiry_date?: Maybe<DateTimeNullableFilter>;
  issue_date?: Maybe<DateTimeNullableFilter>;
  passport_number?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  visa_country?: Maybe<StringNullableFilter>;
  visa_type?: Maybe<StringNullableFilter>;
  users?: Maybe<UserNullableRelationFilter>;
};

export type TypedClaimsResolver_Hackthenorth2021_Applications_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_APPLICATIONS_Output";
  short_name: StringFieldAnswerTypeWrapper;
  short_grad_year: NumberFieldAnswerTypeWrapper;
  short_grad_level: StringFieldAnswerTypeWrapper;
  short_school: StringFieldAnswerTypeWrapper;
  short_program: StringFieldAnswerTypeWrapper;
  short_location: StringFieldAnswerTypeWrapper;
  short_hackathon_count: StringFieldAnswerTypeWrapper;
  long_desire: StringFieldAnswerTypeWrapper;
  long_collaboration: StringFieldAnswerTypeWrapper;
  long_obstacle: StringFieldAnswerTypeWrapper;
  long_hacker_type: StringArrayFieldAnswerTypeWrapper;
  long_links: StringArrayFieldAnswerTypeWrapper;
  survey_gender: StringArrayFieldAnswerTypeWrapper;
  survey_race: StringArrayFieldAnswerTypeWrapper;
  survey_ethnicity: StringArrayFieldAnswerTypeWrapper;
  survey_education: BooleanFieldAnswerTypeWrapper;
  survey_personal_experience: StringFieldAnswerTypeWrapper;
  survey_family_experience: BooleanFieldAnswerTypeWrapper;
  survey_groups: StringArrayFieldAnswerTypeWrapper;
  survey_underrep_groups: StringFieldAnswerTypeWrapper;
  survey_tech_preferences: StringArrayFieldAnswerTypeWrapper;
  survey_workshop_preferences: StringArrayFieldAnswerTypeWrapper;
  boat_custom_color: StringFieldAnswerTypeWrapper;
  boat_custom_expression: StringFieldAnswerTypeWrapper;
  boat_custom_accessory: StringFieldAnswerTypeWrapper;
  visited_steps: StringArrayFieldAnswerTypeWrapper;
  version_number: NumberFieldAnswerTypeWrapper;
  reviewer_id: NumberFieldAnswerTypeWrapper;
  legal_name: StringFieldAnswerTypeWrapper;
  preferred_name: StringFieldAnswerTypeWrapper;
  pronouns: StringArrayFieldAnswerTypeWrapper;
  phone_number: StringFieldAnswerTypeWrapper;
  location: StringFieldAnswerTypeWrapper;
  address_1: StringFieldAnswerTypeWrapper;
  address_2: StringFieldAnswerTypeWrapper;
  city: StringFieldAnswerTypeWrapper;
  province_state_region: StringFieldAnswerTypeWrapper;
  country: StringFieldAnswerTypeWrapper;
  postal_code: StringFieldAnswerTypeWrapper;
  shirt_type: StringFieldAnswerTypeWrapper;
  shirt_size: StringFieldAnswerTypeWrapper;
  swag_pickup: BooleanFieldAnswerTypeWrapper;
  accessibility_needs: StringFieldAnswerTypeWrapper;
  terms_and_conditions: BooleanFieldAnswerTypeWrapper;
  age_18: BooleanFieldAnswerTypeWrapper;
  guardian_name: StringFieldAnswerTypeWrapper;
  guardian_contact: StringFieldAnswerTypeWrapper;
  waiver_consent: FileFieldAnswerTypeWrapper;
  recording_consent: BooleanFieldAnswerTypeWrapper;
  mlh_share_consent: BooleanFieldAnswerTypeWrapper;
  mlh_email_consent: BooleanFieldAnswerTypeWrapper;
  meetups: StringArrayFieldAnswerTypeWrapper;
  mentorship_interest: BooleanFieldAnswerTypeWrapper;
  mentorship_expertise: StringArrayFieldAnswerTypeWrapper;
  kitchener_waterloo: BooleanFieldAnswerTypeWrapper;
  second_dose_vaccine: BooleanFieldAnswerTypeWrapper;
  tech_talks: StringArrayFieldAnswerTypeWrapper;
  recruitment_consent: BooleanFieldAnswerTypeWrapper;
  resume: FileFieldAnswerTypeWrapper;
  canadian_citizen: BooleanFieldAnswerTypeWrapper;
  permanent_resident: BooleanFieldAnswerTypeWrapper;
  looking_for_internships: BooleanFieldAnswerTypeWrapper;
  looking_for_fulltime: BooleanFieldAnswerTypeWrapper;
  networking: StringArrayFieldAnswerTypeWrapper;
  coffee_chat: StringArrayFieldAnswerTypeWrapper;
  wics_coffee_chat: BooleanFieldAnswerTypeWrapper;
  team_formation_location: StringFieldAnswerTypeWrapper;
  team_formation_competitiveness: StringFieldAnswerTypeWrapper;
  team_formation_skills_and_technologies: StringArrayFieldAnswerTypeWrapper;
  team_formation_about_me: StringFieldAnswerTypeWrapper;
  team_formation_interests: StringArrayFieldAnswerTypeWrapper;
  team_formation_profile_pic: FileFieldAnswerTypeWrapper;
  team_formation_profile_pic_url: StringFieldAnswerTypeWrapper;
  team_formation_discord_tag: StringFieldAnswerTypeWrapper;
  team_formation_devpost: StringFieldAnswerTypeWrapper;
  team_formation_github: StringFieldAnswerTypeWrapper;
  team_formation_linkedin: StringFieldAnswerTypeWrapper;
  team_formation_facebook: StringFieldAnswerTypeWrapper;
  team_formation_airtable_id: StringFieldAnswerTypeWrapper;
  team_formation_team_found: BooleanFieldAnswerTypeWrapper;
  personal_rsvp_stage_fixed: StringFieldAnswerTypeWrapper;
  event_rsvp_stage: StringFieldAnswerTypeWrapper;
  career_rsvp_stage: StringFieldAnswerTypeWrapper;
  rsvp_deadline: StringFieldAnswerTypeWrapper;
  discord_id: StringFieldAnswerTypeWrapper;
  discord_tag: StringFieldAnswerTypeWrapper;
  interests: StringArrayFieldAnswerTypeWrapper;
  terms_conditions: BooleanFieldAnswerTypeWrapper;
  schedule_saved_events: StringArrayFieldAnswerTypeWrapper;
  onboarding_progress: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Applications_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_APPLICATIONS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2021_Applications_Output;
};

export type TypedClaimsResolver_Hackthenorth2021_Contact_Lists_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_CONTACT_LISTS_Output";
  query: StringFieldAnswerTypeWrapper;
  transformer: StringFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  mailjet_list_id: NumberFieldAnswerTypeWrapper;
  last_run_at: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Contact_Lists_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_CONTACT_LISTS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2021_Contact_Lists_Output;
};

export type TypedClaimsResolver_Hackthenorth2021_Mentorship_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_MENTORSHIP_PROFILES_Output";
  mentorship_profile_photo: FileFieldAnswerTypeWrapper;
  discord_id: StringFieldAnswerTypeWrapper;
  name: StringFieldAnswerTypeWrapper;
  pronouns: StringArrayFieldAnswerTypeWrapper;
  user_id: NumberFieldAnswerTypeWrapper;
  institution: StringFieldAnswerTypeWrapper;
  mentorship_topics: StringArrayFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Mentorship_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2021_MENTORSHIP_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2021_Mentorship_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2021_Mentorship_Requests_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_MENTORSHIP_REQUESTS_Output";
  mentee_discord_tag: StringFieldAnswerTypeWrapper;
  reopened: BooleanFieldAnswerTypeWrapper;
  category: StringArrayFieldAnswerTypeWrapper;
  mentor_id: NumberFieldAnswerTypeWrapper;
  mentee_id: NumberFieldAnswerTypeWrapper;
  mentee_name: StringFieldAnswerTypeWrapper;
  priority: NumberFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  title: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Mentorship_Requests_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2021_MENTORSHIP_REQUESTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2021_Mentorship_Requests_Output;
  };

export type TypedClaimsResolver_Hackthenorth2021_Reimbursements_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_REIMBURSEMENTS_Output";
  team_name: StringFieldAnswerTypeWrapper;
  receipts: FileFieldAnswerTypeWrapper;
  transfer_id: NumberFieldAnswerTypeWrapper;
  amount: NumberFieldAnswerTypeWrapper;
  item_description: StringFieldAnswerTypeWrapper;
  currency: StringFieldAnswerTypeWrapper;
  date_purchased: StringFieldAnswerTypeWrapper;
  expense_category: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Reimbursements_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2021_REIMBURSEMENTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2021_Reimbursements_Output;
  };

export type TypedClaimsResolver_Hackthenorth2021_Reviews_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_REVIEWS_Output";
  time_spent: NumberFieldAnswerTypeWrapper;
  overall_score: NumberFieldAnswerTypeWrapper;
  baseline_skillset_score: NumberFieldAnswerTypeWrapper;
  nontraditional_tech_score: NumberFieldAnswerTypeWrapper;
  accessed_sensitive_info: BooleanFieldAnswerTypeWrapper;
  finished_at: StringFieldAnswerTypeWrapper;
  reviewer_name: StringFieldAnswerTypeWrapper;
  application_id: NumberFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Reviews_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_REVIEWS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2021_Reviews_Output;
};

export type TypedClaimsResolver_Hackthenorth2021_Sponsor_Perks_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_SPONSOR_PERKS_Output";
  sponsor_company_id: NumberFieldAnswerTypeWrapper;
  available_perks: StringArrayFieldAnswerTypeWrapper;
  website_logo_file: FileFieldAnswerTypeWrapper;
  website_logo_website_link: StringFieldAnswerTypeWrapper;
  website_logo_status: StringFieldAnswerTypeWrapper;
  website_logo_due_date: StringFieldAnswerTypeWrapper;
  website_feature_message: StringFieldAnswerTypeWrapper;
  website_feature_website_link: StringFieldAnswerTypeWrapper;
  website_feature_link_desc: StringFieldAnswerTypeWrapper;
  website_feature_status: StringFieldAnswerTypeWrapper;
  website_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_file: FileFieldAnswerTypeWrapper;
  newsletter_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_due_date: StringFieldAnswerTypeWrapper;
  newsletter_feature_file: FileFieldAnswerTypeWrapper;
  newsletter_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_input_due_date: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_optional: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_optional: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_status: StringFieldAnswerTypeWrapper;
  pre_hack_contest_title: StringFieldAnswerTypeWrapper;
  pre_hack_contest_description: StringFieldAnswerTypeWrapper;
  pre_hack_contest_due_date: StringFieldAnswerTypeWrapper;
  pre_hack_contest_status: StringFieldAnswerTypeWrapper;
  api_prize_judging_type: StringFieldAnswerTypeWrapper;
  api_prize_title: StringFieldAnswerTypeWrapper;
  api_prize_description: StringFieldAnswerTypeWrapper;
  api_prize_due_date: StringFieldAnswerTypeWrapper;
  api_prize_status: StringFieldAnswerTypeWrapper;
  social_media_assets_files: StringArrayFieldAnswerTypeWrapper;
  social_media_assets_files_fixed: FileFieldAnswerTypeWrapper;
  social_media_assets_caption: StringFieldAnswerTypeWrapper;
  social_media_assets_title_one: StringFieldAnswerTypeWrapper;
  social_media_assets_website_link_one: StringFieldAnswerTypeWrapper;
  social_media_assets_title_two: StringFieldAnswerTypeWrapper;
  social_media_assets_website_link_two: StringFieldAnswerTypeWrapper;
  social_media_assets_title_three: StringFieldAnswerTypeWrapper;
  social_media_assets_website_link_three: StringFieldAnswerTypeWrapper;
  social_media_assets_due_date: StringFieldAnswerTypeWrapper;
  social_media_assets_status: StringFieldAnswerTypeWrapper;
  social_media_takeover_date_takeover_date: StringFieldAnswerTypeWrapper;
  social_media_takeover_date_contact_info: StringFieldAnswerTypeWrapper;
  social_media_takeover_date_due_date: StringFieldAnswerTypeWrapper;
  social_media_takeover_date_status: StringFieldAnswerTypeWrapper;
  api_workshop_time_one: StringFieldAnswerTypeWrapper;
  api_workshop_time_two: StringFieldAnswerTypeWrapper;
  api_workshop_time_three: StringFieldAnswerTypeWrapper;
  api_workshop_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  api_workshop_recording_available: BooleanFieldAnswerTypeWrapper;
  api_workshop_representative: StringFieldAnswerTypeWrapper;
  api_workshop_representative_name: StringFieldAnswerTypeWrapper;
  api_workshop_representative_email: StringFieldAnswerTypeWrapper;
  api_workshop_video_link: StringFieldAnswerTypeWrapper;
  api_workshop_status: StringFieldAnswerTypeWrapper;
  api_workshop_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_time_time_one: StringFieldAnswerTypeWrapper;
  tech_talk_time_time_two: StringFieldAnswerTypeWrapper;
  tech_talk_time_time_three: StringFieldAnswerTypeWrapper;
  tech_talk_time_representative: StringFieldAnswerTypeWrapper;
  tech_talk_time_representative_name: StringFieldAnswerTypeWrapper;
  tech_talk_time_representative_email: StringFieldAnswerTypeWrapper;
  tech_talk_time_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  tech_talk_time_recording_available: BooleanFieldAnswerTypeWrapper;
  tech_talk_time_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_time_status: StringFieldAnswerTypeWrapper;
  career_development_time_one: StringFieldAnswerTypeWrapper;
  career_development_time_two: StringFieldAnswerTypeWrapper;
  career_development_time_three: StringFieldAnswerTypeWrapper;
  career_development_representative: StringFieldAnswerTypeWrapper;
  career_development_representative_name: StringFieldAnswerTypeWrapper;
  career_development_representative_email: StringFieldAnswerTypeWrapper;
  career_development_title: StringFieldAnswerTypeWrapper;
  career_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  career_accepted_recording_available: BooleanFieldAnswerTypeWrapper;
  career_development_description: StringFieldAnswerTypeWrapper;
  career_development_due_date: StringFieldAnswerTypeWrapper;
  career_development_status: StringFieldAnswerTypeWrapper;
  hopin_representatives_representatives: StringArrayFieldAnswerTypeWrapper;
  hopin_representatives_emails: StringFieldAnswerTypeWrapper;
  hopin_representatives_emails_fixed: StringArrayFieldAnswerTypeWrapper;
  hopin_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  hopin_representatives_count: NumberFieldAnswerTypeWrapper;
  hopin_representatives_due_date: StringFieldAnswerTypeWrapper;
  hopin_representatives_status: StringFieldAnswerTypeWrapper;
  discord_representatives_representatives: StringArrayFieldAnswerTypeWrapper;
  discord_representatives_emails: StringArrayFieldAnswerTypeWrapper;
  discord_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  discord_representatives_count: NumberFieldAnswerTypeWrapper;
  discord_representatives_due_date: StringFieldAnswerTypeWrapper;
  discord_representatives_status: StringFieldAnswerTypeWrapper;
  stage_time_uploaded: BooleanFieldAnswerTypeWrapper;
  stage_time_video_link: StringFieldAnswerTypeWrapper;
  stage_time_due_date: StringFieldAnswerTypeWrapper;
  stage_time_status: StringFieldAnswerTypeWrapper;
  coffee_chats_start_time: StringFieldAnswerTypeWrapper;
  coffee_chats_duration: StringFieldAnswerTypeWrapper;
  coffee_chats_representatives: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_emails: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_favorited_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_due_date: StringFieldAnswerTypeWrapper;
  coffee_chats_status: StringFieldAnswerTypeWrapper;
  social_media_promotion_consent: BooleanFieldAnswerTypeWrapper;
  social_media_promotion_due_date: StringFieldAnswerTypeWrapper;
  social_media_promotion_status: StringFieldAnswerTypeWrapper;
  meetup_event_details_title: StringFieldAnswerTypeWrapper;
  meetup_event_details_description: StringFieldAnswerTypeWrapper;
  meetup_event_details_breakout_rooms: StringFieldAnswerTypeWrapper;
  meetup_event_details_due_date: StringFieldAnswerTypeWrapper;
  meetup_event_details_status: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Sponsor_Perks_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_SPONSOR_PERKS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2021_Sponsor_Perks_Output;
};

export type TypedClaimsResolver_Hackthenorth2021_Technical_Jargon_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2021_TECHNICAL_JARGON_Output";
  term: StringFieldAnswerTypeWrapper;
  definition: StringFieldAnswerTypeWrapper;
  source: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2021_Technical_Jargon_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2021_TECHNICAL_JARGON_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2021_Technical_Jargon_Output;
  };

export type TypedClaimsResolver_Hackthenorth2022_Applications_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_APPLICATIONS_Output";
  short_name: StringFieldAnswerTypeWrapper;
  short_grad_year: NumberFieldAnswerTypeWrapper;
  short_grad_level: StringFieldAnswerTypeWrapper;
  short_school: StringFieldAnswerTypeWrapper;
  short_program: StringFieldAnswerTypeWrapper;
  short_location: StringFieldAnswerTypeWrapper;
  short_hackathon_count: StringFieldAnswerTypeWrapper;
  long_hackathon_plans: StringFieldAnswerTypeWrapper;
  long_skill_development: StringFieldAnswerTypeWrapper;
  long_achievement: StringFieldAnswerTypeWrapper;
  long_obstacle: StringFieldAnswerTypeWrapper;
  long_achievement_or_obstacle: StringFieldAnswerTypeWrapper;
  long_hacker_type: StringArrayFieldAnswerTypeWrapper;
  long_links: StringArrayFieldAnswerTypeWrapper;
  survey_accessibility: StringFieldAnswerTypeWrapper;
  survey_laptop: BooleanFieldAnswerTypeWrapper;
  survey_gender: StringArrayFieldAnswerTypeWrapper;
  survey_race: StringArrayFieldAnswerTypeWrapper;
  survey_ethnicity: StringArrayFieldAnswerTypeWrapper;
  survey_identity_groups: StringArrayFieldAnswerTypeWrapper;
  survey_personal_experience: StringFieldAnswerTypeWrapper;
  survey_clubs: StringFieldAnswerTypeWrapper;
  survey_tech_preferences: StringArrayFieldAnswerTypeWrapper;
  survey_workshop_preferences: StringArrayFieldAnswerTypeWrapper;
  apps_character_name: StringFieldAnswerTypeWrapper;
  apps_theme_name: StringFieldAnswerTypeWrapper;
  visited_steps: StringArrayFieldAnswerTypeWrapper;
  reviewer_id: NumberFieldAnswerTypeWrapper;
  rsvp_legal_name: StringFieldAnswerTypeWrapper;
  rsvp_preferred_name: StringFieldAnswerTypeWrapper;
  rsvp_pronouns: StringArrayFieldAnswerTypeWrapper;
  rsvp_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_mlh_share_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_accessibility_needs: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_type: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_size: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_accomodations: StringFieldAnswerTypeWrapper;
  rsvp_food_dietary_restrictions: StringArrayFieldAnswerTypeWrapper;
  rsvp_project_stream: StringFieldAnswerTypeWrapper;
  rsvp_tech_talks: StringArrayFieldAnswerTypeWrapper;
  rsvp_meetups: StringArrayFieldAnswerTypeWrapper;
  rsvp_hackathon_host_request: StringFieldAnswerTypeWrapper;
  rsvp_sponsor_interest: StringArrayFieldAnswerTypeWrapper;
  rsvp_career_resume: FileFieldAnswerTypeWrapper;
  rsvp_career_recruitment_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_career_canadian_citizen: BooleanFieldAnswerTypeWrapper;
  rsvp_career_permanent_resident: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_internships: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_fulltime: BooleanFieldAnswerTypeWrapper;
  rsvp_career_coffee_chat: StringArrayFieldAnswerTypeWrapper;
  rsvp_consent_age_18: BooleanFieldAnswerTypeWrapper;
  rsvp_consent_terms_conditions: BooleanFieldAnswerTypeWrapper;
  personal_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_rsvp_stage: StringFieldAnswerTypeWrapper;
  event_rsvp_stage: StringFieldAnswerTypeWrapper;
  career_rsvp_stage: StringFieldAnswerTypeWrapper;
  confirmation_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_details_rsvp_stage: StringFieldAnswerTypeWrapper;
  rsvp_deadline: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_name: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_contact: StringFieldAnswerTypeWrapper;
  rsvp_consent_waiver: FileFieldAnswerTypeWrapper;
  rsvp_travel_location: StringFieldAnswerTypeWrapper;
  rsvp_travel_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_bus_opt_in: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_covid_test_requirement: StringFieldAnswerTypeWrapper;
  rsvp_travel_terms_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_immigration_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_share_email_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_requires_visa: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_tracking_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_receipt_upload: FileFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_arrival_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_departure_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_currency: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_amount: NumberFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_name: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_address: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_etransfer_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_paypal_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_flight_reimbursement: StringFieldAnswerTypeWrapper;
  rsvp_travel_departure_airport: StringFieldAnswerTypeWrapper;
  rsvp_travel_arrival_airport: StringFieldAnswerTypeWrapper;
  rsvp_event_accommodations: StringFieldAnswerTypeWrapper;
  rsvp_entry_eligibility_acknowledgement: BooleanFieldAnswerTypeWrapper;
  networking_github_link: StringFieldAnswerTypeWrapper;
  networking_instagram_link: StringFieldAnswerTypeWrapper;
  networking_facebook_link: StringFieldAnswerTypeWrapper;
  networking_twitter_link: StringFieldAnswerTypeWrapper;
  networking_linkedin_link: StringFieldAnswerTypeWrapper;
  networking_discord_tag: StringFieldAnswerTypeWrapper;
  networking_tiktok_link: StringFieldAnswerTypeWrapper;
  networking_biography: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Applications_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_APPLICATIONS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2022_Applications_Output;
};

export type TypedClaimsResolver_Hackthenorth2022_Contact_Lists_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_CONTACT_LISTS_Output";
  query: StringFieldAnswerTypeWrapper;
  transformer: StringFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  mailjet_list_id: NumberFieldAnswerTypeWrapper;
  filter_unsubscribed_emails: BooleanFieldAnswerTypeWrapper;
  last_run_at: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Contact_Lists_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_CONTACT_LISTS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2022_Contact_Lists_Output;
};

export type TypedClaimsResolver_Hackthenorth2022_Mentorship_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_MENTORSHIP_PROFILES_Output";
  name: StringFieldAnswerTypeWrapper;
  pronouns: StringArrayFieldAnswerTypeWrapper;
  other_pronouns: StringFieldAnswerTypeWrapper;
  user_id: NumberFieldAnswerTypeWrapper;
  institution: StringFieldAnswerTypeWrapper;
  mentorship_topics: StringArrayFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Mentorship_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2022_MENTORSHIP_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2022_Mentorship_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2022_Mentorship_Requests_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_MENTORSHIP_REQUESTS_Output";
  reopened: BooleanFieldAnswerTypeWrapper;
  category: StringArrayFieldAnswerTypeWrapper;
  mentor_id: NumberFieldAnswerTypeWrapper;
  mentee_id: NumberFieldAnswerTypeWrapper;
  mentee_name: StringFieldAnswerTypeWrapper;
  priority: NumberFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  title: StringFieldAnswerTypeWrapper;
  location: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Mentorship_Requests_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2022_MENTORSHIP_REQUESTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2022_Mentorship_Requests_Output;
  };

export type TypedClaimsResolver_Hackthenorth2022_Reimbursements_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_REIMBURSEMENTS_Output";
  team_name: StringFieldAnswerTypeWrapper;
  receipts: FileFieldAnswerTypeWrapper;
  transfer_id: NumberFieldAnswerTypeWrapper;
  amount: NumberFieldAnswerTypeWrapper;
  item_description: StringFieldAnswerTypeWrapper;
  currency: StringFieldAnswerTypeWrapper;
  date_purchased: StringFieldAnswerTypeWrapper;
  expense_category: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Reimbursements_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2022_REIMBURSEMENTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2022_Reimbursements_Output;
  };

export type TypedClaimsResolver_Hackthenorth2022_Reviews_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_REVIEWS_Output";
  time_spent: NumberFieldAnswerTypeWrapper;
  overall_score: NumberFieldAnswerTypeWrapper;
  baseline_skillset_score: NumberFieldAnswerTypeWrapper;
  nontraditional_tech_score: NumberFieldAnswerTypeWrapper;
  accessed_sensitive_info: BooleanFieldAnswerTypeWrapper;
  finished_at: StringFieldAnswerTypeWrapper;
  reviewer_name: StringFieldAnswerTypeWrapper;
  normalized_score: NumberFieldAnswerTypeWrapper;
  unnormalized_score: NumberFieldAnswerTypeWrapper;
  application_id: NumberFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Reviews_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_REVIEWS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2022_Reviews_Output;
};

export type TypedClaimsResolver_Hackthenorth2022_Sponsor_Perks_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_SPONSOR_PERKS_Output";
  sponsor_company_id: NumberFieldAnswerTypeWrapper;
  available_perks: StringArrayFieldAnswerTypeWrapper;
  website_logo_file: FileFieldAnswerTypeWrapper;
  website_logo_website_link: StringFieldAnswerTypeWrapper;
  website_logo_status: StringFieldAnswerTypeWrapper;
  website_logo_due_date: StringFieldAnswerTypeWrapper;
  website_feature_website_link: StringFieldAnswerTypeWrapper;
  website_feature_link_description: StringFieldAnswerTypeWrapper;
  website_feature_message: StringFieldAnswerTypeWrapper;
  website_feature_status: StringFieldAnswerTypeWrapper;
  website_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_due_date: StringFieldAnswerTypeWrapper;
  newsletter_feature_image_file: FileFieldAnswerTypeWrapper;
  newsletter_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_image_file: FileFieldAnswerTypeWrapper;
  newsletter_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_status: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_one: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_two: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_three: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_name: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_email: StringFieldAnswerTypeWrapper;
  tech_talk_details_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  tech_talk_details_status: StringFieldAnswerTypeWrapper;
  tech_talk_details_due_date: StringFieldAnswerTypeWrapper;
  api_prize_title: StringFieldAnswerTypeWrapper;
  api_prize_description: StringFieldAnswerTypeWrapper;
  api_prize_judging_confirmation: StringFieldAnswerTypeWrapper;
  api_prize_status: StringFieldAnswerTypeWrapper;
  api_prize_due_date: StringFieldAnswerTypeWrapper;
  api_workshop_time_one: StringFieldAnswerTypeWrapper;
  api_workshop_time_two: StringFieldAnswerTypeWrapper;
  api_workshop_time_three: StringFieldAnswerTypeWrapper;
  api_workshop_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  api_workshop_representative: StringFieldAnswerTypeWrapper;
  api_workshop_representative_name: StringFieldAnswerTypeWrapper;
  api_workshop_representative_email: StringFieldAnswerTypeWrapper;
  api_workshop_status: StringFieldAnswerTypeWrapper;
  api_workshop_due_date: StringFieldAnswerTypeWrapper;
  lightning_challenge_description: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_one: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_two: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_three: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_name: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_email: StringFieldAnswerTypeWrapper;
  lightning_challenge_status: StringFieldAnswerTypeWrapper;
  lightning_challenge_due_date: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_one: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_two: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_three: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_status: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_due_date: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_name: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_time: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_status: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_due_date: StringFieldAnswerTypeWrapper;
  select_table_location_one: StringFieldAnswerTypeWrapper;
  select_table_location_two: StringFieldAnswerTypeWrapper;
  select_table_location_three: StringFieldAnswerTypeWrapper;
  select_table_location_status: StringFieldAnswerTypeWrapper;
  select_table_location_due_date: StringFieldAnswerTypeWrapper;
  name_an_area_one: StringFieldAnswerTypeWrapper;
  name_an_area_two: StringFieldAnswerTypeWrapper;
  name_an_area_status: StringFieldAnswerTypeWrapper;
  name_an_area_due_date: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_description: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_status: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_due_date: StringFieldAnswerTypeWrapper;
  shipped_swag_json_string: StringFieldAnswerTypeWrapper;
  shipped_swag_status: StringFieldAnswerTypeWrapper;
  shipped_swag_due_date: StringFieldAnswerTypeWrapper;
  stage_time_presentation_file: FileFieldAnswerTypeWrapper;
  stage_time_video_link: StringFieldAnswerTypeWrapper;
  stage_time_status: StringFieldAnswerTypeWrapper;
  stage_time_due_date: StringFieldAnswerTypeWrapper;
  coffee_chats_start_time: StringFieldAnswerTypeWrapper;
  coffee_chats_duration: StringFieldAnswerTypeWrapper;
  coffee_chats_representatives: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_emails: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_favorited_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_status: StringFieldAnswerTypeWrapper;
  coffee_chats_due_date: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_one: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_two: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_three: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_status: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_due_date: StringFieldAnswerTypeWrapper;
  career_development_session_title: StringFieldAnswerTypeWrapper;
  career_development_session_description: StringFieldAnswerTypeWrapper;
  career_development_session_time_one: StringFieldAnswerTypeWrapper;
  career_development_session_time_two: StringFieldAnswerTypeWrapper;
  career_development_session_time_three: StringFieldAnswerTypeWrapper;
  career_development_session_status: StringFieldAnswerTypeWrapper;
  career_development_session_due_date: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2022_Sponsor_Perks_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2022_SPONSOR_PERKS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2022_Sponsor_Perks_Output;
};

export type TypedClaimsResolver_Hackthenorth2023_Applications_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_APPLICATIONS_Output";
  short_name: StringFieldAnswerTypeWrapper;
  short_grad_year: NumberFieldAnswerTypeWrapper;
  short_grad_level: StringFieldAnswerTypeWrapper;
  short_school: StringFieldAnswerTypeWrapper;
  short_program: StringFieldAnswerTypeWrapper;
  short_birthdate: StringFieldAnswerTypeWrapper;
  short_location: StringFieldAnswerTypeWrapper;
  short_hackathon_count: StringFieldAnswerTypeWrapper;
  long_hackathon_important_trait: StringFieldAnswerTypeWrapper;
  long_hackathon_favourite_thing: StringFieldAnswerTypeWrapper;
  long_hackathon_favourite_thing_link: StringFieldAnswerTypeWrapper;
  long_haiku: StringFieldAnswerTypeWrapper;
  long_hacker_type: StringArrayFieldAnswerTypeWrapper;
  long_links: StringArrayFieldAnswerTypeWrapper;
  long_explore_subject_areas: StringFieldAnswerTypeWrapper;
  survey_accessibility: StringFieldAnswerTypeWrapper;
  survey_laptop: BooleanFieldAnswerTypeWrapper;
  survey_gender: StringArrayFieldAnswerTypeWrapper;
  survey_race: StringArrayFieldAnswerTypeWrapper;
  survey_ethnicity: StringArrayFieldAnswerTypeWrapper;
  survey_identity_groups: StringArrayFieldAnswerTypeWrapper;
  survey_personal_experience: StringFieldAnswerTypeWrapper;
  survey_clubs: StringFieldAnswerTypeWrapper;
  survey_tech_preferences: StringArrayFieldAnswerTypeWrapper;
  survey_workshop_preferences: StringArrayFieldAnswerTypeWrapper;
  apps_character_name: StringFieldAnswerTypeWrapper;
  visited_steps: StringArrayFieldAnswerTypeWrapper;
  reviewer_id: NumberFieldAnswerTypeWrapper;
  rsvp_legal_name: StringFieldAnswerTypeWrapper;
  rsvp_preferred_name: StringFieldAnswerTypeWrapper;
  rsvp_preferred_pronouns: StringArrayFieldAnswerTypeWrapper;
  rsvp_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_waterloo_student_id: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_relationship: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_email: StringFieldAnswerTypeWrapper;
  rsvp_mlh_share_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_accessibility_needs: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_type: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_size: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_accomodations: StringFieldAnswerTypeWrapper;
  rsvp_food_dietary_restrictions: StringArrayFieldAnswerTypeWrapper;
  rsvp_project_stream: StringFieldAnswerTypeWrapper;
  rsvp_tech_talks: StringArrayFieldAnswerTypeWrapper;
  rsvp_meetups: StringArrayFieldAnswerTypeWrapper;
  networking_github_link: StringFieldAnswerTypeWrapper;
  networking_instagram_link: StringFieldAnswerTypeWrapper;
  networking_facebook_link: StringFieldAnswerTypeWrapper;
  networking_twitter_link: StringFieldAnswerTypeWrapper;
  networking_linkedin_link: StringFieldAnswerTypeWrapper;
  networking_discord_tag: StringFieldAnswerTypeWrapper;
  networking_tiktok_link: StringFieldAnswerTypeWrapper;
  networking_biography: StringFieldAnswerTypeWrapper;
  rsvp_sponsor_interest: StringArrayFieldAnswerTypeWrapper;
  rsvp_career_resume: FileFieldAnswerTypeWrapper;
  rsvp_career_recruitment_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_career_canadian_citizen: BooleanFieldAnswerTypeWrapper;
  rsvp_career_permanent_resident: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_internships: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_fulltime: BooleanFieldAnswerTypeWrapper;
  rsvp_career_coffee_chat: StringArrayFieldAnswerTypeWrapper;
  rsvp_consent_age_18: BooleanFieldAnswerTypeWrapper;
  rsvp_consent_terms_conditions: BooleanFieldAnswerTypeWrapper;
  personal_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_rsvp_stage: StringFieldAnswerTypeWrapper;
  event_rsvp_stage: StringFieldAnswerTypeWrapper;
  career_rsvp_stage: StringFieldAnswerTypeWrapper;
  confirmation_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_info_rsvp_stage: StringFieldAnswerTypeWrapper;
  rsvp_deadline: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_name: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_contact: StringFieldAnswerTypeWrapper;
  rsvp_consent_waiver: FileFieldAnswerTypeWrapper;
  rsvp_travel_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_bus_coordinator_interest: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_location: StringFieldAnswerTypeWrapper;
  rsvp_travel_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_terms_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_immigration_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_share_email_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_requires_visa: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_tracking_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_receipt_upload: FileFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_arrival_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_departure_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_currency: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_amount: NumberFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_name: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_address: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_etransfer_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_paypal_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_flight_reimbursement: StringFieldAnswerTypeWrapper;
  rsvp_travel_departure_airport: StringFieldAnswerTypeWrapper;
  rsvp_travel_arrival_airport: StringFieldAnswerTypeWrapper;
  rsvp_event_accommodations: StringFieldAnswerTypeWrapper;
  rsvp_entry_eligibility_acknowledgement: BooleanFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Applications_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_APPLICATIONS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2023_Applications_Output;
};

export type TypedClaimsResolver_Hackthenorth2023_Contact_Lists_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_CONTACT_LISTS_Output";
  query: StringFieldAnswerTypeWrapper;
  transformer: StringFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  mailjet_list_id: NumberFieldAnswerTypeWrapper;
  filter_unsubscribed_emails: BooleanFieldAnswerTypeWrapper;
  last_run_at: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Contact_Lists_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_CONTACT_LISTS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2023_Contact_Lists_Output;
};

export type TypedClaimsResolver_Hackthenorth2023_Extended_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_EXTENDED_PROFILES_Output";
  user_id: NumberFieldAnswerTypeWrapper;
  interests: StringFieldAnswerTypeWrapper;
  profile_photo: FileFieldAnswerTypeWrapper;
  profile_photo_url: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Extended_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_EXTENDED_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Extended_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Mentorship_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_MENTORSHIP_PROFILES_Output";
  name: StringFieldAnswerTypeWrapper;
  pronouns: StringArrayFieldAnswerTypeWrapper;
  other_pronouns: StringFieldAnswerTypeWrapper;
  user_id: NumberFieldAnswerTypeWrapper;
  institution: StringFieldAnswerTypeWrapper;
  mentorship_topics: StringArrayFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Mentorship_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_MENTORSHIP_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Mentorship_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Mentorship_Requests_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_MENTORSHIP_REQUESTS_Output";
  reopened: BooleanFieldAnswerTypeWrapper;
  category: StringArrayFieldAnswerTypeWrapper;
  mentor_id: NumberFieldAnswerTypeWrapper;
  mentee_id: NumberFieldAnswerTypeWrapper;
  mentee_name: StringFieldAnswerTypeWrapper;
  priority: NumberFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  title: StringFieldAnswerTypeWrapper;
  location: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Mentorship_Requests_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_MENTORSHIP_REQUESTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Mentorship_Requests_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Reimbursements_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_REIMBURSEMENTS_Output";
  team_name: StringFieldAnswerTypeWrapper;
  receipts: FileFieldAnswerTypeWrapper;
  transfer_id: NumberFieldAnswerTypeWrapper;
  amount: NumberFieldAnswerTypeWrapper;
  item_description: StringFieldAnswerTypeWrapper;
  currency: StringFieldAnswerTypeWrapper;
  date_purchased: StringFieldAnswerTypeWrapper;
  expense_category: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Reimbursements_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_REIMBURSEMENTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Reimbursements_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Reviews_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_REVIEWS_Output";
  time_spent: NumberFieldAnswerTypeWrapper;
  overall_score: NumberFieldAnswerTypeWrapper;
  q1_score: NumberFieldAnswerTypeWrapper;
  q2_score: NumberFieldAnswerTypeWrapper;
  q3_score: NumberFieldAnswerTypeWrapper;
  baseline_skillset_score: NumberFieldAnswerTypeWrapper;
  nontraditional_tech_score: NumberFieldAnswerTypeWrapper;
  accessed_sensitive_info: BooleanFieldAnswerTypeWrapper;
  finished_at: StringFieldAnswerTypeWrapper;
  reviewer_name: StringFieldAnswerTypeWrapper;
  normalized_score: NumberFieldAnswerTypeWrapper;
  unnormalized_score: NumberFieldAnswerTypeWrapper;
  application_id: NumberFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Reviews_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_REVIEWS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2023_Reviews_Output;
};

export type TypedClaimsResolver_Hackthenorth2023_Signage_Questions_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SIGNAGE_QUESTIONS_Output";
  title: StringFieldAnswerTypeWrapper;
  location: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Signage_Questions_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SIGNAGE_QUESTIONS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Signage_Questions_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Signage_Responses_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SIGNAGE_RESPONSES_Output";
  user_id: NumberFieldAnswerTypeWrapper;
  question_id: NumberFieldAnswerTypeWrapper;
  value: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Signage_Responses_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SIGNAGE_RESPONSES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2023_Signage_Responses_Output;
  };

export type TypedClaimsResolver_Hackthenorth2023_Sponsor_Perks_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SPONSOR_PERKS_Output";
  sponsor_company_id: NumberFieldAnswerTypeWrapper;
  available_perks: StringArrayFieldAnswerTypeWrapper;
  website_logo_file: FileFieldAnswerTypeWrapper;
  website_logo_website_link: StringFieldAnswerTypeWrapper;
  website_logo_status: StringFieldAnswerTypeWrapper;
  website_logo_due_date: StringFieldAnswerTypeWrapper;
  website_feature_website_link: StringFieldAnswerTypeWrapper;
  website_feature_link_description: StringFieldAnswerTypeWrapper;
  website_feature_message: StringFieldAnswerTypeWrapper;
  website_feature_status: StringFieldAnswerTypeWrapper;
  website_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_due_date: StringFieldAnswerTypeWrapper;
  newsletter_feature_image_file: FileFieldAnswerTypeWrapper;
  newsletter_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_image_file: FileFieldAnswerTypeWrapper;
  newsletter_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_status: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_one: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_two: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_three: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_name: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_email: StringFieldAnswerTypeWrapper;
  tech_talk_details_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  tech_talk_details_status: StringFieldAnswerTypeWrapper;
  tech_talk_details_due_date: StringFieldAnswerTypeWrapper;
  api_prize_title: StringFieldAnswerTypeWrapper;
  api_prize_description: StringFieldAnswerTypeWrapper;
  api_prize_judging_confirmation: StringFieldAnswerTypeWrapper;
  api_prize_status: StringFieldAnswerTypeWrapper;
  api_prize_due_date: StringFieldAnswerTypeWrapper;
  api_workshop_time_one: StringFieldAnswerTypeWrapper;
  api_workshop_time_two: StringFieldAnswerTypeWrapper;
  api_workshop_time_three: StringFieldAnswerTypeWrapper;
  api_workshop_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  api_workshop_representative: StringFieldAnswerTypeWrapper;
  api_workshop_representative_name: StringFieldAnswerTypeWrapper;
  api_workshop_representative_email: StringFieldAnswerTypeWrapper;
  api_workshop_status: StringFieldAnswerTypeWrapper;
  api_workshop_due_date: StringFieldAnswerTypeWrapper;
  lightning_challenge_description: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_one: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_two: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_three: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_name: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_email: StringFieldAnswerTypeWrapper;
  lightning_challenge_status: StringFieldAnswerTypeWrapper;
  lightning_challenge_due_date: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_one: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_two: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_three: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_status: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_due_date: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_name: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_time: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_status: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_due_date: StringFieldAnswerTypeWrapper;
  select_table_location_one: StringFieldAnswerTypeWrapper;
  select_table_location_two: StringFieldAnswerTypeWrapper;
  select_table_location_three: StringFieldAnswerTypeWrapper;
  select_table_location_status: StringFieldAnswerTypeWrapper;
  select_table_location_due_date: StringFieldAnswerTypeWrapper;
  name_an_area_one: StringFieldAnswerTypeWrapper;
  name_an_area_two: StringFieldAnswerTypeWrapper;
  name_an_area_status: StringFieldAnswerTypeWrapper;
  name_an_area_due_date: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_description: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_status: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_due_date: StringFieldAnswerTypeWrapper;
  shipped_swag_json_string: StringFieldAnswerTypeWrapper;
  shipped_swag_status: StringFieldAnswerTypeWrapper;
  shipped_swag_due_date: StringFieldAnswerTypeWrapper;
  stage_time_presentation_file: FileFieldAnswerTypeWrapper;
  stage_time_video_link: StringFieldAnswerTypeWrapper;
  stage_time_status: StringFieldAnswerTypeWrapper;
  stage_time_due_date: StringFieldAnswerTypeWrapper;
  coffee_chats_start_time: StringFieldAnswerTypeWrapper;
  coffee_chats_duration: StringFieldAnswerTypeWrapper;
  coffee_chats_representatives: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_emails: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_favorited_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_status: StringFieldAnswerTypeWrapper;
  coffee_chats_due_date: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_one: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_two: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_time_three: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_status: StringFieldAnswerTypeWrapper;
  hackathon_office_hours_due_date: StringFieldAnswerTypeWrapper;
  career_development_session_title: StringFieldAnswerTypeWrapper;
  career_development_session_description: StringFieldAnswerTypeWrapper;
  career_development_session_time_one: StringFieldAnswerTypeWrapper;
  career_development_session_time_two: StringFieldAnswerTypeWrapper;
  career_development_session_time_three: StringFieldAnswerTypeWrapper;
  career_development_session_status: StringFieldAnswerTypeWrapper;
  career_development_session_due_date: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2023_Sponsor_Perks_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2023_SPONSOR_PERKS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2023_Sponsor_Perks_Output;
};

export type TypedClaimsResolver_Hackthenorth2024_Applications_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_APPLICATIONS_Output";
  short_name: StringFieldAnswerTypeWrapper;
  short_grad_year: NumberFieldAnswerTypeWrapper;
  short_grad_level: StringFieldAnswerTypeWrapper;
  short_school: StringFieldAnswerTypeWrapper;
  short_program: StringFieldAnswerTypeWrapper;
  short_birthdate: StringFieldAnswerTypeWrapper;
  short_location: StringFieldAnswerTypeWrapper;
  short_hackathon_count: StringFieldAnswerTypeWrapper;
  long_dreaming_big: StringFieldAnswerTypeWrapper;
  long_passion_project: StringFieldAnswerTypeWrapper;
  long_project_link: StringFieldAnswerTypeWrapper;
  long_hacker_type: StringArrayFieldAnswerTypeWrapper;
  long_links: StringArrayFieldAnswerTypeWrapper;
  long_favourite_joke: StringFieldAnswerTypeWrapper;
  favourite_joke_permission: BooleanFieldAnswerTypeWrapper;
  survey_accessibility: StringFieldAnswerTypeWrapper;
  survey_laptop: BooleanFieldAnswerTypeWrapper;
  survey_gender: StringArrayFieldAnswerTypeWrapper;
  survey_race: StringArrayFieldAnswerTypeWrapper;
  survey_ethnicity: StringArrayFieldAnswerTypeWrapper;
  survey_identity_groups: StringArrayFieldAnswerTypeWrapper;
  survey_personal_experience: StringFieldAnswerTypeWrapper;
  survey_clubs: StringFieldAnswerTypeWrapper;
  survey_tech_preferences: StringArrayFieldAnswerTypeWrapper;
  survey_interested_topics: StringArrayFieldAnswerTypeWrapper;
  apps_car_color: StringFieldAnswerTypeWrapper;
  apps_car_accessories: StringFieldAnswerTypeWrapper;
  apps_car_wheels: StringFieldAnswerTypeWrapper;
  visited_steps: StringArrayFieldAnswerTypeWrapper;
  reviewer_id: NumberFieldAnswerTypeWrapper;
  networking_github_link: StringFieldAnswerTypeWrapper;
  networking_instagram_link: StringFieldAnswerTypeWrapper;
  networking_facebook_link: StringFieldAnswerTypeWrapper;
  networking_twitter_link: StringFieldAnswerTypeWrapper;
  networking_linkedin_link: StringFieldAnswerTypeWrapper;
  networking_discord_tag: StringFieldAnswerTypeWrapper;
  networking_tiktok_link: StringFieldAnswerTypeWrapper;
  networking_biography: StringFieldAnswerTypeWrapper;
  htn_terms_and_conditions: BooleanFieldAnswerTypeWrapper;
  mlh_signed_up_for_emails: BooleanFieldAnswerTypeWrapper;
  rsvp_legal_name: StringFieldAnswerTypeWrapper;
  rsvp_preferred_name: StringFieldAnswerTypeWrapper;
  rsvp_preferred_pronouns: StringArrayFieldAnswerTypeWrapper;
  rsvp_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_waterloo_student_id: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_relationship: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_emergency_contact_email: StringFieldAnswerTypeWrapper;
  rsvp_mlh_share_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_accessibility_needs: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_type: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_size: StringFieldAnswerTypeWrapper;
  rsvp_swag_shirt_accomodations: StringFieldAnswerTypeWrapper;
  rsvp_food_dietary_restrictions: StringArrayFieldAnswerTypeWrapper;
  rsvp_project_stream: StringFieldAnswerTypeWrapper;
  rsvp_tech_talks: StringArrayFieldAnswerTypeWrapper;
  rsvp_meetups: StringArrayFieldAnswerTypeWrapper;
  rsvp_sponsor_interest: StringArrayFieldAnswerTypeWrapper;
  rsvp_career_resume: FileFieldAnswerTypeWrapper;
  rsvp_career_recruitment_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_career_canadian_citizen: BooleanFieldAnswerTypeWrapper;
  rsvp_career_permanent_resident: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_internships: BooleanFieldAnswerTypeWrapper;
  rsvp_career_looking_for_fulltime: BooleanFieldAnswerTypeWrapper;
  rsvp_career_coffee_chat: StringArrayFieldAnswerTypeWrapper;
  rsvp_consent_age_18: BooleanFieldAnswerTypeWrapper;
  rsvp_consent_terms_conditions: BooleanFieldAnswerTypeWrapper;
  personal_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_rsvp_stage: StringFieldAnswerTypeWrapper;
  event_rsvp_stage: StringFieldAnswerTypeWrapper;
  career_rsvp_stage: StringFieldAnswerTypeWrapper;
  confirmation_rsvp_stage: StringFieldAnswerTypeWrapper;
  travel_info_rsvp_stage: StringFieldAnswerTypeWrapper;
  rsvp_deadline: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_name: StringFieldAnswerTypeWrapper;
  rsvp_consent_guardian_contact: StringFieldAnswerTypeWrapper;
  rsvp_consent_waiver: FileFieldAnswerTypeWrapper;
  rsvp_travel_phone_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_bus_coordinator_interest: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_location: StringFieldAnswerTypeWrapper;
  rsvp_travel_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_terms_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_immigration_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_share_email_consent: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_requires_visa: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_visa_tracking_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_receipt_upload: FileFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_friday_flight_arrival_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_number: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_airline: StringFieldAnswerTypeWrapper;
  rsvp_travel_sunday_flight_departure_time: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_currency: StringFieldAnswerTypeWrapper;
  rsvp_travel_flight_payment_amount: NumberFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_method: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_name: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_recipient_address: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_etransfer_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_paypal_email: StringFieldAnswerTypeWrapper;
  rsvp_travel_reimbursement_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_travel_flight_reimbursement: StringFieldAnswerTypeWrapper;
  rsvp_travel_departure_airport: StringFieldAnswerTypeWrapper;
  rsvp_travel_arrival_airport: StringFieldAnswerTypeWrapper;
  rsvp_event_accommodations: StringFieldAnswerTypeWrapper;
  rsvp_entry_eligibility_acknowledgement: BooleanFieldAnswerTypeWrapper;
  rsvp_requests_hackathon_host: BooleanFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Applications_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_APPLICATIONS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2024_Applications_Output;
};

export type TypedClaimsResolver_Hackthenorth2024_Contact_Lists_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_CONTACT_LISTS_Output";
  query: StringFieldAnswerTypeWrapper;
  transformer: StringFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  mailjet_list_id: NumberFieldAnswerTypeWrapper;
  filter_unsubscribed_emails: BooleanFieldAnswerTypeWrapper;
  last_run_at: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Contact_Lists_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_CONTACT_LISTS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2024_Contact_Lists_Output;
};

export type TypedClaimsResolver_Hackthenorth2024_Extended_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_EXTENDED_PROFILES_Output";
  user_id: NumberFieldAnswerTypeWrapper;
  interests: StringFieldAnswerTypeWrapper;
  profile_photo: FileFieldAnswerTypeWrapper;
  profile_photo_url: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Extended_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2024_EXTENDED_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2024_Extended_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2024_Mentorship_Profiles_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_MENTORSHIP_PROFILES_Output";
  name: StringFieldAnswerTypeWrapper;
  pronouns: StringArrayFieldAnswerTypeWrapper;
  other_pronouns: StringFieldAnswerTypeWrapper;
  user_id: NumberFieldAnswerTypeWrapper;
  institution: StringFieldAnswerTypeWrapper;
  mentorship_topics: StringArrayFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Mentorship_Profiles_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2024_MENTORSHIP_PROFILES_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2024_Mentorship_Profiles_Output;
  };

export type TypedClaimsResolver_Hackthenorth2024_Mentorship_Requests_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_MENTORSHIP_REQUESTS_Output";
  reopened: BooleanFieldAnswerTypeWrapper;
  category: StringArrayFieldAnswerTypeWrapper;
  mentor_id: NumberFieldAnswerTypeWrapper;
  mentee_id: NumberFieldAnswerTypeWrapper;
  mentee_name: StringFieldAnswerTypeWrapper;
  priority: NumberFieldAnswerTypeWrapper;
  description: StringFieldAnswerTypeWrapper;
  title: StringFieldAnswerTypeWrapper;
  location: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Mentorship_Requests_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2024_MENTORSHIP_REQUESTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2024_Mentorship_Requests_Output;
  };

export type TypedClaimsResolver_Hackthenorth2024_Reimbursements_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_REIMBURSEMENTS_Output";
  team_name: StringFieldAnswerTypeWrapper;
  receipts: FileFieldAnswerTypeWrapper;
  transfer_id: NumberFieldAnswerTypeWrapper;
  amount: NumberFieldAnswerTypeWrapper;
  item_description: StringFieldAnswerTypeWrapper;
  currency: StringFieldAnswerTypeWrapper;
  date_purchased: StringFieldAnswerTypeWrapper;
  expense_category: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Reimbursements_OutputWrapper =
  {
    __typename?: "TypedClaimsResolver_HACKTHENORTH2024_REIMBURSEMENTS_OutputWrapper";
    answers: TypedClaimsResolver_Hackthenorth2024_Reimbursements_Output;
  };

export type TypedClaimsResolver_Hackthenorth2024_Reviews_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_REVIEWS_Output";
  time_spent: NumberFieldAnswerTypeWrapper;
  overall_score: NumberFieldAnswerTypeWrapper;
  q1_score: NumberFieldAnswerTypeWrapper;
  q2_score: NumberFieldAnswerTypeWrapper;
  q3_score: NumberFieldAnswerTypeWrapper;
  baseline_skillset_score: NumberFieldAnswerTypeWrapper;
  nontraditional_tech_score: NumberFieldAnswerTypeWrapper;
  accessed_sensitive_info: BooleanFieldAnswerTypeWrapper;
  finished_at: StringFieldAnswerTypeWrapper;
  reviewer_name: StringFieldAnswerTypeWrapper;
  normalized_score: NumberFieldAnswerTypeWrapper;
  unnormalized_score: NumberFieldAnswerTypeWrapper;
  application_id: NumberFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Reviews_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_REVIEWS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2024_Reviews_Output;
};

export type TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_SPONSOR_PERKS_Output";
  sponsor_company_id: NumberFieldAnswerTypeWrapper;
  available_perks: StringArrayFieldAnswerTypeWrapper;
  website_logo_file: FileFieldAnswerTypeWrapper;
  website_logo_website_link: StringFieldAnswerTypeWrapper;
  website_logo_status: StringFieldAnswerTypeWrapper;
  website_logo_due_date: StringFieldAnswerTypeWrapper;
  website_feature_website_link: StringFieldAnswerTypeWrapper;
  website_feature_link_description: StringFieldAnswerTypeWrapper;
  website_feature_message: StringFieldAnswerTypeWrapper;
  website_feature_status: StringFieldAnswerTypeWrapper;
  website_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_opportunity_due_date: StringFieldAnswerTypeWrapper;
  newsletter_feature_image_file: FileFieldAnswerTypeWrapper;
  newsletter_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_image_file: FileFieldAnswerTypeWrapper;
  newsletter_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_spotlight_due_date: StringFieldAnswerTypeWrapper;
  newsletter_2_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_2_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_2_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_2_opportunity_due_date: StringFieldAnswerTypeWrapper;
  newsletter_2_feature_image_file: FileFieldAnswerTypeWrapper;
  newsletter_2_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_2_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_2_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_2_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_2_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_2_spotlight_image_file: FileFieldAnswerTypeWrapper;
  newsletter_2_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_2_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_2_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_2_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_2_spotlight_due_date: StringFieldAnswerTypeWrapper;
  newsletter_3_opportunity_link: StringFieldAnswerTypeWrapper;
  newsletter_3_opportunity_name: StringFieldAnswerTypeWrapper;
  newsletter_3_opportunity_status: StringFieldAnswerTypeWrapper;
  newsletter_3_opportunity_due_date: StringFieldAnswerTypeWrapper;
  newsletter_3_feature_image_file: FileFieldAnswerTypeWrapper;
  newsletter_3_feature_title: StringFieldAnswerTypeWrapper;
  newsletter_3_feature_website_link: StringFieldAnswerTypeWrapper;
  newsletter_3_feature_description: StringFieldAnswerTypeWrapper;
  newsletter_3_feature_status: StringFieldAnswerTypeWrapper;
  newsletter_3_feature_due_date: StringFieldAnswerTypeWrapper;
  newsletter_3_spotlight_image_file: FileFieldAnswerTypeWrapper;
  newsletter_3_spotlight_title: StringFieldAnswerTypeWrapper;
  newsletter_3_spotlight_website_link: StringFieldAnswerTypeWrapper;
  newsletter_3_spotlight_description: StringFieldAnswerTypeWrapper;
  newsletter_3_spotlight_status: StringFieldAnswerTypeWrapper;
  newsletter_3_spotlight_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_one: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_title_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_description_two: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_status: StringFieldAnswerTypeWrapper;
  tech_talk_proposal_due_date: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_one: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_two: StringFieldAnswerTypeWrapper;
  tech_talk_details_time_three: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_name: StringFieldAnswerTypeWrapper;
  tech_talk_details_representative_email: StringFieldAnswerTypeWrapper;
  tech_talk_details_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  tech_talk_details_status: StringFieldAnswerTypeWrapper;
  tech_talk_details_due_date: StringFieldAnswerTypeWrapper;
  api_prize_title: StringFieldAnswerTypeWrapper;
  api_prize_description: StringFieldAnswerTypeWrapper;
  api_prize_judging_confirmation: StringFieldAnswerTypeWrapper;
  api_prize_status: StringFieldAnswerTypeWrapper;
  api_prize_due_date: StringFieldAnswerTypeWrapper;
  api_workshop_time_one: StringFieldAnswerTypeWrapper;
  api_workshop_time_two: StringFieldAnswerTypeWrapper;
  api_workshop_time_three: StringFieldAnswerTypeWrapper;
  api_workshop_accepted_hackers_only: BooleanFieldAnswerTypeWrapper;
  api_workshop_description: StringFieldAnswerTypeWrapper;
  api_workshop_representative: StringFieldAnswerTypeWrapper;
  api_workshop_representative_name: StringFieldAnswerTypeWrapper;
  api_workshop_representative_email: StringFieldAnswerTypeWrapper;
  api_workshop_title: StringFieldAnswerTypeWrapper;
  api_workshop_status: StringFieldAnswerTypeWrapper;
  api_workshop_due_date: StringFieldAnswerTypeWrapper;
  lightning_challenge_description: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_one: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_two: StringFieldAnswerTypeWrapper;
  lightning_challenge_time_three: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_name: StringFieldAnswerTypeWrapper;
  lightning_challenge_representative_email: StringFieldAnswerTypeWrapper;
  lightning_challenge_status: StringFieldAnswerTypeWrapper;
  lightning_challenge_due_date: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_one: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_two: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_selection_three: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_status: StringFieldAnswerTypeWrapper;
  sponsor_an_activity_due_date: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_name: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_time: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_status: StringFieldAnswerTypeWrapper;
  sponsor_a_snack_due_date: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_name: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_description: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_time_one: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_time_two: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_time_three: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_status: StringFieldAnswerTypeWrapper;
  sponsor_a_meetup_due_date: StringFieldAnswerTypeWrapper;
  select_table_location_one: StringFieldAnswerTypeWrapper;
  select_table_location_two: StringFieldAnswerTypeWrapper;
  select_table_location_three: StringFieldAnswerTypeWrapper;
  select_table_location_status: StringFieldAnswerTypeWrapper;
  select_table_location_due_date: StringFieldAnswerTypeWrapper;
  social_media_promotion_description: StringFieldAnswerTypeWrapper;
  social_media_promotion_due_date: StringFieldAnswerTypeWrapper;
  social_media_promotion_logo: FileFieldAnswerTypeWrapper;
  social_media_promotion_status: StringFieldAnswerTypeWrapper;
  name_an_area_one: StringFieldAnswerTypeWrapper;
  name_an_area_two: StringFieldAnswerTypeWrapper;
  name_an_area_status: StringFieldAnswerTypeWrapper;
  name_an_area_due_date: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_description: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_status: StringFieldAnswerTypeWrapper;
  welcome_booklet_blurb_due_date: StringFieldAnswerTypeWrapper;
  shipped_swag_description: StringFieldAnswerTypeWrapper;
  shipped_swag_status: StringFieldAnswerTypeWrapper;
  shipped_swag_due_date: StringFieldAnswerTypeWrapper;
  opening_ceremonies_slide_file: FileFieldAnswerTypeWrapper;
  opening_ceremonies_slide_link: StringFieldAnswerTypeWrapper;
  opening_ceremonies_slide_status: StringFieldAnswerTypeWrapper;
  opening_ceremonies_slide_due_date: StringFieldAnswerTypeWrapper;
  closing_ceremonies_slide_file: FileFieldAnswerTypeWrapper;
  closing_ceremonies_slide_link: StringFieldAnswerTypeWrapper;
  closing_ceremonies_slide_status: StringFieldAnswerTypeWrapper;
  closing_ceremonies_slide_due_date: StringFieldAnswerTypeWrapper;
  coffee_chats_start_time: StringFieldAnswerTypeWrapper;
  coffee_chats_duration: StringFieldAnswerTypeWrapper;
  coffee_chats_representatives: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_emails: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_representatives_pronouns: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_favorited_hackers: StringArrayFieldAnswerTypeWrapper;
  coffee_chats_status: StringFieldAnswerTypeWrapper;
  coffee_chats_due_date: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_OutputWrapper = {
  __typename?: "TypedClaimsResolver_HACKTHENORTH2024_SPONSOR_PERKS_OutputWrapper";
  answers: TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output;
};

export type TypedClaimsResolver_Sample_Output = {
  __typename?: "TypedClaimsResolver_SAMPLE_Output";
  sample_field: NumberFieldAnswerTypeWrapper;
  sample_field_3: StringFieldAnswerTypeWrapper;
};

export type TypedClaimsResolver_Sample_OutputWrapper = {
  __typename?: "TypedClaimsResolver_SAMPLE_OutputWrapper";
  answers: TypedClaimsResolver_Sample_Output;
};

/** Update an event's data */
export type UpdateEventInput = {
  slug: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  start_time?: Maybe<Scalars["DateTimeISO"]>;
  end_time?: Maybe<Scalars["DateTimeISO"]>;
};

export type UpdatePasswordSuccess = {
  __typename?: "UpdatePasswordSuccess";
  email: Scalars["String"];
  message: Scalars["String"];
};

export type UrlCreateNestedOneWithoutUsersInput = {
  create?: Maybe<UrlCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<UrlCreateOrConnectWithoutUsersInput>;
  connect?: Maybe<UrlWhereUniqueInput>;
};

export type UrlCreateOrConnectWithoutUsersInput = {
  where: UrlWhereUniqueInput;
  create: UrlCreateWithoutUsersInput;
};

export type UrlCreateWithoutUsersInput = {
  behance?: Maybe<Scalars["String"]>;
  bitbucket?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  deviantart?: Maybe<Scalars["String"]>;
  devpost?: Maybe<Scalars["String"]>;
  dribbble?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  flickr?: Maybe<Scalars["String"]>;
  github?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  jsfiddle?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  portfolio?: Maybe<Scalars["String"]>;
  resume?: Maybe<Scalars["String"]>;
  soundcloud?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
};

export type UrlNullableRelationFilter = {
  is?: Maybe<UrlWhereInput>;
  isNot?: Maybe<UrlWhereInput>;
};

export enum UrlOrderByRelevanceFieldEnum {
  Behance = "behance",
  Bitbucket = "bitbucket",
  Deviantart = "deviantart",
  Devpost = "devpost",
  Dribbble = "dribbble",
  Facebook = "facebook",
  Flickr = "flickr",
  Github = "github",
  Instagram = "instagram",
  Jsfiddle = "jsfiddle",
  Linkedin = "linkedin",
  Portfolio = "portfolio",
  Resume = "resume",
  Soundcloud = "soundcloud",
  Twitter = "twitter",
}

export type UrlOrderByRelevanceInput = {
  fields: Array<UrlOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type UrlOrderByWithRelationAndSearchRelevanceInput = {
  behance?: Maybe<SortOrderInput>;
  bitbucket?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  deviantart?: Maybe<SortOrderInput>;
  devpost?: Maybe<SortOrderInput>;
  dribbble?: Maybe<SortOrderInput>;
  facebook?: Maybe<SortOrderInput>;
  flickr?: Maybe<SortOrderInput>;
  github?: Maybe<SortOrderInput>;
  id?: Maybe<SortOrder>;
  instagram?: Maybe<SortOrderInput>;
  jsfiddle?: Maybe<SortOrderInput>;
  linkedin?: Maybe<SortOrderInput>;
  portfolio?: Maybe<SortOrderInput>;
  resume?: Maybe<SortOrderInput>;
  soundcloud?: Maybe<SortOrderInput>;
  twitter?: Maybe<SortOrderInput>;
  updated_at?: Maybe<SortOrder>;
  users?: Maybe<UserOrderByWithRelationAndSearchRelevanceInput>;
  _relevance?: Maybe<UrlOrderByRelevanceInput>;
};

export type UrlUpdateOneWithoutUsersNestedInput = {
  create?: Maybe<UrlCreateWithoutUsersInput>;
  connectOrCreate?: Maybe<UrlCreateOrConnectWithoutUsersInput>;
  upsert?: Maybe<UrlUpsertWithoutUsersInput>;
  disconnect?: Maybe<UrlWhereInput>;
  delete?: Maybe<UrlWhereInput>;
  connect?: Maybe<UrlWhereUniqueInput>;
  update?: Maybe<UrlUpdateToOneWithWhereWithoutUsersInput>;
};

export type UrlUpdateToOneWithWhereWithoutUsersInput = {
  where?: Maybe<UrlWhereInput>;
  data: UrlUpdateWithoutUsersInput;
};

export type UrlUpdateWithoutUsersInput = {
  behance?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bitbucket?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  deviantart?: Maybe<NullableStringFieldUpdateOperationsInput>;
  devpost?: Maybe<NullableStringFieldUpdateOperationsInput>;
  dribbble?: Maybe<NullableStringFieldUpdateOperationsInput>;
  facebook?: Maybe<NullableStringFieldUpdateOperationsInput>;
  flickr?: Maybe<NullableStringFieldUpdateOperationsInput>;
  github?: Maybe<NullableStringFieldUpdateOperationsInput>;
  instagram?: Maybe<NullableStringFieldUpdateOperationsInput>;
  jsfiddle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  linkedin?: Maybe<NullableStringFieldUpdateOperationsInput>;
  portfolio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  resume?: Maybe<NullableStringFieldUpdateOperationsInput>;
  soundcloud?: Maybe<NullableStringFieldUpdateOperationsInput>;
  twitter?: Maybe<NullableStringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type UrlUpsertWithoutUsersInput = {
  update: UrlUpdateWithoutUsersInput;
  create: UrlCreateWithoutUsersInput;
  where?: Maybe<UrlWhereInput>;
};

export type UrlWhereInput = {
  AND?: Maybe<Array<UrlWhereInput>>;
  OR?: Maybe<Array<UrlWhereInput>>;
  NOT?: Maybe<Array<UrlWhereInput>>;
  behance?: Maybe<StringNullableFilter>;
  bitbucket?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  deviantart?: Maybe<StringNullableFilter>;
  devpost?: Maybe<StringNullableFilter>;
  dribbble?: Maybe<StringNullableFilter>;
  facebook?: Maybe<StringNullableFilter>;
  flickr?: Maybe<StringNullableFilter>;
  github?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  instagram?: Maybe<StringNullableFilter>;
  jsfiddle?: Maybe<StringNullableFilter>;
  linkedin?: Maybe<StringNullableFilter>;
  portfolio?: Maybe<StringNullableFilter>;
  resume?: Maybe<StringNullableFilter>;
  soundcloud?: Maybe<StringNullableFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  users?: Maybe<UserNullableRelationFilter>;
};

export type UrlWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<UrlWhereInput>>;
  OR?: Maybe<Array<UrlWhereInput>>;
  NOT?: Maybe<Array<UrlWhereInput>>;
  behance?: Maybe<StringNullableFilter>;
  bitbucket?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  deviantart?: Maybe<StringNullableFilter>;
  devpost?: Maybe<StringNullableFilter>;
  dribbble?: Maybe<StringNullableFilter>;
  facebook?: Maybe<StringNullableFilter>;
  flickr?: Maybe<StringNullableFilter>;
  github?: Maybe<StringNullableFilter>;
  instagram?: Maybe<StringNullableFilter>;
  jsfiddle?: Maybe<StringNullableFilter>;
  linkedin?: Maybe<StringNullableFilter>;
  portfolio?: Maybe<StringNullableFilter>;
  resume?: Maybe<StringNullableFilter>;
  soundcloud?: Maybe<StringNullableFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  users?: Maybe<UserNullableRelationFilter>;
};

export type User = {
  __typename?: "User";
  address_id?: Maybe<Scalars["Int"]>;
  allergies: Array<Scalars["String"]>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTimeISO"];
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes: Array<Scalars["String"]>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions: Array<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons: Array<Scalars["String"]>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills: Array<Scalars["String"]>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills: Array<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills: Array<Scalars["String"]>;
  updated_at: Scalars["DateTimeISO"];
  event_platform_user_id?: Maybe<Scalars["String"]>;
  _count?: Maybe<UserCount>;
  claims: Array<Claim>;
  bus_attendees: Array<BusAttendee>;
  /** A link to add an Apple Wallet event pass for the user. */
  apple_wallet_url?: Maybe<Scalars["String"]>;
  google_wallet_url?: Maybe<Scalars["String"]>;
};

export type UserCount = {
  __typename?: "UserCount";
  auth_tokens: Scalars["Int"];
  claims: Scalars["Int"];
  collaborators: Scalars["Int"];
  event_activity_redemptions_user: Scalars["Int"];
  event_activity_redemptions_event: Scalars["Int"];
  event_attendees: Scalars["Int"];
  files: Scalars["Int"];
  firebase_tokens: Scalars["Int"];
  forgotten_passwords: Scalars["Int"];
  mentor_request_comments: Scalars["Int"];
  mentor_requests: Scalars["Int"];
  mentors: Scalars["Int"];
  person: Scalars["Int"];
  representatives: Scalars["Int"];
  reviews: Scalars["Int"];
  sponsor_individuals: Scalars["Int"];
  team_members: Scalars["Int"];
  teams: Scalars["Int"];
  user_submissions: Scalars["Int"];
  bus_attendees: Scalars["Int"];
  HardwareRequest: Scalars["Int"];
  HardwareRequestHistoryEntry: Scalars["Int"];
  shift_history_modifications: Scalars["Int"];
};

export type UserCountAuth_TokensArgs = {
  where?: Maybe<AuthTokenWhereInput>;
};

export type UserCountClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
};

export type UserCountCollaboratorsArgs = {
  where?: Maybe<CollaboratorWhereInput>;
};

export type UserCountEvent_Activity_Redemptions_UserArgs = {
  where?: Maybe<EventActivityRedemptionWhereInput>;
};

export type UserCountEvent_Activity_Redemptions_EventArgs = {
  where?: Maybe<EventActivityRedemptionWhereInput>;
};

export type UserCountEvent_AttendeesArgs = {
  where?: Maybe<EventAttendeeWhereInput>;
};

export type UserCountFilesArgs = {
  where?: Maybe<FileWhereInput>;
};

export type UserCountFirebase_TokensArgs = {
  where?: Maybe<FirebaseTokenWhereInput>;
};

export type UserCountForgotten_PasswordsArgs = {
  where?: Maybe<ForgottenPasswordWhereInput>;
};

export type UserCountMentor_Request_CommentsArgs = {
  where?: Maybe<MentorRequestCommentWhereInput>;
};

export type UserCountMentor_RequestsArgs = {
  where?: Maybe<MentorRequestWhereInput>;
};

export type UserCountMentorsArgs = {
  where?: Maybe<MentorWhereInput>;
};

export type UserCountPersonArgs = {
  where?: Maybe<PersonWhereInput>;
};

export type UserCountRepresentativesArgs = {
  where?: Maybe<RepresentativeWhereInput>;
};

export type UserCountReviewsArgs = {
  where?: Maybe<ReviewWhereInput>;
};

export type UserCountSponsor_IndividualsArgs = {
  where?: Maybe<SponsorIndividualWhereInput>;
};

export type UserCountTeam_MembersArgs = {
  where?: Maybe<TeamMemberWhereInput>;
};

export type UserCountTeamsArgs = {
  where?: Maybe<TeamWhereInput>;
};

export type UserCountUser_SubmissionsArgs = {
  where?: Maybe<UserSubmissionWhereInput>;
};

export type UserCountBus_AttendeesArgs = {
  where?: Maybe<BusAttendeeWhereInput>;
};

export type UserCountHardwareRequestArgs = {
  where?: Maybe<HardwareRequestWhereInput>;
};

export type UserCountHardwareRequestHistoryEntryArgs = {
  where?: Maybe<HardwareRequestHistoryEntryWhereInput>;
};

export type UserCountShift_History_ModificationsArgs = {
  where?: Maybe<ShiftHistoryWhereInput>;
};

export type UserCreateManyAddressInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
};

export type UserCreateManyAddressInputEnvelope = {
  data: Array<UserCreateManyAddressInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserCreateManyCompanyInput = {
  address_id?: Maybe<Scalars["Int"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
};

export type UserCreateManyCompanyInputEnvelope = {
  data: Array<UserCreateManyCompanyInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserCreateManyEducationInput = {
  address_id?: Maybe<Scalars["Int"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
};

export type UserCreateManyEducationInputEnvelope = {
  data: Array<UserCreateManyEducationInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserCreateManyEmergency_Contact_AddressInput = {
  address_id?: Maybe<Scalars["Int"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
};

export type UserCreateManyEmergency_Contact_AddressInputEnvelope = {
  data: Array<UserCreateManyEmergency_Contact_AddressInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserCreateManyShipping_AddressInput = {
  address_id?: Maybe<Scalars["Int"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
};

export type UserCreateManyShipping_AddressInputEnvelope = {
  data: Array<UserCreateManyShipping_AddressInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserCreateNestedManyWithoutAddressInput = {
  create?: Maybe<Array<UserCreateWithoutAddressInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutAddressInput>>;
  createMany?: Maybe<UserCreateManyAddressInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  createMany?: Maybe<UserCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutEducationInput = {
  create?: Maybe<Array<UserCreateWithoutEducationInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutEducationInput>>;
  createMany?: Maybe<UserCreateManyEducationInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutEmergency_Contact_AddressInput = {
  create?: Maybe<Array<UserCreateWithoutEmergency_Contact_AddressInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutEmergency_Contact_AddressInput>
  >;
  createMany?: Maybe<UserCreateManyEmergency_Contact_AddressInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutShipping_AddressInput = {
  create?: Maybe<Array<UserCreateWithoutShipping_AddressInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutShipping_AddressInput>
  >;
  createMany?: Maybe<UserCreateManyShipping_AddressInputEnvelope>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedOneWithoutBus_AttendeesInput = {
  create?: Maybe<UserCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBus_AttendeesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutClaimsInput = {
  create?: Maybe<UserCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClaimsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutCollaboratorsInput = {
  create?: Maybe<UserCreateWithoutCollaboratorsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCollaboratorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEvent_Activity_Redemptions_EventInput = {
  create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_EventInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_Activity_Redemptions_EventInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEvent_Activity_Redemptions_UserInput = {
  create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_UserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_Activity_Redemptions_UserInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutEvent_AttendeesInput = {
  create?: Maybe<UserCreateWithoutEvent_AttendeesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_AttendeesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutFilesInput = {
  create?: Maybe<UserCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFilesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutHardwareRequestHistoryEntryInput = {
  create?: Maybe<UserCreateWithoutHardwareRequestHistoryEntryInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutHardwareRequestHistoryEntryInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutHardwareRequestInput = {
  create?: Maybe<UserCreateWithoutHardwareRequestInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutHardwareRequestInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutHtnBucksAccountInput = {
  create?: Maybe<UserCreateWithoutHtnBucksAccountInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutHtnBucksAccountInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMentor_Request_CommentsInput = {
  create?: Maybe<UserCreateWithoutMentor_Request_CommentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentor_Request_CommentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMentor_RequestsInput = {
  create?: Maybe<UserCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentor_RequestsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMentorsInput = {
  create?: Maybe<UserCreateWithoutMentorsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutRepresentativesInput = {
  create?: Maybe<UserCreateWithoutRepresentativesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRepresentativesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutReviewsInput = {
  create?: Maybe<UserCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutShift_History_ModificationsInput = {
  create?: Maybe<UserCreateWithoutShift_History_ModificationsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutShift_History_ModificationsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSponsor_IndividualsInput = {
  create?: Maybe<UserCreateWithoutSponsor_IndividualsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSponsor_IndividualsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTeam_MembersInput = {
  create?: Maybe<UserCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTeam_MembersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTeamsInput = {
  create?: Maybe<UserCreateWithoutTeamsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTeamsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutUser_SubmissionsInput = {
  create?: Maybe<UserCreateWithoutUser_SubmissionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUser_SubmissionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutAddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAddressInput;
};

export type UserCreateOrConnectWithoutBus_AttendeesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutBus_AttendeesInput;
};

export type UserCreateOrConnectWithoutClaimsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutClaimsInput;
};

export type UserCreateOrConnectWithoutCollaboratorsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCollaboratorsInput;
};

export type UserCreateOrConnectWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompanyInput;
};

export type UserCreateOrConnectWithoutEducationInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEducationInput;
};

export type UserCreateOrConnectWithoutEmergency_Contact_AddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEmergency_Contact_AddressInput;
};

export type UserCreateOrConnectWithoutEvent_Activity_Redemptions_EventInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_EventInput;
};

export type UserCreateOrConnectWithoutEvent_Activity_Redemptions_UserInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_UserInput;
};

export type UserCreateOrConnectWithoutEvent_AttendeesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_AttendeesInput;
};

export type UserCreateOrConnectWithoutFilesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutFilesInput;
};

export type UserCreateOrConnectWithoutHardwareRequestHistoryEntryInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutHardwareRequestHistoryEntryInput;
};

export type UserCreateOrConnectWithoutHardwareRequestInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutHardwareRequestInput;
};

export type UserCreateOrConnectWithoutHtnBucksAccountInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutHtnBucksAccountInput;
};

export type UserCreateOrConnectWithoutMentor_Request_CommentsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentor_Request_CommentsInput;
};

export type UserCreateOrConnectWithoutMentor_RequestsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentor_RequestsInput;
};

export type UserCreateOrConnectWithoutMentorsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentorsInput;
};

export type UserCreateOrConnectWithoutRepresentativesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutRepresentativesInput;
};

export type UserCreateOrConnectWithoutReviewsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutReviewsInput;
};

export type UserCreateOrConnectWithoutShift_History_ModificationsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutShift_History_ModificationsInput;
};

export type UserCreateOrConnectWithoutShipping_AddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutShipping_AddressInput;
};

export type UserCreateOrConnectWithoutSponsor_IndividualsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSponsor_IndividualsInput;
};

export type UserCreateOrConnectWithoutTeam_MembersInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTeam_MembersInput;
};

export type UserCreateOrConnectWithoutTeamsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTeamsInput;
};

export type UserCreateOrConnectWithoutUser_SubmissionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUser_SubmissionsInput;
};

export type UserCreateWithoutAddressInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutBus_AttendeesInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutClaimsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutCollaboratorsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutCompanyInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutEducationInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutEmergency_Contact_AddressInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutEvent_Activity_Redemptions_EventInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutEvent_Activity_Redemptions_UserInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutEvent_AttendeesInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutFilesInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutHardwareRequestHistoryEntryInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutHardwareRequestInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutHtnBucksAccountInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutMentor_Request_CommentsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutMentor_RequestsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutMentorsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutRepresentativesInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutReviewsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutShift_History_ModificationsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
};

export type UserCreateWithoutShipping_AddressInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutSponsor_IndividualsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutTeam_MembersInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutTeamsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateWithoutUser_SubmissionsInput = {
  allergies?: Maybe<UserCreateallergiesInput>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTimeISO"]>;
  date_of_birth?: Maybe<Scalars["DateTimeISO"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  gender?: Maybe<Gender>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  updated_at?: Maybe<Scalars["DateTimeISO"]>;
  event_platform_user_id?: Maybe<Scalars["String"]>;
  auth_tokens?: Maybe<AuthTokenCreateNestedManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateNestedManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateNestedManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateNestedManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateNestedManyWithoutUserInput>;
  files?: Maybe<FileCreateNestedManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateNestedManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateNestedManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateNestedManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateNestedManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateNestedManyWithoutUserInput>;
  person?: Maybe<PersonCreateNestedManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateNestedManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateNestedManyWithoutUserInput>;
  sponsor_individuals?: Maybe<SponsorIndividualCreateNestedManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateNestedManyWithoutUserInput>;
  teams?: Maybe<TeamCreateNestedManyWithoutUserInput>;
  address?: Maybe<AddressCreateNestedOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateNestedOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateNestedOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateNestedOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateNestedOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateNestedOneWithoutUsersInput>;
  bus_attendees?: Maybe<BusAttendeeCreateNestedManyWithoutUserInput>;
  HardwareRequest?: Maybe<HardwareRequestCreateNestedManyWithoutUserInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryCreateNestedManyWithoutModifying_UserInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountCreateNestedOneWithoutUserInput>;
  shift_history_modifications?: Maybe<ShiftHistoryCreateNestedManyWithoutModified_ByInput>;
};

export type UserCreateallergiesInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreateemail_HashesInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatefood_RestrictionsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatehacker_HackathonsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatehacker_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatementor_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatesponsor_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserNullableRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export enum UserOrderByRelevanceFieldEnum {
  Allergies = "allergies",
  Avatar = "avatar",
  Bio = "bio",
  Email = "email",
  EmailHashes = "email_hashes",
  EmergencyContactEmail = "emergency_contact_email",
  EmergencyContactName = "emergency_contact_name",
  EmergencyContactPhoneNumber = "emergency_contact_phone_number",
  EmergencyContactRelationship = "emergency_contact_relationship",
  Ethnicity = "ethnicity",
  FoodRestrictions = "food_restrictions",
  HackerHackathons = "hacker_hackathons",
  HackerSchoolEmail = "hacker_school_email",
  HackerSkills = "hacker_skills",
  LegalName = "legal_name",
  MentorLocation = "mentor_location",
  MentorSkills = "mentor_skills",
  Name = "name",
  NativeLanguage = "native_language",
  Password = "password",
  PhoneNumber = "phone_number",
  SponsorPersonalEmail = "sponsor_personal_email",
  SponsorRole = "sponsor_role",
  SponsorSkills = "sponsor_skills",
  EventPlatformUserId = "event_platform_user_id",
}

export type UserOrderByRelevanceInput = {
  fields: Array<UserOrderByRelevanceFieldEnum>;
  sort: SortOrder;
  search: Scalars["String"];
};

export type UserOrderByWithRelationAndSearchRelevanceInput = {
  address_id?: Maybe<SortOrderInput>;
  allergies?: Maybe<SortOrder>;
  avatar?: Maybe<SortOrderInput>;
  bio?: Maybe<SortOrderInput>;
  created_at?: Maybe<SortOrder>;
  date_of_birth?: Maybe<SortOrderInput>;
  email?: Maybe<SortOrder>;
  email_hashes?: Maybe<SortOrder>;
  emergency_contact_address_id?: Maybe<SortOrderInput>;
  emergency_contact_email?: Maybe<SortOrderInput>;
  emergency_contact_name?: Maybe<SortOrderInput>;
  emergency_contact_phone_number?: Maybe<SortOrderInput>;
  emergency_contact_relationship?: Maybe<SortOrderInput>;
  ethnicity?: Maybe<SortOrderInput>;
  food_restrictions?: Maybe<SortOrder>;
  gender?: Maybe<SortOrderInput>;
  hacker_education_id?: Maybe<SortOrderInput>;
  hacker_hackathons?: Maybe<SortOrder>;
  hacker_school_email?: Maybe<SortOrderInput>;
  hacker_skills?: Maybe<SortOrder>;
  hacker_travel_id?: Maybe<SortOrderInput>;
  hacker_urls_id?: Maybe<SortOrderInput>;
  id?: Maybe<SortOrder>;
  legal_name?: Maybe<SortOrderInput>;
  mentor_location?: Maybe<SortOrderInput>;
  mentor_skills?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  native_language?: Maybe<SortOrderInput>;
  password?: Maybe<SortOrder>;
  phone_number?: Maybe<SortOrderInput>;
  role?: Maybe<SortOrderInput>;
  shipping_address_id?: Maybe<SortOrderInput>;
  shirt_size?: Maybe<SortOrderInput>;
  sponsor_company_id?: Maybe<SortOrderInput>;
  sponsor_personal_email?: Maybe<SortOrderInput>;
  sponsor_role?: Maybe<SortOrderInput>;
  sponsor_skills?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  event_platform_user_id?: Maybe<SortOrderInput>;
  auth_tokens?: Maybe<AuthTokenOrderByRelationAggregateInput>;
  claims?: Maybe<ClaimOrderByRelationAggregateInput>;
  collaborators?: Maybe<CollaboratorOrderByRelationAggregateInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionOrderByRelationAggregateInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionOrderByRelationAggregateInput>;
  event_attendees?: Maybe<EventAttendeeOrderByRelationAggregateInput>;
  files?: Maybe<FileOrderByRelationAggregateInput>;
  firebase_tokens?: Maybe<FirebaseTokenOrderByRelationAggregateInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordOrderByRelationAggregateInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentOrderByRelationAggregateInput>;
  mentor_requests?: Maybe<MentorRequestOrderByRelationAggregateInput>;
  mentors?: Maybe<MentorOrderByRelationAggregateInput>;
  person?: Maybe<PersonOrderByRelationAggregateInput>;
  representatives?: Maybe<RepresentativeOrderByRelationAggregateInput>;
  reviews?: Maybe<ReviewOrderByRelationAggregateInput>;
  sponsor_individuals?: Maybe<SponsorIndividualOrderByRelationAggregateInput>;
  team_members?: Maybe<TeamMemberOrderByRelationAggregateInput>;
  teams?: Maybe<TeamOrderByRelationAggregateInput>;
  user_submissions?: Maybe<UserSubmissionOrderByRelationAggregateInput>;
  address?: Maybe<AddressOrderByWithRelationAndSearchRelevanceInput>;
  emergency_contact_address?: Maybe<AddressOrderByWithRelationAndSearchRelevanceInput>;
  education?: Maybe<EducationOrderByWithRelationAndSearchRelevanceInput>;
  travel_information?: Maybe<TravelInformationOrderByWithRelationAndSearchRelevanceInput>;
  urls?: Maybe<UrlOrderByWithRelationAndSearchRelevanceInput>;
  shipping_address?: Maybe<AddressOrderByWithRelationAndSearchRelevanceInput>;
  company?: Maybe<CompanyOrderByWithRelationAndSearchRelevanceInput>;
  bus_attendees?: Maybe<BusAttendeeOrderByRelationAggregateInput>;
  HardwareRequest?: Maybe<HardwareRequestOrderByRelationAggregateInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryOrderByRelationAggregateInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountOrderByWithRelationAndSearchRelevanceInput>;
  shift_history_modifications?: Maybe<ShiftHistoryOrderByRelationAggregateInput>;
  _relevance?: Maybe<UserOrderByRelevanceInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  AddressId = "address_id",
  Allergies = "allergies",
  Avatar = "avatar",
  Bio = "bio",
  CreatedAt = "created_at",
  DateOfBirth = "date_of_birth",
  Email = "email",
  EmailHashes = "email_hashes",
  EmergencyContactAddressId = "emergency_contact_address_id",
  EmergencyContactEmail = "emergency_contact_email",
  EmergencyContactName = "emergency_contact_name",
  EmergencyContactPhoneNumber = "emergency_contact_phone_number",
  EmergencyContactRelationship = "emergency_contact_relationship",
  Ethnicity = "ethnicity",
  FoodRestrictions = "food_restrictions",
  Gender = "gender",
  HackerEducationId = "hacker_education_id",
  HackerHackathons = "hacker_hackathons",
  HackerSchoolEmail = "hacker_school_email",
  HackerSkills = "hacker_skills",
  HackerTravelId = "hacker_travel_id",
  HackerUrlsId = "hacker_urls_id",
  Id = "id",
  LegalName = "legal_name",
  MentorLocation = "mentor_location",
  MentorSkills = "mentor_skills",
  Name = "name",
  NativeLanguage = "native_language",
  Password = "password",
  PhoneNumber = "phone_number",
  Role = "role",
  ShippingAddressId = "shipping_address_id",
  ShirtSize = "shirt_size",
  SponsorCompanyId = "sponsor_company_id",
  SponsorPersonalEmail = "sponsor_personal_email",
  SponsorRole = "sponsor_role",
  SponsorSkills = "sponsor_skills",
  UpdatedAt = "updated_at",
  EventPlatformUserId = "event_platform_user_id",
}

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  allergies?: Maybe<StringNullableListFilter>;
  avatar?: Maybe<StringNullableFilter>;
  bio?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_of_birth?: Maybe<DateTimeNullableFilter>;
  email?: Maybe<StringFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emergency_contact_address_id?: Maybe<IntNullableFilter>;
  emergency_contact_email?: Maybe<StringNullableFilter>;
  emergency_contact_name?: Maybe<StringNullableFilter>;
  emergency_contact_phone_number?: Maybe<StringNullableFilter>;
  emergency_contact_relationship?: Maybe<StringNullableFilter>;
  ethnicity?: Maybe<StringNullableFilter>;
  food_restrictions?: Maybe<StringNullableListFilter>;
  gender?: Maybe<EnumGenderNullableFilter>;
  hacker_education_id?: Maybe<IntNullableFilter>;
  hacker_hackathons?: Maybe<StringNullableListFilter>;
  hacker_school_email?: Maybe<StringNullableFilter>;
  hacker_skills?: Maybe<StringNullableListFilter>;
  hacker_travel_id?: Maybe<IntNullableFilter>;
  hacker_urls_id?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  legal_name?: Maybe<StringNullableFilter>;
  mentor_location?: Maybe<StringNullableFilter>;
  mentor_skills?: Maybe<StringNullableListFilter>;
  name?: Maybe<StringFilter>;
  native_language?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  phone_number?: Maybe<StringNullableFilter>;
  role?: Maybe<IntNullableFilter>;
  shipping_address_id?: Maybe<IntNullableFilter>;
  shirt_size?: Maybe<IntNullableFilter>;
  sponsor_company_id?: Maybe<IntNullableFilter>;
  sponsor_personal_email?: Maybe<StringNullableFilter>;
  sponsor_role?: Maybe<StringNullableFilter>;
  sponsor_skills?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_platform_user_id?: Maybe<StringNullableFilter>;
};

export type UserSubmissionCreateManySubmissionInput = {
  user_id: Scalars["Int"];
  dummy?: Maybe<Scalars["String"]>;
};

export type UserSubmissionCreateManySubmissionInputEnvelope = {
  data: Array<UserSubmissionCreateManySubmissionInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserSubmissionCreateManyUserInput = {
  submission_id: Scalars["Int"];
  dummy?: Maybe<Scalars["String"]>;
};

export type UserSubmissionCreateManyUserInputEnvelope = {
  data: Array<UserSubmissionCreateManyUserInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type UserSubmissionCreateNestedManyWithoutSubmissionInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutSubmissionInput>>;
  connectOrCreate?: Maybe<
    Array<UserSubmissionCreateOrConnectWithoutSubmissionInput>
  >;
  createMany?: Maybe<UserSubmissionCreateManySubmissionInputEnvelope>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
};

export type UserSubmissionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserSubmissionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<UserSubmissionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
};

export type UserSubmissionCreateOrConnectWithoutSubmissionInput = {
  where: UserSubmissionWhereUniqueInput;
  create: UserSubmissionCreateWithoutSubmissionInput;
};

export type UserSubmissionCreateOrConnectWithoutUserInput = {
  where: UserSubmissionWhereUniqueInput;
  create: UserSubmissionCreateWithoutUserInput;
};

export type UserSubmissionCreateWithoutSubmissionInput = {
  dummy?: Maybe<Scalars["String"]>;
  user: UserCreateNestedOneWithoutUser_SubmissionsInput;
};

export type UserSubmissionCreateWithoutUserInput = {
  dummy?: Maybe<Scalars["String"]>;
  submission: SubmissionCreateNestedOneWithoutUser_SubmissionsInput;
};

export type UserSubmissionListRelationFilter = {
  every?: Maybe<UserSubmissionWhereInput>;
  some?: Maybe<UserSubmissionWhereInput>;
  none?: Maybe<UserSubmissionWhereInput>;
};

export type UserSubmissionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type UserSubmissionScalarWhereInput = {
  AND?: Maybe<Array<UserSubmissionScalarWhereInput>>;
  OR?: Maybe<Array<UserSubmissionScalarWhereInput>>;
  NOT?: Maybe<Array<UserSubmissionScalarWhereInput>>;
  submission_id?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  dummy?: Maybe<StringNullableFilter>;
};

export type UserSubmissionUpdateManyMutationInput = {
  dummy?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserSubmissionUpdateManyWithWhereWithoutSubmissionInput = {
  where: UserSubmissionScalarWhereInput;
  data: UserSubmissionUpdateManyMutationInput;
};

export type UserSubmissionUpdateManyWithWhereWithoutUserInput = {
  where: UserSubmissionScalarWhereInput;
  data: UserSubmissionUpdateManyMutationInput;
};

export type UserSubmissionUpdateManyWithoutSubmissionNestedInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutSubmissionInput>>;
  connectOrCreate?: Maybe<
    Array<UserSubmissionCreateOrConnectWithoutSubmissionInput>
  >;
  upsert?: Maybe<
    Array<UserSubmissionUpsertWithWhereUniqueWithoutSubmissionInput>
  >;
  createMany?: Maybe<UserSubmissionCreateManySubmissionInputEnvelope>;
  set?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  update?: Maybe<
    Array<UserSubmissionUpdateWithWhereUniqueWithoutSubmissionInput>
  >;
  updateMany?: Maybe<
    Array<UserSubmissionUpdateManyWithWhereWithoutSubmissionInput>
  >;
  deleteMany?: Maybe<Array<UserSubmissionScalarWhereInput>>;
};

export type UserSubmissionUpdateManyWithoutUserNestedInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<UserSubmissionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<UserSubmissionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<UserSubmissionCreateManyUserInputEnvelope>;
  set?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  delete?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  update?: Maybe<Array<UserSubmissionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<UserSubmissionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<UserSubmissionScalarWhereInput>>;
};

export type UserSubmissionUpdateWithWhereUniqueWithoutSubmissionInput = {
  where: UserSubmissionWhereUniqueInput;
  data: UserSubmissionUpdateWithoutSubmissionInput;
};

export type UserSubmissionUpdateWithWhereUniqueWithoutUserInput = {
  where: UserSubmissionWhereUniqueInput;
  data: UserSubmissionUpdateWithoutUserInput;
};

export type UserSubmissionUpdateWithoutSubmissionInput = {
  dummy?: Maybe<NullableStringFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutUser_SubmissionsNestedInput>;
};

export type UserSubmissionUpdateWithoutUserInput = {
  dummy?: Maybe<NullableStringFieldUpdateOperationsInput>;
  submission?: Maybe<SubmissionUpdateOneRequiredWithoutUser_SubmissionsNestedInput>;
};

export type UserSubmissionUpsertWithWhereUniqueWithoutSubmissionInput = {
  where: UserSubmissionWhereUniqueInput;
  update: UserSubmissionUpdateWithoutSubmissionInput;
  create: UserSubmissionCreateWithoutSubmissionInput;
};

export type UserSubmissionUpsertWithWhereUniqueWithoutUserInput = {
  where: UserSubmissionWhereUniqueInput;
  update: UserSubmissionUpdateWithoutUserInput;
  create: UserSubmissionCreateWithoutUserInput;
};

export type UserSubmissionUser_IdSubmission_IdCompoundUniqueInput = {
  user_id: Scalars["Int"];
  submission_id: Scalars["Int"];
};

export type UserSubmissionWhereInput = {
  AND?: Maybe<Array<UserSubmissionWhereInput>>;
  OR?: Maybe<Array<UserSubmissionWhereInput>>;
  NOT?: Maybe<Array<UserSubmissionWhereInput>>;
  submission_id?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  dummy?: Maybe<StringNullableFilter>;
  submission?: Maybe<SubmissionRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type UserSubmissionWhereUniqueInput = {
  user_id_submission_id?: Maybe<UserSubmissionUser_IdSubmission_IdCompoundUniqueInput>;
  AND?: Maybe<Array<UserSubmissionWhereInput>>;
  OR?: Maybe<Array<UserSubmissionWhereInput>>;
  NOT?: Maybe<Array<UserSubmissionWhereInput>>;
  submission_id?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  dummy?: Maybe<StringNullableFilter>;
  submission?: Maybe<SubmissionRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type UserUpdateManyMutationInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutAddressInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutCompanyInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutEducationInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutEmergency_Contact_AddressInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutShipping_AddressInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithoutAddressNestedInput = {
  create?: Maybe<Array<UserCreateWithoutAddressInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutAddressInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutAddressInput>>;
  createMany?: Maybe<UserCreateManyAddressInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutAddressInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutCompanyNestedInput = {
  create?: Maybe<Array<UserCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<UserCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutCompanyInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutEducationNestedInput = {
  create?: Maybe<Array<UserCreateWithoutEducationInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutEducationInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutEducationInput>>;
  createMany?: Maybe<UserCreateManyEducationInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutEducationInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutEducationInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutEmergency_Contact_AddressNestedInput = {
  create?: Maybe<Array<UserCreateWithoutEmergency_Contact_AddressInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutEmergency_Contact_AddressInput>
  >;
  upsert?: Maybe<
    Array<UserUpsertWithWhereUniqueWithoutEmergency_Contact_AddressInput>
  >;
  createMany?: Maybe<UserCreateManyEmergency_Contact_AddressInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<
    Array<UserUpdateWithWhereUniqueWithoutEmergency_Contact_AddressInput>
  >;
  updateMany?: Maybe<
    Array<UserUpdateManyWithWhereWithoutEmergency_Contact_AddressInput>
  >;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutShipping_AddressNestedInput = {
  create?: Maybe<Array<UserCreateWithoutShipping_AddressInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutShipping_AddressInput>
  >;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutShipping_AddressInput>>;
  createMany?: Maybe<UserCreateManyShipping_AddressInputEnvelope>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutShipping_AddressInput>>;
  updateMany?: Maybe<
    Array<UserUpdateManyWithWhereWithoutShipping_AddressInput>
  >;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateOneRequiredWithoutBus_AttendeesNestedInput = {
  create?: Maybe<UserCreateWithoutBus_AttendeesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutBus_AttendeesInput>;
  upsert?: Maybe<UserUpsertWithoutBus_AttendeesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutBus_AttendeesInput>;
};

export type UserUpdateOneRequiredWithoutClaimsNestedInput = {
  create?: Maybe<UserCreateWithoutClaimsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutClaimsInput>;
  upsert?: Maybe<UserUpsertWithoutClaimsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutClaimsInput>;
};

export type UserUpdateOneRequiredWithoutCollaboratorsNestedInput = {
  create?: Maybe<UserCreateWithoutCollaboratorsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutCollaboratorsInput>;
  upsert?: Maybe<UserUpsertWithoutCollaboratorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutCollaboratorsInput>;
};

export type UserUpdateOneRequiredWithoutEvent_Activity_Redemptions_EventNestedInput =
  {
    create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_EventInput>;
    connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_Activity_Redemptions_EventInput>;
    upsert?: Maybe<UserUpsertWithoutEvent_Activity_Redemptions_EventInput>;
    connect?: Maybe<UserWhereUniqueInput>;
    update?: Maybe<UserUpdateToOneWithWhereWithoutEvent_Activity_Redemptions_EventInput>;
  };

export type UserUpdateOneRequiredWithoutEvent_AttendeesNestedInput = {
  create?: Maybe<UserCreateWithoutEvent_AttendeesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_AttendeesInput>;
  upsert?: Maybe<UserUpsertWithoutEvent_AttendeesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutEvent_AttendeesInput>;
};

export type UserUpdateOneRequiredWithoutHardwareRequestHistoryEntryNestedInput =
  {
    create?: Maybe<UserCreateWithoutHardwareRequestHistoryEntryInput>;
    connectOrCreate?: Maybe<UserCreateOrConnectWithoutHardwareRequestHistoryEntryInput>;
    upsert?: Maybe<UserUpsertWithoutHardwareRequestHistoryEntryInput>;
    connect?: Maybe<UserWhereUniqueInput>;
    update?: Maybe<UserUpdateToOneWithWhereWithoutHardwareRequestHistoryEntryInput>;
  };

export type UserUpdateOneRequiredWithoutHardwareRequestNestedInput = {
  create?: Maybe<UserCreateWithoutHardwareRequestInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutHardwareRequestInput>;
  upsert?: Maybe<UserUpsertWithoutHardwareRequestInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutHardwareRequestInput>;
};

export type UserUpdateOneRequiredWithoutHtnBucksAccountNestedInput = {
  create?: Maybe<UserCreateWithoutHtnBucksAccountInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutHtnBucksAccountInput>;
  upsert?: Maybe<UserUpsertWithoutHtnBucksAccountInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutHtnBucksAccountInput>;
};

export type UserUpdateOneRequiredWithoutMentor_Request_CommentsNestedInput = {
  create?: Maybe<UserCreateWithoutMentor_Request_CommentsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentor_Request_CommentsInput>;
  upsert?: Maybe<UserUpsertWithoutMentor_Request_CommentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutMentor_Request_CommentsInput>;
};

export type UserUpdateOneRequiredWithoutMentor_RequestsNestedInput = {
  create?: Maybe<UserCreateWithoutMentor_RequestsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentor_RequestsInput>;
  upsert?: Maybe<UserUpsertWithoutMentor_RequestsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutMentor_RequestsInput>;
};

export type UserUpdateOneRequiredWithoutMentorsNestedInput = {
  create?: Maybe<UserCreateWithoutMentorsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMentorsInput>;
  upsert?: Maybe<UserUpsertWithoutMentorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutMentorsInput>;
};

export type UserUpdateOneRequiredWithoutRepresentativesNestedInput = {
  create?: Maybe<UserCreateWithoutRepresentativesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutRepresentativesInput>;
  upsert?: Maybe<UserUpsertWithoutRepresentativesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutRepresentativesInput>;
};

export type UserUpdateOneRequiredWithoutReviewsNestedInput = {
  create?: Maybe<UserCreateWithoutReviewsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutReviewsInput>;
  upsert?: Maybe<UserUpsertWithoutReviewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutReviewsInput>;
};

export type UserUpdateOneRequiredWithoutShift_History_ModificationsNestedInput =
  {
    create?: Maybe<UserCreateWithoutShift_History_ModificationsInput>;
    connectOrCreate?: Maybe<UserCreateOrConnectWithoutShift_History_ModificationsInput>;
    upsert?: Maybe<UserUpsertWithoutShift_History_ModificationsInput>;
    connect?: Maybe<UserWhereUniqueInput>;
    update?: Maybe<UserUpdateToOneWithWhereWithoutShift_History_ModificationsInput>;
  };

export type UserUpdateOneRequiredWithoutSponsor_IndividualsNestedInput = {
  create?: Maybe<UserCreateWithoutSponsor_IndividualsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSponsor_IndividualsInput>;
  upsert?: Maybe<UserUpsertWithoutSponsor_IndividualsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutSponsor_IndividualsInput>;
};

export type UserUpdateOneRequiredWithoutTeam_MembersNestedInput = {
  create?: Maybe<UserCreateWithoutTeam_MembersInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTeam_MembersInput>;
  upsert?: Maybe<UserUpsertWithoutTeam_MembersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutTeam_MembersInput>;
};

export type UserUpdateOneRequiredWithoutUser_SubmissionsNestedInput = {
  create?: Maybe<UserCreateWithoutUser_SubmissionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutUser_SubmissionsInput>;
  upsert?: Maybe<UserUpsertWithoutUser_SubmissionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutUser_SubmissionsInput>;
};

export type UserUpdateOneWithoutEvent_Activity_Redemptions_UserNestedInput = {
  create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_UserInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutEvent_Activity_Redemptions_UserInput>;
  upsert?: Maybe<UserUpsertWithoutEvent_Activity_Redemptions_UserInput>;
  disconnect?: Maybe<UserWhereInput>;
  delete?: Maybe<UserWhereInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutEvent_Activity_Redemptions_UserInput>;
};

export type UserUpdateOneWithoutFilesNestedInput = {
  create?: Maybe<UserCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutFilesInput>;
  upsert?: Maybe<UserUpsertWithoutFilesInput>;
  disconnect?: Maybe<UserWhereInput>;
  delete?: Maybe<UserWhereInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutFilesInput>;
};

export type UserUpdateOneWithoutTeamsNestedInput = {
  create?: Maybe<UserCreateWithoutTeamsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTeamsInput>;
  upsert?: Maybe<UserUpsertWithoutTeamsInput>;
  disconnect?: Maybe<UserWhereInput>;
  delete?: Maybe<UserWhereInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateToOneWithWhereWithoutTeamsInput>;
};

export type UserUpdateToOneWithWhereWithoutBus_AttendeesInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutBus_AttendeesInput;
};

export type UserUpdateToOneWithWhereWithoutClaimsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutClaimsInput;
};

export type UserUpdateToOneWithWhereWithoutCollaboratorsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutCollaboratorsInput;
};

export type UserUpdateToOneWithWhereWithoutEvent_Activity_Redemptions_EventInput =
  {
    where?: Maybe<UserWhereInput>;
    data: UserUpdateWithoutEvent_Activity_Redemptions_EventInput;
  };

export type UserUpdateToOneWithWhereWithoutEvent_Activity_Redemptions_UserInput =
  {
    where?: Maybe<UserWhereInput>;
    data: UserUpdateWithoutEvent_Activity_Redemptions_UserInput;
  };

export type UserUpdateToOneWithWhereWithoutEvent_AttendeesInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutEvent_AttendeesInput;
};

export type UserUpdateToOneWithWhereWithoutFilesInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutFilesInput;
};

export type UserUpdateToOneWithWhereWithoutHardwareRequestHistoryEntryInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutHardwareRequestHistoryEntryInput;
};

export type UserUpdateToOneWithWhereWithoutHardwareRequestInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutHardwareRequestInput;
};

export type UserUpdateToOneWithWhereWithoutHtnBucksAccountInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutHtnBucksAccountInput;
};

export type UserUpdateToOneWithWhereWithoutMentor_Request_CommentsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutMentor_Request_CommentsInput;
};

export type UserUpdateToOneWithWhereWithoutMentor_RequestsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutMentor_RequestsInput;
};

export type UserUpdateToOneWithWhereWithoutMentorsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutMentorsInput;
};

export type UserUpdateToOneWithWhereWithoutRepresentativesInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutRepresentativesInput;
};

export type UserUpdateToOneWithWhereWithoutReviewsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutReviewsInput;
};

export type UserUpdateToOneWithWhereWithoutShift_History_ModificationsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutShift_History_ModificationsInput;
};

export type UserUpdateToOneWithWhereWithoutSponsor_IndividualsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutSponsor_IndividualsInput;
};

export type UserUpdateToOneWithWhereWithoutTeam_MembersInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutTeam_MembersInput;
};

export type UserUpdateToOneWithWhereWithoutTeamsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutTeamsInput;
};

export type UserUpdateToOneWithWhereWithoutUser_SubmissionsInput = {
  where?: Maybe<UserWhereInput>;
  data: UserUpdateWithoutUser_SubmissionsInput;
};

export type UserUpdateWithWhereUniqueWithoutAddressInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutAddressInput;
};

export type UserUpdateWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutCompanyInput;
};

export type UserUpdateWithWhereUniqueWithoutEducationInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutEducationInput;
};

export type UserUpdateWithWhereUniqueWithoutEmergency_Contact_AddressInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutEmergency_Contact_AddressInput;
};

export type UserUpdateWithWhereUniqueWithoutShipping_AddressInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutShipping_AddressInput;
};

export type UserUpdateWithoutAddressInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutBus_AttendeesInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutClaimsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutCollaboratorsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutCompanyInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutEducationInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutEmergency_Contact_AddressInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutEvent_Activity_Redemptions_EventInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutEvent_Activity_Redemptions_UserInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutEvent_AttendeesInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutFilesInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutHardwareRequestHistoryEntryInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutHardwareRequestInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutHtnBucksAccountInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutMentor_Request_CommentsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutMentor_RequestsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutMentorsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutRepresentativesInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutReviewsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutShift_History_ModificationsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
};

export type UserUpdateWithoutShipping_AddressInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutSponsor_IndividualsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutTeam_MembersInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutTeamsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  user_submissions?: Maybe<UserSubmissionUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateWithoutUser_SubmissionsInput = {
  allergies?: Maybe<UserUpdateallergiesInput>;
  avatar?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bio?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_of_birth?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  email_hashes?: Maybe<UserUpdateemail_HashesInput>;
  emergency_contact_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  emergency_contact_relationship?: Maybe<NullableStringFieldUpdateOperationsInput>;
  ethnicity?: Maybe<NullableStringFieldUpdateOperationsInput>;
  food_restrictions?: Maybe<UserUpdatefood_RestrictionsInput>;
  gender?: Maybe<NullableEnumGenderFieldUpdateOperationsInput>;
  hacker_hackathons?: Maybe<UserUpdatehacker_HackathonsInput>;
  hacker_school_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  hacker_skills?: Maybe<UserUpdatehacker_SkillsInput>;
  legal_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_location?: Maybe<NullableStringFieldUpdateOperationsInput>;
  mentor_skills?: Maybe<UserUpdatementor_SkillsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  native_language?: Maybe<NullableStringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  phone_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<NullableIntFieldUpdateOperationsInput>;
  shirt_size?: Maybe<NullableIntFieldUpdateOperationsInput>;
  sponsor_personal_email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_role?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sponsor_skills?: Maybe<UserUpdatesponsor_SkillsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  event_platform_user_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  auth_tokens?: Maybe<AuthTokenUpdateManyWithoutUserNestedInput>;
  claims?: Maybe<ClaimUpdateManyWithoutUserNestedInput>;
  collaborators?: Maybe<CollaboratorUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionUpdateManyWithoutUserNestedInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionUpdateManyWithoutVolunteerNestedInput>;
  event_attendees?: Maybe<EventAttendeeUpdateManyWithoutUserNestedInput>;
  files?: Maybe<FileUpdateManyWithoutUserNestedInput>;
  firebase_tokens?: Maybe<FirebaseTokenUpdateManyWithoutUserNestedInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordUpdateManyWithoutUserNestedInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentUpdateManyWithoutUserNestedInput>;
  mentor_requests?: Maybe<MentorRequestUpdateManyWithoutUserNestedInput>;
  mentors?: Maybe<MentorUpdateManyWithoutUserNestedInput>;
  person?: Maybe<PersonUpdateManyWithoutUserNestedInput>;
  representatives?: Maybe<RepresentativeUpdateManyWithoutUserNestedInput>;
  reviews?: Maybe<ReviewUpdateManyWithoutUserNestedInput>;
  sponsor_individuals?: Maybe<SponsorIndividualUpdateManyWithoutUserNestedInput>;
  team_members?: Maybe<TeamMemberUpdateManyWithoutUserNestedInput>;
  teams?: Maybe<TeamUpdateManyWithoutUserNestedInput>;
  address?: Maybe<AddressUpdateOneWithoutUsers_As_AddressNestedInput>;
  emergency_contact_address?: Maybe<AddressUpdateOneWithoutUsers_As_Emergency_Contact_AddressNestedInput>;
  education?: Maybe<EducationUpdateOneWithoutUsersNestedInput>;
  travel_information?: Maybe<TravelInformationUpdateOneWithoutUsersNestedInput>;
  urls?: Maybe<UrlUpdateOneWithoutUsersNestedInput>;
  shipping_address?: Maybe<AddressUpdateOneWithoutUsers_As_Shipping_AddressNestedInput>;
  company?: Maybe<CompanyUpdateOneWithoutUsersNestedInput>;
  bus_attendees?: Maybe<BusAttendeeUpdateManyWithoutUserNestedInput>;
  HardwareRequest?: Maybe<HardwareRequestUpdateManyWithoutUserNestedInput>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryUpdateManyWithoutModifying_UserNestedInput>;
  HtnBucksAccount?: Maybe<HtnBucksAccountUpdateOneWithoutUserNestedInput>;
  shift_history_modifications?: Maybe<ShiftHistoryUpdateManyWithoutModified_ByNestedInput>;
};

export type UserUpdateallergiesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdateemail_HashesInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdatefood_RestrictionsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdatehacker_HackathonsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdatehacker_SkillsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdatementor_SkillsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpdatesponsor_SkillsInput = {
  set?: Maybe<Array<Scalars["String"]>>;
  push?: Maybe<Array<Scalars["String"]>>;
};

export type UserUpsertWithWhereUniqueWithoutAddressInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutAddressInput;
  create: UserCreateWithoutAddressInput;
};

export type UserUpsertWithWhereUniqueWithoutCompanyInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutCompanyInput;
  create: UserCreateWithoutCompanyInput;
};

export type UserUpsertWithWhereUniqueWithoutEducationInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutEducationInput;
  create: UserCreateWithoutEducationInput;
};

export type UserUpsertWithWhereUniqueWithoutEmergency_Contact_AddressInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutEmergency_Contact_AddressInput;
  create: UserCreateWithoutEmergency_Contact_AddressInput;
};

export type UserUpsertWithWhereUniqueWithoutShipping_AddressInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutShipping_AddressInput;
  create: UserCreateWithoutShipping_AddressInput;
};

export type UserUpsertWithoutBus_AttendeesInput = {
  update: UserUpdateWithoutBus_AttendeesInput;
  create: UserCreateWithoutBus_AttendeesInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutClaimsInput = {
  update: UserUpdateWithoutClaimsInput;
  create: UserCreateWithoutClaimsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutCollaboratorsInput = {
  update: UserUpdateWithoutCollaboratorsInput;
  create: UserCreateWithoutCollaboratorsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutEvent_Activity_Redemptions_EventInput = {
  update: UserUpdateWithoutEvent_Activity_Redemptions_EventInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_EventInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutEvent_Activity_Redemptions_UserInput = {
  update: UserUpdateWithoutEvent_Activity_Redemptions_UserInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_UserInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutEvent_AttendeesInput = {
  update: UserUpdateWithoutEvent_AttendeesInput;
  create: UserCreateWithoutEvent_AttendeesInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutFilesInput = {
  update: UserUpdateWithoutFilesInput;
  create: UserCreateWithoutFilesInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutHardwareRequestHistoryEntryInput = {
  update: UserUpdateWithoutHardwareRequestHistoryEntryInput;
  create: UserCreateWithoutHardwareRequestHistoryEntryInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutHardwareRequestInput = {
  update: UserUpdateWithoutHardwareRequestInput;
  create: UserCreateWithoutHardwareRequestInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutHtnBucksAccountInput = {
  update: UserUpdateWithoutHtnBucksAccountInput;
  create: UserCreateWithoutHtnBucksAccountInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutMentor_Request_CommentsInput = {
  update: UserUpdateWithoutMentor_Request_CommentsInput;
  create: UserCreateWithoutMentor_Request_CommentsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutMentor_RequestsInput = {
  update: UserUpdateWithoutMentor_RequestsInput;
  create: UserCreateWithoutMentor_RequestsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutMentorsInput = {
  update: UserUpdateWithoutMentorsInput;
  create: UserCreateWithoutMentorsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutRepresentativesInput = {
  update: UserUpdateWithoutRepresentativesInput;
  create: UserCreateWithoutRepresentativesInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutReviewsInput = {
  update: UserUpdateWithoutReviewsInput;
  create: UserCreateWithoutReviewsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutShift_History_ModificationsInput = {
  update: UserUpdateWithoutShift_History_ModificationsInput;
  create: UserCreateWithoutShift_History_ModificationsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutSponsor_IndividualsInput = {
  update: UserUpdateWithoutSponsor_IndividualsInput;
  create: UserCreateWithoutSponsor_IndividualsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutTeam_MembersInput = {
  update: UserUpdateWithoutTeam_MembersInput;
  create: UserCreateWithoutTeam_MembersInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutTeamsInput = {
  update: UserUpdateWithoutTeamsInput;
  create: UserCreateWithoutTeamsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserUpsertWithoutUser_SubmissionsInput = {
  update: UserUpdateWithoutUser_SubmissionsInput;
  create: UserCreateWithoutUser_SubmissionsInput;
  where?: Maybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  allergies?: Maybe<StringNullableListFilter>;
  avatar?: Maybe<StringNullableFilter>;
  bio?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_of_birth?: Maybe<DateTimeNullableFilter>;
  email?: Maybe<StringFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emergency_contact_address_id?: Maybe<IntNullableFilter>;
  emergency_contact_email?: Maybe<StringNullableFilter>;
  emergency_contact_name?: Maybe<StringNullableFilter>;
  emergency_contact_phone_number?: Maybe<StringNullableFilter>;
  emergency_contact_relationship?: Maybe<StringNullableFilter>;
  ethnicity?: Maybe<StringNullableFilter>;
  food_restrictions?: Maybe<StringNullableListFilter>;
  gender?: Maybe<EnumGenderNullableFilter>;
  hacker_education_id?: Maybe<IntNullableFilter>;
  hacker_hackathons?: Maybe<StringNullableListFilter>;
  hacker_school_email?: Maybe<StringNullableFilter>;
  hacker_skills?: Maybe<StringNullableListFilter>;
  hacker_travel_id?: Maybe<IntNullableFilter>;
  hacker_urls_id?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  legal_name?: Maybe<StringNullableFilter>;
  mentor_location?: Maybe<StringNullableFilter>;
  mentor_skills?: Maybe<StringNullableListFilter>;
  name?: Maybe<StringFilter>;
  native_language?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  phone_number?: Maybe<StringNullableFilter>;
  role?: Maybe<IntNullableFilter>;
  shipping_address_id?: Maybe<IntNullableFilter>;
  shirt_size?: Maybe<IntNullableFilter>;
  sponsor_company_id?: Maybe<IntNullableFilter>;
  sponsor_personal_email?: Maybe<StringNullableFilter>;
  sponsor_role?: Maybe<StringNullableFilter>;
  sponsor_skills?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_platform_user_id?: Maybe<StringNullableFilter>;
  auth_tokens?: Maybe<AuthTokenListRelationFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  firebase_tokens?: Maybe<FirebaseTokenListRelationFilter>;
  forgotten_passwords?: Maybe<ForgottenPasswordListRelationFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  person?: Maybe<PersonListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
  address?: Maybe<AddressNullableRelationFilter>;
  emergency_contact_address?: Maybe<AddressNullableRelationFilter>;
  education?: Maybe<EducationNullableRelationFilter>;
  travel_information?: Maybe<TravelInformationNullableRelationFilter>;
  urls?: Maybe<UrlNullableRelationFilter>;
  shipping_address?: Maybe<AddressNullableRelationFilter>;
  company?: Maybe<CompanyNullableRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
  HardwareRequest?: Maybe<HardwareRequestListRelationFilter>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryListRelationFilter>;
  HtnBucksAccount?: Maybe<HtnBucksAccountNullableRelationFilter>;
  shift_history_modifications?: Maybe<ShiftHistoryListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars["String"]>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  allergies?: Maybe<StringNullableListFilter>;
  avatar?: Maybe<StringNullableFilter>;
  bio?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_of_birth?: Maybe<DateTimeNullableFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emergency_contact_address_id?: Maybe<IntNullableFilter>;
  emergency_contact_email?: Maybe<StringNullableFilter>;
  emergency_contact_name?: Maybe<StringNullableFilter>;
  emergency_contact_phone_number?: Maybe<StringNullableFilter>;
  emergency_contact_relationship?: Maybe<StringNullableFilter>;
  ethnicity?: Maybe<StringNullableFilter>;
  food_restrictions?: Maybe<StringNullableListFilter>;
  gender?: Maybe<EnumGenderNullableFilter>;
  hacker_education_id?: Maybe<IntNullableFilter>;
  hacker_hackathons?: Maybe<StringNullableListFilter>;
  hacker_school_email?: Maybe<StringNullableFilter>;
  hacker_skills?: Maybe<StringNullableListFilter>;
  legal_name?: Maybe<StringNullableFilter>;
  mentor_location?: Maybe<StringNullableFilter>;
  mentor_skills?: Maybe<StringNullableListFilter>;
  name?: Maybe<StringFilter>;
  native_language?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  phone_number?: Maybe<StringNullableFilter>;
  role?: Maybe<IntNullableFilter>;
  shipping_address_id?: Maybe<IntNullableFilter>;
  shirt_size?: Maybe<IntNullableFilter>;
  sponsor_company_id?: Maybe<IntNullableFilter>;
  sponsor_personal_email?: Maybe<StringNullableFilter>;
  sponsor_role?: Maybe<StringNullableFilter>;
  sponsor_skills?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event_platform_user_id?: Maybe<StringNullableFilter>;
  auth_tokens?: Maybe<AuthTokenListRelationFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  firebase_tokens?: Maybe<FirebaseTokenListRelationFilter>;
  forgotten_passwords?: Maybe<ForgottenPasswordListRelationFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  person?: Maybe<PersonListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  sponsor_individuals?: Maybe<SponsorIndividualListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
  address?: Maybe<AddressNullableRelationFilter>;
  emergency_contact_address?: Maybe<AddressNullableRelationFilter>;
  education?: Maybe<EducationNullableRelationFilter>;
  travel_information?: Maybe<TravelInformationNullableRelationFilter>;
  urls?: Maybe<UrlNullableRelationFilter>;
  shipping_address?: Maybe<AddressNullableRelationFilter>;
  company?: Maybe<CompanyNullableRelationFilter>;
  bus_attendees?: Maybe<BusAttendeeListRelationFilter>;
  HardwareRequest?: Maybe<HardwareRequestListRelationFilter>;
  HardwareRequestHistoryEntry?: Maybe<HardwareRequestHistoryEntryListRelationFilter>;
  HtnBucksAccount?: Maybe<HtnBucksAccountNullableRelationFilter>;
  shift_history_modifications?: Maybe<ShiftHistoryListRelationFilter>;
};

export type Volunteer = {
  __typename?: "Volunteer";
  id: Scalars["Int"];
  notes: Scalars["String"];
  signed_forms: Scalars["Boolean"];
  event_attendee_id: Scalars["Int"];
  category_id: Scalars["Int"];
};

export type VolunteerCategory = {
  __typename?: "VolunteerCategory";
  id: Scalars["Int"];
  name: Scalars["String"];
  _count?: Maybe<VolunteerCategoryCount>;
};

export type VolunteerCategoryCount = {
  __typename?: "VolunteerCategoryCount";
  Volunteer: Scalars["Int"];
  VolunteerShift: Scalars["Int"];
};

export type VolunteerCategoryCountVolunteerArgs = {
  where?: Maybe<VolunteerWhereInput>;
};

export type VolunteerCategoryCountVolunteerShiftArgs = {
  where?: Maybe<VolunteerShiftWhereInput>;
};

export type VolunteerCategoryCreateInput = {
  name: Scalars["String"];
};

export type VolunteerCategoryCreateNestedOneWithoutVolunteerInput = {
  create?: Maybe<VolunteerCategoryCreateWithoutVolunteerInput>;
  connectOrCreate?: Maybe<VolunteerCategoryCreateOrConnectWithoutVolunteerInput>;
  connect?: Maybe<VolunteerCategoryWhereUniqueInput>;
};

export type VolunteerCategoryCreateNestedOneWithoutVolunteerShiftInput = {
  create?: Maybe<VolunteerCategoryCreateWithoutVolunteerShiftInput>;
  connectOrCreate?: Maybe<VolunteerCategoryCreateOrConnectWithoutVolunteerShiftInput>;
  connect?: Maybe<VolunteerCategoryWhereUniqueInput>;
};

export type VolunteerCategoryCreateOrConnectWithoutVolunteerInput = {
  where: VolunteerCategoryWhereUniqueInput;
  create: VolunteerCategoryCreateWithoutVolunteerInput;
};

export type VolunteerCategoryCreateOrConnectWithoutVolunteerShiftInput = {
  where: VolunteerCategoryWhereUniqueInput;
  create: VolunteerCategoryCreateWithoutVolunteerShiftInput;
};

export type VolunteerCategoryCreateWithoutVolunteerInput = {
  name: Scalars["String"];
  VolunteerShift?: Maybe<VolunteerShiftCreateNestedManyWithoutCategoryInput>;
};

export type VolunteerCategoryCreateWithoutVolunteerShiftInput = {
  name: Scalars["String"];
  Volunteer?: Maybe<VolunteerCreateNestedManyWithoutCategoryInput>;
};

export type VolunteerCategoryRelationFilter = {
  is?: Maybe<VolunteerCategoryWhereInput>;
  isNot?: Maybe<VolunteerCategoryWhereInput>;
};

export type VolunteerCategoryUpdateInput = {
  name: Scalars["String"];
};

export type VolunteerCategoryUpdateOneRequiredWithoutVolunteerNestedInput = {
  create?: Maybe<VolunteerCategoryCreateWithoutVolunteerInput>;
  connectOrCreate?: Maybe<VolunteerCategoryCreateOrConnectWithoutVolunteerInput>;
  upsert?: Maybe<VolunteerCategoryUpsertWithoutVolunteerInput>;
  connect?: Maybe<VolunteerCategoryWhereUniqueInput>;
  update?: Maybe<VolunteerCategoryUpdateToOneWithWhereWithoutVolunteerInput>;
};

export type VolunteerCategoryUpdateOneRequiredWithoutVolunteerShiftNestedInput =
  {
    create?: Maybe<VolunteerCategoryCreateWithoutVolunteerShiftInput>;
    connectOrCreate?: Maybe<VolunteerCategoryCreateOrConnectWithoutVolunteerShiftInput>;
    upsert?: Maybe<VolunteerCategoryUpsertWithoutVolunteerShiftInput>;
    connect?: Maybe<VolunteerCategoryWhereUniqueInput>;
    update?: Maybe<VolunteerCategoryUpdateToOneWithWhereWithoutVolunteerShiftInput>;
  };

export type VolunteerCategoryUpdateToOneWithWhereWithoutVolunteerInput = {
  where?: Maybe<VolunteerCategoryWhereInput>;
  data: VolunteerCategoryUpdateWithoutVolunteerInput;
};

export type VolunteerCategoryUpdateToOneWithWhereWithoutVolunteerShiftInput = {
  where?: Maybe<VolunteerCategoryWhereInput>;
  data: VolunteerCategoryUpdateWithoutVolunteerShiftInput;
};

export type VolunteerCategoryUpdateWithoutVolunteerInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  VolunteerShift?: Maybe<VolunteerShiftUpdateManyWithoutCategoryNestedInput>;
};

export type VolunteerCategoryUpdateWithoutVolunteerShiftInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  Volunteer?: Maybe<VolunteerUpdateManyWithoutCategoryNestedInput>;
};

export type VolunteerCategoryUpsertWithoutVolunteerInput = {
  update: VolunteerCategoryUpdateWithoutVolunteerInput;
  create: VolunteerCategoryCreateWithoutVolunteerInput;
  where?: Maybe<VolunteerCategoryWhereInput>;
};

export type VolunteerCategoryUpsertWithoutVolunteerShiftInput = {
  update: VolunteerCategoryUpdateWithoutVolunteerShiftInput;
  create: VolunteerCategoryCreateWithoutVolunteerShiftInput;
  where?: Maybe<VolunteerCategoryWhereInput>;
};

export type VolunteerCategoryWhereInput = {
  AND?: Maybe<Array<VolunteerCategoryWhereInput>>;
  OR?: Maybe<Array<VolunteerCategoryWhereInput>>;
  NOT?: Maybe<Array<VolunteerCategoryWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  Volunteer?: Maybe<VolunteerListRelationFilter>;
  VolunteerShift?: Maybe<VolunteerShiftListRelationFilter>;
};

export type VolunteerCategoryWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<VolunteerCategoryWhereInput>>;
  OR?: Maybe<Array<VolunteerCategoryWhereInput>>;
  NOT?: Maybe<Array<VolunteerCategoryWhereInput>>;
  name?: Maybe<StringFilter>;
  Volunteer?: Maybe<VolunteerListRelationFilter>;
  VolunteerShift?: Maybe<VolunteerShiftListRelationFilter>;
};

export type VolunteerCreateInput = {
  category_id: Scalars["Float"];
  event_attendee_id: Scalars["Float"];
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerCreateManyCategoryInput = {
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
  event_attendee_id: Scalars["Int"];
};

export type VolunteerCreateManyCategoryInputEnvelope = {
  data: Array<VolunteerCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerCreateManyEvent_AttendeeInput = {
  id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
  category_id: Scalars["Int"];
};

export type VolunteerCreateManyEvent_AttendeeInputEnvelope = {
  data: Array<VolunteerCreateManyEvent_AttendeeInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<VolunteerCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<VolunteerCreateOrConnectWithoutCategoryInput>>;
  createMany?: Maybe<VolunteerCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<VolunteerWhereUniqueInput>>;
};

export type VolunteerCreateNestedManyWithoutEvent_AttendeeInput = {
  create?: Maybe<Array<VolunteerCreateWithoutEvent_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerCreateOrConnectWithoutEvent_AttendeeInput>
  >;
  createMany?: Maybe<VolunteerCreateManyEvent_AttendeeInputEnvelope>;
  connect?: Maybe<Array<VolunteerWhereUniqueInput>>;
};

export type VolunteerCreateOrConnectWithoutCategoryInput = {
  where: VolunteerWhereUniqueInput;
  create: VolunteerCreateWithoutCategoryInput;
};

export type VolunteerCreateOrConnectWithoutEvent_AttendeeInput = {
  where: VolunteerWhereUniqueInput;
  create: VolunteerCreateWithoutEvent_AttendeeInput;
};

export type VolunteerCreateWithoutCategoryInput = {
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
  event_attendee: EventAttendeeCreateNestedOneWithoutVolunteerInput;
};

export type VolunteerCreateWithoutEvent_AttendeeInput = {
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
  category: VolunteerCategoryCreateNestedOneWithoutVolunteerInput;
};

export type VolunteerListRelationFilter = {
  every?: Maybe<VolunteerWhereInput>;
  some?: Maybe<VolunteerWhereInput>;
  none?: Maybe<VolunteerWhereInput>;
};

export type VolunteerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type VolunteerScalarWhereInput = {
  AND?: Maybe<Array<VolunteerScalarWhereInput>>;
  OR?: Maybe<Array<VolunteerScalarWhereInput>>;
  NOT?: Maybe<Array<VolunteerScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  signed_forms?: Maybe<BoolFilter>;
  event_attendee_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
};

export type VolunteerShiftCreateManyCategoryInput = {
  shift_id: Scalars["Int"];
  event_id: Scalars["Int"];
};

export type VolunteerShiftCreateManyCategoryInputEnvelope = {
  data: Array<VolunteerShiftCreateManyCategoryInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerShiftCreateManyShiftInput = {
  event_id: Scalars["Int"];
  category_id: Scalars["Int"];
};

export type VolunteerShiftCreateManyShiftInputEnvelope = {
  data: Array<VolunteerShiftCreateManyShiftInput>;
  skipDuplicates?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerShiftCreateNestedManyWithoutCategoryInput = {
  create?: Maybe<Array<VolunteerShiftCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerShiftCreateOrConnectWithoutCategoryInput>
  >;
  createMany?: Maybe<VolunteerShiftCreateManyCategoryInputEnvelope>;
  connect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
};

export type VolunteerShiftCreateNestedManyWithoutShiftInput = {
  create?: Maybe<Array<VolunteerShiftCreateWithoutShiftInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerShiftCreateOrConnectWithoutShiftInput>
  >;
  createMany?: Maybe<VolunteerShiftCreateManyShiftInputEnvelope>;
  connect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
};

export type VolunteerShiftCreateOrConnectWithoutCategoryInput = {
  where: VolunteerShiftWhereUniqueInput;
  create: VolunteerShiftCreateWithoutCategoryInput;
};

export type VolunteerShiftCreateOrConnectWithoutShiftInput = {
  where: VolunteerShiftWhereUniqueInput;
  create: VolunteerShiftCreateWithoutShiftInput;
};

export type VolunteerShiftCreateWithoutCategoryInput = {
  event_id: Scalars["Int"];
  shift: ShiftCreateNestedOneWithoutVolunteerShiftInput;
};

export type VolunteerShiftCreateWithoutShiftInput = {
  event_id: Scalars["Int"];
  category: VolunteerCategoryCreateNestedOneWithoutVolunteerShiftInput;
};

export type VolunteerShiftListRelationFilter = {
  every?: Maybe<VolunteerShiftWhereInput>;
  some?: Maybe<VolunteerShiftWhereInput>;
  none?: Maybe<VolunteerShiftWhereInput>;
};

export type VolunteerShiftScalarWhereInput = {
  AND?: Maybe<Array<VolunteerShiftScalarWhereInput>>;
  OR?: Maybe<Array<VolunteerShiftScalarWhereInput>>;
  NOT?: Maybe<Array<VolunteerShiftScalarWhereInput>>;
  shift_id?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
};

export type VolunteerShiftUpdateManyMutationInput = {
  event_id?: Maybe<IntFieldUpdateOperationsInput>;
};

export type VolunteerShiftUpdateManyWithWhereWithoutCategoryInput = {
  where: VolunteerShiftScalarWhereInput;
  data: VolunteerShiftUpdateManyMutationInput;
};

export type VolunteerShiftUpdateManyWithWhereWithoutShiftInput = {
  where: VolunteerShiftScalarWhereInput;
  data: VolunteerShiftUpdateManyMutationInput;
};

export type VolunteerShiftUpdateManyWithoutCategoryNestedInput = {
  create?: Maybe<Array<VolunteerShiftCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerShiftCreateOrConnectWithoutCategoryInput>
  >;
  upsert?: Maybe<
    Array<VolunteerShiftUpsertWithWhereUniqueWithoutCategoryInput>
  >;
  createMany?: Maybe<VolunteerShiftCreateManyCategoryInputEnvelope>;
  set?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  disconnect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  delete?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  connect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  update?: Maybe<
    Array<VolunteerShiftUpdateWithWhereUniqueWithoutCategoryInput>
  >;
  updateMany?: Maybe<
    Array<VolunteerShiftUpdateManyWithWhereWithoutCategoryInput>
  >;
  deleteMany?: Maybe<Array<VolunteerShiftScalarWhereInput>>;
};

export type VolunteerShiftUpdateManyWithoutShiftNestedInput = {
  create?: Maybe<Array<VolunteerShiftCreateWithoutShiftInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerShiftCreateOrConnectWithoutShiftInput>
  >;
  upsert?: Maybe<Array<VolunteerShiftUpsertWithWhereUniqueWithoutShiftInput>>;
  createMany?: Maybe<VolunteerShiftCreateManyShiftInputEnvelope>;
  set?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  disconnect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  delete?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  connect?: Maybe<Array<VolunteerShiftWhereUniqueInput>>;
  update?: Maybe<Array<VolunteerShiftUpdateWithWhereUniqueWithoutShiftInput>>;
  updateMany?: Maybe<Array<VolunteerShiftUpdateManyWithWhereWithoutShiftInput>>;
  deleteMany?: Maybe<Array<VolunteerShiftScalarWhereInput>>;
};

export type VolunteerShiftUpdateWithWhereUniqueWithoutCategoryInput = {
  where: VolunteerShiftWhereUniqueInput;
  data: VolunteerShiftUpdateWithoutCategoryInput;
};

export type VolunteerShiftUpdateWithWhereUniqueWithoutShiftInput = {
  where: VolunteerShiftWhereUniqueInput;
  data: VolunteerShiftUpdateWithoutShiftInput;
};

export type VolunteerShiftUpdateWithoutCategoryInput = {
  event_id?: Maybe<IntFieldUpdateOperationsInput>;
  shift?: Maybe<ShiftUpdateOneRequiredWithoutVolunteerShiftNestedInput>;
};

export type VolunteerShiftUpdateWithoutShiftInput = {
  event_id?: Maybe<IntFieldUpdateOperationsInput>;
  category?: Maybe<VolunteerCategoryUpdateOneRequiredWithoutVolunteerShiftNestedInput>;
};

export type VolunteerShiftUpsertWithWhereUniqueWithoutCategoryInput = {
  where: VolunteerShiftWhereUniqueInput;
  update: VolunteerShiftUpdateWithoutCategoryInput;
  create: VolunteerShiftCreateWithoutCategoryInput;
};

export type VolunteerShiftUpsertWithWhereUniqueWithoutShiftInput = {
  where: VolunteerShiftWhereUniqueInput;
  update: VolunteerShiftUpdateWithoutShiftInput;
  create: VolunteerShiftCreateWithoutShiftInput;
};

export type VolunteerShiftWhereInput = {
  AND?: Maybe<Array<VolunteerShiftWhereInput>>;
  OR?: Maybe<Array<VolunteerShiftWhereInput>>;
  NOT?: Maybe<Array<VolunteerShiftWhereInput>>;
  shift_id?: Maybe<IntFilter>;
  event_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
  shift?: Maybe<ShiftRelationFilter>;
  category?: Maybe<VolunteerCategoryRelationFilter>;
};

export type VolunteerShiftWhereUniqueInput = {
  shift_id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<VolunteerShiftWhereInput>>;
  OR?: Maybe<Array<VolunteerShiftWhereInput>>;
  NOT?: Maybe<Array<VolunteerShiftWhereInput>>;
  event_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
  shift?: Maybe<ShiftRelationFilter>;
  category?: Maybe<VolunteerCategoryRelationFilter>;
};

export type VolunteerUpdateInput = {
  notes?: Maybe<Scalars["String"]>;
  signed_forms?: Maybe<Scalars["Boolean"]>;
};

export type VolunteerUpdateManyMutationInput = {
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  signed_forms?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type VolunteerUpdateManyWithWhereWithoutCategoryInput = {
  where: VolunteerScalarWhereInput;
  data: VolunteerUpdateManyMutationInput;
};

export type VolunteerUpdateManyWithWhereWithoutEvent_AttendeeInput = {
  where: VolunteerScalarWhereInput;
  data: VolunteerUpdateManyMutationInput;
};

export type VolunteerUpdateManyWithoutCategoryNestedInput = {
  create?: Maybe<Array<VolunteerCreateWithoutCategoryInput>>;
  connectOrCreate?: Maybe<Array<VolunteerCreateOrConnectWithoutCategoryInput>>;
  upsert?: Maybe<Array<VolunteerUpsertWithWhereUniqueWithoutCategoryInput>>;
  createMany?: Maybe<VolunteerCreateManyCategoryInputEnvelope>;
  set?: Maybe<Array<VolunteerWhereUniqueInput>>;
  disconnect?: Maybe<Array<VolunteerWhereUniqueInput>>;
  delete?: Maybe<Array<VolunteerWhereUniqueInput>>;
  connect?: Maybe<Array<VolunteerWhereUniqueInput>>;
  update?: Maybe<Array<VolunteerUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: Maybe<Array<VolunteerUpdateManyWithWhereWithoutCategoryInput>>;
  deleteMany?: Maybe<Array<VolunteerScalarWhereInput>>;
};

export type VolunteerUpdateManyWithoutEvent_AttendeeNestedInput = {
  create?: Maybe<Array<VolunteerCreateWithoutEvent_AttendeeInput>>;
  connectOrCreate?: Maybe<
    Array<VolunteerCreateOrConnectWithoutEvent_AttendeeInput>
  >;
  upsert?: Maybe<
    Array<VolunteerUpsertWithWhereUniqueWithoutEvent_AttendeeInput>
  >;
  createMany?: Maybe<VolunteerCreateManyEvent_AttendeeInputEnvelope>;
  set?: Maybe<Array<VolunteerWhereUniqueInput>>;
  disconnect?: Maybe<Array<VolunteerWhereUniqueInput>>;
  delete?: Maybe<Array<VolunteerWhereUniqueInput>>;
  connect?: Maybe<Array<VolunteerWhereUniqueInput>>;
  update?: Maybe<
    Array<VolunteerUpdateWithWhereUniqueWithoutEvent_AttendeeInput>
  >;
  updateMany?: Maybe<
    Array<VolunteerUpdateManyWithWhereWithoutEvent_AttendeeInput>
  >;
  deleteMany?: Maybe<Array<VolunteerScalarWhereInput>>;
};

export type VolunteerUpdateWithWhereUniqueWithoutCategoryInput = {
  where: VolunteerWhereUniqueInput;
  data: VolunteerUpdateWithoutCategoryInput;
};

export type VolunteerUpdateWithWhereUniqueWithoutEvent_AttendeeInput = {
  where: VolunteerWhereUniqueInput;
  data: VolunteerUpdateWithoutEvent_AttendeeInput;
};

export type VolunteerUpdateWithoutCategoryInput = {
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  signed_forms?: Maybe<BoolFieldUpdateOperationsInput>;
  event_attendee?: Maybe<EventAttendeeUpdateOneRequiredWithoutVolunteerNestedInput>;
};

export type VolunteerUpdateWithoutEvent_AttendeeInput = {
  notes?: Maybe<StringFieldUpdateOperationsInput>;
  signed_forms?: Maybe<BoolFieldUpdateOperationsInput>;
  category?: Maybe<VolunteerCategoryUpdateOneRequiredWithoutVolunteerNestedInput>;
};

export type VolunteerUpsertWithWhereUniqueWithoutCategoryInput = {
  where: VolunteerWhereUniqueInput;
  update: VolunteerUpdateWithoutCategoryInput;
  create: VolunteerCreateWithoutCategoryInput;
};

export type VolunteerUpsertWithWhereUniqueWithoutEvent_AttendeeInput = {
  where: VolunteerWhereUniqueInput;
  update: VolunteerUpdateWithoutEvent_AttendeeInput;
  create: VolunteerCreateWithoutEvent_AttendeeInput;
};

export type VolunteerWhereInput = {
  AND?: Maybe<Array<VolunteerWhereInput>>;
  OR?: Maybe<Array<VolunteerWhereInput>>;
  NOT?: Maybe<Array<VolunteerWhereInput>>;
  id?: Maybe<IntFilter>;
  notes?: Maybe<StringFilter>;
  signed_forms?: Maybe<BoolFilter>;
  event_attendee_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
  event_attendee?: Maybe<EventAttendeeRelationFilter>;
  category?: Maybe<VolunteerCategoryRelationFilter>;
};

export type VolunteerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  AND?: Maybe<Array<VolunteerWhereInput>>;
  OR?: Maybe<Array<VolunteerWhereInput>>;
  NOT?: Maybe<Array<VolunteerWhereInput>>;
  notes?: Maybe<StringFilter>;
  signed_forms?: Maybe<BoolFilter>;
  event_attendee_id?: Maybe<IntFilter>;
  category_id?: Maybe<IntFilter>;
  event_attendee?: Maybe<EventAttendeeRelationFilter>;
  category?: Maybe<VolunteerCategoryRelationFilter>;
};
