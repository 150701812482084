import { GetHackersQuery } from "src/api/sponsors/getHackers.generated";
import { FieldAnswerWhereInput, QueryMode } from "src/api/types.generated";
// import { HackerFragmentFragment } from "src/shared/contexts/HackerContext/graphql/hackerFragment.generated";
import { Field } from "src/shared/contexts/HackerContext/types";
import { getFieldAnswer, HackerAPIFile } from "src/shared/utils/hackerapi";

import { THackerProfile } from "./components/HackerProfile/types";
import { HACKER_FILTERS_TO_FIELDS } from "./constants";

export type THackerFilter = {
  type: "school" | "program" | "starred" | "gradYear";
  value: string[] | boolean;
};

const prepareFieldEqualFilter = (filter: THackerFilter) => {
  const field = HACKER_FILTERS_TO_FIELDS[filter.type] ?? "";
  const combinedFilterValues =
    filter.value instanceof Array
      ? filter.value.map((value) => ({
          AND: [
            { field: { is: { slug: { equals: field } } } },
            {
              value: {
                contains: value,
              },
            },
          ],
        }))
      : [];

  const graphQLQuery = {
    claim: {
      is: {
        field_answers: {
          some: {
            OR: [...combinedFilterValues],
          },
        },
      },
    },
  };

  return graphQLQuery;
};

// Compute server-side filters
/* eslint-disable @typescript-eslint/camelcase */
export const parseFilters = (
  filters: THackerFilter[],
  companyClaimId?: number,
  queryString?: string
): FieldAnswerWhereInput[] => {
  if (!companyClaimId) return [];
  const fieldAnswerFilters: FieldAnswerWhereInput[] = [];
  filters.forEach((filter: THackerFilter) => {
    const field = HACKER_FILTERS_TO_FIELDS[filter.type] ?? "";
    switch (filter.type) {
      case "school":
        return fieldAnswerFilters.push(prepareFieldEqualFilter(filter));
      case "program":
        return fieldAnswerFilters.push(prepareFieldEqualFilter(filter));
      case "gradYear":
        fieldAnswerFilters.push({
          claim: {
            is: {
              field_answers: {
                some: {
                  AND: [
                    {
                      field: {
                        is: { slug: { equals: field } },
                      },
                    },
                    { value: { gte: filter.value[0] } },
                    { value: { lte: filter.value[1] } },
                  ],
                },
              },
            },
          },
        });
        return;
      case "starred":
        return;
      default:
        return;
    }
  });

  if (queryString && queryString.length > 0) {
    fieldAnswerFilters.push({
      claim: {
        is: {
          OR: [
            {
              user: {
                is: {
                  name: {
                    contains: queryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              user: {
                is: {
                  email: {
                    contains: queryString,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
            },
            {
              name: {
                contains: queryString,
                mode: QueryMode.Insensitive,
              },
            },
          ],
        },
      },
    });
  }

  return fieldAnswerFilters;
};

// export const computeHackerTags = (
//   claimId: number,
//   fields: HackerFragmentFragment["fields"],
//   companyClaimId: number
// ): HackerFilter[] => {
//   const tags: HackerFilter[] = [];

//   Object.values(HackerFilter).forEach((filter: HackerFilter) => {
//     const field = HACKER_FILTERS_TO_FIELDS[filter] ?? "";
//     const fieldAnswer = getFieldAnswer(fields, field);
//     switch (filter) {
//       case HackerFilter.INTERESTED:
//       case HackerFilter.COFFEE_CHAT:
//         if ((fieldAnswer ?? []).includes(companyClaimId.toString()))
//           tags.push(filter);
//         return;
//       case HackerFilter.SEEKING_INTERNSHIP:
//       case HackerFilter.SEEKING_FULLTIME:
//       case HackerFilter.CANADIAN_CITIZEN:
//       case HackerFilter.PERMANENT_RESIDENT:
//         if (fieldAnswer ?? false) tags.push(filter);
//         return;
//       // case HackerFilter.ORGANIZER:
//       //   if (ORGANIZER_CLAIMS.includes(claimId)) tags.push(filter);
//       //   return;
//       default:
//         return;
//     }
//   });

//   return tags;
// };

export const parseHackerProfiles = (
  data: GetHackersQuery | undefined,
  companyClaimId: number
): THackerProfile[] =>
  (
    data?.claims?.map(({ id, fields, user }) => ({
      id,
      name: getFieldAnswer(fields, Field.SHORT_NAME) ?? "",
      graduatingYear: getFieldAnswer(fields, Field.SHORT_GRAD_YEAR) ?? 0,
      school: getFieldAnswer(fields, Field.SHORT_SCHOOL),
      program: getFieldAnswer(fields, Field.SHORT_PROGRAM),
      email: user.email,
      links: Object.values(getFieldAnswer(fields, Field.LONG_LINKS) ?? {}),
      resume: getFieldAnswer(fields, Field.RESUME)?.[0] as HackerAPIFile,
      // tags: computeHackerTags(id, fields, companyClaimId),
      levelOfStudy: getFieldAnswer(fields, Field.SHORT_GRAD_LEVEL),
    })) as THackerProfile[]
  ).filter(
    (h) =>
      !h.email.includes("@hackthenorth.com") && !h.email.includes("@htn.com")
  ) ?? [];
