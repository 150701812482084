/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetMentorshipRequestsQueryVariables = Types.Exact<{
  slug: Types.Scalars["String"];
  menteeId: Types.Scalars["String"];
}>;

export type GetMentorshipRequestsQuery = {
  claims: Array<
    Pick<Types.Claim, "id" | "name" | "created_at"> & {
      stage: Pick<Types.Stage, "slug">;
      fields: Array<
        Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
          answer?: Types.Maybe<
            Pick<Types.FieldAnswer, "id" | "value" | "updated_at">
          >;
        }
      >;
    }
  >;
};

export const GetMentorshipRequestsDocument = gql`
  query GetMentorshipRequests($slug: String!, $menteeId: String!) {
    claims(
      where: {
        pipeline: { is: { slug: { equals: $slug } } }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "mentee_id" } } } }
              { value: { equals: $menteeId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage {
        slug
      }
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
      created_at
    }
  }
`;

/**
 * __useGetMentorshipRequestsQuery__
 *
 * To run a query within a React component, call `useGetMentorshipRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMentorshipRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMentorshipRequestsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      menteeId: // value for 'menteeId'
 *   },
 * });
 */
export function useGetMentorshipRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMentorshipRequestsQuery,
    GetMentorshipRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMentorshipRequestsQuery,
    GetMentorshipRequestsQueryVariables
  >(GetMentorshipRequestsDocument, baseOptions);
}
export function useGetMentorshipRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMentorshipRequestsQuery,
    GetMentorshipRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMentorshipRequestsQuery,
    GetMentorshipRequestsQueryVariables
  >(GetMentorshipRequestsDocument, baseOptions);
}
export type GetMentorshipRequestsQueryHookResult = ReturnType<
  typeof useGetMentorshipRequestsQuery
>;
export type GetMentorshipRequestsLazyQueryHookResult = ReturnType<
  typeof useGetMentorshipRequestsLazyQuery
>;
export type GetMentorshipRequestsQueryResult = ApolloReactCommon.QueryResult<
  GetMentorshipRequestsQuery,
  GetMentorshipRequestsQueryVariables
>;
