import { Spacer, Flex, Spinner } from "@hackthenorth/north";
import { Label, Checkbox, Link } from "north.js";
import React from "react";
// import { OptionsType, OptionTypeBase } from "react-select";
import { default as ReactSelect } from "react-select";

import { useGetFavoritedHackersQuery } from "src/api/sponsors/getFavoritedHackers.generated";
import { CURRENT_EVENT_SLUG } from "src/shared/constants/event";
import { Route } from "src/shared/constants/route";
// import { formatTimeV2 } from "src/shared/utils/formatTime";
import {
  // answersToOptions,
  formatSelectedOptions,
  optionsToAnswers,
  optionsToNorthV2Options,
  // timeToOptionV2,
} from "src/shared/utils/react-select";
// import { CoffeeChatsData } from "src/views/sponsor/perks/sync";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkRepresentatives, PerkSelect } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription, TextInputTitle } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { TIME_OPTIONS, DURATION_OPTIONS } from "./constants";

export const COFFEE_CHATS_VALIDATION_FIELDS: (keyof SponsorPerksData)[] = [
  "coffee_chats_start_time",
  "coffee_chats_duration",
];

export const CoffeeChats: React.FC<PerkData> = ({
  getState,
  updateState,
  updateStates,
  isReadOnly,
}) => {
  const names = getState("coffee_chats_representatives") ?? [];
  const emails = getState("coffee_chats_representatives_emails") ?? [];
  const pronouns = getState("coffee_chats_representatives_pronouns") ?? [];

  const resetRows = () =>
    updateStates([
      ["coffee_chats_representatives", Array(5).fill("")],
      ["coffee_chats_representatives_emails", Array(5).fill("")],
      ["coffee_chats_representatives_pronouns", Array(5).fill("")],
    ]);

  const addRow = () => {
    updateStates([
      ["coffee_chats_representatives", [...names, ""]],
      ["coffee_chats_representatives_emails", [...emails, ""]],
      ["coffee_chats_representatives_pronouns", [...pronouns, ""]],
    ]);
  };

  const deleteRow = (i: number) => {
    updateStates([
      [
        "coffee_chats_representatives",
        [...names.slice(0, i), ...names.slice(i + 1)],
      ],
      [
        "coffee_chats_representatives_emails",
        [...emails.slice(0, i), ...emails.slice(i + 1)],
      ],
      [
        "coffee_chats_representatives_pronouns",
        [...pronouns.slice(0, i), ...pronouns.slice(i + 1)],
      ],
    ]);
  };

  const updateNames = (val: string[]) =>
    updateState("coffee_chats_representatives", val);

  const updateEmails = (val: string[]) =>
    updateState("coffee_chats_representatives_emails", val);

  const updatePronouns = (val: string[]) =>
    updateState("coffee_chats_representatives_pronouns", val);

  const { data, loading: fetchingHackers } = useGetFavoritedHackersQuery({
    variables: {
      // favoritedHackerIds:
      //   company?.favorited_hackers?.map((hackerId) => parseInt(hackerId)) ?? [],
      eventSlug: CURRENT_EVENT_SLUG,
      favoritedHackerIds:
        getState("coffee_chats_favorited_hackers")?.map((id: any) =>
          parseInt(id)
        ) ?? [],
    },
  });

  const hackerOptions =
    data?.claims.map((hacker) => ({
      label:
        hacker.fields_HACKTHENORTH2024_APPLICATIONS.answers.short_name.value ??
        "",
      value: hacker.id.toString(),
    })) ?? [];

  const selectedHackers = hackerOptions.filter((option) =>
    getState("coffee_chats_hackers")?.includes(option.value)
  );

  return (
    <>
      <Header
        title="Coffee Chats"
        subtitle="This perk is to schedule coffee chats with hackers."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("coffee_chats_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("coffee_chats_status") ?? "Incomplete"}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.COFFEE_CHATS}
        readOnly={isReadOnly}
      >
        <TextInputTitle style={{ fontSize: "20px" }}>Start time</TextInputTitle>
        <Spacer height={8} />
        <TextDescription>
          Choose a time block to host your coffee chats.
        </TextDescription>
        <Spacer height={8} />
        <TextDescription style={{ fontStyle: "italic" }}>
          Note that all times are in Eastern Daylight Time (EDT).
        </TextDescription>
        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("coffee_chats_start_time", e.target.value)
          }
          value={getState("coffee_chats_start_time") ?? ""}
          isReadOnly={isReadOnly}
        >
          {optionsToNorthV2Options(TIME_OPTIONS)}
        </PerkSelect>
        <Spacer height={8} />
        <TextDescription style={{ fontStyle: "italic" }}>
          If none of these times work for you, please email{" "}
          <Link href="mailto:sponsor@hackthenorth.com">
            sponsor@hackthenorth.com
          </Link>
        </TextDescription>
        <Spacer height={24} />
        <TextInputTitle style={{ fontSize: "20px" }}>Duration</TextInputTitle>
        <Spacer height={8} />
        <TextDescription>How long will each Coffee Chat be? </TextDescription>
        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) => updateState("coffee_chats_duration", e.target.value)}
          value={getState("coffee_chats_duration") ?? ""}
          isReadOnly={isReadOnly}
        >
          {optionsToNorthV2Options(
            DURATION_OPTIONS as { label: string; value: string }[]
          )}
        </PerkSelect>
        <Spacer height={24} />
        <PerkRepresentatives
          names={names}
          emails={emails}
          pronouns={pronouns}
          isReadOnly={isReadOnly}
          description={
            <TextDescription>
              Which representatives will be participating in Coffee Chats?
            </TextDescription>
          }
          updateNames={updateNames}
          updateEmails={updateEmails}
          updatePronouns={updatePronouns}
          resetRows={resetRows}
          addRow={addRow}
          deleteRow={deleteRow}
        />
        <Spacer height={24} />
        <TextInputTitle style={{ fontSize: "20px" }}>Hackers</TextInputTitle>
        <Spacer height={8} />
        <TextDescription>
          Which hackers would you like to talk with? Note: This list only shows
          starred hackers who have indicated interest in having a Coffee Chat
          with your company. To star a hacker, head to the{" "}
          <Link href={Route.SPONSOR_RECRUITMENT}>Recruitment page</Link>
        </TextDescription>
        <Spacer height={16} />
        {fetchingHackers ? (
          <Spinner />
        ) : (
          <ReactSelect
            options={hackerOptions}
            isMulti
            isDisabled={isReadOnly}
            onChange={(options) =>
              updateState("coffee_chats_hackers", optionsToAnswers(options))
            }
            value={selectedHackers}
            placeholder="Select favorited hackers"
          />
        )}
        {selectedHackers.length > 0 && (
          <>
            <Spacer height={4} />
            <TextDescription>
              {formatSelectedOptions(selectedHackers)}
            </TextDescription>
          </>
        )}
        <Spacer height={16} />
        <Flex>
          <Checkbox
            checked={
              selectedHackers.length === hackerOptions.length &&
              hackerOptions.length !== 0
            }
            disabled={isReadOnly}
            onChange={(e) =>
              updateState(
                "coffee_chats_hackers",
                e.target.value ? optionsToAnswers(hackerOptions) : []
              )
            }
            label="Select all starred hackers"
          />
        </Flex>
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription>
      Coffee Chats will be 1:1 scheduled chats in a private room between
      representatives from your company and chosen hackers who have indicated
      interest. During the event, each representative will be automatically
      grouped with hackers that you have selected here. There will be a system
      in place to view the queue and invite the next hacker to chat.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      At the scheduled start time of your Coffee Chats, your representatives
      will chat with hackers one by one until all chats finish. Note that
      hackers are essentially “lined up” for each representative to chat with,
      and they will receive an estimated time of when to be ready. For example,
      if your chats start at 3:00 PM and each chat is 10 minutes long, then the
      hacker in the 3rd position in line will expect to begin their chat at 3:30
      PM.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      We recommend your Coffee Chats to last no more than 2 hours, since all
      chats need to be completed in one sitting. For example, if you are
      planning to have 10-minute chats and have 5 representatives participating,
      please select up to 45 hackers to chat with.
    </TextDescription>
  </>
);
